import { useState } from "react";

import { DocumentVersion, File } from "@models";
import { Delete } from "@mui/icons-material";
import { ConfirmationModal } from "src/components/ConfirmationModal";
import GenericButton from "src/components/GenericButton/GenericButton";

export const DeleteButton = ({
  file,
  handleDeleteFile,
}: {
  file: DocumentVersion["file"] | File;
  handleDeleteFile: () => void;
}) => {
  if (!file) {
    return null;
  }

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleDeleteClick = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmationOpen(false);
  };

  return (
    <>
      <GenericButton onClick={handleDeleteClick}>
        <Delete fontSize="small" />
      </GenericButton>
      <div className="flex items-center gap-2">
        {deleteConfirmationOpen && (
          <ConfirmationModal
            key="confirm-delete"
            title="Delete document"
            confirmText="Confirm"
            content="Are you sure you want to delete this file?"
            onAbort={handleDeleteCancel}
            onConfirm={handleDeleteFile}
            open={deleteConfirmationOpen}
          />
        )}
      </div>
    </>
  );
};

import { useEffect } from "react";

export const useKeyboardShortcuts = (
  keyToFunctionMap: Record<string, () => void>,
  deps: any[] = []
) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const keyIdentifier = event.key.toLowerCase();

      // Special case for Escape key - allow it to work even when input is focused
      if (event.key === "Escape") {
        const keyFunction = keyToFunctionMap[event.key];
        if (keyFunction) {
          keyFunction();
          event.preventDefault();
          return;
        }
      }

      // Check if the active element is an input-like element
      const activeElement = document.activeElement;
      const isInputFocused =
        activeElement instanceof HTMLElement &&
        (activeElement.isContentEditable ||
          activeElement.tagName === "INPUT" ||
          activeElement.tagName === "TEXTAREA" ||
          activeElement.tagName === "SELECT");

      // If an input element is focused, don't trigger other shortcuts
      if (isInputFocused) {
        return;
      }

      const keyFunction = keyToFunctionMap[keyIdentifier];
      if (keyFunction) {
        keyFunction();
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [keyToFunctionMap, ...deps]);
};

import { ROUTES } from "@config";
import { useDeleteFileMutation } from "@hooks";
import { DocumentVersion, File } from "@models";
import { fillRouteWithSlugs } from "@utils";
import { useNavigate, useParams } from "react-router-dom";
import DocViewerTopBar from "src/components/DocViewer/components/docViewerTopBar/DocViewerTopBar";
import NoPreview from "src/components/DocViewer/components/fileDocViewer/NoPreview";
import { RenderImg } from "src/components/DocViewer/components/fileDocViewer/RenderImg";
import { RenderPDF } from "src/components/DocViewer/components/fileDocViewer/RenderPDF";
import { handleFileDownload } from "src/components/DocViewer/components/helpers/fileDownload";
import { ROUTE_SLUGS } from "src/config/navigation/routes";

export const FileViewer = ({
  file,
  fileBlob,
  fileId,
}: {
  file: DocumentVersion["file"] | File;
  fileBlob: Blob;
  fileId?: string;
}) => {
  const { orgId = "", deviceId = "" } = useParams();

  if (!file) return null;

  const navigate = useNavigate();

  const deleteFileMutation = useDeleteFileMutation();

  const handleDeleteClick = async () => {
    if (!fileId) return;

    try {
      await deleteFileMutation.mutateAsync({
        orgId,
        fileId,
        deviceId,
      });
      navigate(
        fillRouteWithSlugs(ROUTES.QMS, {
          [ROUTE_SLUGS.ORG_ID]: orgId,
          [ROUTE_SLUGS.DEVICE_ID]: deviceId,
        })
      );
    } catch (error) {
      console.error("Failed to delete file:", error);
    }
  };

  return (
    <div className=" flex w-full flex-1 flex-col gap-y-2">
      <DocViewerTopBar
        handleFileDownload={() =>
          handleFileDownload(fileBlob, file.filename || "file")
        }
        handleFileDelete={handleDeleteClick}
        file={file}
      />

      {file.mimeType?.includes("image") && fileBlob ? (
        <RenderImg file={file} fileBlob={fileBlob} />
      ) : file.mimeType?.includes("pdf") && fileBlob ? (
        <RenderPDF fileBlob={fileBlob} />
      ) : (
        <NoPreview file={file} />
      )}
    </div>
  );
};

import { BuildOutlined, PrecisionManufacturingOutlined, WebOutlined } from "@mui/icons-material";
import { HARDWARE_DEMO_DEVICE } from "./hardware-demo-device";
import { SOFTWARE_DEMO_DEVICE } from "./software-demo-device";
import { SYSTEM_DEMO_DEVICE } from "./system-demo-device";
export const ActiveDemoDevices = {
  HARDWARE: {
    device: HARDWARE_DEMO_DEVICE,
    icon: BuildOutlined,
  },
  SOFTWARE: {
    device: SOFTWARE_DEMO_DEVICE,
    icon: WebOutlined,
  },
  SYSTEM: {
    device: SYSTEM_DEMO_DEVICE,
    icon: PrecisionManufacturingOutlined,
  },
};
export type ActiveDemoDeviceKeys = keyof typeof ActiveDemoDevices;

import { ContentCopy } from "@mui/icons-material";
import { useState } from "react";
import GenericButton from "src/components/GenericButton/GenericButton";
import { UPGRADE_PLAN_TOOLTIP } from "../../../../../utils";
import { handleCopy } from "../../../utils/handlers";

export const CopyButton = ({
  generatedDoc,
  isDocEnabled,
}: {
  generatedDoc: string;
  isDocEnabled: boolean;
}) => {
  const [loadingClipboard, setLoadingClipboard] = useState(false);
  const [_, setCopySuccess] = useState(false);

  return (
    <GenericButton
      onClick={() =>
        handleCopy(generatedDoc, setLoadingClipboard, setCopySuccess)
      }
      isDisabled={!isDocEnabled || loadingClipboard}
      tooltipContent={UPGRADE_PLAN_TOOLTIP}
      hideTooltip={isDocEnabled}
    >
      <ContentCopy fontSize="small" />
    </GenericButton>
  );
};

export default CopyButton;

import { DragHandleOutlined } from "@mui/icons-material";
import { Button, LinearProgress, Menu, MenuItem } from "@mui/material";
import {
  DataGridPremium,
  DataGridPremiumProps,
  GRID_REORDER_COL_DEF,
  GridCellEditStartReasons,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridRowClassNameParams,
  GridRowModel,
  GridRowOrderChangeParams,
  GridRowSelectionModel,
  GridSlots,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import json2md from "json2md";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { StepValue, TableElement } from "../../../types";
// Put props outside of the component to avoid re-creating them on each render
// https://mui.com/x/react-data-grid/performance/#extract-static-objects-and-memoize-root-props
const slots = {
  // noRowsOverlay: LoadingOrEmptyOverlay,
  loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
  rowReorderIcon: DragHandleOutlined,
};

const autosizeOptions = {
  includeOutliers: false,
  includeHeaders: true,
  expand: true,
};

type Props = {
  value: string;
  element: TableElement;
  isFetchingSuggestion: boolean;
  onChange: (id: string, value: StepValue) => void;
};
export const Table = forwardRef(
  ({ element, value, isFetchingSuggestion, onChange }: Props, ref) => {
    const apiRef = useGridApiRef();

    const copyTable = () => {
      const rows = apiRef.current.getRowModels();
      const headers = columns
        .filter((col) => col.field !== "__reorder__")
        .map((col) => col.headerName);

      const tableData = [
        headers.join("\t"),
        ...Array.from(rows.values()).map((row: GridRowModel) =>
          headers
            .filter((header) => header !== undefined)
            .map((header) => row[header] || "")
            .join("\t")
        ),
      ].join("\n");

      navigator.clipboard
        .writeText(tableData)
        .then(() => {
          // Optionally show a success message
        })
        .catch((err) => {
          console.error("Failed to copy table: ", err);
        });
    };

    useImperativeHandle(ref, () => ({
      copyTable,
    }));

    const columns: GridColDef[] = useMemo(
      () =>
        [
          {
            ...GRID_REORDER_COL_DEF,
            field: "__reorder__",
            maxWidth: 30,
          },
        ].concat(
          element.options.columns.map((col) => ({
            field: col.name,
            headerName: col.name,
            flex: col.options?.flex ?? 1,
            type: col.options?.type ?? "string",
            valueOptions: col.options?.valueOptions,
            editable: col.options?.editable ?? true,
            // needs to be set but I forgot why. Sorry.
            maxWidth: col.options?.maxWidth ?? 10000,
          }))
        ),
      [element.options.columns]
    );

    /**
     *
     * @param {string[]} columnDefs  - array of column definitions
     */
    const parseTable = (
      columnDefs: {
        name: string;
        options?: {
          flex?: number;
        };
      }[],
      value: string
    ) => {
      const lines = value.split("\n").filter((line) => line.trim() !== "");

      // Remove the separator line
      lines.splice(1, 1);

      const rows = lines.slice(1);

      if (rows.length === 0) {
        return [];
      }

      const processedRows: {
        index: number;
        parsedRow: Record<string, string>;
      }[] = [];

      rows.forEach((row, rowIndex) => {
        let cells = row
          .split("|")
          // We need to trim the first and last values because they are empty strings
          .slice(1, -1)
          .map((cell) => cell.trim());

        cells = [
          ...cells,
          // fill the remaining cells with empty strings
          ...Array(columnDefs.length - cells.length).fill(""),
        ];

        const parsedRow: Record<string, string> = {};
        cells.forEach((cell, columnIndex) => {
          // If the column is the id column, set the id based on the column value
          // if (index == indexOf(columnDefs, element.options.idColumn)) {
          //   parsedRow["id"] = column;
          // }

          parsedRow[columnDefs[columnIndex].name] = cell;
          parsedRow["id"] = rowIndex.toString();
        });

        processedRows.push({ index: rowIndex, parsedRow });
      });

      return processedRows.map((row) => row.parsedRow);
    };

    let rowData: Record<string, string>[] = useMemo(
      () => parseTable(element.options.columns, value),
      [value]
    );

    // update the layout when the data changes
    useEffect(() => {
      apiRef.current.autosizeColumns(autosizeOptions);
    }, [rowData]);

    const [selectedRow, setSelectedRow] = useState<string>();

    const [contextMenu, setContextMenu] = useState<{
      mouseX: number;
      mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();

      const id = event.currentTarget.getAttribute("data-id");

      if (!id) return;

      setSelectedRow(id);
      setContextMenu(
        contextMenu === null
          ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
          : null
      );
    };

    /**
     * Close the context menu
     */
    const handleClose = () => {
      setContextMenu(null);
    };

    /**
     * Convert the row data to markdown and update the element value
     * @param data - the updated row data
     */
    const handleUpdateRowData = (data: Record<string, string>[]) => {
      const markdown = json2md({
        table: {
          headers: element.options.columns.map((col) => col.name),
          rows: data,
        },
      });

      onChange(element.id, { answer: markdown });
    };

    const processRowUpdate: NonNullable<
      DataGridPremiumProps["processRowUpdate"]
    > = (newRow, oldRow) => {
      const rowIndex = rowData.findIndex((row) => row.id === oldRow.id);
      // Update the row with the new values and set the state
      const updatedRowData = [
        ...rowData.slice(0, rowIndex),
        newRow,
        ...rowData.slice(rowIndex + 1),
      ];

      // we also need to set the local rowData because events like clipboard paste with multiple updated rows will fire multiple times and the rowData will be out of sync if not updated right away
      rowData = updatedRowData;
      handleUpdateRowData(updatedRowData);

      return newRow;
    };

    const handleAddRow = (e: React.MouseEvent) => {
      e.stopPropagation();

      // If no row is selected, insert a new row at the end
      const rowIndex = selectedRow
        ? rowData.findIndex((row) => row.id === selectedRow)
        : rowData.length - 1;

      // Insert a new empty object
      const updatedRowData = [
        ...rowData.slice(0, rowIndex + 1),
        // Add a new row with empty values
        {
          id: Date.now().toString(),
          ...element.options.columns.reduce(
            (obj, col) => {
              obj[col.name] = "";
              return obj;
            },
            {} as Record<string, string>
          ),
        },
        ...rowData.slice(rowIndex + 1),
      ];
      handleUpdateRowData(updatedRowData);
      handleClose();
    };

    const handleDeleteRow = (e: React.MouseEvent) => {
      e.stopPropagation();

      handleUpdateRowData(rowData.filter((row) => row.id !== selectedRow));
      handleClose();
    };

    const [rowSelectionModel, setRowSelectionModel] =
      useState<GridRowSelectionModel>([]);

    const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
      const rowsClone = [...rowData];
      const row = rowsClone.splice(params.oldIndex, 1)[0];
      rowsClone.splice(params.targetIndex, 0, row);

      handleUpdateRowData(rowsClone);
      // We need to reset the selection model and update the cell focus since the id changes and the selection model is based on the id.
      // The result otherwise would be that the old cell and row would stay selected since they now have the id of the dragged row and thus create confusion
      setRowSelectionModel([]);
      apiRef.current.setCellFocus(params.targetIndex.toString(), "__reorder__");
    };

    const getRowClassName = (params: GridRowClassNameParams) =>
      params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd";

    const [cellModesModel, setCellModesModel] =
      React.useState<GridCellModesModel>({});

    const handleCellClick = (
      params: GridCellParams,
      event: React.MouseEvent
    ) => {
      if (!params.isEditable) {
        return;
      }

      // Ignore portal
      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    };

    const handleCellModesModelChange = (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    };

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DataGridPremium
          apiRef={apiRef}
          rows={rowData}
          autoHeight
          columns={columns}
          ignoreValueFormatterDuringExport
          getRowHeight={() => "auto"}
          autosizeOnMount
          cellSelection
          rowReordering
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={handleCellClick}
          processRowUpdate={processRowUpdate}
          disableColumnMenu
          slots={slots}
          loading={isFetchingSuggestion}
          scrollbarSize={0}
          slotProps={{
            row: {
              onContextMenu: handleContextMenu,
            },
          }}
          sx={{
            lineHeight: 1.5,
            fontSize: "1rem",
            pointerEvents: isFetchingSuggestion ? "none" : "auto",
            ".MuiDataGrid-cell": {
              backgroundColor: "unset !important",
            },

            ".MuiDataGrid-cell.MuiDataGrid-cell--editing": {
              backgroundColor: "unset",
              boxShadow: "unset",
            },
            "& .MuiDataGrid-row:has(.MuiDataGrid-cell--editing):hover": {
              backgroundColor: "transparent",
            },

            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },

            ".MuiInputBase-input": {
              paddingLeft: "0",
            },

            ".MuiDataGrid-overlayWrapper": {
              height: isFetchingSuggestion ? "4px" : 100,
            },
            // ".MuiDataGrid-overlayWrapperInner": {
            //   height: "200px  !important",
            // },
            ".MuiDataGrid-cell:not(:last-child), .MuiDataGrid-columnHeader:not(:last-child)":
              {
                borderRight: "1px solid rgb(224, 224, 224)",
              },

            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "9px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
            ".MuiDataGrid-editInputCell": {
              px: "9px",
              fontSize: "1rem",
              lineHeight: "1.5em",
              py: "0",
            },
            ".MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within":
              {
                outline: "none",
              },
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
              whiteSpace: "wrap",
            },

            ".MuiDataGrid-cellContent, .MuiDataGrid-columnHeaderTitle": {
              //  textOverflow: "ellipsis",
              whiteSpace: "wrap",
              // wordWrap: "break-word",
            },
            // ".MuiDataGrid-virtualScroller": {
            //   overflow: "hidden",
            // },
            ".MuiDataGrid-row:last-child .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            ".MuiDataGrid-container--top [role=row]": {
              backgroundColor: "transparent",
            },
          }}
          hideFooter
          onRowOrderChange={handleRowOrderChange}
          getRowClassName={getRowClassName}
          autosizeOptions={autosizeOptions}
          disableRowSelectionOnClick
          onRowSelectionModelChange={(
            newRowSelectionModel: GridRowSelectionModel
          ): void => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          onCellEditStart={(params, event) => {
            if (params.reason === GridCellEditStartReasons.printableKeyDown) {
              event.defaultMuiPrevented = true;
            }
          }}
        />
        {element.options.addRowEnabled !== false && (
          <Button
            variant="text"
            color="inherit"
            disabled={isFetchingSuggestion}
            onClick={handleAddRow}
          >
            Add row
          </Button>
        )}
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          slotProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              },
            },
          }}
        >
          <MenuItem onClick={handleDeleteRow}>Delete row</MenuItem>
          <MenuItem onClick={handleAddRow}>Insert row below</MenuItem>
        </Menu>
      </div>
    );
  }
);

import { ROUTES } from "@config";
import { ExpandMore } from "@mui/icons-material";
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { fillRouteWithSlugs } from "@utils";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import {
  BaseUser,
  DocumentVersionApprover,
  TEMPLATE_TYPE,
} from "../../../../../stores/models";
import ApproversTabContentLoading from "./ApproversTabContentLoading";
import AssignApprovers from "./assignApprovers/AssignApprovers";
import RevisionSummary from "./revisionSummary/RevisionSummary";

type AuthorContentProps = {
  author: BaseUser;
  enableApproversEditing: boolean;
  handleSave: (
    savedApprovers: DocumentVersionApprover[],
    selectedUsers: BaseUser[],
    revisionSummary: string
  ) => void;
  revisionSummary: string;
  saving: boolean;
  savedApprovers: DocumentVersionApprover[];
  savedRevisionSummary?: string;
  selectedUsers: Array<BaseUser | null>;
  setEnableApproversEditing: (b: boolean) => void;
  setRevisionSummary: (s: string) => void;
  setSelectedUsers: (users: Array<BaseUser | null>) => void;
  dependentDocs: [TEMPLATE_TYPE, string][];
  isDemoDevice: boolean;
};

export const AuthorContent = ({
  author,
  enableApproversEditing,
  handleSave,
  revisionSummary,
  saving,
  savedApprovers,
  savedRevisionSummary,
  selectedUsers,
  setEnableApproversEditing: setShouldEnableEditing,
  setRevisionSummary,
  setSelectedUsers,
  dependentDocs,
  isDemoDevice,
}: AuthorContentProps) => {
  const { deviceId = "", orgId = "" } = useParams();

  if (!author) {
    return <ApproversTabContentLoading />;
  }

  const areApproversValid =
    selectedUsers.every((u) => u !== null) &&
    selectedUsers.length === 2 &&
    selectedUsers[0]?.id !== selectedUsers[1]?.id;
  const isSummaryValid = !!revisionSummary;
  const assigneesChanged = selectedUsers.some((u) =>
    savedApprovers.every((a) => a.user.id !== u?.id)
  );
  const summaryChanged = revisionSummary !== savedRevisionSummary;
  const approvalConfigChanged = assigneesChanged || summaryChanged;

  const [seeMore, setSeeMore] = useState<boolean>(false);
  const [agreement, setAgreement] = useState<boolean>(false);

  const shouldCheckForDependencies =
    dependentDocs.length > 0 ? agreement : true;
  const saveEnabled =
    approvalConfigChanged &&
    areApproversValid &&
    isSummaryValid &&
    !saving &&
    shouldCheckForDependencies;

  const handleChange = () => {
    setSeeMore(!seeMore);
  };

  return (
    <>
      <div
        className={`flex flex-col gap-4 mb-4 overflow-auto ${
          seeMore ? "overflow-scroll max-h-[70vh]" : ""
        }`}
      >
        <AssignApprovers
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          enableApproversEditing={enableApproversEditing}
          setEnableApproversEditing={setShouldEnableEditing}
          organizationId={orgId}
          userId={author.id}
          approvers={savedApprovers}
          saving={saving}
          isDemoDevice={isDemoDevice}
        />
        <RevisionSummary
          revisionSummary={revisionSummary}
          setRevisionSummary={setRevisionSummary}
        />
        {dependentDocs.length > 0 && (
          <div className="flex flex-col gap-2">
            <Alert severity="warning">
              <Typography variant="body1" fontWeight={600}>
                Dependency Update Warning
              </Typography>
              <div>
                Because the documents listed below use data from this document,
                you should regenerate and re-approve them.
                <ul className="p-0">
                  <Collapse in={seeMore} collapsedSize={35}>
                    {dependentDocs.map(([template, docName]) => (
                      <li key={template} className="list-inside">
                        <Link
                          to={fillRouteWithSlugs(ROUTES.QMS_OPEN_DOC, {
                            [ROUTE_SLUGS.ORG_ID]: orgId,
                            [ROUTE_SLUGS.DEVICE_ID]: deviceId,
                            [ROUTE_SLUGS.TEMPLATE_ID]: template,
                          })}
                        >
                          {docName}
                        </Link>
                      </li>
                    ))}
                  </Collapse>
                  {dependentDocs.length > 2 && (
                    <div
                      className="flex items-center cursor-pointer mt-3 hover:underline"
                      onClick={handleChange}
                    >
                      <span>Show more</span>
                      <ExpandMore
                        className={` ${seeMore ? "rotate-180" : ""} transition-all`}
                      />
                    </div>
                  )}
                </ul>
              </div>
            </Alert>
            <div>
              <FormGroup>
                <FormControlLabel
                  className="m-0"
                  control={
                    <Checkbox
                      id="agreement"
                      size="small"
                      checked={agreement}
                      onChange={() => setAgreement(!agreement)}
                    />
                  }
                  label="Confirm the dependency update warning above."
                />
              </FormGroup>
            </div>
          </div>
        )}
      </div>
      <Button
        variant="contained"
        disabled={!saveEnabled}
        onClick={() =>
          handleSave(
            savedApprovers,
            selectedUsers.filter((u) => u !== null),
            revisionSummary
          )
        }
        size="large"
      >
        Submit
      </Button>
    </>
  );
};

export default AuthorContent;

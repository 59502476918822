import { TextField } from "@mui/material";
import { AnswerItem, AnswerItemsElement, StepValue } from "@types";
import React from "react";
import DateInput from "src/components/Form/Elements/Date";
import Select from "src/components/Form/Elements/Select";
import RadioButton from "../RadioButton";

interface ElementsMapperProps {
  element: AnswerItemsElement;
  accordion: AnswerItem;
  disabled?: boolean;
  onChange: ({
    fieldKey,
    value,
    accordionIdx,
  }: {
    fieldKey: string;
    value?: StepValue;
    accordionIdx: number;
  }) => void;
  accordionIdx: number;
}

export const AccordionListMapper: React.FC<ElementsMapperProps> = ({
  element,
  accordion,
  disabled = false,
  onChange,
  accordionIdx,
}) => {
  return element.options.fields.map((field) => {
    const fieldValue = accordion?.fields?.[field.fieldKey];

    if (field.transformerConfig?.transformer && accordion?.fields) {
      const transformedValue = field.transformerConfig.transformer(
        Object.entries(accordion.fields).reduce((acc, [key, value]) => {
          return field.transformerConfig?.inputs.includes(key)
            ? { ...acc, [key]: value }
            : acc;
        }, {})
      );

      accordion.fields[field.fieldKey] = transformedValue?.toString() ?? "";
    }

    switch (field.element) {
      case "select":
        return (
          <Select
            key={element.id + field.label}
            disabled={disabled}
            onChange={(_, value) => {
              onChange({
                fieldKey: field.fieldKey,
                value,
                accordionIdx,
              });
            }}
            value={fieldValue}
            style={field.style}
            label={field.label}
            element={{
              ...element,
              type: "select",
              options: {
                label: field.label || "",
                options: (field.options || []).map(
                  (opt) => opt?.value?.toString() ?? ""
                ),
              },
            }}
          />
        );
      case "radioButton":
        return (
          <RadioButton
            key={element.id + field.label}
            element={{
              id: element.id,
              type: "radioButton",
              options: {
                label: field.label,
                containerAlignment: field.containerAlignment,
                options: field.options || [],
              },
            }}
            onChange={(event) =>
              onChange({
                fieldKey: field.fieldKey,
                value: { answer: event.target.value },
                accordionIdx,
              })
            }
            value={fieldValue ?? ""}
            style={field.style}
          />
        );
      case "dateInput":
        return (
          <DateInput
            key={element.id + field.label}
            value={fieldValue}
            onChange={(value) => {
              onChange({
                fieldKey: field.fieldKey,
                value,
                accordionIdx,
              });
            }}
            style={field.style}
            label={field.label}
          />
        );
      default:
        return (
          <TextField
            style={field.style}
            label={field.label}
            key={element.id + field.label}
            id={element.id}
            onChange={(event) => {
              onChange({
                fieldKey: field.fieldKey,
                value: { answer: event.target.value },
                accordionIdx,
              });
            }}
            multiline={!!field.rows}
            fullWidth
            value={fieldValue}
            minRows={field.rows}
            disabled={disabled || field.disabled}
            InputProps={{
              readOnly: field.readOnly,
              disableUnderline: true,
            }}
          />
        );
    }
  });
};

export default AccordionListMapper;

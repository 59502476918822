import { CloseOutlined, SearchOutlined } from "@mui/icons-material";
import { IconButton, InputBase, Tooltip } from "@mui/material";
import { useRef, useState } from "react";
import { useKeyboardShortcuts } from "src/hooks/useKeyboardShortcuts";

export const SearchExplorer = ({
  search,
  setSearch,
}: {
  search: string;
  setSearch: (search: string) => void;
}) => {
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  // Reference to the search input
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useKeyboardShortcuts({
    Escape: () => {
      setSearch("");
      searchInputRef.current?.blur();
    },
    "/": () => {
      if (search === "") {
        searchInputRef.current?.focus();
      }
    },
  });

  const isSearchActive = isSearchFocused || search;

  return (
    <div className="flex items-center w-full flex-1 justify-between">
      <InputBase
        sx={{ flex: 1 }}
        placeholder={"Search documents..."}
        value={isSearchActive ? search : "Document explorer"}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        onFocus={() => setIsSearchFocused(true)}
        onBlur={() => setIsSearchFocused(false)}
        inputProps={{ "aria-label": "search documents" }}
        inputRef={searchInputRef}
      />

      <div className="flex items-center">
        <Tooltip
          title={
            isSearchActive ? (
              <>
                Clear search <kbd>Esc</kbd>
              </>
            ) : (
              <>
                Start search <kbd>/</kbd>
              </>
            )
          }
        >
          <IconButton
            type="button"
            aria-label={isSearchActive ? "clear search" : "search"}
            size="small"
            color="inherit"
            onClick={() => {
              if (isSearchActive) {
                setSearch("");
                searchInputRef.current?.blur();
              } else {
                searchInputRef.current?.focus();
              }
            }}
          >
            {isSearchActive ? (
              <CloseOutlined color="inherit" data-testid="clear-search" />
            ) : (
              <SearchOutlined color="inherit" data-testid="search" />
            )}
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

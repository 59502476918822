import { useCreateDemoDeviceMutation } from "@hooks";
import { Typography } from "@mui/material";
import {
  ActiveDemoDeviceKeys,
  ActiveDemoDevices,
} from "src/components/DeviceCreation/modal-list-items/demo-devices";
import { DemoDevicePicker } from "src/components/DeviceCreation/modal-list-items/DemoDeviceModalComponents/DemoDevicePicker";
import { Loading } from "src/pages";

export const DemoDeviceModalContent = ({ orgId }: { orgId: string }) => {
  const createDemoDeviceMutation = useCreateDemoDeviceMutation();

  const createDemoDevice = (key: ActiveDemoDeviceKeys) => {
    createDemoDeviceMutation.mutate({
      request: ActiveDemoDevices[key].device,
      orgId: orgId,
    });
  };

  return (
    <>
      {createDemoDeviceMutation.isPending ? (
        <div>
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3/4 z-[9999] bg-green-500">
            <div className="bg-white border border-color-primary-medium border-solid rounded-md p-4 flex flex-col items-center justify-center h-20">
              <Typography variant="body1">Creating demo device...</Typography>
              <Loading />
            </div>
          </div>
          <div className="opacity-50 blur">
            <DemoDevicePicker onClick={createDemoDevice} disabled />
          </div>
        </div>
      ) : (
        <DemoDevicePicker onClick={createDemoDevice} />
      )}
    </>
  );
};

import { useAuth0 } from "@auth0/auth0-react";
import { UserUiState, UserUiStateKey } from "@models";
import { useEffect } from "react";
import { useUpdateUserUiStateMutation } from "src/hooks/mutations";
import { useGetUser } from "src/hooks/queries";
import { useStore } from "src/stores";
import { DEMO_COMPLETE_MODAL_KEY, WELCOME_MODAL_KEY } from "src/utils/consts";

/**
 * Optional conditions for when a tooltip/modal should be opened
 * @param userUiState - UserUiState object
 * @returns boolean - true if the tooltip/modal should be opened
 */
const USER_UI_STATE_CONDITIONS: Partial<
  Record<UserUiStateKey, (userUiState: UserUiState | undefined) => boolean>
> = {
  [WELCOME_MODAL_KEY]: (_: UserUiState | undefined) => true, // Always open first if not seen
  [DEMO_COMPLETE_MODAL_KEY]: (userUiState: UserUiState | undefined) =>
    !!userUiState?.approvalTooltipSeen, // Only open if user has seen approval tooltip
};

/**
 * Handle precedence of tooltips/welcome modal, so only one can be open at a time
 * @param databaseId UserUiStateKey (db key) associated with this tooltip
 * @returns
 *  - closeItem() - function to close the tooltip/modal and release the lock
 *  - itemIsOpen - boolean indicating if the tooltip/modal is open (has the lock)
 */
const useUiStateItemLock = (databaseId: UserUiStateKey) => {
  const { isAuthenticated } = useAuth0();
  const { data: user, isLoading: isLoadingUser } = useGetUser(isAuthenticated);
  const { mutate: updateUserUiState } = useUpdateUserUiStateMutation();
  const { uiStore } = useStore();

  const uiStateItemSeenPreviously = user?.userUiState[databaseId];
  const uiStateItemNeedsToOpen =
    !isLoadingUser && uiStateItemSeenPreviously === false;
  const lockAcquired = uiStore.activeUiStateItem === databaseId;
  const itemIsOpen = lockAcquired && uiStateItemNeedsToOpen;

  useEffect(() => {
    // Attempt to acquire lock for tooltip
    // Onboarding takes priority all other tooltips until completed

    // If there's a conditional (USER_UI_STATE_CONDITIONS) lock
    if (
      USER_UI_STATE_CONDITIONS[databaseId]?.(user?.userUiState) &&
      uiStateItemNeedsToOpen
    ) {
      uiStore.setActiveUiStateItem(databaseId);
    } else if (
      // Normal non-conditional lock
      uiStore.activeUiStateItem === undefined &&
      uiStateItemNeedsToOpen &&
      USER_UI_STATE_CONDITIONS[databaseId] === undefined
    ) {
      uiStore.setActiveUiStateItem(databaseId);
    }

    // On unmount remove lock
    return () => {
      if (uiStore.activeUiStateItem === databaseId) {
        uiStore.setActiveUiStateItem(undefined);
      }
    };
  }, [uiStore.activeUiStateItem, uiStateItemNeedsToOpen]);

  const closeItem = () => {
    updateUserUiState({ [databaseId]: true });
    uiStore.setActiveUiStateItem(undefined);
  };

  return { closeItem, itemIsOpen };
};

export default useUiStateItemLock;

export type RoadmapStageId =
  | "planning"
  | "design-and-development"
  | "verification-and-validation"
  | "regulatory-preparation"
  | "submission"
  | "post-market"
  | "qms";

export enum ROADMAP_STAGE_ID {
  QMS = "qms",
  PLANNING = "planning",
  DESIGN_AND_DEVELOPMENT = "design-and-development",
  VERIFICATION_AND_VALIDATION = "verification-and-validation",
  REGULATORY_PREPARATION = "regulatory-preparation",
  SUBMISSION = "submission",
  POST_MARKET = "post-market",
}

export type RoadmapStageConfig = {
  title: string;
  id: ROADMAP_STAGE_ID;
  disabled?: boolean;
};

export const ROADMAP_STAGES: RoadmapStageConfig[] = [
  {
    title: "QMS",
    id: ROADMAP_STAGE_ID.QMS,
    disabled: false,
  },
  {
    title: "Planning",
    id: ROADMAP_STAGE_ID.PLANNING,
    disabled: false,
  },
  {
    title: "Design and Development",
    id: ROADMAP_STAGE_ID.DESIGN_AND_DEVELOPMENT,
    disabled: false,
  },
  {
    title: "Verification and Validation",
    id: ROADMAP_STAGE_ID.VERIFICATION_AND_VALIDATION,
    disabled: false,
  },
  {
    title: "Regulatory Preparation",
    id: ROADMAP_STAGE_ID.REGULATORY_PREPARATION,
    disabled: false,
  },
  {
    title: "Submission",
    id: ROADMAP_STAGE_ID.SUBMISSION,
    disabled: false,
  },
  {
    title: "Post Market",
    id: ROADMAP_STAGE_ID.POST_MARKET,
    disabled: false,
  },
];

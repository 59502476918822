import { ROUTES } from "@config";
import { Alert, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { CustomDeviceModalItemSection } from "src/components/DeviceCreation/modal-list-items/CustomDeviceModalComponents/CustomDeviceModalItemSection";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { fillRouteWithSlugs } from "src/utils/navigation";

export const FreePlanLimitModalItemContent = ({ orgId }: { orgId: string }) => {
  return (
    <CustomDeviceModalItemSection>
      <Typography variant="h6">Device limit reached</Typography>
      <Alert severity="warning">
        The free plan allows you to create only one device. Please upgrade to a
        paid plan to create additional devices.
      </Alert>
      <Button
        component={Link}
        to={fillRouteWithSlugs(ROUTES.SUBSCRIPTION, {
          [ROUTE_SLUGS.ORG_ID]: orgId,
        })}
        variant="contained"
        color="primary"
        className="self-end"
      >
        Upgrade
      </Button>
    </CustomDeviceModalItemSection>
  );
};

import { ASSISTANT_CONFIG, documentIsHidden } from "../config";
import {
  Device,
  Document,
  DocumentAnswer,
  DocumentVersion,
  DocumentVersionApproverStatus,
  TEMPLATE_TYPE,
  User,
} from "../stores/models";
import {
  AnswerItem,
  DocumentDataKey,
  DocumentMetadata,
  TemplateElement,
} from "../types";

import { useGetUserByIdAsync } from "@hooks";
import Handlebars from "handlebars";
import { chain, orderBy } from "lodash";
import moment from "moment";
import { getFile } from "src/services";
import { getOrgFromUser } from "src/utils/user";
import { Entries } from "type-fest";
import {
  getDocVersionRevisionNumber,
  getDocVersionsWithStatus,
  getDocumentIdentifier,
  getElementConfigByDataKey,
  getLatestDocumentVersion,
  hasHardware,
  hasSoftware,
  hasSterileComponent,
  isSoftwareOnly,
  loadDocumentReferences,
} from ".";

// Register handlebars helpers
Handlebars.registerHelper("ifEquals", (arg1, arg2, options) => {
  return arg1 == arg2 ? options.fn() : options.inverse();
});

const reduceOp = function (args: any, reducer: (a: any, b: any) => boolean) {
  args = Array.from(args);
  args.pop(); // => options
  var first = args.shift();
  return args.reduce(reducer, first);
};

Handlebars.registerHelper({
  eq: function () {
    return reduceOp(arguments, (a, b) => a === b);
  },
  ne: function () {
    return reduceOp(arguments, (a, b) => a !== b);
  },
  lt: function () {
    return reduceOp(arguments, (a, b) => a < b);
  },
  gt: function () {
    return reduceOp(arguments, (a, b) => a > b);
  },
  lte: function () {
    return reduceOp(arguments, (a, b) => a <= b);
  },
  gte: function () {
    return reduceOp(arguments, (a, b) => a >= b);
  },
  and: function () {
    return reduceOp(arguments, (a, b) => a && b);
  },
  or: function () {
    return reduceOp(arguments, (a, b) => a || b);
  },
});

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const imageFileToBase64 = async (orgId: string, fileId: string) => {
  const fileResponse = await getFile({
    orgId,
    fileId,
  });

  if (fileResponse.data) {
    return await blobToBase64(fileResponse.data);
  }
};

export const generateDocument = async ({
  orgId,
  user,
  device,
  document,
  documentVersion,
  documents,
  type,
  data,
}: {
  orgId: string;
  user: User;
  device: Device;
  document: Document;
  documentVersion: DocumentVersion;
  documents: Document[];
  type: TEMPLATE_TYPE;
  data: Partial<Record<DocumentDataKey, string | AnswerItem[]>>;
}) => {
  const path = `/templates/md/${type}.md`;
  const { href } = new URL(path, import.meta.url);
  const response = await fetch(href);
  const body = await response.text();

  const templateConfig = ASSISTANT_CONFIG[type];

  const documentReferences = loadDocumentReferences(documents);

  const dependencyData = await loadDependencies(type, documents, orgId);
  const formattedDependencyData = await formatDependencyData(dependencyData);
  const deviceCharacteristics = loadDeviceCharacteristics(device);

  // Merge the data from the reference elements with the data from the document
  data = {
    ...data,
    ...formattedDependencyData,
    ...deviceCharacteristics,
    ...documentReferences,
  };

  const templateElements = templateConfig.elements.filter(
    (e): e is TemplateElement => !e.hasOwnProperty("entity")
  );

  await Promise.all(
    templateElements.map(async (element) => {
      let stepValue = data[element.id];

      if (
        "outputTransformer" in element.element.options &&
        element.element.options.outputTransformer &&
        Array.isArray(stepValue)
      ) {
        stepValue = element.element.options.outputTransformer({
          answer: stepValue,
          element: element.element,
        });
      }

      if (typeof stepValue === "string") {
        const isImage = isAnswerImageFile(documentVersion.answers, element.id);
        if (isImage) {
          const base64 = await imageFileToBase64(orgId, stepValue);
          stepValue = base64;
        }

        const formattedAnswer = await formatAnswer(element, stepValue);
        data[element.id] = formattedAnswer;
      }
    })
  );

  const metadata = await getDocumentMetadata(
    type,
    user,
    document,
    documentVersion,
    documents,
    orgId,
    device
  );
  const combinedData = {
    ...data,
    ...metadata,
  };

  const template = Handlebars.compile(body, {
    noEscape: true,
    strict: true,
  });

  return template(combinedData);
};

export const generateSignatureAndHistoryTables = async (
  templateType: TEMPLATE_TYPE,
  user: User,
  document: Document,
  documentVersion: DocumentVersion,
  documents: Document[],
  orgId: string,
  device: Device
) => {
  const metadata = await getDocumentMetadata(
    templateType,
    user,
    document,
    documentVersion,
    documents,
    orgId,
    device
  );

  let templateText = `
    ## Document Signature Table
    
    | Process  | Person          | Date            | Initials       |
    | -------- | --------------- | --------------- | -------------- |
    | Creation | {{ createdBy }} | {{ createdAt }} | {{ initials }} |
    {{#each approvers}}
    | Approval | {{ name }}      | {{ approvedAt }} | {{ initials }}|
    {{/each}}
    
    ## Revision History
    
    | Document Number     | Revision | Date | Change History  |
    | ------------------- | -------- | ---- | --------------- |
    {{#each revisions}}
    | {{ ../documentNumber }} | {{ revision }}    | {{ createdAt }} | {{notes}} |
    {{/each}}`;

  // remove indentation
  templateText = templateText.replace(/^\s+/gm, "");

  const template = Handlebars.compile(templateText, { strict: true });
  return template(metadata);
};

export const loadDependencies = async (
  type: TEMPLATE_TYPE,
  documents: Document[],
  orgId: string
): Promise<Partial<Record<DocumentDataKey, string>>> => {
  const data: Partial<Record<DocumentDataKey, string>> = {};
  const dependencyConfig = ASSISTANT_CONFIG[type].dependencies;

  // TODO test that this function returns an empty object if there are no dependencies
  if (!dependencyConfig) return {};

  // get all document answers in one array
  const allAnswers = documents.flatMap(
    (d) => getLatestDocumentVersion(d)?.answers || []
  );

  // make sure we have only one answer for each dependency. Because sometimes answers are migrated from one document to another, they might be duplicated since they continue to live in the old document. A good example is the expected-lifetime that was recently moved from the POST_MARKET_CLINICAL_FOLLOW_UP_PLAN to the USER_NEEDS document. If users answer it in the new document (USER_NEEDS) it would be potentially duplicated since it could also exist in the old location (POST_MARKET_CLINICAL_FOLLOW_UP_PLAN) if they answered it there before. The long term solution is to write proper migrations in the BE and make sure that for new doc version of the document where the answer was previously defined, the answer is not copied over anymore. This is because whenever we generate a new document version we always copy over all answers from the previous document version.
  // On the other hand, if the answer is not defined in the new document, we should use the answer from the old document.

  // If we have it in the new document and not in the old document use the answer from the new document
  // 1. check if the answer is defined multiple times
  // 2. If there is only one answer, use it
  // 3. Otherwise check for which document the the answer is defined in the doc config
  // 4. Use the answer from the document that is defined in the doc config

  chain(allAnswers)
    .filter((a) => dependencyConfig.includes(a.element))
    .groupBy("element")
    .values()
    .map(async (answers): Promise<DocumentAnswer> => {
      if (answers.length === 1) {
        return answers[0];
      } else {
        const elementConfig = Object.entries(ASSISTANT_CONFIG).find(
          ([_, value]) =>
            value.elements.find((element) => element.id === answers[0].element)
        );

        if (!elementConfig) {
          throw new Error(
            `Could not find element config for ${answers[0].element}`
          );
        }

        const relatedDocument = documents.find(
          (d) => d.name === elementConfig[0]
        );

        if (!relatedDocument) {
          // If we cannot find the related document, we cannot find the answer then just use the latest answer that we have in the answers array
          return orderBy(answers, ["createdAt"], ["desc"])[0];
        }

        const relatedDocumentVersion =
          getLatestDocumentVersion(relatedDocument);

        if (!relatedDocumentVersion) {
          throw new Error(
            `Could not find related document version for ${answers[0].element}`
          );
        }

        const relatedAnswer = relatedDocumentVersion.answers.find(
          (a) => a.element === answers[0].element
        );

        if (!relatedAnswer) {
          throw new Error(`Could not find answer for ${answers[0].element}`);
        }

        if (relatedAnswer.answerFileId) {
          const base64 = await imageFileToBase64(
            orgId,
            relatedAnswer.answerFileId
          );
          relatedAnswer.answer = base64;
        }

        return relatedAnswer;
      }
    })
    .forEach(async (a) => {
      const answer = await a;
      data[answer.element] = answer.answer ?? undefined;
    })
    .value();

  return data;
};

export const isAnswerImageFile = (
  answers: DocumentVersion["answers"],
  elementId: string
) => {
  return !!answers.find((a) => a.element === elementId)?.answerFileId;
};

export const formatDependencyData = async (
  data: Partial<Record<DocumentDataKey, string>>
) => {
  const formattedData: Partial<Record<DocumentDataKey, string>> = {};
  await Promise.all(
    (Object.entries(data) as Entries<typeof data>).map(async ([key, value]) => {
      const element = getElementConfigByDataKey(key);
      if (!value && element.required) {
        throw new Error(`Could not find answer for dependency ${key}`);
      }
      // Format the answer according to the element type
      const formattedAnswer = await formatAnswer(element, value);
      formattedData[key] = formattedAnswer;
    })
  );
  return formattedData;
};

export const formatAnswer = async (
  element: TemplateElement,
  value: string = ""
): Promise<string> => {
  switch (element.element.type) {
    case "textField":
      const fixed = value
        .replace(/^\s+/gm, "  ") // Fix indentation
        .replace(/\n/g, "<br>"); // Replace newlines with <br> tags
      return fixed;

    case "table":
      // Clean up. Make sure the markdown is formatted correctly with no leading or trailing spaces
      return value
        .split("\n")
        .filter((line) => line.trim() !== "")
        .map((line) => line.trim())
        .join("\n");

    case "fileUpload":
      return `![${element.id}](${value})`;

    default:
      return value;
  }
};

export const loadDeviceCharacteristics = (
  device: Device
): Record<string, boolean | string> => {
  return {
    "device-name": device.name,
    "device-description": device.description,
    "has-software": hasSoftware(device),
    "has-hardware": hasHardware(device),
    "is-software-only": isSoftwareOnly(device),
    "has-sterile-component": hasSterileComponent(device),
  };
};

export const getDocumentMetadata = async (
  templateType: TEMPLATE_TYPE,
  user: User,
  document: Document,
  documentVersion: DocumentVersion,
  documents: Document[],
  orgId: string,
  device: Device
): Promise<DocumentMetadata> => {
  const templateConfig = ASSISTANT_CONFIG[templateType];
  if (documentIsHidden(templateConfig)) {
    throw new Error("Template config is hidden.");
  }

  const documentAuthor = await useGetUserByIdAsync({
    userId: documentVersion.createdBy,
  });

  const documentIdentifier = getDocumentIdentifier(
    templateType,
    document,
    documentVersion,
    document.versions
  );
  const revision = getDocVersionRevisionNumber(
    documentVersion,
    document.versions
  );
  const documentNumber = document.id;

  return {
    createdAt: moment(documentVersion.createdAt).format("YYYY-MM-DD"),
    approvedAt: moment().format("YYYY-MM-DD"),
    createdBy:
      !documentAuthor.firstName || !documentAuthor.lastName
        ? ""
        : documentAuthor.firstName + " " + documentAuthor.lastName,
    initials:
      !documentAuthor.firstName || !documentAuthor.lastName
        ? ""
        : documentAuthor.firstName[0] + documentAuthor.lastName[0],
    approvers: documentVersion.documentVersionApprover.map((a) => ({
      name: a.user.firstName + " " + a.user.lastName,
      approvedAt:
        a.approvalStatus === DocumentVersionApproverStatus.APPROVED
          ? moment(a.updatedAt).format("YYYY-MM-DD")
          : "Pending Approval",
      initials: (a.user.firstName || "")[0] + (a.user.lastName || "")[0],
    })),
    documentIdentifier,
    documentNumber: documentNumber.toString(),
    ["company-name"]: getOrgFromUser(user, orgId)?.name ?? "",
    revision,
    revisions: getDocVersionsWithStatus({
      documents,
      document,
      user,
      orgId,
      device,
    })
      .filter((v) => v.revision <= revision)
      .map((v) => ({
        revision: v.revision,
        createdAt: moment(v.createdAt).format("YYYY-MM-DD"),
        // Remove newlines from the revision summary because it breaks the markdown table
        notes: v.revisionSummary.replace(/^\s+/gm, "").replace(/\n/g, " "),
      }))
      .sort((a, b) => a.revision - b.revision),
  };
};

import { Button } from "@mui/material";
import { useState } from "react";
import { ConfirmationModal } from "src/components/ConfirmationModal";

export const EditRawDocButton = ({
  onCancelEditRawDocument,
  onSaveRawDocument,
}: {
  onSaveRawDocument: () => void;
  onCancelEditRawDocument: () => void;
}) => {
  const [confirmRawEditModalOpen, setConfirmRawEditModalOpen] = useState(false);

  return (
    <>
      <div className="flex items-center gap-2">
        <Button
          variant="outlined"
          color="inherit"
          onClick={onCancelEditRawDocument}
        >
          Abort
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setConfirmRawEditModalOpen(true)}
        >
          Save
        </Button>
      </div>
      <ConfirmationModal
        key="confirm-raw-edit"
        title="Are you sure you want to save the edited document?"
        confirmText="Save"
        content={
          <>
            If you save raw edited document, Formly is
            <ul>
              <li>not able to track the changes</li>
              <li>will not be able to use them for suggestions</li>
              <li>
                not be able to tell you which other documents are influenced by
                the changes you made and therefore might need revision.
              </li>
            </ul>
          </>
        }
        onConfirm={() => {
          onSaveRawDocument();
          setConfirmRawEditModalOpen(false);
        }}
        open={confirmRawEditModalOpen}
        onAbort={() => setConfirmRawEditModalOpen(false)}
      />
    </>
  );
};

export default EditRawDocButton;

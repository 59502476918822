import { Document, TEMPLATE_TYPE } from "@models";
import { Edit } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { DocStatus, DocumentStatus } from "@types";
import { useState } from "react";
import { ConfirmationModal } from "src/components/ConfirmationModal";
import { VisuallyHiddenInput } from "src/components/DocViewer/components/fileDocViewer/BtnAnimation";
import GenericButton from "src/components/GenericButton/GenericButton";
import {
  isStaticallyGeneratedDocument,
  UPGRADE_PLAN_TOOLTIP,
} from "../../../../../utils";

export const EditButton = ({
  loadingNewDocVersion,
  onEditClick,
  selectedDoc,
  isDocEnabled,
  docVersionStatus,
  handleCreateNewVersionWithFile,
}: {
  loadingNewDocVersion: boolean;
  onEditClick?: (document: Document) => void;
  selectedDoc: Document;
  isDocEnabled: boolean;
  docVersionStatus: DocStatus;
  handleCreateNewVersionWithFile?: (
    doc: Document,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
}) => {
  const templateId = selectedDoc.name as TEMPLATE_TYPE;

  const [editConfirmationOpen, setEditConfirmationOpen] = useState(false);

  const handleEditClick = () => {
    setEditConfirmationOpen(true);
  };

  const handleEditConfirm = () => {
    setEditConfirmationOpen(false);
    onEditClick && onEditClick(selectedDoc);
  };

  const handleEditCancel = () => {
    setEditConfirmationOpen(false);
  };

  const getEditButton = (docVersionStatus: DocStatus) => {
    const isUnapproved = docVersionStatus === "UNAPPROVED";
    const isStaticallyGenerated = isStaticallyGeneratedDocument(templateId);

    if (isStaticallyGenerated && isUnapproved) {
      return (
        <GenericButton
          isDisabled={!isDocEnabled}
          onClick={handleEditClick}
          tooltipContent={UPGRADE_PLAN_TOOLTIP}
          hideTooltip={isDocEnabled}
          text="Reset draft"
        />
      );
    }

    if (docVersionStatus === DocumentStatus.LIVE) {
      return (
        <GenericButton
          isDisabled={!isDocEnabled || loadingNewDocVersion}
          onClick={handleEditClick}
          tooltipContent={UPGRADE_PLAN_TOOLTIP}
          hideTooltip={isDocEnabled}
          text="New revision"
        />
      );
    }

    if (docVersionStatus === DocumentStatus.UNAPPROVED) {
      return (
        <GenericButton
          isDisabled={!isDocEnabled || loadingNewDocVersion}
          id="basic-button"
          onClick={handleEditClick}
          tooltipContent={UPGRADE_PLAN_TOOLTIP}
          hideTooltip={isDocEnabled}
        >
          {loadingNewDocVersion ? (
            <CircularProgress size={"1rem"} color="inherit" />
          ) : (
            <Edit fontSize="small" data-testid="edit-button-document" />
          )}
        </GenericButton>
      );
    }
    return null;
  };

  return (
    <>
      {getEditButton(docVersionStatus)}
      <ConfirmationModal
        key="confirm-edit"
        title="Edit document"
        confirmText="Confirm"
        content="Are you sure you want to edit this document? This will create a new draft revision or replace the current draft."
        onAbort={handleEditCancel}
        open={editConfirmationOpen}
        {...(!handleCreateNewVersionWithFile && {
          onConfirm: handleEditConfirm,
        })}
        {...(handleCreateNewVersionWithFile && {
          confirmButtonProps: {
            component: "label",
          },
          children: (
            <VisuallyHiddenInput
              type="file"
              onChange={(e) => {
                handleCreateNewVersionWithFile(selectedDoc, e);
                setEditConfirmationOpen(false);
              }}
              accept=".pdf, .txt, .docx, .png, .jpeg"
              max={1}
              data-testid="hidden-file-input"
            />
          ),
        })}
      />
    </>
  );
};

export default EditButton;

import { DocumentVersion } from "@models";
import { Typography } from "@mui/material";

export const RenderImg = ({
  file,
  fileBlob,
}: {
  file: DocumentVersion["file"];
  fileBlob: Blob;
}) => {
  if (!file) return null;

  return (
    <div className="flex justify-start flex-col p-4 gap-5 border border-solid border-slate-300 h-full rounded-md">
      <img
        className="blob-to-image"
        src={URL.createObjectURL(fileBlob)}
        style={{
          maxWidth: "100%",
          width: "auto",
          height: "auto",
          maxHeight: "400px",
          objectFit: "contain",
        }}
      ></img>
      <div className="flex flex-col justify-center gap-2">
        <div className="flex flex-col">
          <Typography fontWeight={500}>{file.filename}</Typography>
        </div>
        <div className="flex pb-1 gap-2 justify-between border-0 border-b border-solid border-gray-300">
          <span className="text-sm text-gray-500">Created at</span>
          <span className="text-sm ">
            {file.createdAt && new Date(file.createdAt).toUTCString()}
          </span>
        </div>
        <div className="flex pb-1 gap-2 justify-between border-0 border-b border-solid border-gray-300">
          <span className="text-sm text-gray-500">Updated at</span>
          <span className="text-sm ">
            {file.updatedAt && new Date(file.updatedAt).toUTCString()}
          </span>
        </div>
      </div>
    </div>
  );
};

import { Device, SimpleSubscription, User } from "@models";
import { AutoAwesomeOutlined, BuildCircleOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { BubbleAccordion } from "src/components/BubbleAccordion/BubbleAccordion";
import { CustomDeviceModalContent } from "src/components/DeviceCreation/modal-list-items/CustomDeviceModalContent";
import { DemoDeviceModalContent } from "src/components/DeviceCreation/modal-list-items/DemoDeviceModalContent";
import { getOrgFromUser } from "src/utils/user";

export const DeviceCreationModal = ({
  open,
  onClose,
  subscription,
  user,
  devices,
  orgId,
  setExpandedId,
  expandedId,
}: {
  open: boolean;
  onClose: () => void;
  subscription: SimpleSubscription;
  user: User;
  devices: Device[];
  orgId: string;
  setExpandedId: (id: number) => void;
  expandedId: number;
}) => {
  const org = getOrgFromUser(user, orgId);

  if (!org) {
    return null;
  }

  const handleChange =
    (id: number) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedId(isExpanded ? id : -1); // -1 is none expanded
    };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>Create A Device</DialogTitle>
      <DialogContent>
        <BubbleAccordion
          expandedId={expandedId}
          handleChange={handleChange}
          title="Demo Device"
          subtitle="Take a tour of our features"
          Icon={AutoAwesomeOutlined}
          id={0}
        >
          <div data-testid="demo-device-content">
            <DemoDeviceModalContent orgId={orgId} />
          </div>
        </BubbleAccordion>
        <BubbleAccordion
          expandedId={expandedId}
          handleChange={handleChange}
          title="Custom Device"
          subtitle="Start making your own device"
          Icon={BuildCircleOutlined}
          id={1}
        >
          <div data-testid="normal-device-content">
            <CustomDeviceModalContent
              subscription={subscription}
              devices={devices}
              org={org}
            />
          </div>
        </BubbleAccordion>
      </DialogContent>
      <DialogActions className="flex gap-x-4">
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

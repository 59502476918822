export const handleFileDownload = (fileBlob: Blob, fileName: string) => {
  if (fileBlob) {
    const url = window.URL.createObjectURL(fileBlob);

    const tempLink = window.document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", `${fileName}`);

    window.document.body.appendChild(tempLink);
    tempLink.click();

    window.document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(url);
  }
};

import { Chip, ChipProps, Tooltip } from "@mui/material";

export const DemoChip = ({ size }: { size?: ChipProps["size"] }) => {
  return (
    <Tooltip
      title={
        "Demo devices are only for testing the software and cannot be used to complete a certification."
      }
      placement="bottom-end"
    >
      <Chip label="Demo" color="default" {...(size ? { size } : {})} />
    </Tooltip>
  );
};

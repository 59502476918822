export const getUserQueryKey = () => ["user"];

export const getUserByIdQueryKey = (userId: string) => ["user", userId];

export const getUserTodosQueryKey = (deviceId: string) => [
  "user",
  "todos",
  deviceId,
];

export const getDocumentsQueryKey = (deviceId: string) => [
  "documents",
  deviceId,
];

export const getFileQueryKey = (fileId: string) => ["file", fileId];

export const getAllFilesInOrgQueryKey = (deviceId?: string) => [
  "allFilesInOrg",
  deviceId,
];

export const getDevicesQueryKey = (orgId: string) => ["devices", orgId];

export const getDocumentQueryKey = (deviceId: string, docId: string) => [
  "document",
  deviceId,
  docId,
];

export const getTasksQueryKey = (deviceId: string) => ["tasks", deviceId];

export const getDeviceQueryKey = (deviceId: string) => ["device", deviceId];

export const getAnswerItemsQueryKey = ({
  orgId,
  itemId,
  type,
}: {
  orgId: string;
  itemId: string;
  type: string;
}) => ["answerItems", orgId, itemId, type];

export const getAnswerItemsByTypeQueryKey = ({
  orgId,
  type,
}: {
  orgId: string;
  type: string;
}) => ["answerItemsByType", orgId, type];

export const getCreateDocumentMutationKey = () => ["createDocument"];

export const getApproversQueryKey = (docId: string, docVersionId: string) => [
  "documentApprovers",
  docId,
  docVersionId,
];

export const getPatchApproverStatusMutationKey = () => ["patchApproverStatus"];

export const getOrganizationUsersQueryKey = ({
  orgId,
  skip,
  take,
}: {
  orgId?: string;
  skip?: number;
  take?: number;
}) => ["organizationUsers", orgId, skip, take];

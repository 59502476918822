import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { WizardTopBarLeftButton } from "@types";

export const WizardTopBar = ({
  forwardDisabled,
  backDisabled,
  nextStep,
  prevStep,
  leftButton,
}: {
  forwardDisabled: boolean;
  backDisabled: boolean;
  nextStep: () => void;
  prevStep: () => void;
  leftButton?: WizardTopBarLeftButton;
}) => {
  return (
    <div className="flex items-center justify-between rounded-md bg-gray-100">
      {leftButton ? (
        <Button
          color="inherit"
          startIcon={leftButton?.startIcon || <ChevronLeft />}
          onClick={leftButton?.onClick}
        >
          {leftButton?.text}
        </Button>
      ) : (
        <div></div>
      )}
      <div className="flex gap-x-1">
        <IconButton onClick={prevStep} disabled={backDisabled}>
          <ChevronLeft fontSize="small" />
        </IconButton>
        <IconButton onClick={nextStep} disabled={forwardDisabled}>
          <ChevronRight fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
};

import { useGetUser } from "@hooks";
import { Document, DocumentVersion } from "@models";
import { MoreVert } from "@mui/icons-material";
import { Divider, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import GenericButton from "src/components/GenericButton/GenericButton";
import {
  captureSentryMessage,
  hasRevisionOneOreMoreApprovals,
  isDocVersionAuthor,
  isDocVersionDraft,
  UPGRADE_PLAN_TOOLTIP,
} from "../../../../../utils";

export const MoreOptions = ({
  onEditRawDocument,
  selectedDoc,
  selectedVersion,
  isDocEnabled,
}: {
  onEditRawDocument: () => void;
  selectedDoc: Document;
  selectedVersion: DocumentVersion;
  isDocEnabled: boolean;
}) => {
  const { data: user } = useGetUser();

  if (!user) {
    captureSentryMessage("User not found to display DocViewerTopBar");
    return null;
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const moreOptionsOpen = Boolean(anchorEl);
  const handleClickMoreOptions = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMoreOptions = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <GenericButton
        isDisabled={!isDocEnabled}
        ariacontrols={moreOptionsOpen ? "basic-menu" : undefined}
        ariahaspopup={true}
        ariaexpanded={moreOptionsOpen ? true : undefined}
        id="basic-button"
        onClickEvent={handleClickMoreOptions}
        tooltipContent={UPGRADE_PLAN_TOOLTIP}
        hideTooltip={isDocEnabled}
      >
        <MoreVert />
      </GenericButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={moreOptionsOpen}
        onClose={handleCloseMoreOptions}
        onClick={handleCloseMoreOptions}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        disablePortal
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography color="error" className="px-4">
          Danger Zone
        </Typography>
        <Divider className="my-2" />
        <MenuItem
          disabled={
            !isDocVersionDraft(selectedVersion, selectedDoc.versions) ||
            hasRevisionOneOreMoreApprovals(selectedVersion) ||
            !isDocVersionAuthor(selectedVersion, user)
          }
          onClick={() => {
            handleCloseMoreOptions();
            onEditRawDocument();
          }}
        >
          Edit raw document
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MoreOptions;

import { DocumentVersion } from "@models";
import { AssignmentLateOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";

const NoPreview = ({ file }: { file: DocumentVersion["file"] }) => {
  if (!file) return null;

  return (
    <div className="flex justify-start flex-col p-4 gap-5 border border-solid border-slate-300 h-full rounded-md">
      <div
        style={{
          width: "100%",
          height: "200px",
          objectFit: "contain",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <AssignmentLateOutlined className="text-[8rem] opacity-20" />
        <Typography fontWeight={500} className="opacity-30" variant="h2">
          Preview not available
        </Typography>
      </div>
      <div className="flex flex-col">
        <Typography fontWeight={500}>{file.filename}</Typography>
      </div>
      <div className="flex pb-1 gap-2 justify-between border-0 border-b border-solid border-gray-300">
        <span className="text-sm text-gray-500">Created at</span>
        <span className="text-sm ">
          {file.createdAt && new Date(file.createdAt).toUTCString()}
        </span>
      </div>
      <div className="flex pb-1 gap-2 justify-between border-0 border-b border-solid border-gray-300">
        <span className="text-sm text-gray-500">Updated at</span>
        <span className="text-sm ">
          {file.updatedAt && new Date(file.updatedAt).toUTCString()}
        </span>
      </div>
    </div>
  );
};

export default NoPreview;

import { Device, Document, TEMPLATE_TYPE, User } from "@models";
import { isTaskVisible } from "@utils";
import { useParams } from "react-router-dom";
import OnboardingTooltip from "src/components/OnboardingTooltip/OnboardingTooltip";
import Card from "src/components/Roadmap/Cards/Card";
import { ROADMAP_CARD_ID, RoadmapCard, RoadmapStageId } from "../../../config";
import { areAllCardTasksCompleted } from "../utils";

type Props = {
  roadmap: {
    title: string;
    id: RoadmapStageId;
    disabled?: boolean;
    cards: RoadmapCard[];
  }[];
  documents: Document[];
  user: User;
  device: Device;
  onClickOpenCardDialog: (
    cardId: ROADMAP_CARD_ID,
    stageId: RoadmapStageId
  ) => void;
};

export const Cards = (props: Props) => {
  const { orgId = "" } = useParams();

  const { roadmap, onClickOpenCardDialog } = props;

  let foundNextTodoCard = false;

  return (
    <div className="flex">
      {roadmap.map((step) => {
        return (
          <div className="mt-3 flex w-1/5 flex-col gap-y-2" key={step.title}>
            {step.cards.map((card) => {
              const taskIds = card.tasks
                .filter((task) =>
                  isTaskVisible(task, props.documents, props.device)
                )
                .map((task) => task.id);

              const cardDocuments = props.documents.filter((document) =>
                taskIds.includes(document.name as TEMPLATE_TYPE)
              );

              const allCardTasksCompleted = areAllCardTasksCompleted(
                taskIds,
                cardDocuments,
                props.user,
                props.documents,
                orgId,
                props.device
              );

              const CardToRender = () => (
                <Card
                  title={card.title}
                  cardId={card.id}
                  disabled={step.disabled || card.enabled === false}
                  openCardDialog={() => onClickOpenCardDialog(card.id, step.id)}
                  allCardTasksCompleted={allCardTasksCompleted}
                />
              );

              if (!foundNextTodoCard && !allCardTasksCompleted) {
                foundNextTodoCard = true;
                return (
                  <OnboardingTooltip
                    databaseId="nextTodoCardTooltipSeen"
                    placement="right-start"
                    title="Your next task awaits..."
                    subtitle="Grey cards have tasks you must complete."
                    buttonText="Got it!"
                    key={`${card.title}-tooltip`}
                  >
                    <CardToRender key={card.title} />
                  </OnboardingTooltip>
                );
              }

              return <CardToRender key={card.title} />;
            })}
          </div>
        );
      })}
    </div>
  );
};

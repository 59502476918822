import { RoadmapTaskId } from "@config";
import { Device, Document, TEMPLATE_TYPE, User } from "@models";
import { getDocTypeStatus, isStatusComplete } from "@utils";

export const areAllCardTasksCompleted = (
  cardTasks: RoadmapTaskId[],
  cardDocuments: Document[],
  user: User,
  documents: Document[],
  orgId: string,
  device: Device
) => {
  // if the card is empty then it is not completed
  if (cardTasks.length === 0) {
    return false;
  }

  return cardTasks.every((task) =>
    cardDocuments.some(
      (document) =>
        document.name === task &&
        isStatusComplete(
          getDocTypeStatus({
            templateId: document.name as TEMPLATE_TYPE,
            user: user,
            documents: documents,
            orgId,
            device,
          })
        )
    )
  );
};

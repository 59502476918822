import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import AccordionList from "src/components/Form/Elements/AccordionList/AccordionList";
import DateInput from "src/components/Form/Elements/Date";
import FileUpload from "src/components/Form/Elements/FileUpload";
import ParsedFileUpload from "src/components/Form/Elements/ParsedFileUpload";
import Select from "src/components/Form/Elements/Select";
import TextElement from "src/components/Form/Elements/TextField";
import { useSnackbar } from "..";
import { DocumentVersion, TEMPLATE_TYPE } from "../../stores/models";
import {
  DocumentDataKey,
  StepValue,
  Suggestion,
  TemplateElement,
} from "../../types";
import { Table } from "./Elements/Table";
import { Toolbar } from "./Toolbar";
import { handleCSVUpload } from "./utils";

type Props = {
  stepValue: StepValue;
  hasInlineSuggestion?: boolean;
  disabled?: boolean;
  onChange: (step: TemplateElement, value: StepValue) => void;
  onRegenerateSuggestion: () => void;
  onReRunTransformer: () => void;
  onApplySuggestion?: (id: DocumentDataKey, value: string) => void;
  step: TemplateElement;
  suggestion: Suggestion;
  onUndo: () => void;
  onRedo: () => void;
  documentVersion: DocumentVersion;
};

export const ElementMapper: React.FC<Props> = ({
  disabled,
  hasInlineSuggestion = false,
  onChange,
  onRegenerateSuggestion,
  onReRunTransformer,
  onUndo,
  onRedo,
  step,
  suggestion,
  stepValue,
  documentVersion,
}) => {
  const { showSnackbar, hideSnackbar } = useSnackbar();
  const { templateId } = useParams<{
    templateId: TEMPLATE_TYPE;
  }>();

  if (!templateId) {
    throw new Error("No template id specified");
  }
  const { element } = step;

  const hasTransformer = !!element.transformerConfig;
  const isFetchingSuggestion = suggestion.loading;

  const renderElement = () => {
    switch (element.type) {
      case "fileUpload":
        return (
          <FileUpload
            element={element}
            answerFileId={stepValue.answerFileId}
            onChange={(_, value) => onChange(step, value)}
          />
        );

      case "parsedFileUploadElement":
        return (
          <ParsedFileUpload
            element={element}
            value={stepValue.answer || ""}
            onChange={(_, value) => onChange(step, value)}
          />
        );

      case "table":
        return (
          <Table
            key={element.id}
            ref={tableRef}
            element={element}
            value={stepValue.answer || ""}
            onChange={(_, value) => onChange(step, value)}
            isFetchingSuggestion={isFetchingSuggestion}
          />
        );

      case "textField":
        return (
          <TextElement
            disabled={disabled}
            onChange={(_, value) => onChange(step, value)}
            element={element}
            value={stepValue.answer || ""}
          />
        );

      case "select":
        return (
          <Select
            disabled={disabled}
            onChange={(_, value) => onChange(step, value)}
            value={stepValue.answer || ""}
            element={element}
          />
        );

      case "answerItemsElement":
        return (
          <AccordionList
            disabled={disabled}
            onChange={(value) => onChange(step, value)}
            answerItems={stepValue.answerItems || []}
            step={step}
            element={element}
            documentVersion={documentVersion}
          />
        );

      case "dateInput":
        return (
          <DateInput
            disabled={disabled}
            onChange={(value) => onChange(step, value)}
            value={stepValue.answer || ""}
          />
        );

      default:
        return null;
    }
  };

  const importAnswer = async (file: File) => {
    try {
      hideSnackbar();
      const importedAnswer = await handleCSVUpload(file, templateId, step.id);
      onChange(step, { answer: importedAnswer });
    } catch (error: any) {
      showSnackbar(error.message || "Please upload a valid CSV file.");
    }
  };

  const handleCopyTable = useCallback(() => {
    if (element.type === "table" && tableRef.current) {
      tableRef.current.copyTable();
    }
  }, [element.type]);

  const tableRef = React.useRef<{ copyTable: () => void } | null>(null);

  return (
    <div className="flex flex-col">
      <Toolbar
        hasCSVUpload={element.type === "table"}
        hasInlineSuggestion={hasInlineSuggestion}
        hasTransformer={hasTransformer}
        onRegenerateSuggestion={onRegenerateSuggestion}
        onReRunTransformer={onReRunTransformer}
        onCSVUpload={(file: File) => importAnswer(file)}
        onUndo={element.type === "table" ? onUndo : undefined}
        onRedo={element.type === "table" ? onRedo : undefined}
        onCopyTable={element.type === "table" ? handleCopyTable : undefined}
      />
      {renderElement()}
    </div>
  );
};

import { Breadcrumbs, Skeleton, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { DocExplorer } from "../components/DocExplorer";
import DocViewer from "../components/DocViewer";
import { useDocProgressMap, useGetDevice, useGetDocuments } from "../hooks";
import { TEMPLATE_TYPE } from "../stores/models";

const getTemplateFromUrl = (templateId: string) => {
  if (
    templateId &&
    Object.values(TEMPLATE_TYPE).includes(templateId as TEMPLATE_TYPE)
  ) {
    return templateId as TEMPLATE_TYPE;
  }
  return undefined;
};

const QMS = () => {
  const { deviceId = "", templateId = "", orgId = "" } = useParams();
  const templateIdParsed = getTemplateFromUrl(templateId);
  const { data: device, isLoading: isLoadingDevice } = useGetDevice({
    orgId,
    deviceId,
  });
  const { data: documents, isPending: isLoadingDocuments } = useGetDocuments(
    orgId,
    deviceId
  );
  const docProgressMap = useDocProgressMap(deviceId);

  return (
    <div className="flex flex-1 flex-col gap-y-2">
      <Breadcrumbs aria-label="breadcrumb">
        <Typography>
          {isLoadingDevice || !device ? <Skeleton width={100} /> : device.name}
        </Typography>
        <Typography>Electronic Quality Management System (eQMS)</Typography>
      </Breadcrumbs>

      <div className="flex w-full flex-1 gap-4">
        <DocExplorer
          className="sticky top-0 h-[75vh] flex-shrink-0 flex-grow-0 basis-72"
          deviceId={deviceId}
          templateId={templateIdParsed}
          isLoading={isLoadingDocuments}
          documents={documents}
        />
        <div className="flex flex-1 justify-center">
          <DocViewer
            documents={documents}
            isLoading={isLoadingDocuments}
            deviceId={deviceId}
            templateId={templateIdParsed}
            docProgress={
              templateIdParsed ? docProgressMap[templateIdParsed] : undefined
            }
          />
        </div>
      </div>
    </div>
  );
};

export default QMS;

import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetUsersByOrg } from "../../../../../../hooks";
import {
  BaseUser,
  DocumentVersionApprover,
} from "../../../../../../stores/models";
import {
  UserState,
  getApproverStatusText,
  userStatusDictionary,
} from "../../utils";
import ApproverStatusChip from "../StatusChip";
import InviteNewUser from "./inviteNewUser/InviteNewUser";
import SearchAssignees from "./searchAssignees/SearchAssignees";

export const AssignApprovers = ({
  approvers,
  enableApproversEditing,
  organizationId,
  saving,
  selectedUsers,
  setEnableApproversEditing,
  setSelectedUsers,
  userId,
  isDemoDevice,
}: {
  approvers: DocumentVersionApprover[];
  enableApproversEditing: boolean;
  organizationId: string;
  saving: boolean;
  selectedUsers: Array<BaseUser | null>;
  setEnableApproversEditing: (b: boolean) => void;
  setSelectedUsers: (users: Array<BaseUser | null>) => void;
  userId: string;
  isDemoDevice: boolean;
}) => {
  const [users, setUsers] = useState<UserState[]>([]);

  const { data: organizationUsers } = useGetUsersByOrg({
    orgId: organizationId,
    includeAutoApprovers: isDemoDevice,
  });

  useEffect(() => {
    if (organizationUsers) {
      const filteredOutCurrentUser = organizationUsers.filter(
        (user: UserState) => user.id !== userId
      );

      setUsers(filteredOutCurrentUser);
    }
  }, [organizationUsers]);

  return (
    <div>
      <div className="flex gap-2 items-end pb-2">
        <Typography variant="body1" fontWeight="bold">
          Assignees
        </Typography>
        {!enableApproversEditing && (
          <IconButton
            size="small"
            onClick={() => setEnableApproversEditing(true)}
          >
            <EditIcon fontSize="small" cursor="pointer" />
          </IconButton>
        )}
      </div>
      <div className="flex flex-col">
        <div className="flex gap-3 w-full">
          {enableApproversEditing ? (
            <SearchAssignees
              users={users}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
            />
          ) : (
            Object.values(approvers).map(
              (approver) =>
                approver && (
                  <ApproverStatusChip
                    person={approver.user}
                    key={approver.userId}
                    text={getApproverStatusText(approver)}
                    color={
                      userStatusDictionary[approver.approvalStatus].variant
                    }
                    loading={saving}
                  />
                )
            )
          )}
        </div>
        {enableApproversEditing && (
          <InviteNewUser
            users={users}
            setUsers={setUsers}
            orgId={organizationId}
          />
        )}
      </div>
    </div>
  );
};

export default AssignApprovers;

import { Tooltip } from "@mui/material";
import { TreeItem2Content, TreeItem2Root } from "@mui/x-tree-view";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import { Leaf } from "@types";

export const DocExplorerLeafContent = ({
  label,
  id,
  tooltipText,
  disabled = false,
  Icon,
  color = "primary",
  highlightedText,
}: Omit<Leaf, "link">) => {
  const { getContentProps } = useTreeItem2({
    itemId: id,
    label: label,
    disabled: disabled,
  });

  return (
    <TreeItem2Root key={`${id}-template-tree-item`}>
      <TreeItem2Content
        {...getContentProps()}
        className="flex items-center gap-2"
        data-testid={`${id}-template-tree-item`}
      >
        <Tooltip title={tooltipText} key={`${id}-tooltip`}>
          <Icon fontSize="small" color={color} />
        </Tooltip>
        <span style={{ wordBreak: "break-word" }}>
          {highlightedText || label}
        </span>
      </TreeItem2Content>
    </TreeItem2Root>
  );
};

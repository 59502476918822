import { ROUTES } from "@config";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { fillRouteWithSlugs } from "@utils";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import useUiStateItemLock from "src/hooks/useUiStateItemLock";
import { addDeviceModalSearchParam } from "src/pages/Dashboard";
import animationData from "../../assets/computer.json";

export const DemoCompleteModal = ({ orgId }: { orgId: string }) => {
  const navigate = useNavigate();
  const { closeItem, itemIsOpen } = useUiStateItemLock("demoCompleteModalSeen");

  return (
    <Dialog
      open={itemIsOpen}
      onClose={closeItem}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      disableRestoreFocus
      maxWidth="xs"
    >
      <DialogContent className="flex flex-col items-center justify-center">
        <Typography variant="h5">Nice work!</Typography>
        <Typography variant="body1" className="text-center w-3/4">
          You have completed a demo device. Now let's create your own!
        </Typography>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: animationData,
          }}
          speed={2}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeItem} color="inherit">
          Keep Exploring
        </Button>
        <Button
          onClick={() => {
            closeItem();
            navigate(
              fillRouteWithSlugs(ROUTES.DEVICE_MANAGEMENT, {
                [ROUTE_SLUGS.ORG_ID]: orgId,
              }) + addDeviceModalSearchParam("custom")
            );
          }}
          color="primary"
          variant="contained"
          autoFocus
        >
          Create Your Own Device
        </Button>
      </DialogActions>
    </Dialog>
  );
};

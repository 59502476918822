import { Leaf } from "@types";
import { Link } from "react-router-dom";
import { DocExplorerLeafContent } from "src/components/DocExplorer/components/DocExplorerLeafContent";

const DocExplorerLeaf = ({
  id,
  label,
  link,
  highlightedText,
  Icon,
  tooltipText,
  color,
  disabled,
}: Leaf) => {
  return (
    <Link
      to={link}
      className={`text-body no-underline ${disabled ? "pointer-events-none opacity-50" : ""}`}
      data-testid={`${id}-${tooltipText}`}
    >
      <DocExplorerLeafContent
        id={id}
        label={label}
        tooltipText={tooltipText}
        Icon={Icon}
        color={color}
        highlightedText={highlightedText}
      />
    </Link>
  );
};

export default DocExplorerLeaf;

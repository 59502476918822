import { useEffect, useState } from "react";

import { useGetDocuments, useGetFile, useGetUser } from "@hooks";
import { TEMPLATE_TYPE } from "@models";
import { CloudUploadOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { DocViewerTopBar } from "src/components/DocViewer/components/docViewerTopBar/DocViewerTopBar";
import NoPreview from "src/components/DocViewer/components/fileDocViewer/NoPreview";
import { RenderImg } from "src/components/DocViewer/components/fileDocViewer/RenderImg";
import { RenderPDF } from "src/components/DocViewer/components/fileDocViewer/RenderPDF";
import { handleFileDownload } from "src/components/DocViewer/components/helpers/fileDownload";
import useOutstandingAction from "src/components/DocViewer/components/hooks/useOutstandingAction";
import { DocumentViewerSkeleton } from "src/components/Skeletons";
import DocInfoDrawer from "../docInfoDrawer/DocInfoDrawer";
import useSelectDocumentVersion from "../hooks/useSelectDocument";
import { BaseAnimation } from "./BtnAnimation";
import FileUpload from "./FileUpload";

export const FileDocViewer = ({
  deviceId,
  templateId,
}: {
  deviceId: string;
  templateId: TEMPLATE_TYPE;
}) => {
  const { orgId = "" } = useParams();
  const { data: user } = useGetUser();

  // If the template id changes reset the selected document version otherwise the old selected document version will be used for the new template
  useEffect(() => {
    setSelectedDocVersionId(undefined);
    setUploadStatus("idle");
  }, [templateId]);

  const [showDocInfoDrawer, setShowDocInfoDrawer] = useState(false);

  const { data: documents } = useGetDocuments(orgId, deviceId);

  const toggleDrawer = () => setShowDocInfoDrawer((prev) => !prev);

  const [uploadStatus, setUploadStatus] = useState<
    "error" | "idle" | "success" | "pending"
  >("idle");

  const {
    setSelectedDocVersionId,
    selectedDocVersion,
    selectedDoc,
    handleCreateNewVersionWithFile,
    selectLoading,
    selectDocumentVersion,
  } = useSelectDocumentVersion({
    documents,
    templateId,
    setUploadStatus,
    deviceId,
  });

  const {
    data: fileBlob,
    isSuccess,
    isLoading: isFileLoading,
  } = useGetFile({
    orgId,
    fileId: selectedDocVersion?.fileId || "",
  });

  const outstandingAction = useOutstandingAction({
    document: selectedDoc,
    selectedDocVersionId: selectedDocVersion?.id || "",
    user,
    setDocInfoDrawerOpen: toggleDrawer,
    selectedDocVersion: selectedDocVersion,
  });

  useEffect(() => {
    outstandingAction.reset();
  }, [templateId]);

  if (isFileLoading || selectLoading || !selectedDoc) {
    return <DocumentViewerSkeleton />;
  }

  const hasAnyVersionsYet = selectedDoc?.versions.length || 0 > 0;

  return (
    <div className=" flex w-full flex-1 flex-col gap-y-2">
      {selectedDoc && selectedDocVersion && documents && (
        <DocViewerTopBar
          docProps={{
            docs: documents,
            selectedDoc: selectedDoc,
            selectedVersion: selectedDocVersion,
            hasOutstandingAction: outstandingAction.shouldShow,
            loadingNewDocVersion: selectLoading,
            onDocInfoButtonClick: toggleDrawer,
            onSelectDocVersion: selectDocumentVersion,
            isDocEnabled: true,
            handleCreateNewVersionWithFile: handleCreateNewVersionWithFile,
          }}
          handleFileDownload={() =>
            handleFileDownload(
              fileBlob?.data,
              selectedDocVersion.file?.filename || "file"
            )
          }
        />
      )}
      {hasAnyVersionsYet &&
        selectedDocVersion?.file &&
        isSuccess &&
        fileBlob && (
          <>
            {selectLoading ? (
              <BaseAnimation
                Icon={CloudUploadOutlined}
                text={`Upload in progress...`}
                buttonText={"Uploading"}
                ButtonIcon={CloudUploadOutlined}
                animated={false}
                isLoading={true}
              />
            ) : (
              <>
                {selectedDocVersion.file.mimeType?.includes("image") &&
                fileBlob ? (
                  <RenderImg
                    file={selectedDocVersion.file}
                    fileBlob={fileBlob.data}
                  />
                ) : selectedDocVersion.file.mimeType?.includes("pdf") &&
                  fileBlob ? (
                  <RenderPDF fileBlob={fileBlob.data} />
                ) : (
                  <NoPreview file={selectedDocVersion.file} />
                )}
              </>
            )}
          </>
        )}
      {!hasAnyVersionsYet && (
        <FileUpload
          uploadStatus={uploadStatus}
          handleFileChange={(e) =>
            handleCreateNewVersionWithFile(selectedDoc, e)
          }
        />
      )}
      {selectedDocVersion && selectedDoc && (
        <DocInfoDrawer
          selectedDoc={selectedDoc}
          isOpen={showDocInfoDrawer}
          onDrawerClose={toggleDrawer}
          onDrawerOpen={toggleDrawer}
          selectedDocVersion={selectedDocVersion}
          deviceId={deviceId}
          templateId={templateId}
        />
      )}
    </div>
  );
};

import { ROADMAP_CARD_ID, ROUTES } from "@config";
import Handlebars from "handlebars";
import moment from "moment";
import Papa from "papaparse";
import { TEMPLATE_TYPE } from "../../stores/models";
import {
  AnswerItem,
  DocumentDataKey,
  FunctionalGroup,
  TemplateAssistantConfig,
  TemplateElement,
} from "../../types";
import {
  captureSentryException,
  fillRouteWithSlugs,
  getTableColumns,
  hasHardware,
  hasSoftware,
  hasSterileComponent,
  markdownTableToObject,
  mergeTables,
  parseMarkdownTable,
  toMarkdownTable,
} from "../../utils";
import { ROUTE_SLUGS } from "../navigation/routes";

export const config: TemplateAssistantConfig = {
  [TEMPLATE_TYPE.INTENDED_USE]: {
    id: TEMPLATE_TYPE.INTENDED_USE,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Intended Use",
    elements: [
      {
        id: "intended-use",
        prompt: true,
        required: true,
        element: {
          id: "intended-use",
          type: "textField",
          options: {
            label: "What is the intended use for the device?",
            rows: 5,
            helperText: `Your intended use describes how your product functions at its core. What you described in your intended use statement MUST be defendable by  
                data and will end up going into your instructions manual so it is important to phrase this statement carefully so you don't promise too much or too little.`,
          },
        },
      },
      {
        id: "indications-for-use",
        context: ["intended-use"],
        prompt: true,
        required: true,
        element: {
          id: "indications-for-use",
          type: "textField",
          options: {
            label: "What are the indications for use for the device?",
            rows: 5,
            helperText:
              "Indications for use describe the medical condition(s) and/or disease(s) to be screened, monitored, treated, diagnosed, or prevented by the device. This can include exclusion statements about which patients should be excluded but only when necessary.",
          },
        },
      },
      {
        id: "contraindications",
        context: ["intended-use", "indications-for-use"],
        prompt: true,
        required: true,
        element: {
          id: "contraindications",
          type: "textField",
          options: {
            label: "What are the contraindications for the device?",
            rows: 5,
            helperText:
              "Contraindications are medical indications or patients that should be excluded from using your device. This will also go into your instructions manual so be specific. This can include pregnant women, patients with epilepsy, etc.",
          },
        },
      },
      {
        id: "patient-population",
        context: ["contraindications"],
        prompt: true,
        required: true,
        element: {
          id: "patient-population",
          type: "textField",
          options: {
            label: "What patient populations is your device intended for?",
            rows: 5,
            helperText:
              "Describe the patients that will be receiving the benefit of your device. You can include particular ages, health conditions or characteristics, etc. If it is broadly applicable to all people it could be better to keep your patient population more vague and high level. This description can and often does overlap with your user profile depending on how your device is being used.",
          },
        },
      },
      {
        id: "user-profile",
        context: ["contraindications", "patient-population"],
        prompt: true,
        required: true,
        element: {
          id: "user-profile",
          type: "textField",
          options: {
            label:
              "What types of users do you intended to have use your device?",
            rows: 5,
            helperText:
              "Provide a description of your typical users of your software. This can be a regular every day consumer or it could be a medical professional. If they have any specific qualifications or proficiencies in order to use the device make sure to include those as well.",
          },
        },
      },

      {
        id: "use-environment",
        context: ["contraindications", "patient-population", "user-profile"],
        prompt: true,
        required: true,
        element: {
          id: "use-environment",
          type: "textField",
          options: {
            label: "What is the intended use environment for the device?",
            rows: 5,
            helperText:
              "Describe the typical use environment. What sort of devices is this running on? Does the software only run on one device or multiple devices? Is it loud and chaotic like in an emergency ward? How's the lighting? Also, add other software or hardware which is required the your device. Most commonly, apps require users to have a smartphone with a compatible operating system (iOS / Android).",
          },
        },
      },
      {
        id: "principles-of-operation",
        context: [
          "intended-use",
          "indications-for-use",
          "contraindications",
          "patient-population",
          "user-profile",
          "use-environment",
        ],
        prompt: true,
        required: true,
        element: {
          id: "principles-of-operation",
          type: "textField",
          options: {
            label: "What are your devices principles of operation?",
            rows: 5,
            helperText:
              "Provide a very basic principle of operation for your device. What does it receive as information and what does it output? Does it transform information or perhaps cause mechanical changes as part of its operation? Give a very brief overview of how it works when operated correctly.",
          },
        },
      },
      {
        id: "part-of-the-body",
        context: [
          "intended-use",
          "indications-for-use",
          "contraindications",
          "patient-population",
          "user-profile",
          "use-environment",
          "principles-of-operation",
        ],
        prompt: true,
        required: true,
        element: {
          id: "part-of-the-body",
          type: "textField",
          options: {
            label:
              "What parts of the body does your device interact with if any?",
            rows: 5,
            helperText:
              "If your device comes in contact with the body, please describe where and how it interacts with the body. Make sure to include the type of human tissue it comes into contact with such as intact skin, open wounds, muscular tissue, blood, etc.",
          },
        },
      },
      {
        id: "variants-and-accessories",
        required: true,
        element: {
          id: "variants-and-accessories",
          type: "textField",
          options: {
            label:
              "Describe briefly any variants and/or accessories to your device.",
            rows: 5,
            helperText:
              "Provide a description of any different kinds of variants of your device that may provide different functionalities. Also, are there accessories of your device that are core to its function? Describe those as well. If there are none (often there are not) then just say that the device does not have any variants or accessories.",
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.QUALITY_MANUAL_POLICY_OBJECTIVES]: {
    id: TEMPLATE_TYPE.QUALITY_MANUAL_POLICY_OBJECTIVES,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "TCD",
    docName: "Quality Manual",
    elements: [
      {
        id: "role-of-company",
        required: true,
        element: {
          id: "role-of-company",
          type: "select",
          options: {
            label: "What is the role of the company?",
            options: [
              "Manufacturer",
              "Authorised Representative",
              "Distributor",
            ],
            multiple: true,
            default: "Manufacturer",
            helperText:
              "-Manufacturer: natural or legal person with responsibility for design and/or manufacture of a medical device with the intention of making the medical device available for use, under his name; whether or not such a medical device is designed and/or manufactured by that person himself or on his behalf by another person(s)\n-Authorized representative: natural or legal person established within a country or jurisdiction who has received a written mandate from the manufacturer to act on his behalf for specified tasks with regard to the latter’s obligations under that country or jurisdiction’s legislation\n-Distributer: natural or legal person in the supply chain who, on his own behalf, furthers the availability of a medical device to the end user (Note: More than one distributor may be involved in the supply chain and persons in the supply chain involved in activities such as storage and transport on behalf of the manufacturer, importer or distributor, are not distributors under this definition.)",
          },
        },
      },
      {
        id: "quality-policy",
        prompt: true,
        required: true,
        element: {
          id: "quality-policy",
          type: "textField",
          options: {
            label: "What is the quality policy for your company?",
            rows: 5,
            helperText:
              "Describe what your company is about, specifically, its mission and things which are important for it. Maybe you're developing software for patients with a certain disease and your goal is to improve their lives. In addition, the policy should include a commitment to meet legal requirements, keep the QMS up to date and define quality objectives to work towards.",
          },
        },
      },
      {
        id: "quality-objectives",
        prompt: true,
        required: true,
        context: ["quality-policy"],
        element: {
          id: "quality-objectives",
          type: "textField",
          options: {
            label: "What are the quality objectives of your company?",
            rows: 5,
            helperText:
              "Quality objectives shall be measurable and consistent with the quality policy. Those objectives should not (only) refer to the quality of your devices but the quality of your QMS and the overall work of your organization. Typical examples are: hiring excellence in staff, providing, best-of-class device performance, high standards of customer satisfaction, etc.\n Try to define measurable, yet still abstract quality objectives here. In a next step, those are narrowed down to very concrete measures (for example: monthly number of user complaints). This way you don't need to update your quality manual and objectives a lot. And you can re-use a system that you already have, combining QMS with your regular business goals.",
          },
        },
      },
      // {
      //   id: "short-term-goals",
      //   prompt: true,
      //   required: true,
      //   context: ["quality-policy", "quality-objectives"],
      //   element: {
      //     id: "short-term-goals",
      //     type: "textField",
      //     options: {
      //       label:
      //         "What are your short-term goals for your company as it relates to your quality policy?",
      //       rows: 5,
      //       helperText:
      //         "How does your team track its goals? Your auditors want to see how your quality objectives translate into your daily work. You should formulate strategic goals for your company that are somewhat related to your quality goals and which are tracked at least on an annual basis. Do you already have a goal-oriented system in place to track your team’s work? Even better: align business and quality goals and describe your system here.",
      //     },
      //   },
      // },
      // {
      //   id: "employee-roles",

      // },
      // {
      //   id: "quality-sops",
      // },
    ],
  },
  [TEMPLATE_TYPE.SOP_DOCUMENT_RECORD_CONTROL]: {
    id: TEMPLATE_TYPE.SOP_DOCUMENT_RECORD_CONTROL,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "SOP",
    docName: "SOP Document and Record Control",
    elements: [],
  },
  [TEMPLATE_TYPE.SOP_HUMAN_RESOURCES_ADMINISTRATION]: {
    id: TEMPLATE_TYPE.SOP_HUMAN_RESOURCES_ADMINISTRATION,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "SOP",
    docName: "SOP Human Resources and Training",
    elements: [],
  },
  [TEMPLATE_TYPE.SOP_UPDATE_OF_REGULATIONS]: {
    id: TEMPLATE_TYPE.SOP_UPDATE_OF_REGULATIONS,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "SOP",
    docName: "SOP Update of Regulations",
    elements: [],
  },
  [TEMPLATE_TYPE.LIST_OF_EMPLOYEE_TRAINING]: {
    id: TEMPLATE_TYPE.LIST_OF_EMPLOYEE_TRAINING,
    docName: "List of Employee Training",
    docType: "LST",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docReferences: [TEMPLATE_TYPE.SOP_HUMAN_RESOURCES_ADMINISTRATION],
    elements: [
      {
        id: "employee-information",
        prompt: true,
        required: true,
        element: {
          id: "employee-information",
          type: "table",
          options: {
            label: "Provide the information about the employee.",
            helperText: `Their functional group can be any or multiple of the following: Quality, Software/Product, Business, Management, Corporate, or other.`,
            columns: [
              {
                name: "Employee Name",
              },
              {
                name: "Start Date",
              },
              {
                name: "Functional Group",
              },
              {
                name: "Manager / Supervisor",
              },
            ],
            addRowEnabled: true,
            default: `| Employee Name        | Start Date | Functional Group | Manager / Supervisor |
            | -------------------- | ---------- | ----------------- | --------------------- |
            |                      |            |                   |                       |`,
          },
        },
      },
      {
        id: "employee-training",
        prompt: true,
        required: true,
        element: {
          id: "employee-training",
          type: "table",
          options: {
            label: "Input training for the employee.",
            helperText:
              "Fill in the following table with each SOP that you have created so far that is relevant to the employee's functional group as well as all other training they have received, including group trainings. For example, all software team members should understand all the SOPs for software but don't need to read the SOP for human resources.",
            columns: [
              {
                name: "Training Name",
              },
              {
                name: "Initial / Continuous Training",
              },
              {
                name: "Training Delivery",
              },
              {
                name: "Due Date",
              },
              {
                name: "Date Completed",
              },
              {
                name: "Summary of Learnings",
              },
              {
                name: "Initials",
              },
            ],
            addRowEnabled: true,
            default: `| Training Name | Initial / Continuous Training | Training Delivery | Due Date | Date Completed | Summary of Learnings | Initials |
            | ------------- | ----------------------------- | ----------------- | -------- | -------------- | -------------------- | -------- |
            |               |                               |                   |          |                |                      |          |`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.GROUP_TRAINING_RECORD]: {
    id: TEMPLATE_TYPE.GROUP_TRAINING_RECORD,
    docName: "Group Training Record",
    docType: "RCD",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    elements: [
      {
        id: "group-training-information",
        prompt: false,
        required: true,
        element: {
          id: "group-training-information",
          type: "table",
          options: {
            label: "Provide general information on the group training.",
            helperText:
              "For the training method, describe if it was remote, on-site, managed through software, etc.",
            default: `| Training Information | Details |
      | -------------------- | ------- |
      | Name of Training     |         |
      | Date of Training     |         |
      | Training Method      |         |
      | Trainer              |         |
      | Content              |         |`,
            columns: [
              {
                name: "Training Information",
              },
              {
                name: "Details",
              },
            ],
          },
        },
      },
      {
        id: "group-participants",
        prompt: false,
        required: true,
        element: {
          id: "group-participants",
          type: "table",
          options: {
            label: "Provide a list of who attended the training.",
            helperText:
              "Give the names of the persons attending the group training, their job titles, and whether they completed it or not.",
            default: `| No. | Name | Title | Completed |
      | --- | ---- | --------- | --------- |
      | 1   |      |           |           |
      | 2   |      |           |           |
      |     |      |           |           |`,
            addRowEnabled: true,
            columns: [
              {
                name: "No.",
              },
              {
                name: "Name",
              },
              {
                name: "Title",
              },
              {
                name: "Completed",
              },
            ],
          },
        },
      },
      {
        id: "group-training-review",
        prompt: false,
        required: true,
        element: {
          id: "group-training-review",
          type: "table",
          options: {
            label: "Who reviewed the training for its effectiveness?",
            helperText:
              "Provide the details of the supervisor who reviewed the training and confirmed that is was effective.",
            default: `| Action | Name | Team / Role | Date | Initials |
      | ------ | ---- | ----------- | ---- | -------- |
      | Review |      |             |      |          |`,
            columns: [
              {
                name: "Action",
              },
              {
                name: "Name",
              },
              {
                name: "Team / Role",
              },
              {
                name: "Date",
              },
              {
                name: "Initials",
              },
            ],
          },
        },
      },
      {
        id: "group-actions",
        prompt: false,
        required: true,
        element: {
          id: "group-actions",
          type: "textField",
          options: {
            label: "Are any additional actions required?",
            rows: 5,
            helperText:
              'If the training was insufficient in providing all of the information required, sometimes additional actions may be warranted to ensure your team has sufficient learnings. Provide any additional actions that should be performed to ensure that the group has been properly trained on the subject matter.\n\nIf no additional actions are required, write "No additional actions required."',
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.REQUIRED_DOCUMENTATION_TRAINING_BY_FUNCTIONAL_GROUP]: {
    id: TEMPLATE_TYPE.REQUIRED_DOCUMENTATION_TRAINING_BY_FUNCTIONAL_GROUP,
    docName: "Required Documentation Training by Functional Group",
    docType: "RCD",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    elements: [],
  },
  [TEMPLATE_TYPE.MDR_DEVICE_CLASSIFICATION]: {
    id: TEMPLATE_TYPE.MDR_DEVICE_CLASSIFICATION,

    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "MDR Classification Document",
    elements: [
      {
        id: "duration-of-use",
        prompt: true,
        required: true,
        element: {
          id: "duration-of-use",
          type: "select",
          options: {
            label: "What is the duration of use for your product?",

            options: [
              "Transient - less than 60 minutes.",
              "Short term - not less than 60 minutes and not more than 30 days.",
              "Long term - more than 30 days.",
            ],
            default: "Transient - less than 60 minutes.",
            helperText:
              "Provide your product duration of use here. It can include:\nTransient - Normally intended for continuous use for less than 60 minutes. Short term - Normally intended for continuous use for not less than 60 minutes and not more than 30 days. Long term - Normally intended for continuous use for more than 30 days.",
          },
        },
      },
      {
        id: "device-classification",
        prompt: true,
        context: ["intended-use", "duration-of-use"],
        required: true,
        element: {
          id: "device-classification",
          type: "select",
          options: {
            label:
              "What is the device classification based on Annex VIII of the EU MDR 2017/745?",
            options: ["Class I", "Class IIa", "Class IIb", "Class III"],
            default: "Class IIa",
            helperText: "",
          },
        },
      },
      {
        id: "device-classification-justification",
        prompt: true,
        context: ["intended-use", "device-classification", "duration-of-use"],
        required: true,
        element: {
          id: "device-classification-justification",
          type: "textField",
          options: {
            label: "What is your justification for your device classification?",
            rows: 5,
            helperText:
              "The classification of your device should be according to the rules of Annex VIII of the EU MDR 2017/745 rules. Provide only those rules that are applicable to you in your device classification.",
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOP_INTEGRATED_SOFTWARE_DEVELOPMENT]: {
    id: TEMPLATE_TYPE.SOP_INTEGRATED_SOFTWARE_DEVELOPMENT,
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    docType: "SOP",
    docName: "SOP Software Development",
    elements: [],
  },
  [TEMPLATE_TYPE.SOFTWARE_REQUIREMENTS_LIST]: {
    id: TEMPLATE_TYPE.SOFTWARE_REQUIREMENTS_LIST,
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    docType: "LST",
    docName: "Software Requirements List",
    deprecationConfig: {
      isDeprecated: true,
      deprecationMessage:
        "This document is obsolete and was replaced by a new document. Please use the following document instead:",
      linkToNewTemplate: (orgId, deviceId) =>
        fillRouteWithSlugs(ROUTES.DEVICE_OVERVIEW, {
          [ROUTE_SLUGS.ORG_ID]: orgId,
          [ROUTE_SLUGS.DEVICE_ID]: deviceId,
        }) +
        "?card=" +
        ROADMAP_CARD_ID.PRODUCT_REQUIREMENTS +
        "&task=" +
        TEMPLATE_TYPE.SUBSYSTEM_REQUIREMENTS_LIST,
      linkText: "Subsystem Requirements List",
    },
    elements: [
      {
        id: "software-requirements",
        prompt: true,
        context: ["user-needs", "software-items-table"],
        required: true,
        element: {
          id: "software-requirements",
          type: "table",
          options: {
            label: "What are your software requirements?",
            helperText:
              "Software requirements define the key functions of your device as required. Think of them as user stories but more generalized to the function of the actual software. We will use your User Needs and Risk Assessment as inputs for your software requirements as they form the framework for what should be designed into the device.",
            // | Software ID | Software Item | Category | Software Requirement |
            columns: [
              {
                name: "Software ID",
                options: { flex: 0 },
              },
              {
                name: "Software Item",
                options: { flex: 0 },
              },
              {
                name: "Category",
                options: { flex: 0 },
              },
              {
                name: "Software Requirement",
              },
            ],
          },
        },
      },
      {
        id: "risk-control-software-requirements",
        prompt: true,
        context: ["risk-controls", "software-items-table"],
        required: true,
        element: {
          id: "risk-control-software-requirements",
          type: "table",
          options: {
            label:
              "Provide software requirements that are based on your risk controls.",
            helperText:
              "During the risk assessment, we decided what risks may need additional risk controls to make the product safe enough for our users. Here, we want to implement software requirements for those risk controls so that we can build them into the product. Provide a software requirement for any risk controls or any software features that are to be implemented to help reduce risk to the patients. These should be worded the same way as the software requirements we created in the previous step.",
            // | Software ID | Software Item | Category | Software Requirement |
            columns: [
              {
                name: "Software ID",
                options: { flex: 0 },
              },
              {
                name: "Software Item",
                options: { flex: 0 },
              },
              {
                name: "Category",
                options: { flex: 0 },
              },
              {
                name: "Software Requirement",
              },
            ],
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOFTWARE_REQUIREMENTS_REVIEW_CHECKLIST]: {
    id: TEMPLATE_TYPE.SOFTWARE_REQUIREMENTS_REVIEW_CHECKLIST,
    docName: "Software Requirements Checklist",
    docType: "RCD",
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    elements: [
      {
        id: "software-req-checklist-table",
        prompt: false,
        required: true,
        element: {
          id: "software-req-checklist-table",
          type: "table",
          options: {
            label:
              "Complete this checklist while reviewing your software requirements.",
            default: `| Software Requirements...                                            | Yes/No | Comment |
              | ----------------------------------------------------------------------------- | ------ | ------- |
              | are traceable to design input or risk control measures.                       |        |         |
              | are complete.                                                                 |        |         |
              | are understandable, uniquely identifiable and do not contradict each other.   |        |         |
              | include user interface requirements (mockups, wireframes, etc.), if relevant. |        |         |
              | include IT security requirements, if relevant.                                |        |         |
              | are testable and include acceptance criteria.                                 |        |         |`,
            columns: [
              {
                name: "Software Requirements",
              },
              {
                name: "Yes/No",
                options: { flex: 0 },
              },
              {
                name: "Comment",
              },
            ],
            helperText:
              "If any of these boxes are ticked as no, please provide commentary as to why it the software requirements would still be acceptable even if the box is marked as no.",
          },
        },
      },
      {
        id: "software-req-checklist-comments",
        prompt: false,
        required: false,
        element: {
          id: "software-req-checklist-comments",
          type: "textField",
          options: {
            label:
              "Provide any comments related to the software requirements review that might be relevant.",
            rows: 5,
            helperText:
              "Cover any issues that may have arisen in the review and justify why they are still acceptable or maybe even unacceptable. Anything you think might be important to note.",
          },
        },
      },
      {
        id: "software-req-checklist-results",
        prompt: false,
        required: true,
        element: {
          id: "software-req-checklist-results",
          type: "select",
          options: {
            label: "Did the software requirements pass review?",
            options: [
              "Software requirements acceptable",
              "Software requirements not acceptable",
              "Software requirements acceptable with obligations",
            ],
          },
        },
      },
      {
        id: "software-req-checklist-obligations",
        prompt: false,
        required: false,
        element: {
          id: "software-req-checklist-obligations",
          type: "textField",
          options: {
            label: "What obligations did the software requirements pass with?",
            rows: 5,
            helperText:
              "ONLY FILL THIS OUT IF THE REVIEW PASSED WITH OBLIGATIONS. Cover any issues that may have arisen in the review and justify why they are still acceptable or maybe even unacceptable. Anything you think might be important to note.",
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.RISK_MANAGEMENT_REPORT]: {
    id: TEMPLATE_TYPE.RISK_MANAGEMENT_REPORT,
    docName: "Risk Management Report",
    docType: "TCD",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    elements: [
      {
        id: "risk-analysis-results",
        prompt: false,
        required: true,
        context: ["risk-analysis", "risk-controls"],
        element: {
          id: "risk-analysis-results",
          type: "table",
          options: {
            label:
              "Provide a summary of the information from the risk analysis performed during your risk assessment.",
            helperText:
              "Make sure that the number of risks, risk types, acceptable risks, and unacceptable risks are identified correctly.",
            columns: [
              {
                name: "Criteria",
              },
              {
                name: "Data",
              },
            ],
          },
          transformerConfig: {
            type: "default",
            inputs: ["risk-analysis", "risk-controls"],
            transformer: (inputs: Record<DocumentDataKey, string>) => {
              const riskAnalysisRows = parseMarkdownTable(
                inputs["risk-analysis"]
              );

              const riskControlsRows = parseMarkdownTable(
                inputs["risk-controls"]
              );

              const numberOfRisks = riskAnalysisRows.length;
              const riskTypes = Array.from(
                new Set(riskAnalysisRows.map((row) => row["Risk Type"]))
              ).join(", ");
              const acceptableRisks = riskAnalysisRows.filter(
                (row) => row["Risk Acceptability"].toLowerCase() === "yes"
              ).length;
              const unacceptableRisks = riskAnalysisRows.filter(
                (row) => row["Risk Acceptability"].toLowerCase() === "no"
              ).length;
              const unacceptableRisksAfterControls = riskControlsRows.filter(
                (row) => row["New Risk Acceptability"].toLowerCase() === "no"
              ).length;

              return `
                | Criteria                                            | Data                              |
                | --------------------------------------------------- | --------------------------------- |
                | Number of Risks                                     | ${numberOfRisks}                  |
                | Risk types identified                               | ${riskTypes}                      |
                | Number of Acceptable Risks                          | ${acceptableRisks}                |
                | Number of Unacceptable Risks Prior to Risk Controls | ${unacceptableRisks}              |
                | Number of Unacceptable Risks After Risk Controls    | ${unacceptableRisksAfterControls} |`;
            },
          },
        },
      },
      {
        id: "risk-controls-results",
        prompt: true,
        required: true,
        context: ["risk-controls"],
        element: {
          id: "risk-controls-results",
          type: "table",
          options: {
            label:
              "Provide a summary of the risk controls you implemented, if any.",
            helperText:
              "Identify the number and type of risk control measures implemented.",
            columns: [
              {
                name: "Criteria",
              },
              {
                name: "Data",
              },
            ],
          },
        },
      },
      {
        id: "overall-residual-risk",
        prompt: true,
        required: true,
        context: ["risk-controls"],
        element: {
          id: "overall-residual-risk",
          type: "textField",
          options: {
            label:
              "Describe the overall residual risk following risk mitigation.",
            rows: 5,
            helperText: `If you have any risks that are still unacceptable, they need to be listed here. This section is defined by your risk matrix that was created in the risk management plan. If you have no "unacceptable" risks, then your overall residual risk is acceptable.`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.RISK_MANAGEMENT_PLAN]: {
    id: TEMPLATE_TYPE.RISK_MANAGEMENT_PLAN,
    docName: "Risk Management Plan",
    docType: "TCD",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    elements: [
      {
        id: "device-usage",
        prompt: true,
        required: true,
        element: {
          id: "device-usage",
          type: "select",
          options: {
            label: "How often will your device be used?",
            options: [
              "Device will be used daily and will have a lifetime use of multiple years.",
              "Device will be used monthly and will have a lifetime use of multiple years.",
              "Device will be used yearly and will have a lifetime of multiple years.",
              "Device will be used daily and will have a lifetime use of under a year.",
              "Device will be used monthly and will have a lifetime use of under a year.",
              "Device will be used yearly and will have a lifetime of under a year.",
            ],
            helperText: `Identify how often the device will be used. This information will not be put into any official documentation but helps us to understand your product usage to build your risk documentation.
  
               The usage amount you specify should be for your user, whatever is the more frequent amount, select that option.`,
          },
        },
      },
      {
        id: "risk-acceptability-criteria",
        prompt: true,
        required: true,
        element: {
          id: "risk-acceptability-criteria",
          type: "select",
          options: {
            label:
              "How would you classify the health risk of your intended users?",
            options: [
              "Minor injury would be considered a significant risk to the user group and/or patient population while using the device and severe hospitalization or death is incredibly rare.",
              "Minor injury can be common for this user group and/or patient population while using the device but severe hospitalization and death is very rare.",
              "Minor injury is very common and unavoidable for this user group and/or patient population while using the device and severe hospitalization is not uncommon.",
            ],
            helperText:
              "Is the risk of death or hospitalization common for your users or would someone experiencing a hospitalization event be a significant event in their life? We will use this data to estimate what kind of risks to your patients are acceptable.",
          },
        },
      },
      {
        id: "risk-acceptance-matrix",
        prompt: true,
        required: true,
        context: [
          "patient-population",
          "risk-acceptability-criteria",
          "intended-use",
        ],
        element: {
          id: "risk-acceptance-matrix",
          type: "table",
          options: {
            label:
              "Check the risk matrix table to make sure it is appropriate for your device.",
            columns: [
              { name: "Probability of Occurrence" },
              {
                name: "S1: Negligible",
              },
              {
                name: "S2: Marginal",
              },
              {
                name: "S3: Serious / Major",
              },
              {
                name: "S4: Critical",
              },
              {
                name: "S5: Catastrophic / Fatal",
              },
            ],
            // helperText: `| Code | Severity | Definition |

            // | ---- | -------------------- | ---------------------------------------------------------------------------------------- |

            // | S1 | Negligible | Results in inconvenience or temporary discomfort |

            // | S2 | Minor | Results in temporary injury or impairment not requiring medical or surgical intervention |

            // | S3 | Serious / Major | Results in injury or impairment requiring medical or surgical intervention |

            // | S4 | Critical | Results in permanent impairment or irreversible injury |

            // | S5 | Catastrophic / Fatal | Results in death |

            // | Code | Probability | Estimated Maximum Event Count |

            // | ---- | ----------- | ------------------------------------------------- |

            // | P1 | Improbable | Event is unlikely to happen (1 in 1,000,000 uses) |

            // | P2 | Remote | Event will rarely happen (1 in 100,000 uses) |

            // | P3 | Occasional | Event will happen occasionally (1 in 10,000 uses) |

            // | P4 | Probable | Event will probably to happen (1 in 1,000 uses) |

            // | P5 | Frequent | Event will happen very frequently (1 in 100 uses) |`,

            helperText: `
              Click inside the table to make adjustments.
  
              The risk matrix is a semi-quantitative way of determining if the risks of your device are acceptable before you put your product on the market. The matrix uses the probability of a risk occurring on the y axis and the severity of the risk on the x axis to determine if it is acceptable. The cells of the risk matrix are one of three determinations:
              
              - Acceptable: If a risk is acceptable we are saying that it wouldn't be out of the ordinary for this type of risk to occur. For example, if your app is used to monitor glucose, a patient experiencing low glucose blood sugar will be a fairly likely risk for your device. As such, this may happen frequently and we would consider that risk acceptable.
  
              - Reduce Risk AFAP: AFAP stands for "As Far As Possible". Risks that fall into this category are borderline unacceptable and we really want to avoid them but may happen occasionally given the nature of the device. Sticking with our glucose monitor example, one type of risk in this category might be hypoglycemic shock which we hope does not happen as it can be serious and want to reduce the risk of it happening as much as possible but if it does happen it isn't a completely abnormal risk for our users.
  
              - Unacceptable: These are risks that are alarming and should never happen with our device. The most obvious of these risks for low risk devices is risk of death. If there is a possibility our glucose monitoring app leads to a patients death that is obviously unacceptable and we would want to reduce this risk as much as possible.`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.RISK_ASSESSMENT]: {
    id: TEMPLATE_TYPE.RISK_ASSESSMENT,
    docName: "Risk Assessment",
    docType: "TCD",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    elements: [
      {
        id: "software-safety-classification",
        prompt: true,
        required: true,
        element: {
          id: "software-safety-classification",
          type: "select",
          options: {
            label: "What is the software safety classification of your device?",
            helperText: `The safety classification is based on ISO 62304:2006 and is dependent on the type of risk your software will present the patient with if it fails.  The software safety classifications are the following: 
              Class A: No injury or damage to health is possible
              Class B: Non-serious injury is possible
              Class C: Death or serious Injury is possible`,
            options: [
              "Class A: No injury or damage to health is possible",
              "Class B: Non-serious injury is possible",
              "Class C: Death or serious Injury is possible",
            ],
          },
        },
        visibilityCondition: (_, device) => {
          return hasSoftware(device);
        },
      },
      {
        id: "risk-analysis",
        prompt: true,
        context: [
          "intended-use",
          "user-profile",
          "patient-population",
          "user-needs",
          "risk-acceptance-matrix",
          "software-safety-classification",
        ],
        required: true,
        element: {
          id: "risk-analysis",
          type: "table",

          options: {
            columns: [
              {
                name: "Risk ID",
                options: { flex: 0 },
              },
              {
                name: "Risk Type",
                options: { flex: 0 },
              },
              {
                name: "Hazard",
              },
              {
                name: "p1",
                options: { flex: 0 },
              },
              {
                name: "Hazardous Situation",
              },
              {
                name: "p2",
                options: { flex: 0 },
              },
              {
                name: "Harm",
              },
              {
                name: "p1*p2",
                options: { flex: 0 },
              },
              {
                name: "P",
                options: { flex: 0 },
              },
              {
                name: "S",
                options: { flex: 0 },
              },
              {
                name: "Risk Acceptability",
              },
            ],
            idColumn: "Risk ID",
            label:
              "Check the risk analysis so that the risks and probabilities are accurate for your device.",
            helperText: `The risk analysis tries to walk through any foreseeable risks of that may occur with the device given its intended use and patient and user populations. These risks should be ones that you can imagine happening with a typical user of the device. Risks follow the typical pathway from Hazard -> Hazardous Situation -> Harm. Between hazard and hazardous situation you can imagine there being a sequence events that can occur. That is why we include a probability there as p1. Between the hazardous situation and the harm, there is another sequence of events that can occur which begets yet another probability. We multiple these two probabilities in the "p1*p2" column to get our final probability of that risk leading to harm for our users or patients.
  
              **Please check the math on the final p1*p2 calculations to ensure they are correct, our AI sometimes forgets its arithmetic**.
              After the risk probability is calculated, we will determine the final P value based on the "Probability of Occurrence Table" and determine the severity of the harm based on the "Severity of Harm Table". Finally, we use our risk matrix we created earlier to determine if our risk is acceptable, reduce risk AFAP, or unacceptable. This is captured in the final column. Double check that these values are correct, amend any risks that could be improved, and add any risks that were forgotten.`,
          },
        },
      },

      {
        id: "risk-controls",
        prompt: true,
        context: ["intended-use", "risk-analysis", "risk-acceptance-matrix"],
        required: true,
        element: {
          id: "risk-controls",
          type: "table",
          options: {
            // Adjust to
            // columns: {
            //   name: string;
            //   options?: {
            //     flex?: number;
            //   };
            // }[];

            columns: [
              { name: "Risk ID", options: { flex: 0 } },
              { name: "Risk Control ID", options: { flex: 0 } },
              { name: "Risk Control Type" },
              { name: "Risk Control Methods" },
              { name: "Risk Control Verification" },
              { name: "Risk Control Effective" },
              { name: "New Probability" },
              { name: "New Severity" },
              { name: "New Risk Acceptability" },
            ],
            idColumn: "Risk ID",

            label:
              "Check that the risk controls suggested in the table are the method you would prefer to reduce risk.",
            helperText: `Risk controls are ways of reducing risks so that the final risk of harm to the patient and/or user is acceptable. There are 3 ways of reducing risk: implementing a safer design, adding protective measures (like alarms, alerts, protective sleeves, etc.), or adding information for safety that the user or patient can read. These are in the "Risk Control Type" column and should not be changed. The other information like the "risk control methods" can be changed to match what you would like to do to reduce risk. Only those risks in the"reduce risk AFAP" or "unacceptable" range really need risk controls but you can implement ones for other risks as well if you would like. Finally, a new Probability of Occurrence and new Severity of Harm are calculated based on the expected results of the risk controls and we can determine our new risk acceptability using the risk matrix we created earlier (and used during the risk analysis last step). We will confirm if these risk controls REALLY worked in the later stages (that is what risk control verification means). For now, we can estimate what we think will happen.`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.USER_NEEDS_LIST]: {
    id: TEMPLATE_TYPE.USER_NEEDS_LIST,
    hidden: false,
    functionalGroup: FunctionalGroup.PD,
    docType: "LST",
    docName: "User Needs List",
    dependencies: ["use-environment"],
    elements: [
      {
        id: "user-needs",
        context: ["use-environment"],
        prompt: true,
        required: true,
        element: {
          id: "user-needs",
          type: "table",
          options: {
            label: "What are the user needs for your product?",
            columns: [
              { name: "User Need ID", options: { flex: 0 } },
              { name: "Type of User Need", options: { flex: 0 } },
              { name: "Category", options: { flex: 0 } },
              { name: "Description" },
            ],
            helperText: `Below is a user needs table where you define what your device will need to be able to do to satisfy your user. Think of it as a to do list of the most critical items that your device must achieve at minimum to work.

- The “**User Need ID**” is a number we will use for traceability. Just make sure they are all unique and follow the format “UNX” where X is a number.
- The “**Type of User Need**” column can be “<ins>Essential</ins>” or “<ins>Product</ins>”. Essential user needs are ones we have defined as being necessary for all products of a specific type (software, hardware, electrical, etc.) and will be similar across all devices. “Product” user needs are ones specific to your particular product. You will want to identify everything including specific features and functions of your product here that your users will need when using your product.
- The “**Category**” column describes what category of requirement the user need originates from. These can include Usability, Safety, Performance, Compatibility, etc. 
- The “**Description**” column describes the user need. User needs are intentionally high level descriptions for what users expect from the device. The collection of all user needs 

Review the rows that have been populated for you and update any descriptions so they are accurate based on what your device does. We want our user needs to be achievable and match exactly what our current version of our product to be. Keep it simple and accurate.

**NOTE**: The regulations don’t specify the “correct” number of user needs a product requires. You should have enough user needs that all the functions of your product are covered.`,
          },
        },
      },
      {
        id: "expected-lifetime",
        prompt: false,
        required: true,
        element: {
          id: "expected-lifetime",
          type: "textField",
          options: {
            label: "What is the expected lifetime of your device?",
            type: "number",
            helperText: `Yes, software products do not age but their operation environments can change and be modified over time. It doesn't always make sense to give our software a "lifetime" but we have to given it is a regulatory requirement. The software lifetime can depend on other factors that it runs on and the need for constant, significant updates to the software that you expect. Also, if it is a higher risk device, you may want to lower the device lifetime just in case any operating environment or accessory changes a few years down the road could significantly impact its safety.
  
            A round number in years. When in doubt, 5 years is the normal lifetime for a software device so just use that.`,
          },
        },
      },
      {
        id: "sterilization-process",
        visibilityCondition: (_, device) => hasSterileComponent(device),
        prompt: true,
        required: true,
        element: {
          id: "sterilization-process",
          type: "textField",
          options: {
            label: "What type of sterilization process do you use?",
          },
        },
      },
      {
        id: "shelf-life",
        visibilityCondition: (_, device) => hasHardware(device),
        prompt: false,
        required: true,
        element: {
          id: "shelf-life",
          type: "textField",
          options: {
            type: "number",
            label: "What is your product's expected shelf-life?",
            helperText: `A round number in years.`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.USER_NEEDS_CHECKLIST]: {
    id: TEMPLATE_TYPE.USER_NEEDS_CHECKLIST,
    docName: "User Needs Review Checklist",
    docType: "RCD",
    hidden: false,
    functionalGroup: FunctionalGroup.PD,
    elements: [
      {
        id: "user-needs-checklist-table",
        prompt: false,
        required: true,
        element: {
          id: "user-needs-checklist-table",
          type: "table",
          options: {
            label:
              "Complete this checklist while reviewing your user needs list.",
            helperText:
              "If any of these boxes are ticked as no, please provide commentary as to why the user needs would still be acceptable even if the box is marked as no.",
            columns: [
              { name: "Checklist Items" },
              { name: "Yes/No", options: { flex: 0 } },
              { name: "Comment" },
            ],

            default: `| Checklist Items                                                                        | Yes/No | Comment |
              | -------------------------------------------------------------------------------------- | ------ | ------- |
              | Each user need has an identifiable number for traceability.                            |        |         |
              | The user needs address all relevant stakeholder requirements.                          |        |         |
              | The user needs take into consideration all relevant aspects of intended use.           |        |         |
              | The user needs include all necessary regulatory requirements.                          |        |         |
              | The user needs include all necessary organizational, technical, or other requirements. |        |         |
              | The user needs are not ambiguous, self-contradicting or conflicting with each other.   |        |         |`,
          },
        },
      },
      {
        id: "user-needs-checklist-comments",
        prompt: false,
        required: false,
        element: {
          id: "user-needs-checklist-comments",
          type: "textField",
          options: {
            label:
              "Provide any comments related to the user needs review that might be relevant.",
            rows: 5,
            helperText:
              "Cover any issues that may have arisen in the review and justify why they are still acceptable or maybe even unacceptable. Anything you think might be important to note.",
          },
        },
      },
      {
        id: "user-needs-checklist-results",
        prompt: false,
        required: true,
        element: {
          id: "user-needs-checklist-results",
          type: "select",
          options: {
            label: "Did the user needs pass review?",
            options: [
              "User needs acceptable",
              "User needs not acceptable",
              "User needs acceptable with obligations",
            ],
          },
        },
      },
      {
        id: "user-needs-checklist-obligations",
        prompt: false,
        required: false,
        element: {
          id: "user-needs-checklist-obligations",
          type: "textField",
          options: {
            label: "What obligations did the user needs pass with?",
            rows: 5,
            helperText:
              "ONLY FILL THIS OUT IF THE REVIEW PASSED WITH OBLIGATIONS. This can be minor updates to any requirements or considerations for features or user needs. Any minor changes that should be included as a result of review.",
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOFTWARE_ARCHITECTURE_CHECKLIST]: {
    id: TEMPLATE_TYPE.SOFTWARE_ARCHITECTURE_CHECKLIST,
    docName: "Software Architecture Checklist",
    docType: "RCD",
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    elements: [
      {
        id: "software-architecture-checklist-table",
        prompt: false,
        required: true,
        element: {
          id: "software-architecture-checklist-table",
          type: "table",
          options: {
            label:
              "Complete this checklist while reviewing your software architecture.",
            columns: [
              { name: "Criteria" },
              { name: "Yes/No", options: { flex: 0 } },
              { name: "Comments" },
            ],
            default: `| Criteria                                                                                                                     | Yes/No | Comments |
                | ---------------------------------------------------------------------------------------------------------------------------- | ------ | -------- |
                | The software architecture implements all system and software requirements.                                                   |        |          |
                | All software systems are listed and their respective safety class has been determined.                                       |        |          |
                | All software items are listed and described, including interfaces.                                                           |        |          |
                | The software architecture supports interfaces between software items and between software items and hardware (if necessary). |        |          |
                | The architecture supports proper operation of any SOUP items, as needed.                                                     |        |          |
                | The software architecture can be implemented with resources provided by the manufacturer.                                    |        |          |`,
            helperText:
              "If any of these boxes are ticked as no, please provide commentary as to why it the software architecture would still be acceptable even if the box is marked as no.",
          },
        },
      },
      {
        id: "software-architecture-checklist-comments",
        prompt: false,
        required: false,
        element: {
          id: "software-architecture-checklist-comments",
          type: "textField",
          options: {
            label:
              "Provide any comments related to the software architecture review that might be relevant.",
            rows: 5,
            helperText:
              "Cover any issues that may have arisen in the review and justify why they are still acceptable or maybe even unacceptable. Anything you think might be important to note.",
          },
        },
      },
      {
        id: "software-architecture-checklist-results",
        prompt: false,
        required: true,
        element: {
          id: "software-architecture-checklist-results",
          type: "select",
          options: {
            label: "Did the software architecture pass review?",
            options: [
              "Software Architecture acceptable",
              "Software Architecture not acceptable",
              "Software Architecture acceptable with obligations",
            ],
          },
        },
      },
      {
        id: "software-architecture-checklist-obligations",
        prompt: false,
        required: false,
        element: {
          id: "software-architecture-checklist-obligations",
          type: "textField",
          options: {
            label: "What obligations did the software architecture pass with?",
            rows: 5,
            helperText:
              "ONLY FILL THIS OUT IF THE REVIEW PASSED WITH OBLIGATIONS. This can be requirements for future resources or the eventual separation of software items. Any minor changes that should be included as a result of review.",
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOUP_LIST]: {
    id: TEMPLATE_TYPE.SOUP_LIST,
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    docType: "LST",
    docName: "Software of Unknown Provenance (SOUP) List",
    elements: [
      {
        id: "soup-dependencies",
        context: ["software-items-table"],
        prompt: false,
        required: true,
        element: {
          id: "soup-dependencies",
          type: "textField",
          options: {
            label: "Provide your software dependencies for each software item.",
            helperText: `Software of unknown provenance (SOUP) is a regulatory term for any software that your team did not create or that wasn't created with the intention of being used in your medical device but, nonetheless, is a part of your medical device.
              
              SOUP can include third party libraries, off the shelf software, and other cloud services. For this reason, we separated them by their associated software items. However, these software items are ONLY considered SOUP if they are used as part of your medical device. If they are not part of the software items that make up your medical device, you don't need to include them. Regulators want to make sure that if we are using foreign software in our medical device, there will be no problems with it failing or performing incorrectly that lead to significant patient harm.
  
  Please provide your software dependencies for each of your software items below. We will consider off the shelf software in the next step so don't worry if not all of your potential SOUP is covered by these dependencies lists. **Make sure the versions in your package manager are pinned and cannot automatically update.**
  
  Here is the format to use for each software item with example dependencies:
  \`\`\`
    Software Item: {name_of_the_software_item}
    Dependencies:
    "react": "18.2.0",
    "react-dom": "18.2.0",
    "react-router-dom": "6.4.2",
    "vite": "4.5.0",
    or
    "numpy==1.21.2",
    "pandas==1.3.3",
    "scikit-learn==1.0",
  \`\`\`
  
  **Exclude any software items that contain no dependencies (e.g. your database)**.
    `,
            rows: 5,
          },
          transformerConfig: {
            type: "default",
            inputs: ["software-items-table"],
            transformer: (inputs: Record<DocumentDataKey, string>) => {
              const softwareItemsTable = markdownTableToObject(
                inputs["software-items-table"]
              );
              return softwareItemsTable
                .map((softwareItem: Record<string, string>) => {
                  return `Software Item: ${softwareItem["Software Item"]}\nDependencies:\n`;
                })
                .join("\n");
            },
          },
        },
      },
      {
        id: "soup-ots",
        prompt: false,
        required: true,
        element: {
          id: "soup-ots",
          type: "table",
          options: {
            label: "Provide any off the shelf software in your medical device.",
            helperText: `Off the shelf software (OTS) is software that serves a specific purpose but was not developed for your medical device. Obviously OTS sounds the same as SOUP but we can think of OTS software as being more independently operating software packages like authentication services, operating systems, etc. OTS can be free, open source, or commercially available.
              Not all OTS is considered a SOUP. OTS is a SOUP when it is used as part of your medical device and to achieve the intended purpose of your medical device software. For example, let's say you have an OTS notification system software that you are using. If the notifications are being used to send your users information on discounts or sales on your product, it is not part of your medical device and therefore not SOUP. However, if that notification system is sending diabetes patients alerts that their blood sugar is low, then that is likely a critical part of your medical device and should be considered a SOUP.
              Provide the name, manufacturer, version, and description as in the previous step but for your OTS SOUP items. Do NOT include any OTS items that are NOT SOUP.`,
            columns: [
              { name: "Name" },
              { name: "Manufacturer" },
              { name: "Version" },
              { name: "Description" },
            ],
            default: `| Name | Manufacturer | Version | Description |
              | ---- | ------------ | ------- | ----------- |
              |      |              |         |             |`,
          },
        },
      },
      {
        id: "soup-risk-levels",
        prompt: true,
        required: true,
        context: ["soup-dependencies", "soup-ots"],
        element: {
          id: "soup-risk-levels",
          type: "table",
          options: {
            label: "Assign a risk level and license to each SOUP item.",
            helperText: `Regulatory requirements are higher for critical SOUP items. In this step, we will determine which SOUP items are considered critical to the functioning of your software medical device. We have assigned all SOUP items as low risk because most are. You need to go through your list and determine which software items should be considered "medium" or "high" risk based on the following criteria:
              - Low Risk - SOUP malfunction is unlikely to lead to patient or user harm
              - Medium Risk - SOUP malfunction has the potential to lead to reversible patient or user harm
              - High Risk - SOUP malfunction has the potential to lead to irreversible patient or user harm
  
              For those SOUP items that are medium and high risk, we will be adding a few more details to them in the next step. It is possible to have NO medium or high risk SOUP items!
              
              **You will need to perform two tasks in the table:**
              * Check the risk levels of each SOUP item and update them to Medium or High Risk if necessary.
              * Update the license for each SOUP item if necessary.`,
            columns: [
              { name: "SOUP ID", options: { flex: 0 } },
              { name: "Software Item" },
              { name: "SOUP Name" },
              { name: "Version", options: { flex: 0 } },
              { name: "Manufacturer" },
              { name: "Description" },
              { name: "Risk Level" },
              { name: "License", options: { flex: 0 } },
            ],
          },
        },
      },
      {
        id: "soup-requirements",
        prompt: true,
        required: true,
        context: [
          "soup-risk-levels",
          "minimum-requirements",
          "software-connections-table",
        ],
        element: {
          id: "soup-requirements",
          type: "table",
          options: {
            label:
              "Provide functional and technical requirements for each medium or high risk SOUP.",
            helperText: `For those SOUP items that you marked as "medium risk" or "high risk", you will need to provide additional details in the "Requirements" column. In this column, very briefly state the following:
                - The functional and performance requirements for the SOUP item that are necessary for your medical device to function
                - The system hardware and software necessary to support the proper operation of the SOUP item
                
                For example, if it is a SOUP item that calculates a blood sugar risk level for diabetic patients, the requirements could be "Calculation of blood sugar risk level using glucose levels and patient biometrics, requires connection to database and access to patient data".`,
            columns: [
              { name: "SOUP ID", options: { flex: 0 } },
              { name: "Software Item", options: { flex: 0 } },
              { name: "SOUP Name", options: { flex: 0 } },
              { name: "Version", options: { flex: 0 } },
              { name: "Manufacturer", options: { flex: 0 } },
              { name: "Description" },
              { name: "Risk Level", options: { flex: 0 } },
              { name: "License", options: { flex: 0 } },
              { name: "Requirements" },
            ],
          },
        },
      },
      {
        id: "soup-list",
        prompt: false,
        required: true,
        context: ["soup-requirements", "soup-risk-levels"],
        element: {
          id: "soup-list",
          type: "table",
          options: {
            label: "Review the final SOUP list.",
            helperText: `Now that we have all of our SOUP items, we can combine them into a single list. This list should include all SOUP items, their risk level, and their requirements.`,
            columns: [
              { name: "SOUP ID", options: { flex: 0 } },
              { name: "Software Item", options: { flex: 0 } },
              { name: "SOUP Name", options: { flex: 0 } },
              { name: "Version", options: { flex: 0 } },
              { name: "Manufacturer", options: { flex: 0 } },
              { name: "Description" },
              { name: "Risk Level", options: { flex: 0 } },
              { name: "License", options: { flex: 0 } },
              { name: "Requirements" },
            ],
          },
          transformerConfig: {
            type: "default",
            inputs: ["soup-risk-levels", "soup-requirements"],
            transformer: (inputs: Record<DocumentDataKey, string>) => {
              try {
                const table1 = parseMarkdownTable(inputs["soup-requirements"]);

                const table2 = parseMarkdownTable(inputs["soup-risk-levels"]);

                const mergedTable = mergeTables(table1, table2, "SOUP ID");
                const mergedMarkdown = toMarkdownTable(mergedTable);

                return mergedMarkdown;
              } catch (e) {
                captureSentryException(e);
                return "";
              }
            },
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOFTWARE_TEST_PLAN]: {
    id: TEMPLATE_TYPE.SOFTWARE_TEST_PLAN,
    docName: "Software System Test Plan",
    docType: "TCD",
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    elements: [
      {
        id: "system-test-plan",
        prompt: true,
        required: true,
        context: [
          "subsystem-requirements",
          "risk-control-subsystem-requirements",
        ],
        element: {
          id: "system-test-plan",
          type: "table",

          options: {
            label: "Create a software system test plan for your software.",
            columns: [
              { name: "SR ID", options: { flex: 0 } },
              { name: "Test ID", options: { flex: 0 } },
              { name: "Objective" },
              { name: "Test Steps" },
              { name: "Acceptance Criteria" },
            ],
            idColumn: "Test ID",
            helperText: `The software system test plan is in place to make sure that all of your software requirements have been met. What you will do is describe different tests to ensure that all aspects of the software requirements are tested and can be confirmed as being incorporated into the software. We have asked the AI to provide example tests but you may need to create more specific ones in order to accurately capture the tasks required to meet your software requirements. Correct any steps that are incorrect and provide more detail to those that are more vague. You will execute this plan to create a report in later stages.`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOFTWARE_ARCHITECTURE]: {
    id: TEMPLATE_TYPE.SOFTWARE_ARCHITECTURE,
    docName: "Software Architecture",
    docType: "TCD",
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    elements: [
      {
        id: "software-items-table",
        prompt: false,
        required: true,
        element: {
          id: "software-items-table",
          type: "table",
          options: {
            label:
              "Describe your software items and tools that make up your software.",
            default: `
              | Software Item | Description             | Programming Language (Version) | Framework (Version) |
              | ------------- | ----------------------- | ------------------------------ | ------------------- |
              | Backend       | Handles data processing | JavaScript (x.x.x)             | NodeJS (x.x.x)      |
              | Frontend      | Displays health data    | TypeScript (5.0.2)             | React (18.2.0)      |
              | Database      | Health data storage     | SQL (x.x.x)                    | PostgreSQL (x.x.x)  |
              `,
            columns: [
              { name: "Software Item" },
              { name: "Description" },
              { name: "Programming Language (Version)" },
              { name: "Framework (Version)" },
            ],
            helperText: `The software architecture you describe here should be detailed enough that the auditors understand the basics of how your software is organized and what its interdependencies are. Keep everything high level and include versions of technologies you use where possible. We recommend breaking your software architecture into the following:
  
                - Description: Write a sentence describing what the software item does in a basic sense
                - Examples of software items: Frontend(s), Backend(s), Database(s), Medical Algorithms
                - Examples of frameworks: React, JavaScript, FastAPI, NodeJS, PostgreSQL, etc.`,
          },
        },
      },
      {
        id: "software-connections-table",
        prompt: true,
        required: true,
        context: ["software-items-table"],
        element: {
          id: "software-connections-table",
          type: "table",
          options: {
            label:
              "Describe how the software is deployed and its connections, and hardware requirements.",
            // default: `
            // | Software Item | Infrastructure | Hardware Requirements               | Operating System | Connections        |
            // | ------------- | -------------- | ----------------------------------- | ---------------- | ------------------ |
            // | Frontend      | AWS            | 1 servers (4 GB memory, 2 CPU each) |                  | Backend            |
            // | Backend       | AWS            | 2 servers (4 GB memory, 2 CPU each) |                  | Frontend, Database |
            // | Database      | Google Cloud   | 3 servers (2 GB memory, 2 CPU each) |                  | Backend            |
            // `,
            columns: [
              { name: "Software Item" },
              { name: "Infrastructure" },
              { name: "Hardware Requirements" },
              { name: "Operating System" },
              { name: "Connections" },
            ],
            helperText: `The software connections provides an idea of how the software is deployed and interconnected. We also will add some basic hardware requirements for the software item. Below are some examples of the software connectivity descriptions.
  
              - Examples of infrastructure: AWS, Digital Ocean, Internal hardware
              - Hardware requirements: Put your minimum requirements for the software item(e.g. - 2 servers (4 GB memory, 2 CPU each), Wi-Fi connection,  iOS and Android Operating system)
              - Connections: List all software items connected to this one. For example if you are filling this out for your Frontend and it is connected to "Backend 1" and the "Database" you would write "Backend 1, Database". Easy as that.`,
          },
        },
      },
      {
        id: "software-architecture-diagram",
        prompt: false,
        context: ["software-connections-table"],
        required: true,
        element: {
          id: "software-architecture-diagram",
          type: "fileUpload",
          options: {
            label: "Upload a diagram of your software architecture.",
            helperText:
              "This diagram should be a high level overview of your software architecture. It should include all software items and their connections. It should also include any hardware requirements for your software items. Consolidate all diagrams into 1 file before uploading.",
            allowedFileTypes: "image/png,image/jpeg,image/jpg",
          },
        },
      },
      {
        id: "software-architecture-security",
        prompt: true,
        context: ["software-items-table", "software-connections-table"],
        required: true,
        element: {
          id: "software-architecture-security",
          type: "textField",
          options: {
            label: "Describe your software security and encryption practices.",
            helperText:
              "Please describe how data security and encryption are handled by your software, if applicable.",
            rows: 5,
          },
        },
      },
      {
        id: "software-architecture-backup",
        prompt: true,
        context: ["software-items-table", "software-connections-table"],
        required: true,
        element: {
          id: "software-architecture-backup",
          type: "textField",
          options: {
            label: "Describe your software backup methods and details.",
            helperText:
              "Please describe how data backup is handled by your software, if applicable. Include backup methods and details like location and frequency.",
            rows: 5,
          },
        },
      },
      {
        id: "minimum-requirements",
        prompt: true,
        context: ["intended-use", "software-items-table"],
        required: true,
        element: {
          id: "minimum-requirements",
          type: "textField",
          options: {
            label:
              "Provide the minimum user system requirements for the computer that your software will run on.",
            rows: 5,
            helperText:
              "This should include things like minimum hardware requirements (CPU, RAM, etc.), minimum software requirements (browser version, operating systems version, etc.), and any other requirements that are necessary for the software to run (Bluetooth version, etc.).",
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.USABILITY_EVALUATION_PLAN]: {
    id: TEMPLATE_TYPE.USABILITY_EVALUATION_PLAN,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docName: "Usability Evaluation Plan",
    docType: "TCD",
    elements: [],
  },
  [TEMPLATE_TYPE.USABILITY_EVALUATION_PROTOCOL]: {
    id: TEMPLATE_TYPE.USABILITY_EVALUATION_PROTOCOL,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docName: "Usability Evaluation Protocol",
    docType: "TCD",
    dependencies: ["user-profile", "use-environment"],
    elements: [
      {
        id: "user-interface",
        prompt: true,
        required: true,
        element: {
          id: "user-interface",
          type: "textField",
          options: {
            label: "Describe the user interface of your device.",
            rows: 5,
            helperText:
              "Provide some details about how your user interacts with your application including the type of platform it is accessed from (browser or smartphone). Additionally, if there are physical steps the user must follow, authentication requirements, or other processes the user needs to undergo during normal use of the device describe them briefly here. This will help set the stage for the testing your users will perform.",
          },
        },
      },
      {
        id: "user-training",
        prompt: true,
        required: true,
        element: {
          id: "user-training",
          type: "textField",
          options: {
            label:
              "Describe the training that is required for your users to be able to use the device.",
            rows: 5,
            helperText:
              "If no specialized training is required, describe briefly any information or company materials that should typically be reviewed prior to use. If nothing is required to be reviewed prior to use, then state no training is required.",
          },
        },
      },
      {
        id: "user-materials",
        prompt: true,
        required: true,
        element: {
          id: "user-materials",
          type: "textField",
          options: {
            label:
              "Describe the informational or training materials that are provided to users with your product.",
            rows: 5,
            helperText:
              "Typically, you will always provide your instructions for use. If you have any additional materials that are provided, please provide the names of those materials.",
          },
        },
      },
      {
        id: "hazard-related-use-scenarios",
        prompt: true,
        required: true,
        context: ["risk-analysis"],
        element: {
          id: "hazard-related-use-scenarios",
          type: "table",
          options: {
            label: "Confirm your hazard-related use scenarios.",
            columns: [
              {
                name: "Risk ID",
                options: { flex: 0 },
              },
              {
                name: "Risk Type",
                options: { flex: 0 },
              },
              {
                name: "Hazard",
              },
              {
                name: "Hazardous Situation",
              },
              {
                name: "Harm",
              },
            ],
            idColumn: "Risk ID",
            helperText:
              "These are pulled from your risk assessment. Confirm that these cover all of the use scenarios for your device that could potentially lead to a hazardous situation.",
          },
        },
      },
      {
        id: "hazard-related-tasks",
        prompt: true,
        context: ["hazard-related-use-scenarios"],
        required: true,
        element: {
          id: "hazard-related-tasks",
          type: "table",
          options: {
            label: "Determine the tasks for your hazard-related use scenarios.",
            columns: [
              {
                name: "Task ID",
                options: { flex: 0 },
              },
              {
                name: "Task",
              },
              {
                name: "Hazard Related Use-Scenario",
              },
              {
                name: "Risk ID",
                options: { flex: 0 },
              },
              {
                name: "Critical Task",
              },
            ],
            idColumn: "Task ID",
            helperText: `Determine the tasks that your user will perform to represent normal use of the device to cover all the hazard-related use scenarios. Also determine if any of these tasks are "critical tasks". A "critical task" is one that, if performed incorrectly, could lead to significant harm (i.e. - hospitalization or requiring medical intervention).`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.USABILITY_EVALUATION_REPORT]: {
    id: TEMPLATE_TYPE.USABILITY_EVALUATION_REPORT,
    docName: "Usability Evaluation Report",
    docType: "TCD",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    elements: [
      {
        id: "formative-evaluations",
        prompt: true,
        required: true,
        element: {
          id: "formative-evaluations",
          type: "textField",
          options: {
            label:
              "Describe what formative evaluations you performed while creating and testing your device.",
            rows: 5,
            helperText: `Formative evaluations are typically part of the development process for medical devices whether or not they are formally planned. Essentially, formative evaluation is testing or checking the quality of your user interface while you develop it (aka not on the final product). This can be achieved by talking to potential users, talking to design experts, testing functionality, and even doing actual trial runs with real users.
  
            Provide a summary of the type of formative testing you performed while you were designing the device. If you consulted with users, experts, or even your team members about the usability of the device and the user interface then include a summary of that here.`,
          },
        },
      },
      {
        id: "formative-summary-suggestions",
        prompt: false,
        required: true,

        element: {
          id: "formative-summary-suggestions",
          type: "textField",
          options: {
            label:
              "Provide the suggestions that your domain experts gave on how the user interface should be designed.",
            rows: 5,
            helperText: `During the process of developing your product you typically work with domain experts who know your users the best. These can be medical experts or just knowledgeable employees. Provide any suggestions or key features that were discussed to be implemented into the user interface during the design process that aimed to make the product more user friendly.`,
          },
        },
      },
      {
        id: "formative-summary-outputs",
        prompt: false,
        required: true,

        element: {
          id: "formative-summary-outputs",
          type: "textField",
          options: {
            label:
              "Describe how you designed your user interface based on the suggestions from your domain experts.",
            rows: 5,
            helperText: `After talking to your domain experts internally, you developed your product with the best user experience you could imagine. Provide the details of what features you implemented based on feedback from your team, being specific to include functional or design aspects that are intended to be user friendly.`,
          },
        },
      },
      {
        id: "user-testing",
        prompt: false,
        required: true,
        element: {
          id: "user-testing",
          type: "table",
          options: {
            label: "Document the results of your user testing.",
            columns: [
              { name: "User ID", options: { flex: 0 } },
              { name: "UD1" },
              { name: "UD2" },
              { name: "UD3" },
              { name: "UD4" },
              { name: "UD5" },
            ],
            helperText: `
              Include any significant user errors encountered and general feedback on the product. If the users said good or bad things about the product, include them here. Also, note any tasks that failed and why.
              
              As a reminder here is what each row should contain for your users:
              - User Type: Is this a lay person, a health professional, someone with a specific training to use your product? Provide an idea of the type of person amongst your user profile that is using the product to give context to their responses.
              - Date of Test: Month, day and year that the test was performed with the user.
              - All Materials and Instructions Provided: Describe what was provided to the user when they used the application. If there was specialized training that occurred prior to their use make sure to state that. If no instructions were given, state so. If the material or instructions for use were provided electronically within the app then state so.
              - Product Tested: Provide the version and name of the product tested with the user.
              - Tasks Passed: Provide the number of tasks the user successfully completed.
              - Tasks Failed: Provide the number of tasks the user did not successfully complete.
              - User Errors: Describe any user errors that the user committed while using the application. User errors are when the user misuses the product and their misuse has the potential to lead to user harm.
              - Feedback from Users: Provide any feedback acquired from the users during or following use of the application.
              - Observations by Tester: Provide any observations related to usability that the person administering the user test made note of while that user was performing tasks. These can be positive or negative.
              - Harms / Hazardous Situations Encountered: Describe any particular harms or hazardous situations that were encountered by the users while using the application. If none were encountered then list ""None"".
              - Critical Tasks Passed: Provide the number of critical tasks that were passed. If there were no critical tasks tested then write "Not Applicable".
              - Critical Tasks Failed: Provide the number of critical tasks that were not passed. If there were no critical tasks tested then write "Not Applicable".
              `,
            default: `
              | User ID                                  | UD1 | UD2 | UD3 | UD4 | UD5 |
              | ---------------------------------------- | --- | --- | --- | --- | --- |
              | User Name                                |     |     |     |     |     |
              | User Age                                 |     |     |     |     |     |
              | User Type                                |     |     |     |     |     |
              | Date of Test                             |     |     |     |     |     |
              | All Materials and Instructions Provided  |     |     |     |     |     |
              | Product Tested                           |     |     |     |     |     |
              | Tasks Passed                             |     |     |     |     |     |
              | Tasks Failed                             |     |     |     |     |     |
              | Use Errors                               |     |     |     |     |     |
              | Feedback from User                       |     |     |     |     |     |
              | Observations by Tester                   |     |     |     |     |     |
              | Harms / Hazardous Situations Encountered |     |     |     |     |     |
              | Critical Tasks Passed                    |     |     |     |     |     |
              | Critical Tasks Failed                    |     |     |     |     |     |`,
          },
        },
      },
      {
        id: "user-testing-summary",
        prompt: true,
        required: true,
        context: ["user-testing"],
        element: {
          id: "user-testing-summary",
          type: "textField",
          options: {
            label:
              "Summarize the results of the user testing your performed as part of your summative evaluation.",
            rows: 5,
            helperText: `Include any significant user errors encountered and general feedback on the product. If the users said good or bad things about the product include them here. Also, note any tasks that failed and why.`,
          },
        },
      },
      {
        id: "user-testing-impact",
        prompt: false,
        required: true,
        element: {
          id: "user-testing-impact",
          type: "table",
          options: {
            label: "Review the impact of the user testing.",
            default: `
  | Processes or Specification            | Impacted | Changes to be Implemented |
  | ------------------------------------- | -------- | ------------------------- |
  | Risk Management File                  |   Yes/No       |                           |
  | Software Requirements                 |     Yes/No      |                           |
  | Software and/or User Interface Design |      Yes/No     |                           |
  | Use Specification                     |      Yes/No     |                           |
  `,
            columns: [
              { name: "Processes or Specification", options: { flex: 0 } },
              { name: "Impacted", options: { flex: 0 } },
              { name: "Changes to be Implemented" },
            ],
            helperText: `Use the results from your user testing to determine what processes or product specifications are impacted. Using the data you just provided in the user testing table, fill in the table below with whether or not each of the listed processes are impacted. Criteria for each potential impacted process are as follows:
              
              - Risk Management File: The risk management file is only impacted if you encountered new risks for the application that weren’t already captured in your risk assessment or you found that the risk controls you put into place didn’t work. If users encountered new risks in the “User Errors” row or the “Harms / Hazardous Situations Encountered” row that weren’t identified in your risk assessment then put “Yes” in the “Impacted” column and “Update risk management file with new risks” in the “Changes to be Implemented” column. Otherwise, put “No” in the “Impacted” column.
              - Software Requirements: Your software requirements will be impacted if you find that there are new software design features that need to be implemented or existing design features that need to be significantly changed. A small change like font size, button location, colors, etc. do not require software requirements updates. If you need to change a whole function or feature of the software based on the feedback from your user testing, put “Yes” in the “Impact” column and fill in those changes in “Changes to be Implemented” column. Otherwise, put “No” in the “Impact” column.
              - Software and/or User Interface Design: If there are changes to the user interface that need to be implemented based on user feedback, put “Yes” in the “Impacted” section and put what they are in the “Changes to be Implemented” section. Not all feedback necessitates a change. Usually, if tasks fail repeatedly you will want to implement changes based on that.
              - Use Specification: Your use specification includes your intended use, indications for use, intended use environment, intended user group and patient population. Usually, you will only have a significant change to your use specification if some aspects of your testing fail so significantly that your intended users can’t use the product. Do you need to change how the product is used (only used in a healthcare facility vs at home)? If these need to be updated, put “Yes” into the “Impacted” column and provide a description of what will need to be changed. Otherwise put “No” in the “Impacted” column.`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOFTWARE_SYSTEM_TEST_REPORT]: {
    id: TEMPLATE_TYPE.SOFTWARE_SYSTEM_TEST_REPORT,
    docName: "Software System Test Report",
    docType: "TCD",
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    elements: [
      {
        id: "system-test-report",
        prompt: false,
        required: true,
        context: ["system-test-plan"],

        element: {
          id: "system-test-report",
          type: "table",
          transformerConfig: {
            type: "default",
            inputs: ["system-test-plan"],
            transformer: (inputs: Record<DocumentDataKey, string>) => {
              try {
                // transform the table to a list of objects to be able to manipulate it
                const tableAsArray = parseMarkdownTable(
                  inputs["system-test-plan"]
                );

                // create a new array to store the new table
                const systemTestReport = tableAsArray.map(
                  (row: Record<string, string>) => ({
                    "SR ID": row["SR ID"],
                    "Test ID": row["Test ID"],
                    Objective: row["Objective"],
                    "Acceptance Criteria": row["Acceptance Criteria"],
                    Acceptable: "",
                    "Results if Unacceptable": "",
                  })
                );

                const mergedMarkdown = toMarkdownTable(systemTestReport);

                return mergedMarkdown;
              } catch (e) {
                captureSentryException(e);
                return "";
              }
            },
          },
          options: {
            label: "Create a software system test report for your software.",
            // | SR ID | Test ID | Objective                                     | Acceptance Criteria                                                 | Acceptable | Results if Unacceptable |
            columns: [
              { name: "SR ID", options: { flex: 0 } },
              { name: "Test ID", options: { flex: 0 } },
              { name: "Objective" },
              { name: "Acceptance Criteria" },
              { name: "Acceptable", options: { flex: 0 } },
              { name: "Results if Unacceptable" },
            ],
            helperText: `We created a software system test plan earlier which needed to be executed by your team in order to verify the software system meets the software requirements. Using the table below, check that each software test was completed and, if the acceptance criteria were not met, how the test results differed from expectations along with their impact. For the "Acceptable" column put "Acceptable" if the test met acceptance criteria or "Unacceptable" if it didn't.`,
          },
        },
      },
    ],
  },

  [TEMPLATE_TYPE.BUG_FIX_REPORT]: {
    id: TEMPLATE_TYPE.BUG_FIX_REPORT,
    docName: "Bug Fix Report",
    docType: "RCD",
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    docReferences: [TEMPLATE_TYPE.SOP_CHANGE_MANAGEMENT],
    elements: [
      {
        id: "bug-fix-id",
        prompt: false,
        required: true,
        element: {
          id: "bug-fix-id",
          type: "textField",
          options: {
            label: "Provide an ID for your bug fix.",
            helperText:
              "Create an ID for your bug fix. This can be #B01 or whatever naming schema you prefer. If you have one that you are using in Jira feel free to just use that.",
          },
        },
      },
      {
        id: "bug-fix-date",
        prompt: false,
        required: true,
        element: {
          id: "bug-fix-date",
          type: "textField",
          options: {
            label: "What date was the bug first reported?",
            helperText: "Use YYYY-MM-DD format.",
          },
        },
      },
      {
        id: "bug-description",
        prompt: false,
        required: true,
        element: {
          id: "bug-description",
          type: "textField",
          options: {
            label: "Provide a description of the bug.",
            helperText:
              "Describe the bug in detail, including the affected software items and the scenario the user encountered or system encountered when the bug presented itself.",
            rows: 5,
          },
        },
      },
      {
        id: "bug-software-version",
        prompt: false,
        required: true,
        element: {
          id: "bug-software-version",
          type: "textField",
          options: {
            label: "What product and software version was the bug reported in?",
            helperText:
              "Please provide the product name AND the software version.",
          },
        },
      },
      {
        id: "bug-corrective",
        prompt: false,
        required: true,
        element: {
          id: "bug-corrective",
          type: "textField",
          options: {
            label: "Describe how you will correct the bug.",
            helperText:
              "Describe how the bug will be corrected. No need for specific code or anything, just give a description of how you will implement a change to correct the error.",
            rows: 5,
          },
        },
      },
      {
        id: "bug-preventive",
        prompt: false,
        required: true,
        element: {
          id: "bug-preventive",
          type: "textField",
          options: {
            label:
              "Describe how you will prevent future bugs like this from occurring.",
            helperText:
              "Describe how you will ensure that bugs like this will be identified in the future. An example can be adding a test to your usability evaluation plan or adding in another software system test. If this bug is already captured via another test, identify that preventative actions aren't required and describe why. If it is impossible to prevent bugs like this from occurring then state so and describe why.",
            rows: 5,
          },
        },
      },
      {
        id: "bug-software-requirement",
        prompt: false,
        required: true,
        element: {
          id: "bug-software-requirement",
          type: "textField",
          options: {
            label: "What software requirement does this bug relate to, if any?",
            helperText:
              "Does this bug relate to a specific software requirement? If it does, put the software requirement ID# and the description of the software requirement here. If not, say there is none and describe why a new one should be added or a new one isn't needed.",
            rows: 5,
          },
        },
      },
      {
        id: "bug-risk",
        prompt: false,
        required: true,
        element: {
          id: "bug-risk",
          type: "table",
          options: {
            label:
              "Does the bug present any new risks that aren't in your risk assessment already?",
            columns: [
              { name: "Risk ID", options: { flex: 0 } },
              { name: "Hazard" },
              { name: "Hazardous Situation" },
              { name: "Harm" },
            ],
            helperText: `If the risk is already captured in the risk assessment, provide the risk ID and a short description of the risk here. If there is no risk that covers this bug, describe the new risk to be created and provide the new risk ID and a short description here. If the bug presents no risk to the user, state that here.`,
            default: `
              | Risk ID | Hazard | Hazardous Situation | Harm |
              | ------- | ------ | ------------------- | ---- |`,
            addRowEnabled: true,
          },
        },
      },
      {
        id: "bug-test",
        prompt: false,
        required: true,
        element: {
          id: "bug-test",
          type: "table",
          options: {
            label:
              "Provide a software test that checks to make sure the bug is fixed.",
            columns: [
              { name: "SR ID", options: { flex: 0 } },
              { name: "Test ID", options: { flex: 0 } },
              { name: "Objective" },
              { name: "Test Steps" },
              { name: "Acceptance Criteria" },
            ],
            helperText: `If there is a software test that already exists than can be used to ensure that this bug is successfully fixed, insert it in the table. If there is no software test that covers this bug, describe a new software test that will be added to the software system test plan to account for it. Then update that document to include the new test.

            - SR ID: Provide the software requirements ID that you described previously (if relevant)
            - Test ID: Provide a test ID for the test you will perform to check if the bug has been fixed (If you are creating a new test, make sure not to use a test ID that already exists in your Software System Test plan)
            - Objective: Provide the objective of your test. This can be "Download a file from the dashboard"
            - Test Steps: Provide the steps that will be performed by the tester in order to complete the test. Number the steps out as "1., 2. 3. etc."
            - Acceptance Criteria: Provide what you have determined is the acceptance criteria for the test so the tester knows whether it was successfully passed or not.`,
            default: `
              | SR ID | Test ID | Objective | Test Steps | Acceptance Criteria |
              | ----- | ------- | --------- | ---------- | ------------------- |
              |       |         |           |            |                     |`,
            addRowEnabled: true,
          },
        },
      },
      {
        id: "bug-test-results",
        prompt: false,
        required: true,
        element: {
          id: "bug-test-results",
          type: "table",
          transformerConfig: {
            // We want to take the bug-test table and transform it into a bug-test-results table
            type: "default",
            inputs: ["bug-test"],
            transformer: (inputs: Record<DocumentDataKey, string>) => {
              try {
                // transform the table to a list of objects to be able to manipulate it
                const tableAsArray = parseMarkdownTable(inputs["bug-test"]);

                // create a new array to store the new table
                const bugTestResults = tableAsArray.map(
                  (row: Record<string, string>) => ({
                    "SR ID": row["SR ID"],
                    "Test ID": row["Test ID"],
                    Objective: row["Objective"],
                    "Test Steps": row["Test Steps"],
                    "Acceptance Criteria": row["Acceptance Criteria"],
                    "Acceptable (Yes/No)": "",
                    Comments: "",
                  })
                );

                const mergedMarkdown = toMarkdownTable(bugTestResults);

                return mergedMarkdown;
              } catch (e) {
                captureSentryException(e);
                return "";
              }
            },
          },
          options: {
            label: "Provide the results of the bug test.",
            columns: [
              { name: "SR ID", options: { flex: 0 } },
              { name: "Test ID", options: { flex: 0 } },
              { name: "Objective" },
              { name: "Test Steps" },
              { name: "Acceptance Criteria" },
              { name: "Acceptable (Yes/No)", options: { flex: 0 } },
              { name: "Comments" },
            ],
            helperText: `Perform the test and provide the results here. If the test is performed and it meets the acceptance criteria, then you can fill out the "Acceptable" column with a yes. If there were any issues or points worth noting from the test put them in the "Comments" column.`,
            default: `
              | SR ID | Test ID | Objective | Test Steps | Acceptance Criteria | Acceptable (Yes/No) | Comments |
              | ----- | ------- | --------- | ---------- | ------------------- | ------------------- | -------- |`,
            addRowEnabled: true,
          },
        },
      },
      {
        id: "bug-tester",
        prompt: false,
        required: true,
        element: {
          id: "bug-tester",
          type: "table",
          options: {
            label: "What date was the test performed and who performed it?",
            columns: [
              { name: "Test Date", options: { flex: 0 } },
              { name: "Tester Name" },
            ],
            default: `
              | Test Date | Tester Name |
              | --------- | ----------- |
              | ${moment().format("YYYY-MM-DD")}          |             |`,
          },
        },
      },
      {
        id: "bug-additional",
        prompt: false,
        required: true,
        element: {
          id: "bug-additional",
          type: "textField",
          options: {
            label: "Was any additional testing required for this bug?",
            helperText: `Is there any additional testing required in order to ensure that the bug is fixed? If it is a bug that concerns the usability of the device and the ability of the user to perform normal operations of the device or even critical tasks, it might be relevant to add a new task in your Usability Evaluation Plan to ensure that the product usability is sufficient. Other types of testing can be cybersecurity tests or clinical investigations. Describe the additional testing performed here if any was performed including any relevant test ID's and descriptions as appropriate.

            If no additional testing is required then state that "No additional testing was required" here.`,
            rows: 5,
          },
        },
      },
      {
        id: "bug-release",
        prompt: false,
        required: true,
        element: {
          id: "bug-release",
          type: "table",
          options: {
            label:
              "Provide the details for the updated software with the bug fix.",
            columns: [
              { name: "Product Name" },
              { name: "Updated Version with Bug Fix" },
              { name: "Date Released" },
            ],
            default: `
              | Product Name | Updated Version with Bug Fix | Date Released |
              | ------------ | ---------------------------- | ------------- |
              |              |                              |               |`,
            addRowEnabled: true,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.LIST_OF_KNOWN_ANOMALIES]: {
    id: TEMPLATE_TYPE.LIST_OF_KNOWN_ANOMALIES,
    docName: "List of Known Anomalies",
    docType: "LST",
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    elements: [
      {
        id: "software-version",
        prompt: false,
        required: true,
        element: {
          id: "software-version",
          type: "textField",
          options: {
            label: "What is the version of the software?",
          },
        },
      },
      {
        id: "known-anomalies",
        prompt: false,
        required: true,
        element: {
          id: "known-anomalies",
          type: "table",
          options: {
            label: "Document any known anomalies that you have.",
            // | Name of Anomaly | Description | Impact on Risk | Risk Acceptable | Correction Action(s) | Correction Timeline |
            columns: [
              { name: "Name of Anomaly" },
              { name: "Description" },
              {
                name: "Impact on Risk",
                options: {
                  // type: "singleSelect",
                  // valueOptions: [
                  //   "No impact on risk",
                  //   "Low impact on risk",
                  //   "Significant impact on risk",
                  // ],
                },
              },
              { name: "Risk Acceptable", options: { flex: 0 } },
              { name: "Correction Action(s)" },
              { name: "Correction Timeline" },
            ],
            default: `
              | Name of Anomaly | Description | Impact on Risk | Risk Acceptable | Correction Action(s) | Correction Timeline |
              | --------------- | ----------- | --------------- | --------------- | ------------------- | ------------------- |
              |                 |             |                 |                 |                     |                     |`,

            helperText: `If there are any bugs or anomalies in the software after testing that you do not plan to fix during this software release, list them below. These can come out of software testing or usual software problem documentation during development. For each column describe the anomaly and what its impact is on the user and/or patient as far as risk is concerned. All known anomalies should have an acceptable risk to the patients or else they need to be fixed prior to release. If you have no known anomalies left to release then nice work, you can leave this table blank.
  
              The definition of each table column is as follows:
              - Name of Anomaly: Describe what the anomaly is using a few words. For example if your data doesn't sync as quickly as you would like it to you could name this "Data Sync Delay"
              - Description: Here provide a more detailed description of what the anomaly is. Provide some context regarding what, when, and why.
              - Impact on Risk: The anomaly should have one of three options "No impact on risk", "Low impact on risk", or "Significant impact on risk". The impact on risk refers to patient risk and if the anomaly has no possibility of leading to patient harm in any way, then it has "No impact on risk". If it can lead to some patient harm ("Low risk") or can lead to significant patient harm ("significant risk"), then you need do say so and describe what the risk is to the patient. 
              - Risk Acceptable: This is either "Acceptable" or "Unacceptable". If your impact on risk is "No impact on risk" or "Low risk" then you can put "Acceptable" If it is a "Significant impact on risk" then it is "unacceptable".
              - Correction Action(s): Describe here what corrective actions you will take for this anomaly. These can be actions you intend to take down the road or in the very next software version or patch. An example related to the data syncing delay is "improve algorithm for data synchronization". 
              - Correction Timeline: Describe when you plan to implement these changes. This can be "Next major software release", "Next minor software release" or "Next bug fix".`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOP_DEPLOYMENT]: {
    id: TEMPLATE_TYPE.SOP_DEPLOYMENT,
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    docType: "SOP",
    docName: "SOP Deployment",
    elements: [],
  },
  [TEMPLATE_TYPE.DEPLOYMENT_EVALUATION_CHECKLIST]: {
    id: TEMPLATE_TYPE.DEPLOYMENT_EVALUATION_CHECKLIST,
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    docType: "RCD",
    docName: "Deployment Evaluation Checklist",
    elements: [
      {
        id: "deployment-customer",
        prompt: false,
        required: true,
        element: {
          id: "deployment-customer",
          type: "table",
          options: {
            label: "Provide the customer's information here.",
            helperText:
              "Fill in the table with information on the customer for which you will be deploying your software.",
            columns: [{ name: "Customer Information" }, { name: "" }],
            default: `
              | Customer Information     |     |
              | ------------------------ | --- |
              | Customer Contact Name    |     |
              | Customer Contact Email   |     |
              | Customer Contact Phone   |     |
              | Customer Company Name    |     |
              | Customer Company Address |     |`,
          },
        },
      },
      {
        id: "deployment-infrastructure",
        prompt: false,
        required: true,
        element: {
          id: "deployment-infrastructure",
          type: "textField",
          options: {
            label:
              "Describe the infrastructure the product will be deployed in.",
            helperText:
              "Provide a description of the infrastructure that the product will be deployed on. Include information on the complexity of the system, any interdependencies, and the number of users.",
          },
        },
      },
      {
        id: "infrastructure-complexity",
        prompt: false,
        required: true,
        element: {
          id: "infrastructure-complexity",
          type: "select",
          options: {
            label: "What is the complexity of the infrastructure.",
            options: [
              "Insignificant Complexity: The infrastructure is well established with few interdependencies and a robust backup and support system in place. There is a very low risk of system failures or issues that could compromise the safety and performance of the deployed device.",
              "The infrastructure is complex with multiple interdependencies and a medium to high risk of system failures or issues that could compromise the safety and performance of the deployed device.",
            ],
          },
        },
      },
      {
        id: "infrastructure-complexity-justification",
        prompt: false,
        required: true,
        element: {
          id: "infrastructure-complexity-justification",
          type: "textField",
          options: {
            label: "Provide a justification for the complexity level selected.",
            helperText:
              "Give details on why you chose either insignificant or significant complexity previously. Provide a description of the risks or failures that could be expected and why those do or do not compromise the safety and performance of your device when it is deployed.",
            rows: 5,
          },
        },
      },
      {
        id: "installation-site",
        prompt: false,
        required: true,
        element: {
          id: "installation-site",
          type: "select",
          options: {
            label: "How will installation occur?",
            options: ["On-site", "Remotely"],
          },
        },
      },
      {
        id: "installation-plan",
        prompt: false,
        required: true,
        element: {
          id: "installation-plan",
          type: "textField",
          options: {
            label: "Describe your plan for installing the product.",
            helperText:
              "Provide a description of the installation plan. Include information on the installation process, the steps involved, what responsibility team members will have during the process, and the expected timeline for completion.",
          },
        },
      },
      {
        id: "installation-acceptance-criteria",
        prompt: false,
        required: true,
        element: {
          id: "installation-acceptance-criteria",
          type: "textField",
          options: {
            label:
              "What are the acceptance criteria for a successful installation?",
            helperText:
              "Provide a list of criteria that will need to be met in order for the installation to be considered successful. This can be a list of tests or actions like 'user can sign into their account' or 'program startup successful on client computer'.",
            rows: 5,
          },
        },
      },
      {
        id: "measuring-monitoring",
        prompt: false,
        required: true,
        element: {
          id: "measuring-monitoring",
          type: "select",
          options: {
            label:
              "How will measuring and monitoring of the deployed device occur?",
            options: ["Automatically", "Manually"],
          },
        },
      },
      {
        id: "measuring-monitoring-plan",
        prompt: false,
        required: true,
        element: {
          id: "measuring-monitoring-plan",
          type: "textField",
          options: {
            label:
              "What is your plan for measuring and monitoring your deployed device?",
            helperText:
              "Provide a description of the measuring and monitoring plan for the device. Describe how device monitoring and measuring will be conducted. If it will be measured automatically through on-site or remote software, systems or equipment, describe what those are and provide information on how and when they will be used. If you will monitor the device manually provide a brief description of how this will be done (phone calls, emails, on site visits, etc.) and how often.",
          },
        },
      },
      {
        id: "deployment-date",
        prompt: false,
        required: true,
        element: {
          id: "deployment-date",
          type: "textField",
          options: {
            label: "What date did you do your deployment?",
          },
        },
      },
      {
        id: "installation-success",
        prompt: false,
        required: true,
        element: {
          id: "installation-success",
          type: "select",
          options: {
            label: "Was your installation successful?",
            options: [
              "Yes - Installation met all acceptance criteria.",
              "No - Installation did not meet all acceptance criteria.",
            ],
          },
        },
      },
      {
        id: "installation-description",
        prompt: false,
        required: true,
        element: {
          id: "installation-description",
          type: "textField",
          options: {
            label: "Describe how the installation went?",
            helperText:
              "Provide a description of the installation process and any issues that were encountered during the installation. Describe whether the acceptance criteria was met and, if any issues were encountered",
          },
        },
      },
      {
        id: "deployment-success",
        prompt: false,
        required: true,
        element: {
          id: "deployment-success",
          type: "select",
          options: {
            label: "Did deployment occur successfully?",
            options: [
              "Yes - The product was properly and successfully deployed.",
              "No - The product was not properly and successfully deployed.",
            ],
          },
        },
      },
      {
        id: "deployed-version",
        prompt: false,
        required: true,
        element: {
          id: "deployed-version",
          type: "textField",
          options: {
            label: "What version of your product was deployed?",
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOP_SOFTWARE_VALIDATION]: {
    id: TEMPLATE_TYPE.SOP_SOFTWARE_VALIDATION,
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    docType: "SOP",
    docName: "SOP Computer System Validation",
    elements: [],
  },
  [TEMPLATE_TYPE.LIST_OF_VALIDATED_SOFTWARE]: {
    id: TEMPLATE_TYPE.LIST_OF_VALIDATED_SOFTWARE,
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    docType: "LST",
    docName: "List of Validated Software",
    elements: [
      {
        id: "validated-software-list",
        prompt: false,
        required: true,
        element: {
          id: "validated-software-list",
          type: "table",
          options: {
            label: "Provide your validated software in this list.",
            columns: [
              { name: "Software Name" },
              { name: "Manufacturer" },
              { name: "Processes Covered" },
              { name: "Validation Form Document Number" },
              { name: "In use / Decommissioned" },
              { name: "Last Validation Date" },
              { name: "Next Review Date" },
            ],
            addRowEnabled: true,
            helperText: `Only add items to this list once you have validated them with a Software Validation Form.`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOFTWARE_VALIDATION_FORM]: {
    id: TEMPLATE_TYPE.SOFTWARE_VALIDATION_FORM,
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    docType: "RCD",
    docName: "Software Validation Form",
    elements: [
      {
        id: "validation-software-name",
        prompt: false,
        required: true,
        element: {
          id: "validation-software-name",
          type: "textField",
          options: {
            label: "Provide the name of the software you intended to validate.",
          },
        },
      },
      {
        id: "validation-software-version",
        prompt: false,
        required: true,
        element: {
          id: "validation-software-version",
          type: "textField",
          options: {
            label:
              "Provide the version of the software you intended to validate.",
          },
        },
      },
      {
        id: "validation-software-manufacturer",
        prompt: false,
        required: true,
        element: {
          id: "validation-software-manufacturer",
          type: "textField",
          options: {
            label:
              "Provide the manufacturer of the software you intended to validate.",
          },
        },
      },
      {
        id: "validation-software-link",
        prompt: false,
        required: true,
        element: {
          id: "validation-software-link",
          type: "textField",
          options: {
            label:
              "Provide a link to the website of the software you intended to validate.",
          },
        },
      },
      {
        id: "validation-software-description",
        prompt: true,
        context: [
          "validation-software-name",
          "validation-software-manufacturer",
        ],
        required: true,
        element: {
          id: "validation-software-description",
          type: "textField",
          options: {
            label:
              "Provide a description of the software you intended to validate.",
            rows: 5,
            helperText:
              "In your description, make sure to include the features that your organization will be using specifically to replace any quality management processes or for production and service provisions. ",
          },
        },
      },
      {
        id: "validation-qualification",
        prompt: false,
        required: true,
        element: {
          id: "validation-qualification",
          type: "table",
          options: {
            label: "Answer the questions in the table with a Yes or No.",
            helperText: `If any of the criteria are answered with "yes", the software is considered quality relevant and should be validated through completion of this form. If all criteria are answered with "no", the software is not quality relevant and does not need to be validated.`,
            default: `
  |Criteria | Yes/No |
  | ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ | ------ |
  | Would the failure or latent flaws of the software affect the safety or quality of the medical device(s)?                                                                                                                             |        |
  | Does the software automate or execute an activity required by regulatory requirements (in particular, the requirements for the quality management system)?                                                                           |        |
  | Could the output of the software end up in a released product without being checked, verified, or validated first? (ISO 13485:2016 - Section 7.5.6) |        |
                `,
            columns: [
              { name: "Criteria" },
              { name: "Yes/No", options: { flex: 0 } },
            ],
          },
        },
      },
      {
        id: "validation-processes-replacing",
        prompt: true,
        required: true,
        context: [
          "validation-software-name",
          "validation-software-description",
        ],
        element: {
          id: "validation-processes-replacing",
          type: "textField",
          options: {
            label: "What processes is the software intended to replace?",
            rows: 5,
            helperText: `ONLY CONTINUE IF YOU MARKED AT LEAST ONE ROW AS A "YES" FROM THE PREVIOUS QUESTION. If all rows were marked as no, the software does not need to be validated through this form and you can exit the form.
  
                The processes the software is intended to replace can be manufacturing, testing or quality management processes. For example, an eQMS replaces document storage and electronic signatures for its users.`,
          },
        },
      },
      {
        id: "validation-intended-use",
        prompt: true,
        context: [
          "validation-software-name",
          "validation-software-description",
          "validation-processes-replacing",
        ],
        required: true,
        element: {
          id: "validation-intended-use",
          type: "textField",
          options: {
            label: "What is the intended use of this software?",
            rows: 5,
            helperText:
              "This is essentially a short description of exactly what the software does for your organization specifically. Only include a statement of what it will do for you and not the other features that you will not be using.",
          },
        },
      },
      {
        id: "validation-software-requirements",
        prompt: true,
        required: true,
        context: [
          "validation-software-name",
          "validation-intended-use",
          "validation-processes-replacing",
        ],
        element: {
          id: "validation-software-requirements",
          type: "table",
          options: {
            label:
              "What are the software requirements to meet this intended use?",
            helperText:
              "Provide a simple list of what the software should be doing in order to achieve the intended use.",
            columns: [
              { name: "VS ID", options: { flex: 0 } },
              { name: "Software Requirement" },
            ],
          },
        },
      },
      {
        id: "validation-risk-assessment",
        prompt: true,
        required: true,
        context: [
          "validation-software-name",
          "validation-intended-use",
          "validation-processes-replacing",
          "validation-software-requirements",
        ],
        element: {
          id: "validation-risk-assessment",
          type: "table",
          options: {
            label:
              "Review the risk assessment to make sure all risks are captured.",
            columns: [
              { name: "Risk ID", options: { flex: 0 } },
              { name: "Hazard" },
              { name: "Hazardous Situation" },
              { name: "Harm" },
              { name: "Likelihood" },
              { name: "Severity" },
              { name: "Risk Acceptability" },
            ],
          },
        },
      },
      {
        id: "validation-risk-controls",
        prompt: true,
        required: true,
        context: [
          "validation-software-name",
          "validation-intended-use",
          "validation-processes-replacing",
          "validation-risk-assessment",
        ],
        element: {
          id: "validation-risk-controls",
          type: "table",
          options: {
            label: "Review the risk controls for your software.",
            columns: [
              { name: "Risk ID", options: { flex: 0 } },
              { name: "Risk Control Type" },
              { name: "Risk Control Description" },
              { name: "New Likelihood" },
              { name: "New Severity" },
              { name: "Residual Risk Acceptability" },
            ],
          },
        },
      },
      {
        id: "validation-test-plan",
        prompt: true,
        required: true,
        context: [
          "validation-software-name",
          "validation-intended-use",
          "validation-processes-replacing",
          "validation-software-requirements",
          "validation-risk-controls",
        ],
        element: {
          id: "validation-test-plan",
          type: "table",
          options: {
            label: "Review and update your validation test plan.",
            columns: [
              { name: "VS ID", options: { flex: 0 } },
              { name: "Test ID", options: { flex: 0 } },
              { name: "Objective" },
              { name: "Test Steps" },
              { name: "Acceptance Criteria" },
            ],
          },
        },
      },
      {
        id: "validation-test-environment",
        prompt: true,
        required: true,
        context: [
          "validation-software-name",
          "validation-intended-use",
          "validation-processes-replacing",
          "validation-test-plan",
        ],
        element: {
          id: "validation-test-environment",
          type: "textField",
          options: {
            label:
              "What is the test environment you will test the software in?",
            rows: 5,
            helperText:
              "This can be the browser, operating system, or whatever infrastructure you need to run the software validation tests.",
          },
        },
      },
      {
        id: "validation-participants",
        prompt: false,
        required: true,
        element: {
          id: "validation-participants",
          type: "table",
          options: {
            label: "Who will be participating in the software validation?",
            columns: [{ name: "Name" }, { name: "Position" }],
            addRowEnabled: true,
          },
        },
      },
      {
        id: "validation-test-date",
        prompt: false,
        required: true,
        element: {
          id: "validation-test-date",
          type: "textField",
          options: {
            label: "When was the validation test performed?",
          },
        },
      },
      {
        id: "validation-test-results",
        prompt: true,
        context: ["validation-test-plan"],
        required: true,
        element: {
          id: "validation-test-results",
          type: "table",
          options: {
            label: "Provide the results of your validation testing.",
            columns: [
              { name: "Test ID", options: { flex: 0 } },
              { name: "Test Description" },
              { name: "Acceptance Criteria" },
              { name: "Result" },
              { name: "Pass/Fail" },
            ],
            helperText: `Fill in the table with the results of the testing that you performed in order to validate the software being tested. Provide a quick summary of the test results. If the test results met the acceptance criteria make sure to state so in the table and mark the test as "Pass". If they didn't, mark it as "Fail".`,
          },
        },
      },
      {
        id: "validation-conclusion",
        prompt: true,
        context: [
          "validation-test-results",
          "validation-software-name",
          "validation-intended-use",
        ],
        required: true,
        element: {
          id: "validation-conclusion",
          type: "textField",
          options: {
            label: "What is the conclusion of your validation testing?",
            rows: 5,
          },
        },
      },
      {
        id: "validation-deployment",
        prompt: true,
        required: true,
        context: [
          "validation-software-name",
          "validation-intended-use",
          "validation-processes-replacing",
        ],
        element: {
          id: "validation-deployment",
          type: "textField",
          options: {
            label: "Provide the deployment process for the validated software.",
            rows: 5,
            helperText:
              "Describe how you intend to incorporate the software into your systems process flow. Do you need to remove any existing software? Will there be downtime and will there be a time period to transition over?",
          },
        },
      },
      {
        id: "validation-monitoring-review",
        prompt: true,
        required: true,
        context: [
          "validation-software-name",
          "validation-intended-use",
          "validation-processes-replacing",
          "validation-software-requirements",
        ],
        element: {
          id: "validation-monitoring-review",
          type: "textField",
          options: {
            label:
              "What processes are in place to monitor or review the validated software?",
            rows: 5,
            helperText:
              "How and when will you monitor that this software is working? Provide a brief description of the activities you will employ to ensure that the software is working as promised and that there are no issues that arise that could affect the processes it replaces. This can include a review of the process every 6 months or when there is a new software release.",
          },
        },
      },
      {
        id: "validation-history",
        prompt: false,
        required: true,
        element: {
          id: "validation-history",
          type: "table",
          options: {
            label: "Update the history of your validated software versions.",
            helperText:
              "If this is the first time validating this software, you don't have to add anything.",
            columns: [
              { name: "Validated Software Version" },
              { name: "Date" },
              { name: "Changes in Validation Process" },
            ],
          },
          transformerConfig: {
            type: "default",
            inputs: ["validation-software-version"],
            transformer: (inputs: Record<DocumentDataKey, string>) => {
              return `
  | Validated Software Version        | Date             | Changes in Validation Process |
  | --------------------------------- | ---------------- | ----------------------------- |
  | ${inputs["validation-software-version"]} | ${moment().format("YYYY-MM-DD")} | None, initial validation  |`;
            },
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOP_PURCHASING]: {
    id: TEMPLATE_TYPE.SOP_PURCHASING,
    hidden: false,
    functionalGroup: FunctionalGroup.CP,
    docType: "SOP",
    docName: "SOP Purchasing",
    elements: [],
  },
  [TEMPLATE_TYPE.SOP_SALES]: {
    id: TEMPLATE_TYPE.SOP_SALES,
    hidden: false,
    functionalGroup: FunctionalGroup.CP,
    docType: "SOP",
    docName: "SOP Sales",
    elements: [
      {
        id: "sales-system",
        required: true,
        element: {
          id: "sales-system",
          type: "textField",
          options: {
            label:
              "What system or service will you be using to store customer contracts, offers, and/or sales receipts?",
            rows: 1,
            helperText:
              "If you are using multiple systems, please list them all using a comma to separate them.",
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.LIST_OF_QUALIFIED_SUPPLIERS]: {
    id: TEMPLATE_TYPE.LIST_OF_QUALIFIED_SUPPLIERS,
    docName: "List of Qualified Suppliers",
    docType: "LST",
    hidden: false,
    functionalGroup: FunctionalGroup.CP,
    docReferences: [TEMPLATE_TYPE.SOP_PURCHASING],
    elements: [
      {
        id: "list-of-qualified-suppliers",
        prompt: false,
        required: true,
        element: {
          id: "list-of-qualified-suppliers",
          type: "answerItemsElement",
          options: {
            typeId: "QS",
            outputTransformer: ({
              answer,
              element,
            }: {
              answer: AnswerItem[];
              element: TemplateElement["element"];
            }) => {
              if (element.type !== "answerItemsElement") {
                captureSentryException(
                  "Output transformer not supported for this element type"
                );
                return "";
              }

              const value = answer;
              const fields = element.options.fields;
              const rowLabels = element.options.fields.map((f) => f.label);

              // Generate markdown tables for each item in the value
              const markdownTables = value
                .filter((item: AnswerItem) => item.archived === false)
                .map((item: AnswerItem) => {
                  let table = "| Category | Information |\n";
                  table += "| -------- | ----------- |\n";
                  rowLabels.forEach((label, index) => {
                    const fieldKey = fields[index].fieldKey;
                    table += `| ${label} | ${item?.fields?.[fieldKey] || ""} |\n`;
                  });
                  return table;
                })
                .join("\n");

              return markdownTables;
            },
            label: "Provide the details for your critical suppliers.",
            markdownVisualization: "table",
            fields: [
              {
                label: "Supplier Name",
                fieldKey: "name",
                element: "textField",
              },
              {
                label: "Purchase Description",
                fieldKey: "purchase_description",
                element: "textField",
              },
              {
                label: "Supplier Status",
                fieldKey: "status",
                element: "select",
                alwaysShown: true,
                options: [
                  { value: "Approved", label: "Approved" },
                  {
                    value: "Approved with Monitoring",
                    label: "Approved with Monitoring",
                  },
                  { value: "Blocked", label: "Blocked" },
                ],
              },
              {
                label: "Quality of Products / Services",
                fieldKey: "quality_of_products_services",
                element: "select",
                style: {
                  width: "calc(33% - 5px)",
                  minWidth: "calc(33% - 5px)",
                },
                options: [
                  { value: "0", label: "0" },
                  { value: "1", label: "1" },
                  { value: "2", label: "2" },
                  { value: "3", label: "3" },
                ],
              },
              {
                label: "Timeliness / Punctuality",
                fieldKey: "timeliness_punctuality",
                element: "select",
                style: {
                  width: "calc(33% - 5px)",
                  minWidth: "calc(33% - 5px)",
                },
                options: [
                  { value: "0", label: "0" },
                  { value: "1", label: "1" },
                  { value: "2", label: "2" },
                  { value: "3", label: "3" },
                ],
              },
              {
                label: "Cooperation",
                fieldKey: "cooperation",
                element: "select",
                style: {
                  width: "calc(33% - 5px)",
                  minWidth: "calc(33% - 5px)",
                  flex: 1,
                },
                options: [
                  { value: "0", label: "0" },
                  { value: "1", label: "1" },
                  { value: "2", label: "2" },
                  { value: "3", label: "3" },
                ],
              },
              {
                label: "Payment Terms",
                fieldKey: "payment_terms",
                element: "select",
                style: {
                  width: "calc(33% - 5px)",
                  minWidth: "calc(33% - 5px)",
                },
                options: [
                  { value: "0", label: "0" },
                  { value: "1", label: "1" },
                  { value: "2", label: "2" },
                  { value: "3", label: "3" },
                ],
              },
              {
                label: "Average Score",
                fieldKey: "average_score",
                element: "textField",
                style: {
                  width: "calc(33% - 5px)",
                  minWidth: "calc(33% - 5px)",
                },
                readOnly: true,
                default: "0",
                transformerConfig: {
                  type: "always",
                  inputs: [
                    "quality_of_products_services",
                    "timeliness_punctuality",
                    "cooperation",
                    "payment_terms",
                  ],
                  transformer: (inputs: Record<string, string>) => {
                    return (
                      (Number(inputs["quality_of_products_services"] || 0) +
                        Number(inputs["timeliness_punctuality"] || 0) +
                        Number(inputs["cooperation"] || 0) +
                        Number(inputs["payment_terms"] || 0)) /
                      4
                    );
                  },
                },
              },
              {
                label: "Date of Last Evaluation",
                fieldKey: "date_of_last_evaluation",
                element: "dateInput",
                style: {
                  width: "calc(33% - 5px)",
                  minWidth: "calc(33% - 5px)",
                  flex: 1,
                },
                alwaysShown: true,
              },
              {
                label: "Completed By",
                fieldKey: "completed_by",
                element: "textField",
              },
              {
                label: "Non-Disclosure Agreement (NDA):",
                fieldKey: "non_disclosure_agreement",
                element: "radioButton",
                style: { width: "48%" },
                options: [
                  { value: "Yes", label: "Yes" },
                  { value: "No", label: "No" },
                ],
              },
              {
                label: "Supplier Certification:",
                fieldKey: "certification",
                element: "radioButton",
                style: { width: "48%" },
                options: [
                  { value: "Yes", label: "Yes" },
                  { value: "No", label: "No" },
                ],
              },
              {
                label: "Quality Assurance Agreement (QAA):",
                fieldKey: "quality_assurance_agreement",
                element: "radioButton",
                style: { width: "49%" },
                options: [
                  { value: "Yes", label: "Yes" },
                  { value: "No", label: "No" },
                ],
              },
              {
                label: "Supplier Auditing:",
                fieldKey: "auditing",
                element: "radioButton",
                style: { width: "49%" },
                options: [
                  { value: "Yes", label: "Yes" },
                  { value: "No", label: "No" },
                ],
              },
              {
                label: "Other Monitoring Measures:",
                fieldKey: "other_monitoring_measures",
                element: "radioButton",
                style: { width: "48%" },
                options: [
                  { value: "Yes", label: "Yes" },
                  { value: "No", label: "No" },
                ],
              },
            ],
            helperText: `You only need to include those suppliers here who have undergone supplier qualification and are considered critical suppliers. Add suppliers to this list once you have finished their supplier qualification checklist and have saved it into your quality management system. For the table:
      
      - Purchase description: What products or services are you purchasing from this supplier?
      - Supplier status: Approved, approved with monitoring, or blocked.
      - Quality of products/services: Your rating on the quality of products or services provided by the supplier (scored 0-3) from the supplier checklist.
      - Timeliness/punctuality: Your rating on the timeliness or punctuality of the supplier (scored 0-3) from the supplier checklist.
      - Cooperation: Your rating on the cooperation of the supplier (scored 0-3) from the supplier checklist.
      - Payment terms: Your rating on the payment terms of the supplier (scored 0-3) from the supplier checklist.
      - Average score: The average score from the evaluation of the above ratings.
      - Date of last evaluation: The date of the last evaluation of the supplier.
      - Completed by: The person who completed the supplier qualification checklist for the above date.
      - Non-disclosure agreement (NDA): Whether the supplier has signed an NDA.
      - Supplier certification: Whether the supplier has provided any certifications related to their product.
      - Quality assurance agreement (QAA): Whether the supplier has signed a QAA.
      - Supplier auditing: Whether the supplier is subject to auditing.
      - Other monitoring measures: Any other monitoring measures in place for this supplier.`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SUPPLIER_CHECKLIST]: {
    id: TEMPLATE_TYPE.SUPPLIER_CHECKLIST,
    docName: "Supplier Qualification Checklist",
    docType: "RCD",
    hidden: false,
    functionalGroup: FunctionalGroup.CP,
    elements: [
      {
        id: "supplier-company",
        required: true,
        prompt: false,
        element: {
          id: "supplier-company",
          type: "table",
          options: {
            label:
              "Provide the company and contact information for the supplier you are qualifying.",
            helperText:
              "Provide information for the supplier below. Contact is your contact at the company so include their name, their position, and information on how to get in contact with them.",
            columns: [
              { name: "Criteria", options: { editable: false } },
              { name: "Information" },
            ],
            default: `
            | Criteria                     |  Information   |
            | ---------------------------- | --- |
            | Company Name                 |     |
            | Address                      |     |
            | Contact Person's Name        |     |
            | Contact Person's Position    |     |
            | Contact Person's Information |     |`,
          },
        },
      },
      {
        id: "supplier-product",
        required: true,
        prompt: false,
        element: {
          id: "supplier-product",
          type: "textField",
          options: {
            label:
              "Provide a description of the product or service you will be purchasing.",
          },
        },
      },
      {
        id: "supplier-quality-requirements",
        required: true,
        prompt: false,
        element: {
          id: "supplier-quality-requirements",
          type: "textField",
          options: {
            label:
              "Provide the metrics by which you will measure the quality of the supplier.",
            helperText: `This will be used as part of your continuous supplier surveillance. Provide metrics that are objective and measurable like server uptime, bugs during development, malfunctions, or interruptions in service.`,
            rows: 5,
          },
        },
      },
      {
        id: "supplier-criticality-table",
        required: true,
        prompt: false,
        element: {
          id: "supplier-criticality-table",
          type: "table",
          options: {
            label: "Fill out the table below.",
            helperText: `Go through each question and answer "Yes" or "No" based on your knowledge of the goods/services that will be provided.

- A supplier is classified as **critical** if the supplier **directly** impacts either safety, performance or regulatory compliance, or both (7.) and (8.) are answered with YES.
- A supplier is classified as **non-critical** if all the questions (1.) and (3.) and (5.) are answered with NO.
- The quality team decides over criticality on a case-by-case basis, if the questions (1.) and (3.) and (5.) and both (7.) and (8.) are answered with NO, but one of the questions on indirect impact (2, 4, 6) are answered with YES.`,
            default: `
| Criticality Criteria                                                                                                                           | Yes / No | Explanation / Commentary |
| ---------------------------------------------------------------------------------------------------------------------------------------------- | -------- | ------------------------ |
| (1.) Could the purchased goods or services have a **direct** impact on the **safety** of the organization's medical devices?                   |          |                          |
| (2.) Could the purchased goods or services have an **indirect** impact on the **safety** of the organization's medical devices?                |          |                          |
| (3.) Could the purchased goods or services have a **direct** impact on the **performance** of the organization's medical devices?              |          |                          |
| (4.) Could the purchased goods or services have an **indirect** impact on the **performance** of the organization's medical devices?           |          |                          |
| (5.) Could the purchased goods or services have a **direct** impact on the **regulatory compliance** of the organization's medical devices?    |          |                          |
| (6.) Could the purchased goods or services have an **indirect** impact on the **regulatory compliance** of the organization's medical devices? |          |                          |
| (7.) Is the organization not able to manufacture medical devices without the supplier's goods or services?                                     |          |                          |
| (8.) Is this the only available supplier for these goods or services?                                                                          |          |                          |
`,

            columns: [
              { name: "Criticality Criteria" },
              { name: "Yes / No" },
              { name: "Explanation / Commentary" },
            ],
          },
        },
      },
      {
        id: "supplier-criticality",
        required: true,
        prompt: false,
        element: {
          id: "supplier-criticality",
          type: "select",
          options: {
            label: "Is your supplier critical?",
            helperText: `Using the Yes and No answers and the criteria in the hint text of the previous question, determine if the supplier is critical. 

If the supplier is NOT critical, you are done and can exit this form as they don't require qualification. You don't need to save this form and you can buy whatever you would like from the supplier. 

If the supplier IS critical, then answer this as ""Yes"" fill out the remaining sections and we will add them to your list of suppliers.`,
            options: [
              "Yes, the supplier is determined to be a critical supplier.",
              "No",
            ],
          },
        },
      },
      {
        id: "supplier-evaluation",
        required: true,
        prompt: false,
        element: {
          id: "supplier-evaluation",
          type: "table",
          options: {
            label: "Score your supplier based on their quality.",
            helperText: `Give a score on how the supplier is with each of the criteria below. If you aren't sure just give your best guess. Suppliers are evaluated along these categories by assigning a score value to each category. Score values are as follows:

- 0: unacceptable
- 1: moderate
- 2: good
- 3: excellent

In order to complete the evaluation, an average score is calculated from all categories.`,
            default: `
| Evaluation Criteria            | Score | Explanation / Commentary |
| ------------------------------ | ----- | ------------------------ |
| Quality of Products/Services   |       |                          |
| Timeliness / Punctuality       |       |                          |
| Cooperation / Customer Service |       |                          |
| Payment Terms                  |       |                          |
`,
            columns: [
              { name: "Evaluation Criteria" },
              { name: "Score" },
              { name: "Explanation / Commentary" },
            ],
          },
        },
      },
      {
        id: "supplier-additional",
        required: false,
        prompt: false,
        element: {
          id: "supplier-additional",
          type: "table",
          options: {
            label:
              "OPTIONAL: Fill out additional criteria for your supplier if desired.",
            helperText: `You can add in additional evaluation criteria if you feel that providing a more extensive evaluation would be a benefit. This could be good if you have a particularly critical supplier or already know some of the criteria listed below.`,
            default: `
| Further Criteria                                                                                                                               | Yes / No | Explanation / Commentary |
| ---------------------------------------------------------------------------------------------------------------------------------------------- | -------- | ------------------------ |
| Is there a non-disclosure agreement (NDA) in place between the supplier and our organization?                                                  |          |                          |
| Does the supplier have a certified management system in place?                                                                                 |          |                          |
| Does the supplier conduct quality reviews before shipment?                                                                                     |          |                          |
| Does the supplier have a system for traceability established?                                                                                  |          |                          |
| Does the supplier have a procedure for the handling of nonconforming products in place?                                                        |          |                          |
| Does the supplier have a procedure for the corrective and preventive actions in place?                                                         |          |                          |
| Does the supplier have a procedure in place to notify customers of changes?                                                                    |          |                          |
| Does the supplier further subcontract the services/products concerned and does the supplier have its own supplier evaluation process in place? |          |`,
            columns: [
              { name: "Further Criteria" },
              { name: "Yes / No" },
              { name: "Explanation / Commentary" },
            ],
          },
        },
      },
      {
        id: "supplier-evaluation-score",
        required: true,
        prompt: false,
        element: {
          id: "supplier-evaluation-score",
          type: "select",
          options: {
            label: "Calculate the average score for your supplier.",
            helperText: `Take every number you provided in the table above and average it. Choose one of the following options based on the final average calculated. The overall score results in the following evaluation outcome:

- Average score 2 - 3: supplier can be approved.
- Average score 1 - 2: supplier can be approved, provided that surveillance measures are in place.
- Average score 0 - 1: supplier cannot be approved and is marked as blocked in the List of Qualified Suppliers.`,
            options: [
              "Average score 2 - 3: supplier can be approved.",
              "Average score 1 - 2: supplier can be approved, provided that surveillance measures are in place.",
              "Average score 0 - 1: supplier cannot be approved and is marked as blocked in the List of Qualified Suppliers.",
            ],
          },
        },
      },
      {
        id: "supplier-final",
        required: true,
        prompt: false,
        element: {
          id: "supplier-final",
          type: "table",
          options: {
            label: "Does the supplier meet all quality requirements?",
            helperText: `Provide a Yes or No based on the evaluation and average score. Provide explanations or commentary if desired.`,
            default: `
| Final Evaluation Result                                                | Yes / No | Explanation / Commentary |
| ---------------------------------------------------------------------- | -------- | ------------------------ |
| Does the supplier comply with the organization's quality requirements? |          |                          |`,
            columns: [
              { name: "Final Evaluation Result" },
              { name: "Yes / No" },
              { name: "Explanation / Commentary" },
            ],
          },
        },
      },
      {
        id: "supplier-evaluator",
        required: true,
        prompt: false,
        element: {
          id: "supplier-evaluator",
          type: "textField",
          options: {
            label: "Provide the name of the person doing this evaluation.",
          },
        },
      },
      {
        id: "supplier-surveillance",
        required: true,
        prompt: false,
        element: {
          id: "supplier-surveillance",
          type: "table",
          options: {
            label: "Provide surveillance requirements for your supplier.",
            helperText: `You are required to provide additional surveillance measures if the supplier evaluation score was between 1-2. Provide some measures here. They should be proportional to the risk of the product the supplier is providing and not all the suggested surveillance requirements are required.`,
            default: `
| Surveillance Measures                                                  | Yes / No | Explanation / Commentary |
| ---------------------------------------------------------------------- | -------- | ------------------------ |
| Is the supplier required to provide proof of certification?            |          |                          |
| Is the supplier required to enter a quality assurance agreement (QAA)? |          |                          |
| Is the supplier required to agree to a supplier audit program?         |          |                          |
| Are any other supplier surveillance measures required?                 |          |                          |`,

            columns: [
              { name: "Surveillance Measures" },
              { name: "Yes / No" },
              { name: "Explanation / Commentary" },
            ],
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.INSTRUCTIONS_FOR_USE]: {
    id: TEMPLATE_TYPE.INSTRUCTIONS_FOR_USE,
    docName: "Instructions for Use",
    docType: "TCD",
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    dependencies: [
      "software-version",
      "release-date",
      "udi-pi",
      "intended-use",
      "principles-of-operation",
      "user-profile",
      "patient-population",
      "clinical-benefits",
      "contraindications",
      "indications-for-use",
    ],
    elements: [
      {
        id: "requirements-for-use",
        prompt: true,
        required: true,
        context: ["minimum-requirements"],
        element: {
          id: "requirements-for-use",
          type: "textField",
          options: {
            rows: 5,
            label:
              "Describe the minimum hardware and software requirements to run the application.",
            helperText:
              "If run on a smartphone or web browser be sure to include the type and version it is to be run on as well as the minimum storage space required for it if relevant.",
          },
        },
      },
      {
        id: "excluded-population",
        prompt: true,
        required: true,
        context: ["patient-population", "intended-use"],
        element: {
          id: "excluded-population",
          type: "textField",
          options: {
            rows: 5,
            label: "Who should not be using your device, if anyone?",
          },
        },
      },
      {
        id: "ifu-accessories",
        prompt: true,
        required: true,
        context: ["variants-and-accessories"],
        element: {
          id: "ifu-accessories",
          type: "textField",
          options: {
            rows: 5,
            label:
              "Provide what specific accessory devices, if any, are required in order to use your device.",
            helperText: `If your device relies on other devices in order to operate, provide information regarding what those devices are. It should be sufficient information so that a user or healthcare provider can acquire exactly the right equipment in order to operate your device. This does NOT include a cell phone if you have a smartphone application that can be used on any phones. Think of more dedicated hardware for this section, like a device that sends heart rate or other vitals to your device for analysis. If your device does not rely on any accessory devices in order to operate, then write "No accessory devices required."`,
          },
        },
      },
      {
        id: "warnings-and-precautions",
        prompt: true,
        required: true,
        context: ["risk-controls", "risk-analysis"],
        element: {
          id: "warnings-and-precautions",
          type: "textField",
          options: {
            rows: 5,
            label:
              "Describe anything the users should be warned about while using the device.",
            helperText: `These can come from your risk controls if there are any requirements for providing the user information to avoid risks. It can include things like the device is not intended to replace a doctor or what to do if the health condition is more severe than what the device allows for treatment.`,
          },
        },
      },
      {
        id: "side-effects",
        prompt: true,
        required: true,
        context: ["contraindications"],
        element: {
          id: "side-effects",
          type: "textField",
          options: {
            rows: 5,
            label: "Provide any known side-effects of using the device.",
            helperText: `If you know of any side effects from testing of your device or from similar devices make sure to list them. If you have no known side-effects then state that.`,
          },
        },
      },
      {
        id: "installation",
        prompt: true,
        required: true,
        context: ["intended-use", "minimum-requirements"],
        element: {
          id: "installation",
          type: "textField",
          options: {
            rows: 5,
            label:
              "Describe the installation process required for your device.",
            helperText: `If your application simply requires download from an app store or installlation on your computer, provide that information here.`,
          },
        },
      },
      {
        id: "security-measures",
        prompt: true,
        required: true,
        context: [
          "software-architecture-security",
          "software-architecture-backup",
        ],
        element: {
          id: "security-measures",
          type: "textField",
          options: {
            rows: 5,
            label: "Describe the security measures implemented in your device.",
            helperText: `Does your user need to go through authentication before using the app? Are the data stored in a secure manner? Provide an overview of the security measures you have in place here.`,
          },
        },
      },
      {
        id: "ifu-notes",
        prompt: true,
        required: true,
        element: {
          id: "ifu-notes",
          type: "textField",
          options: {
            rows: 5,
            label: "Provide any additional notes you would like to include.",
            helperText: `This is an area to put your support contact information or any additional information you want the user to have in case they have issues with the product.`,
          },
        },
      },
      {
        id: "training-required",
        prompt: true,
        required: true,
        context: ["user-training"],
        element: {
          id: "training-required",
          type: "textField",
          options: {
            rows: 5,
            label:
              "Provide an overview of training required to use the device.",
            helperText: `If no training is required in order to properly use the device, state that "No training is required to use the device."`,
          },
        },
      },
      {
        id: "user-instructions",
        prompt: true,
        required: true,
        context: ["user-materials", "user-needs"],
        element: {
          id: "user-instructions",
          type: "textField",
          options: {
            rows: 5,
            label:
              "Provide some step-by-step user instructions for your users, if desired.",
          },
        },
      },
      {
        id: "technical-assistance",
        prompt: false,
        required: true,
        element: {
          id: "technical-assistance",
          type: "textField",
          options: {
            rows: 5,
            label:
              "Provide the contact information where users can receive technical assistance.",
            helperText: `This can be a telephone number, fax address, website, or email address.`,
          },
        },
      },
      {
        id: "manufacturer-name",
        prompt: false,
        required: true,
        element: {
          id: "manufacturer-name",
          type: "textField",
          options: {
            label: "Provide the name of your company.",
          },
        },
      },
      {
        id: "manufacturer-address",
        prompt: false,
        required: true,
        element: {
          id: "manufacturer-address",
          type: "textField",
          options: {
            rows: 5,
            label: "Provide the primary address of your company in the EU.",
            helperText: `This should include your name, street address, city, state, postal code, and country.`,
          },
        },
      },
      {
        id: "electronic-ifu-location",
        required: true,
        prompt: false,
        element: {
          id: "electronic-ifu-location",
          type: "textField",
          options: {
            label: "Where can users find an electronic copy of the IFU?",
            helperText:
              "This needs to be readily available for your users in the software application but also on your website. Describe how users can access the eIFU on the software application and the URL of your website where they can find a copy of the eIFU.",
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOP_POST_MARKET_SURVEILLANCE]: {
    id: TEMPLATE_TYPE.SOP_POST_MARKET_SURVEILLANCE,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "SOP",
    docName: "SOP Post-Market Surveillance",
    elements: [],
  },
  [TEMPLATE_TYPE.POST_MARKET_SURVEILLANCE_PLAN]: {
    id: TEMPLATE_TYPE.POST_MARKET_SURVEILLANCE_PLAN,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "TCD",
    docName: "Post-Market Surveillance Plan",
    dependencies: ["vigilance-search-keywords"],
    elements: [],
  },
  [TEMPLATE_TYPE.POST_MARKET_CLINICAL_FOLLOW_UP_PLAN]: {
    id: TEMPLATE_TYPE.POST_MARKET_CLINICAL_FOLLOW_UP_PLAN,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "TCD",
    docName: "Post-Market Clinical Follow Up Plan",
    dependencies: [
      "manufacturer-name",
      "manufacturer-address",
      "software-version",
      "intended-use",
      "user-profile",
      "patient-population",
      "indications-for-use",
      "contraindications",
      "warnings-and-precautions",
      "variants-and-accessories",
      "ifu-accessories",
      "device-classification",
      "device-classification-justification",
      "similar-device-table",
      "equivalence-comparison",
      "expected-lifetime",
    ],
    elements: [
      {
        id: "pmcf-contacts",
        prompt: false,
        required: true,
        element: {
          id: "pmcf-contacts",
          type: "table",
          options: {
            label:
              "Provide the name of your PRRC and Authorized Representative.",
            helperText: `A fax number is not required but an email and phone number are. The Person Responsible for Regulatory Compliance (PRRC) is someone who has ONE of the following qualifications:<br/>
              (a) a diploma, certificate or other evidence of formal qualification, awarded on completion of a university degree or of a course of study recognised as equivalent by the Member State
              concerned, in law, medicine, pharmacy, engineering or another relevant scientific discipline, and at least one year of professional experience in regulatory affairs or in quality management
              systems relating to medical devices/in vitro diagnostic medical device.<br/>
              (b) four years of professional experience in regulatory affairs or in quality management systems relating to medical devices/in vitro diagnostic medical device.
              
              If no one at your company has this, don't worry, one of our Formly team members can act as your PRRC until you reach the "one year of experience" threshold. 
              
              An Authorized Representative is required for any companies not based in the European Union to act on their behalf. If you do not have an Authorized Representative, leave this blank.`,
            default: `
  | Category                                     | Information |
  | -------------------------------------------- | ----------- |
  | Person responsible for regulatory compliance |             |
  | E-mail                                       |             |
  | Phone                                        |             |
  | Fax                                          |             |
  | Authorised representative (if applicable)    |             |
  | Address                                      |             |
  | Contact person                               |             |
  | E-mail                                       |             |
  | Phone                                        |             |
  | Fax                                          |             |`,
            columns: [
              { name: "Category", options: { flex: 0 } },
              { name: "Information" },
            ],
            addRowEnabled: true,
          },
        },
      },
      {
        id: "novel-product",
        prompt: true,
        required: true,
        element: {
          id: "novel-product",
          type: "select",
          options: {
            label: "Is your product novel?",
            options: ["Yes", "No"],
            helperText: `Novel in this case means is it a completely new type of product without anything else like it on the market. Novelty can include a new type of:
      -Clinical procedure or treatment option
      -Device-patient interface
      -Device application or deployment
      -Medical purpose like an unmet need or new disease
      -Design, mechanism of action, physiochemical properties, or materials never used before
      -Software or components unlike anything on the market
      -Manufacturing process
      
      In order for it to be novel it would need to be very different to what is currently on the market.`,
          },
        },
      },
      {
        id: "novel-procedure",
        prompt: false,
        required: false,
        context: ["novel-product"],
        element: {
          id: "novel-procedure",
          type: "textField",
          options: {
            label: "What are the novel procedures for your product?",
            rows: 5,
            helperText: `If you answered "No" to your product being novel, SKIP THIS QUESTION.
  
              If you answered "Yes" to the previous question because of the novel clinical procedures performed by your product, describe what those are. Otherwise, leave this blank. Novel clinical or surgical procedures can include: 
  
                - the mode of use or treatment option;
                - device-patient interface (including maintenance and adjustment);
                - interaction and control (existing technologies with a new interface or usage context, new way of device application);
                - deployment methods.`,
          },
        },
      },
      {
        id: "novel-features",
        prompt: false,
        required: false,
        element: {
          id: "novel-features",
          type: "textField",
          options: {
            label: "What are the novel features of your product?",
            rows: 5,
            helperText: `If you answered "No" to your product being novel, SKIP THIS QUESTION.
  
              If you answered "Yes" to your product being a novel product describe the features that make it novel. Otherwise, leave this blank. Novel device features can include:
  
              — Novel medical purpose, including a new intended purpose of the device with regard to the clinical setting, severity and stage of disease, site in the body, target population (age, anatomy, physiology, sex) with a particular attention to devices used in paediatrics. For instance, novel devices may aim to meet previously unmet medical needs, whether for a disease as a whole or for specific indications or patient populations, within a broader intended purpose;
              
              — Novel design, including new/modified specifications and properties such as physicochemical properties (e.g. mechanical properties, viscosity, surface characteristics, wavelength, type and intensity of energy), shape, size as well as software algorithms where these constitute an integral part of the functioning of the device;
              
              — Novel mechanism of action, including due to new/modified physical or chemical properties. In case of a combination product, new pharmacological, immunological, metabolic properties of the medicinal substance need to be taken into account;
              
              — Novel materials, including notably new/modified materials or substances in contact with human tissues or body fluids, changes in duration of contact, or in the release characteristics of substances, including degradation products and leachables. Materials also include the characteristics of the surface of the device such as specific coatings and surface treatments;
              
              — Novel site of application for an established material, leading to new/modified contact with and/or mechanical loading of the same or different tissues;
              
              — Novel components including parts, pieces or software that constitute an integral part required for the functioning of the device;
              
              — Novel manufacturing process, including for example additive manufacturing, bio-printing, sterilisation processes, in relation to the state of the art.`,
          },
        },
      },
      {
        id: "pmcf-activities",
        prompt: true,
        required: true,

        element: {
          id: "pmcf-activities",
          type: "table",
          options: {
            label: "Provide any PMCF studies that you plan to perform.",
            columns: [
              { name: "PMCF Number", options: { flex: 0 } },
              { name: "Description" },
              { name: "Aim" },
              { name: "Rationale and Limitations" },
              { name: "Timeline" },
            ],
            addRowEnabled: true,
            helperText: `If you plan to do any studies or activities to keep an eye on your device after it is on the market, provide that information here. You aren't required to initiate any PMCF studies if not dictated by your clinical evaluation to do so. Only provide activities you 100% expect to do as opposed to what you would like to do. 
              The type of PMCF activites are:
              - screening of scientific literature and clinical data;
              - post-market clinical studies;
              - collecting data in registries;
              - surveys of healthcare professionals or patients/users; or
              - review of case reports which may reveal misuse or off-label use.
              
              The aim of PMCF activities can be:
              - confirm the safety and performance of the device; 
              - to identify any side effects or adverse events that were not previously identified;
              - monitoring intended side-effects and contraindications;
              - identifying and analyzing emerging risks;
              - ensuring continued acceptability of the benefit-risk ratio; or 
              - identifying possible systematic misuse or off-label use of the device.
              
              The appropriateness / limitations of studies:
              - sample size, timescales and endpoints;
              - the chosen comparator, on the basis of intended purpose and state of the art;
              - the study design on the basis of all of the above, and why it is sufficient to ensure representative patient populations and provide for adequate controls on sources of bias (an evaluation of the potential sources of bias should form part of this); or
              - a statistical justification for the expected quality of outcomes, and justification for why this is satisfactory in light of the residual risks. This is an important consideration. For example, retrospective surveys with no justification other than “this should demonstrate the expected quality of evidence that we require,” but without showing a statistical rationale, are not acceptable.`,
          },
        },
      },
      {
        id: "pmcf-clinical-evaluation",
        prompt: true,
        required: true,
        context: ["benefit-risk-ratio"],
        element: {
          id: "pmcf-clinical-evaluation",
          type: "textField",
          options: {
            label:
              "What post-market activities are required from the Clinical Evaluation?",
            rows: 5,
            helperText: `Provide any information from your clinical evaluation that should be monitored while your product is on the market. This could be any performance characteristics that were concerning or benefits that did not have enough clinical support. If there were no obvious issues then state that "No post-market activities are required."`,
          },
        },
      },
      {
        id: "pmcf-risk-management",
        prompt: true,
        context: ["benefit-risk-ratio"],
        required: true,
        element: {
          id: "pmcf-risk-management",
          type: "textField",
          options: {
            label:
              "What post-market activities are required from your risk management?",
            rows: 5,
            helperText: `Provide any risks from your risk management that need to be monitored while your product is on the market. This could be new risks that you identified from your clinical evaluation. If no risks need to be monitored then state that "No post-market activities are required."`,
          },
        },
      },
      {
        id: "medical-condition",
        prompt: true,
        required: true,
        context: ["patient-population", "intended-use"],
        element: {
          id: "medical-condition",
          type: "textField",
          options: {
            label: "What medical condition(s) does your device target?",
            rows: 5,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.PERIODIC_SAFETY_UPDATE_REPORT]: {
    id: TEMPLATE_TYPE.PERIODIC_SAFETY_UPDATE_REPORT,
    docName: "Periodic Safety Update Report",
    docType: "TCD",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,

    dependencies: [
      "manufacturer-name",
      "manufacturer-address",
      "pmcf-contacts",
      "notified-body-name",
      "srn",
      "udi-di",
      "pmcf-conclusions",
      "software-version",
      "intended-use",
      "user-profile",
      "patient-population",
      "indications-for-use",
      "contraindications",
      "warnings-and-precautions",
      "variants-and-accessories",
      "ifu-accessories",
      "device-classification",
      "device-classification-justification",
      "literature-search-results",
      "literature-summary",
      "clinical-trial-search-table",
      "vigilance-search-table",
      "similar-device-table",
      "literature-summary-state-of-the-art-performance",
      "literature-summary-state-of-the-art-safety",
      "medical-condition",
    ],
    elements: [
      {
        id: "surveillance-period",
        prompt: false,
        required: true,
        element: {
          id: "surveillance-period",
          type: "table",
          options: {
            label: "Provide the surveillance period for your PSUR.",
            helperText:
              "These are the dates that you are saying your data come from that you will be analyzing in your report. It should typically be from the last 2 years.",
            columns: [
              { name: "Surveillance Start Date" },
              { name: "Surveillance End Date" },
            ],
            default: `
            | Surveillance Start Date | Surveillance End Date |
            | ----------------------- | --------------------- |
            |                         |                       |
            `,
          },
        },
      },
      {
        id: "notified-body-number",
        prompt: false,
        required: true,
        element: {
          id: "notified-body-number",
          type: "textField",
          options: {
            label: "What is your notified body's number?",
            helperText: `Every notified body has a unique number to represent them which is typically included on your certificate of conformity following the conformity assessment.`,
          },
        },
      },
      {
        id: "psur-device-grouping",
        prompt: false,
        required: true,
        element: {
          id: "psur-device-grouping",
          type: "table",
          options: {
            label: "Are you grouping multiple devices into this PSUR?",
            helperText: `If no, mark "No" in response to the question in the first row and leave the rest of the table blank.

If yes, mark "Yes" in response tot he question and fill in the two remaining questions. For the leading device, this is the primary device in the group on which the data is based. Usually this device has the most complete functionality compared to other devices. In your justification for grouping, explain why the devices are grouped together. This could be based on the benefits to report multiple devices in one PSUR or alternatively the disadvantages to report each device separately.`,

            columns: [{ name: "Criteria" }, { name: "Answer" }],
            default: `
            | Criteria                                           | Answer   |
            | -------------------------------------------------- | -------- |
            | Does this PSUR include multiple devices?           | Yes / No |
            | If so, what is the leading device for the group?   |          |
            | If so, what is the justification for the grouping? |          |
            `,
          },
        },
      },
      {
        id: "psur-first-date-market",
        prompt: false,
        required: true,
        element: {
          id: "psur-first-date-market",
          type: "textField",
          options: {
            label:
              "What was the date this device was first introduced to market?",
            helperText: `This can be
            - first declaration of conformity,
            - first EC / EU Certificate issued,
            - first date device CE-marked,
            - first placed on the market,
            - first put into service,
            - if software, date first made available`,
          },
        },
      },
      {
        id: "psur-device-state",
        prompt: false,
        required: true,
        element: {
          id: "psur-device-state",
          type: "select",
          options: {
            label: "What is the current status of this device/group?",
            helperText: `Choose the most appropriate answer.`,
            options: [
              "on the market",
              "no longer on the market",
              "recalled",
              "field safety corrective action initiated",
            ],
          },
        },
      },
      {
        id: "basic-udi-di-differences",
        prompt: false,
        required: true,
        element: {
          id: "basic-udi-di-differences",
          type: "textField",
          options: {
            label: "Are there different basic udi-di's for your device?",
            helperText: `
  If there are different basic udi-di's for your device, provide those and describe the differences. Above for the differences by basic UDI-DI, break down the information based on basic UDI-DI's and explain any device changes within each basic UDI-DI compared to the previous PSUR report.

If you only have one basic udi-di for your device or group, then write "Not applicable"`,
            rows: 5,
          },
        },
      },
      {
        id: "psur-volume-of-sales",
        prompt: false,
        required: true,
        element: {
          id: "psur-volume-of-sales",
          type: "table",
          options: {
            label: "Provide the volume of sales for your device.",
            helperText: `Provide the volume of sales for your device(s). If there are multiple devices make sure to include each model. This could be volumes of sales, units shipped, or units implanted or another suitable indicator. Whichever method is used should be consistent throughout the PSUR in all areas to allow for a comparison of data. 

*EAA = European Economic Area, TR = Turkey, XI = Iceland`,
            columns: [
              { name: "Region" },
              { name: "Total Number of Devices" },
              { name: "Past 12 Months" },
              { name: "Previous 12 Months" },
            ],
            default: `
            | Region        | Total Number of Devices | Past 12 Months | Previous 12 Months |
            | ------------- | ----------------------- | -------------- | ------------------ |
            | EAA + TR + XI |                         |                |                    |
            | Worldwide     |                         |                |                    |
            `,
          },
        },
      },
      {
        id: "psur-volume-description",
        prompt: false,
        required: true,
        element: {
          id: "psur-volume-description",
          type: "select",
          options: {
            label:
              "Select what total number of devices refers to in the previous table.",
            options: [
              "Devices placed on the market or put into service",
              "Units distributed within each time period",
              "Number of episodes of use (for reusable devices)",
              "Active installed base",
              "Units distributed from the date of declaration of conformity or EC/EU mark approval to the end date of each time period",
              "Number of devices implanted",
            ],
          },
        },
      },
      {
        id: "psur-patient-exposure",
        prompt: false,
        required: true,
        element: {
          id: "psur-patient-exposure",
          type: "table",
          options: {
            label: "Provide an estimate of patient exposure for your device.",
            helperText: `This could be the number of patients treated, the number of patients exposed to the device, the number of patients implanted with the device, or another suitable indicator. Whichever method is used should be consistent throughout the PSUR in all areas to allow for a comparison of data. 

*EAA = European Economic Area, TR = Turkey, XI = Iceland`,
            columns: [
              { name: "Region" },
              { name: "Total Number of Patients" },
              { name: "Past 12 Months" },
              { name: "Previous 12 Months" },
            ],
            default: `
            | Region        | Total Number of Patients | Past 12 Months | Previous 12 Months |
            | ------------- | ------------------------ | -------------- | ------------------ |
            | EAA + TR + XI |                          |                |                    |
            | Worldwide     |                          |                |                    |`,
          },
        },
      },
      {
        id: "psur-exposure-description",
        prompt: false,
        required: true,
        element: {
          id: "psur-exposure-description",
          type: "select",
          options: {
            label:
              "Select what patient exposure refers to in the previous table.",
            options: [
              "Number of patients treated",
              "Number of patients exposed to the device",
              "Number of patients implanted with the device",
              "Number of episodes of use (for reusable devices)",
            ],
          },
        },
      },
      {
        id: "psur-serious-incidents",
        prompt: false,
        required: true,
        element: {
          id: "psur-serious-incidents",
          type: "table",
          options: {
            label:
              "Provide any serious incidents that have occurred since the last PSUR.",
            helperText: `Fill in the table with any serious incidents that have occurred in each region according to their particular IMDRF code. The IMDRF Codes are listed in the table below:

| Name of Terminology                                        | Description                                                                                                                                                                                                                         | Annex |
| ---------------------------------------------------------- | ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- | ----- |
| Medical device problem                                     | Terms/codes for describing problems (malfunction, deterioration of function, failure) of medical devices that have occurred in pre- or postmarket contexts (e.g. clinical studies, clinical evaluation or post-market surveillance) | A     |
| Cause of investigation<br>-Type of Investigation           | Terms/codes for describing the type of investigation of the device involved in the reported event.                                                                                                                                  | B     |
| Cause investigation<br>-Investigation Findings             | Terms/codes for describing the findings of the device involved in the reported event.                                                                                                                                               | C     |
| Cause investigation<br>-Investigation Conclusion           | Terms/codes for describing the conclusion of the investigation of the device involved in the reported event.                                                                                                                        | D     |
| Health Effects<br>-Clinical Signs, Symptoms and Conditions | Terms/codes for describing the clinical signs, symptoms and conditions of the affected person appearing as a result of the medical device adverse event/incident.                                                                   | E     |
| Health Effects<br>-Health Impact                           | Terms/codes for describing the consequences of the medical device adverse event/incident on the person affected.                                                                                                                    | F     |
| Component                                                  | Terms/codes for describing the parts and components which were involved in, or affected by, the medical device adverse event/incident.                                                                                              | G     |

If no serious incidents occurred, you can leave this table blank.

*EAA = European Economic Area, TR = Turkey, XI = Iceland`,
            columns: [
              { name: "Region" },
              { name: "IMDRF AET Annex" },
              { name: "Number Past 12 Months" },
              { name: "Percentage Past 12 Months" },
              { name: "Number Previous 12 Months" },
              { name: "Percentage Previous 12 Months" },
            ],
            default: `
            | Region        | IMDRF AET Annex | Number Past 12 Months | Percentage Past 12 Months | Number Previous 12 Months | Percentage Previous 12 Months |
            | ------------- | --------------- | --------------------- | ------------------------- | ------------------------- | ----------------------------- |
            | EAA + TR + XI | Total           |                       |                           |                           |                               |
            | Worldwide     | Total           |                       |                           |                           |                               |
            | EAA + TR + XI | A               |                       |                           |                           |                               |
            | Worldwide     | A               |                       |                           |                           |                               |
            | EAA + TR + XI | B               |                       |                           |                           |                               |
            | Worldwide     | B               |                       |                           |                           |                               |
            | EAA + TR + XI | C               |                       |                           |                           |                               |
            | Worldwide     | C               |                       |                           |                           |                               |
            | EAA + TR + XI | D               |                       |                           |                           |                               |
            | Worldwide     | D               |                       |                           |                           |                               |
            | EAA + TR + XI | E               |                       |                           |                           |                               |
            | Worldwide     | E               |                       |                           |                           |                               |
            | EAA + TR + XI | F               |                       |                           |                           |                               |
            | Worldwide     | F               |                       |                           |                           |                               |
            | EAA + TR + XI | G               |                       |                           |                           |                               |
            | Worldwide     | G               |                       |                           |                           |                               |`,
          },
        },
      },
      {
        id: "psur-serious-incidents-summary",
        prompt: false,
        required: true,
        element: {
          id: "psur-serious-incidents-summary",
          type: "textField",
          options: {
            label: "Summarize the serious incidents that occurred.",
            helperText: `In your summary, include the number of incidents, the root cause, and the health effects.`,
            rows: 5,
          },
        },
      },
      {
        id: "psur-trend-reporting",
        prompt: false,
        required: true,
        element: {
          id: "psur-trend-reporting",
          type: "textField",
          options: {
            label: "Did you notice and trends that need to be reported?",
            helperText: `In the post-market surveillance plan, we describe how to identify and calculate trends that arise from complaint data or other post-market data we collect. Review the plan and describe any trends that you identified according to the metrics and threshold values that were specified in your post-market surveillance plan. If no trends were identified, state that.`,
            rows: 5,
          },
        },
      },
      {
        id: "psur-fsca",
        prompt: false,
        required: true,
        element: {
          id: "psur-fsca",
          type: "table",
          options: {
            label:
              "Identify any Field Safety Correctie Actions that have occurred since the last PSUR.",
            helperText: `If there are no FSCAs that have occurred since the last PSUR then you can leave this blank.`,
            columns: [
              { name: "Type of Action" },
              { name: "Issuing Date" },
              { name: "Scope of FSCA" },
              { name: "Status of FSCA" },
              { name: "Manufacturer's Reference Number" },
              { name: "Action Taken and Rationale" },
              { name: "Impacted Regions" },
            ],
            default: `
            | Type of Action | Issuing Date | Scope of FSCA | Status of FSCA | Manufacturer's Reference Number | Action Taken and Rationale | Impacted Regions |
            | -------------- | ------------ | ------------- | -------------- | ------------------------------- | -------------------------- | ---------------- |
            |                |              |               |                |                                 |                            |                  |`,
            addRowEnabled: true,
          },
        },
      },
      {
        id: "psur-capa",
        prompt: false,
        required: true,
        element: {
          id: "psur-capa",
          type: "table",
          options: {
            label:
              "Identify any Corrective and Preventive Actions that have occured since your last PSUR.",
            helperText: `If there are no CAPAs that have occurred since the last PSUR then you can leave this blank.`,
            columns: [
              { name: "Type of Action" },
              { name: "Initiation Date" },
              { name: "Scope of CAPA" },
              { name: "Status of CAPA" },
              { name: "Manufacturer's Reference Number" },
              { name: "CAPA Description" },
              { name: "Root Cause" },
              { name: "CAPA Effectiveness (if closed)" },
            ],
            default: `
            | Type of Action | Initiation Date | Scope of CAPA | Status of CAPA | Manufacturer's Reference Number | CAPA Description | Root Cause | CAPA Effectiveness (if closed) |
            | -------------- | --------------- | ------------- | -------------- | ------------------------------- | ---------------- | ---------- | ------------------------------ |
            |                |                 |               |                |                                 |                  |            |                                |`,
            addRowEnabled: true,
          },
        },
      },
      {
        id: "psur-complaints",

        prompt: false,
        required: true,
        element: {
          id: "psur-complaints",
          type: "table",
          options: {
            label:
              "Fill in any feedback and complaints that were not serious incidents.",
            helperText: `Since the last PSUR, provide in this table the feedback and complaints that were captured from users, distributers, and importers that were not otherwised captured in the vigilance section as serious incidents within this table. Use the same IMDRF adverse event categories as before. 

| Name of Terminology                                        | Description                                                                                                                                                                                                                         | Annex |
| ---------------------------------------------------------- | ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- | ----- |
| Medical device problem                                     | Terms/codes for describing problems (malfunction, deterioration of function, failure) of medical devices that have occurred in pre- or postmarket contexts (e.g. clinical studies, clinical evaluation or post-market surveillance) | A     |
| Cause of investigation<br>-Type of Investigation           | Terms/codes for describing the type of investigation of the device involved in the reported event.                                                                                                                                  | B     |
| Cause investigation<br>-Investigation Findings             | Terms/codes for describing the findings of the device involved in the reported event.                                                                                                                                               | C     |
| Cause investigation<br>-Investigation Conclusion           | Terms/codes for describing the conclusion of the investigation of the device involved in the reported event.                                                                                                                        | D     |
| Health Effects<br>-Clinical Signs, Symptoms and Conditions | Terms/codes for describing the clinical signs, symptoms and conditions of the affected person appearing as a result of the medical device adverse event/incident.                                                                   | E     |
| Health Effects<br>-Health Impact                           | Terms/codes for describing the consequences of the medical device adverse event/incident on the person affected.                                                                                                                    | F     |
| Component                                                  | Terms/codes for describing the parts and components which were involved in, or affected by, the medical device adverse event/incident.                                                                                              | G     |

*EAA = European Economic Area, TR = Turkey, XI = Iceland`,
            columns: [
              { name: "Region" },
              { name: "IMDRF AET Annex" },
              { name: "Complaints Past 12 Months" },
              { name: "Complaint % Past 12 Months" },
              { name: "Complaints Previous 12 Months" },
              { name: "Complaint % Previous 12 Months" },
            ],
            default: `
| Region        | IMDRF AET Annex | Complaints Past 12 Months | Complaint % Past 12 Months | Complaints Previous 12 Months | Complaint % Previous 12 Months |
| ------------- | --------------- | ------------------------- | -------------------------- | ----------------------------- | ------------------------------ |
| EAA + TR + XI | Total           |                           |                            |                               |                                |
| Worldwide     | Total           |                           |                            |                               |                                |
| EAA + TR + XI | A               |                           |                            |                               |                                |
| Worldwide     | A               |                           |                            |                               |                                |
| EAA + TR + XI | B               |                           |                            |                               |                                |
| Worldwide     | B               |                           |                            |                               |                                |
| EAA + TR + XI | C               |                           |                            |                               |                                |
| Worldwide     | C               |                           |                            |                               |                                |
| EAA + TR + XI | D               |                           |                            |                               |                                |
| Worldwide     | D               |                           |                            |                               |                                |
| EAA + TR + XI | E               |                           |                            |                               |                                |
| Worldwide     | E               |                           |                            |                               |                                |
| EAA + TR + XI | F               |                           |                            |                               |                                |
| Worldwide     | F               |                           |                            |                               |                                |
| EAA + TR + XI | G               |                           |                            |                               |                                |
| Worldwide     | G               |                           |                            |                               |                                |`,
          },
        },
      },
      {
        id: "psur-complaints-capa",
        prompt: false,
        required: true,
        element: {
          id: "psur-complaints-capa",
          type: "textField",
          options: {
            label: "Describe any CAPAs initiated by complaints.",
            helperText: `Were any CAPAs initiate from complaints received from users, distributors, importers, etc. since the last PSUR? If so, provide the CAPA numbers and a description of the CAPAs here.

If no CAPAs were initiated because of complaints, state "No CAPAs were initiated due to complaints."`,
            rows: 5,
          },
        },
      },
      {
        id: "psur-data-limitations",
        prompt: false,
        required: true,
        element: {
          id: "psur-data-limitations",
          type: "textField",
          options: {
            label:
              "Were there any limitations in the data you were able to collect?",
            helperText: `Provide additional commentary on whether there are any limitations in the collected data and whether they impact the ability to formulate meaningful conclusions. Were your sales numbers too low to calculate a complaint rate or maybe the amount of complaints provided were so minimal you could not do trend reporting. 

If there are no limitations, state so and that the data is valid and reliable.`,
            rows: 5,
          },
        },
      },
      {
        id: "psur-conclusions",
        prompt: true,
        required: true,
        context: [
          "psur-serious-incidents-summary",
          "psur-trend-reporting",
          "psur-fsca",
          "psur-capa",
          "psur-complaints",
          "pmcf-conclusions",
        ],
        element: {
          id: "psur-conclusions",
          type: "textField",
          options: {
            label: "What are the conclusions of the PSUR data analyzed?",
            helperText: `Provide an overall conclusion of the PSUR data presented and analyzed here. Include the following as relevant:

- any new or emerging risks that were identified
- any incidents of poor performance or when claimed clinical benefits were not achieved
- new risks or benefits considering specific patient groups, device models, accessories, geographical regions, durations of risk, etc.
- any new benefits
- any perceived changes to the benefit-risk profile`,
            rows: 5,
          },
        },
      },
      {
        id: "psur-manufacturer-actions",
        prompt: false,
        required: true,
        element: {
          id: "psur-manufacturer-actions",
          type: "textField",
          options: {
            label:
              "What actions do you need to take as a result of this PSUR data?",
            helperText: `Provide an overview of any specific actions that have been initiated as a result of the PSUR data. These could be taken to address new or emerging risks or evidence of poor performance. These could include CAPAs, bug fixes, risk management updates, etc. 

If no actions have been required to be taken, then state so.`,
            rows: 5,
          },
        },
      },
      {
        id: "psur-executive-summary",
        prompt: false,
        required: true,
        element: {
          id: "psur-executive-summary",
          type: "textField",
          options: {
            label: "Provide an executive summary of the PSUR.",
            helperText: `Create an executive summary of the following:

- A brief description and status of actions taken by the manufacturer based on the previous PSUR (if applicable);
- A brief description and status of actions taken by the Notified Body as part of the review of the previous PSUR;
- In case the data collection period is changed by the manufacturer, a justification should be provided, and a statement should be provided whether the change affects the comparability of the results gained.

If no real changes have occurred in any of these 3 categories, state so.`,
            rows: 5,
          },
        },
      },
      {
        id: "psur-benefit-risk",
        prompt: false,
        required: true,
        element: {
          id: "psur-benefit-risk",
          type: "select",
          options: {
            label:
              "How has the PSUR data just analyzed impact the benefit-risk profile of the device.",
            helperText: `Think about the results that you have just included in this PSUR and determine whether the risks you identified through complaints or incidents has significantly impacted what you knew about the risk profile of your device. Alternatively, think about the benefits your device provides. Has the complaints or feedback about the benefits your product provides suggest those benefits are not as good as you once thought?

If either of these are true, then your benefit-risk profile may be impacted. If this is impacted then your clinical evaluation will need to be reviewed to ensure that the benefits of your device still outweigh the risks of using it. Take a moment to consider this and select the most appropriate answer from the dropdown.`,
            options: [
              "Based on the analysis of the collected data, it is concluded that the benefit-risk profile of the device(s) has NOT been adversely impacted.",
              "Based on the analysis of the collected data, it is concluded that the benefit-risk profile of the device(s) HAS been adversely impacted.",
            ],
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.POST_MARKET_SURVEILLANCE_REPORT]: {
    id: TEMPLATE_TYPE.POST_MARKET_SURVEILLANCE_REPORT,
    docType: "TCD",
    docName: "Post-Market Surveillance Report",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    dependencies: [
      "vigilance-search-keywords",
      "vigilance-search-results",
      "vigilance-summary",
    ],
    elements: [],
  },
  [TEMPLATE_TYPE.POST_MARKET_CLINICAL_FOLLOW_UP_REPORT]: {
    id: TEMPLATE_TYPE.POST_MARKET_CLINICAL_FOLLOW_UP_REPORT,
    docType: "TCD",
    docName: "Post-Market Clinical Follow Up Report",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    dependencies: [
      "contraindications",
      "device-classification-justification",
      "device-classification",
      "emdn-code",
      "equivalence-comparison",
      "expected-lifetime",
      "ifu-accessories",
      "indications-for-use",
      "intended-use",
      "literature-summary",
      "manufacturer-address",
      "manufacturer-name",
      "medical-condition",
      "novel-features",
      "novel-procedure",
      "novel-product",
      "patient-population",
      "pmcf-activities",
      "pmcf-clinical-evaluation",
      "pmcf-contacts",
      "pmcf-risk-management",
      "similar-device-table",
      "software-version",
      "srn",
      "udi-di",
      "user-profile",
      "variants-and-accessories",
      "warnings-and-precautions",
    ],
    elements: [
      {
        id: "conformity-certificate-number",
        prompt: false,
        required: true,
        element: {
          id: "conformity-certificate-number",
          type: "textField",
          options: {
            label:
              "Provide the certificate number from your conformity assessment.",
            helperText: `This should be provided to you from your notified body as a certification of your device to conform with MDR 2017/745 and you should have received it after your conformity assessment.`,
          },
        },
      },
      {
        id: "pmcf-activities-results",
        prompt: false,
        required: true,
        element: {
          id: "pmcf-activities-results",
          type: "textField",
          options: {
            label: "Provide the results of your PMCF activities.",
            helperText: `Make sure to include the analysis of the findings, whether positive or negative and also the potential impact on the different documents (clinical evaluation report, risk management file, SSCP, etc…). Give an overview of the clinical data that was collected and if there were any deviations from the originally planned activities, describe what those are as well as giving a justification for those deviations.`,
            rows: 5,
          },
        },
      },
      {
        id: "pmcf-clinical-evaluation-results",
        prompt: false,
        required: true,
        element: {
          id: "pmcf-clinical-evaluation-results",
          type: "textField",
          options: {
            label:
              "Provide the results of activities related to the clinical evaluation.",
            helperText: `Provide any information here that should be further analyzed in your clinical evaluation report. This would include any new clinical data to be considered that may significantly impact the benefits or risks associated with use of your device in a clinical environment.

              If there is no information that needs to be considered in the clinical evaluation report that came from the PMCF activities, then state that "No relevant information for the clinical evaluation report needs to be considered".`,
            rows: 5,
          },
        },
      },
      {
        id: "pmcf-risk-management-results",
        prompt: false,
        required: true,
        element: {
          id: "pmcf-risk-management-results",
          type: "textField",
          options: {
            label:
              "Provide the results of activities related to the risk management file.",
            helperText: `Do any of the results of your PMCF need to be incorporated into your risk management file? This would be new risks or changes to existing risk severity or occurrence that already exist in your risk management file.

              If there is no information that needs to be considered in the risk management file that came from the PMCF activities, then state that "No relevant information for the risk management file needs to be considered".`,
            rows: 5,
          },
        },
      },
      {
        id: "pmcf-conclusions",
        prompt: true,
        required: true,
        context: [
          "pmcf-activities-results",
          "pmcf-clinical-evaluation-results",
          "pmcf-risk-management-results",
        ],
        element: {
          id: "pmcf-conclusions",
          type: "textField",
          options: {
            label: "Provide a conclusion for your PMCF results.",
            helperText: `In this section, it is expected that you shall provide an overall conclusion of the findings and relate them to the aims of the PMCF plan. The conclusions shall be taken into account in the following clinical evaluation and in the risk management. Finally, this conclusion shall highlight if any need for preventive and/or corrective measures has been identified. The conclusion may also give input to the next PMCF plan.`,
            rows: 5,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOP_CLINICAL_EVALUATION]: {
    id: TEMPLATE_TYPE.SOP_CLINICAL_EVALUATION,
    docName: "SOP Clinical Evaluation",
    docType: "SOP",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    elements: [],
  },
  [TEMPLATE_TYPE.CLINICAL_EVALUATION_PLAN]: {
    id: TEMPLATE_TYPE.CLINICAL_EVALUATION_PLAN,
    docName: "Clinical Evaluation Plan",
    docType: "TCD",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    dependencies: [
      "device-classification",
      "software-version",
      "intended-use",
      "indications-for-use",
      "patient-population",
      "user-profile",
      "use-environment",
      "part-of-the-body",
      "variants-and-accessories",
      "principles-of-operation",
      "contraindications",
    ],
    elements: [
      {
        id: "clinical-device-description",
        prompt: true,
        required: true,
        context: ["intended-use"],
        element: {
          id: "clinical-device-description",
          type: "textField",
          options: {
            label:
              "Provide a description of your device with special attention to its clinical performance environment.",
            rows: 5,
            helperText:
              "Provide a detailed description of the device and how it is used clinically by your users. This can include specific steps for the healthcare providers (if relevant) and how users interact with the device during its use. Make sure to include how the disease/condition/etc. is handled during normal use of your device. This should be similar to the device description you gave us in the beginning but with more detail on its use in a clinical environment.",
          },
        },
      },
      {
        id: "clinical-benefits",
        prompt: true,
        required: true,
        context: ["intended-use"],
        element: {
          id: "clinical-benefits",
          type: "textField",
          options: {
            label: "Provide the clinical benefits of your device. ",
            rows: 5,
            helperText: `Think of these as your clinical marketing claims that are in your instructions for use. Your intended use is your primary clinical benefit. Any other direct benefits your product gives your patients/users should be included here. 
  
                Do not include "unintentional benefits" here. Only include what you know is true and have data to back up. If there are benefits that are widely known for this class of products then we can state those later in the CER after we analyze all of our clinical data.`,
          },
        },
      },
      {
        id: "clinical-claims",
        required: true,
        prompt: true,
        context: ["intended-use", "clinical-benefits"],
        element: {
          id: "clinical-claims",
          type: "textField",
          options: {
            label:
              "Provide any marketing claims you currently use for your product related to clinical performance or safety that aren't listed as clinical benefits.",
            rows: 5,
            helperText: `You only need to include claims that would need to be substantiated by clinical data. This could be "our product treats x times faster" or "our product is x times safer than other devices". These can include clinical benefits but are really all clinical claims that don't exist in your instructions for use.`,
          },
        },
      },
      {
        id: "risk-hazards",
        required: true,
        context: ["risk-analysis"],
        element: {
          id: "risk-hazards",
          type: "table",
          transformerConfig: {
            type: "default",
            inputs: ["risk-analysis"],
            // Only list the risk that are "hazardous situations, hazard, harm"
            transformer: (inputs: Record<DocumentDataKey, string>) => {
              try {
                // transform the table to a list of objects to be able to manipulate it
                const tableAsArray = parseMarkdownTable(
                  inputs["risk-analysis"]
                );

                // create a new array to store the new table
                const riskTable = tableAsArray.map(
                  (row: Record<string, string>) => ({
                    "Risk ID": row["Risk ID"],
                    "Risk Type": row["Risk Type"],
                    Hazard: row["Hazard"],
                    "Hazardous Situation": row["Hazardous Situation"],
                    Harm: row["Harm"],
                  })
                );

                const mergedMarkdown = toMarkdownTable(riskTable);

                return mergedMarkdown;
              } catch (e) {
                captureSentryException(e);
                return "";
              }
            },
          },
          options: {
            label: "Confirm your risks table.",
            columns: [
              {
                name: "Risk ID",
                options: { flex: 0 },
              },
              {
                name: "Risk Type",
                options: { flex: 0 },
              },
              {
                name: "Hazard",
              },
              {
                name: "Hazardous Situation",
              },
              {
                name: "Harm",
              },
            ],
            idColumn: "Risk ID",
            helperText:
              "This is the hazards table taken from your risk assessment. We will use this table to compare against any risks we find in the clinical evidence we gather later.",
          },
        },
      },
      {
        id: "valid-clinical-association",
        prompt: true,
        required: true,
        element: {
          id: "valid-clinical-association",
          type: "textField",
          options: {
            label: "Describe your clinical association.",
            rows: 5,
            helperText: `The valid clinical association is essentially how the output or performance of your device is associated with improvement in whatever physiological condition you are targeting. This should have some basis in scientific reasoning or fact. `,
          },
        },
      },
      {
        id: "technical-performance",
        prompt: true,
        required: true,
        element: {
          id: "technical-performance",
          type: "textField",
          options: {
            label: "Describe the technical performance of your device.",
            rows: 5,
            helperText: `Describe how your product takes the input data and transform it into the appropriate output data to be used for the valid clinical association.`,
          },
        },
      },
      {
        id: "clinical-performance",
        prompt: true,
        required: true,
        element: {
          id: "clinical-performance",
          type: "textField",
          options: {
            label: "Describe the clinical performance of your device.",
            rows: 5,
            helperText: `Describe how your device is used to yield clinically relevant output in accordance with the intended purpose. Essentially, how does the output of your data lead to the clinical benefits you described earlier`,
          },
        },
      },
      {
        id: "literature-search-keywords",
        prompt: true,
        required: true,
        element: {
          id: "literature-search-keywords",
          type: "table",
          options: {
            label:
              "Confirm the keywords to be used for your literature search.",
            columns: [
              { name: "PICO Category", options: { flex: 0 } },
              { name: "Keywords" },
            ],
            helperText: `The literature search is one of the most important aspects of the clinical evaluation and where you will gather most of your clinical data to use to support your devices performance and safety. The way we will search for publications is to use a standardized search approach called PICO where search terms are chosen according to the device "Patient / Problem", "Indication / Intended Use", "Comparator devices", and "Outcomes expected". `,
          },
        },
      },
      {
        id: "clinical-trial-search-keywords",
        prompt: true,
        required: true,
        context: ["literature-search-keywords"],
        element: {
          id: "clinical-trial-search-keywords",
          type: "table",
          options: {
            label:
              "Confirm the keywords to be used for your clinical trials search.",
            columns: [
              { name: "PICO Category", options: { flex: 0 } },
              { name: "Keywords" },
            ],
            helperText: `You will be searching for clinical trials related to your product as well as similar devices on the market and standards of treatment. We'll need to perform the search as part of the clinical evaluation report so this step is just about putting together some potential keywords to search. Confirm that these keywords make sense to search with in the context of your device and the market.`,
          },
        },
      },
      {
        id: "vigilance-search-keywords",
        prompt: true,
        required: true,
        element: {
          id: "vigilance-search-keywords",
          type: "textField",
          options: {
            label:
              "Confirm the keywords to be used for your vigilance database search.",
            rows: 5,
            helperText: `Vigilance databases house safety issues that doctors and patients have reported about certain medical devices. We will search the vigilance databases for our device and similar devices on the market using their brand names. The FDA databases also have a feature to search for product codes which are broad categories of devices and help broaden our search. Confirm that the competitor devices make sense for your product.`,
          },
        },
      },
      {
        id: "clinical-evaluation-team",
        prompt: false,
        required: true,
        element: {
          id: "clinical-evaluation-team",
          type: "table",
          options: {
            label:
              "Provide the name and position of each member of your clinical evaluation team.",
            columns: [{ name: "Name" }, { name: "Position" }],
            default: `
              | Name | Position |
              | ---- | ---- |
              |      |      |`,
            helperText: `Your clinical evaluation team is required to have specialized experience in the clinical area related to your medical device. Additionally, they should have (i) a degree from higher education in the respective field and 5 years of documented professional experience; or (ii) 10 years of professional experience if a degree is not a prerequisite for a given task. Usually, if you don't have an employee that fits this category you can outsource this to a friend or early adopter. Provide the names of team members who have experience to perform the clinical evaluation. Most clinical evaluation teams are around 3 people or so.`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT]: {
    id: TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT,
    docName: "Clinical Evaluation Report",
    docType: "TCD",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    dependencies: [
      "device-classification",
      "software-version",
      "clinical-device-description",
      "intended-use",
      "user-testing-impact",
      "indications-for-use",
      "patient-population",
      "use-environment",
      "part-of-the-body",
      "variants-and-accessories",
      "principles-of-operation",
      "clinical-benefits",
      "clinical-claims",
      "risk-hazards",
      "contraindications",
      "valid-clinical-association",
      "technical-performance",
      "clinical-performance",
      "formative-evaluations",
      "formative-summary-suggestions",
      "formative-summary-outputs",
      "vigilance-search-keywords",
      "user-profile",
      "literature-search-keywords",
      "clinical-evaluation-team",
    ],
    elements: [
      {
        id: "clinical-background",
        prompt: true,
        required: true,
        element: {
          id: "clinical-background",
          type: "textField",
          options: {
            label:
              "Provide the clinical background for your device including why and how devices are used in the clinical environment.",
            rows: 5,
            helperText: `The purpose here is to set the stage for what why the use of your device is necessary. We want to provide context in the form of a background on how healthcare professionals or ordinary users for your device encounter their disease or condition and what that disease or condition looks like in the patient population.`,
          },
        },
      },
      {
        id: "state-of-the-art",
        prompt: true,
        required: true,
        context: ["clinical-background"],
        element: {
          id: "state-of-the-art",
          type: "textField",
          options: {
            label: "Describe the state of the art for your device.",
            rows: 5,
            helperText: `State of the art is essentially what devices and techniques exist in the clinical space for which your device operates today. You are going to want to list what techniques your users or healthcare providers currently use to tackle the problem you are trying to solve. Also, include any similar devices as well as the historical context for the development of your device if you can.`,
          },
        },
      },
      {
        id: "similar-device-table",
        prompt: true,
        required: true,
        context: ["state-of-the-art"],
        element: {
          id: "similar-device-table",
          type: "table",
          options: {
            label: "Provide a list of similar devices on the market.",
            columns: [
              { name: "Device Name", options: { flex: 0 } },
              { name: "Manufacturer", options: { flex: 0 } },
              { name: "MDR Class", options: { flex: 0 } },
              { name: "Similarities" },
              { name: "Differences" },
            ],

            helperText: `This list should include the device name, manufacturer, and similarities and differences between the devices and your device. One of these devices should be chosen as the equivalent device for the purpose of this clinical evaluation.`,
          },
        },
      },
      {
        id: "equivalent-device-selection",
        prompt: false,
        required: true,
        element: {
          id: "equivalent-device-selection",
          type: "textField",
          options: {
            label: "Select what equivalent device you will use.",

            helperText: `Which device from your list of similar devices is going to be used as your equivalent device. This device should be the most similar in its technical and clinical aspects toy our device.`,
          },
        },
      },

      {
        id: "equivalence-comparison",
        prompt: true,
        required: true,
        context: [
          "device-classification",
          "intended-use",
          "patient-population",
          "user-profile",
          "use-environment",
          "principles-of-operation",
          "part-of-the-body",
          "equivalent-device-selection",
        ],
        element: {
          id: "equivalence-comparison",
          type: "table",
          options: {
            label:
              "Describe how your device differs from the equivalent device.",
            columns: [
              { name: "Category" },
              { name: "Device under evaluation" },
              { name: "Equivalent Device" },
              { name: "Similar / Different" },
              { name: "Justification if Different" },
            ],
            helperText: `Here we want to provide specific details about how our device is different or similar. The main goal is demonstrating that our device is so similar to the equivalent device it wouldn't lead to any additional risks to the patient and should therefore be allowed to exist on the market. Justification for any differences is very important here.`,
          },
        },
      },
      {
        id: "equivalence-summary",
        prompt: true,
        required: true,
        context: ["equivalence-comparison", "equivalent-device-selection"],
        element: {
          id: "equivalence-summary",
          type: "textField",
          options: {
            label:
              "Summarize the main differences and similarities between your device and the equivalent device.",
            rows: 5,
            helperText: `Summarize any main differences between your device and the equivalent that could cause concern for auditors and provide justification as to why they aren't clinically meaningful. Highlight the similarities of the two devices and why they would provide similar benefits while having low risk to users. You will want to specifically mention the technical, clinical, and biological characteristics of the device.`,
          },
        },
      },
      {
        id: "pubmed-search-string",
        prompt: true,
        required: true,
        context: ["literature-search-keywords"],
        videoUrl:
          "https://www.loom.com/share/535ac89b1ad944608e59477e83d9a837?sid=77541aae-867f-4b2f-8d59-53fb9ab5f911",
        element: {
          id: "pubmed-search-string",
          type: "parsedFileUploadElement",
          options: {
            label:
              "Watch the instructional video and copy the search string to Pubmed to retrieve the literature results.",
            rows: 5,
            helperText: `Watch the instructional video prior to performing this step! We will be searching for any relevant scientific publications for our device. When you retrieve the CSV file according to the instructional video, upload it via the upload file button. Once the file is uploaded it should confirm with you the number of publications you retrieved.
  
              Here is a link to the pubmed website: https://pubmed.ncbi.nlm.nih.gov/`,

            allowedFileTypes: ".txt",
            onUpload: (file: File) => {
              return new Promise((resolve, reject) => {
                function mapToMarkdownTable(input: string) {
                  // Split the input into records, each record separated by a double newline
                  const records = input
                    .split(/\r?\n\r?\n/)
                    .filter(
                      (record) =>
                        record.includes("PMID-") &&
                        record.includes("DP  -") &&
                        record.includes("TI  -") &&
                        record.includes("AB  -") &&
                        record.includes("FAU -") &&
                        record.includes("SO  -")
                    );
                  // Initialize the Markdown table with headers
                  let markdownTable = `| ID | Publication Date | Title | Abstract | First Author | Citation |\n| ------ | ------ | ------ | ------ | ------ | ------ |\n`;

                  records.forEach((record) => {
                    const pmidMatch = record.match(/PMID- (\d+)/);
                    const publicationDateMatch = record.match(/DP  - (.+)/);
                    const titleMatch = record
                      .match(/TI\s+-\s+([\s\S]+?)(?=\n[A-Z]{2,}\s+-)/)?.[1]
                      .replace(/\r\n?\s*/g, "");
                    const abstractMatch = record
                      .match(/AB\s+-\s+([\s\S]+?)(?=\n[A-Z]{2,}\s+-)/)?.[1]
                      .replace(/\r\n?\s*/g, "");
                    const firstAuthorMatch = record.match(/FAU - (.+)/);
                    const citationMatch = record
                      .match(/SO\s+-\s+([\s\S]+)/)?.[1]
                      .replace(/\r?\n\s*/g, " ")
                      .trim();

                    const pmid = pmidMatch ? pmidMatch[1].trim() : "";
                    const publicationDate = publicationDateMatch
                      ? publicationDateMatch[1].trim()
                      : "";
                    const title = titleMatch ? titleMatch.trim() : "";
                    const abstract = abstractMatch ? abstractMatch.trim() : "";
                    const firstAuthor = firstAuthorMatch
                      ? firstAuthorMatch[1].trim()
                      : "";
                    const citation = citationMatch || "";

                    markdownTable += `| ${pmid} | ${publicationDate} | ${title} | ${abstract} | ${firstAuthor} | ${citation} |\n`;
                  });

                  return markdownTable;
                }

                // retrieve the file text
                const reader = new FileReader();
                reader.onload = async (e) => {
                  const text = e.target?.result as string;

                  const markdown = mapToMarkdownTable(text);
                  resolve({ type: "table", result: markdown });
                };

                reader.onerror = () => {
                  // If there's an error reading the file, reject the promise
                  reject(new Error("Failed to read file"));
                };

                reader.readAsText(file);
              });
            },
          },
        },
      },
      {
        id: "applied-pubmed-search-string",
        prompt: false,
        required: true,
        element: {
          id: "applied-pubmed-search-string",
          type: "textField",
          options: {
            label: "Paste the search string that you used in Pubmed.",
            rows: 5,
            helperText:
              "This can be the unaltered string that we suggested in the previous step or a modified version of it.",
          },
        },
      },
      {
        id: "cochrane-search-string",
        prompt: true,
        required: true,
        context: ["literature-search-keywords"],
        videoUrl:
          "https://www.loom.com/share/5bdef8a6d82440129d2735d990664bbb?sid=8d659d8e-39da-4425-b0b1-fe4e92401af1",
        element: {
          id: "cochrane-search-string",
          type: "parsedFileUploadElement",
          options: {
            label:
              "Watch the instructional video and copy the search string to the Cochrane Library to retrieve the literature results.",
            rows: 5,
            helperText: `Watch the instructional video prior to performing this step! We will be searching for any relevant scientific publications for our device. When you retrieve the CSV file according to the instructional video, upload it via the upload file button. Once the file is uploaded it should confirm with you the number of publications you retrieved.
  
              Here is a link to the Cochrane Library website: https://www.cochranelibrary.com/advanced-search/search-manager`,
            allowedFileTypes: ".csv",
            onUpload: (file: File) => {
              return new Promise((resolve, reject) => {
                Papa.parse(file, {
                  complete: (results) => {
                    if (results.errors.length > 0) {
                      console.error("Error parsing CSV", results.errors);
                      reject(new Error("Error parsing CSV"));
                    } else {
                      const entries = results.data as string[][];

                      let markdownTable = `| ID | Publication Date | Title | Abstract | First Author | Citation |\n| ------ | ------ | ------ | ------ | ------ | ------ |\n`;

                      // Transform array to markdown table first item is an array of headers and the rest are rows
                      markdownTable += entries
                        .slice(1)
                        .map((row) => {
                          // Authors in cochrane are exported like this: Beishon, LC; Batterham, AP; Quinn, TJ; Nelson, CP; Panerai, RB; Robinson, T; Haunton, VJ
                          // We only want the fist one

                          const authors = row[1]?.split(";");

                          // For some reason the last row sometimes is empty so we need to make sure we are not accessing out of bounds
                          const firstAuthor = authors
                            ? `${authors[0]} ${
                                authors.length > 1 ? "et al." : ""
                              }`
                            : "";

                          return `| ${row[0]} | ${row[4]} | ${row[2]} | ${
                            row[5]
                          } | ${firstAuthor} | ${row.at(-3)} |`;
                        })
                        .join("\n");

                      resolve({ type: "table", result: markdownTable });
                    }
                  },
                });
              });
            },
          },
        },
      },
      {
        id: "applied-cochrane-search-string",
        prompt: false,
        required: true,
        element: {
          id: "applied-cochrane-search-string",
          type: "textField",
          options: {
            label: "Paste the search string that you used in Cochrane.",
            rows: 5,
            helperText:
              "This can be the unaltered string that we suggested in the previous step or a modified version of it.",
          },
        },
      },
      {
        id: "date-of-literature-search",
        prompt: false,
        required: true,
        element: {
          id: "date-of-literature-search",
          type: "textField",
          options: {
            label: "What date did you perform your literature search?",
            helperText:
              "This is the date that you searched Pubmed and Cochrane Library, so likely it is today's date!",
          },
        },
      },
      {
        id: "person-performing-literature-search",
        prompt: false,
        required: true,
        element: {
          id: "person-performing-literature-search",
          type: "textField",
          options: {
            label: "Who performed your literature search?",
          },
        },
      },
      // {
      //   id: "literature-search-raw-csv",
      //   prompt: false,
      //   required: true,
      //   element: {
      //     id: "literature-search-raw-csv",
      //     type: "textField",
      //     options: {
      //       label: "Paste the raw CSV of your literature search.",
      //       rows: 5,
      //       helperText:
      //         "This is the raw CSV of your literature search. It should contain all of the articles you found in your literature search.",
      //     },
      //   },
      // },
      {
        id: "literature-search-table",
        prompt: true,
        required: true,
        context: [
          "pubmed-search-string",
          "cochrane-search-string",
          "equivalent-device-selection",
          "intended-use",
        ],
        // context: ["pubmed-search-string"],
        element: {
          id: "literature-search-table",
          type: "table",
          options: {
            label:
              "Check the filtered table of your literature search results.",

            // |PubID|Title|Authors|Year|Title / Abstract | Full-text | Justification| Performance / Safety / Both | Device / State of the art|
            columns: [
              { name: "PubID", options: { flex: 0 } },
              { name: "Title", options: { flex: 1 } },
              { name: "Authors", options: { flex: 0 } },
              { name: "Year", options: { flex: 0 } },
              { name: "Title / Abstract", options: { flex: 0 } },
              { name: "Full-text", options: { flex: 0 } },
              { name: "Justification", options: { flex: 0 } },
              { name: "Performance & Safety / Safety", options: { flex: 0 } },
              { name: "Device / State of the art", options: { flex: 0 } },
            ],
            helperText: `Here we have automatically filtered the results of your publications as to whether they are relevant or not. Relevant publications are those that are related to your device, the equivalent device you chose, or the state of the art for your product. Additionally, these publications are only included for analysis if they concern clinical testing. Only edit the table if you know for a fact that a publication has been included but instead needs to be included.`,
          },
        },
      },

      {
        id: "literature-search-results",
        prompt: false,
        required: true,
        context: ["literature-search-table"],
        element: {
          id: "literature-search-results",
          type: "table",
          options: {
            label: "What were the results of your literature search?",
            columns: [{ name: "Search Criteria" }, { name: "Results" }],
            helperText: `This is the final summary count of all of your scientific publications that were just reviewed. Review the numbers and confirm that they add up. The number of Full-Text Exclusions and Included Publications should add up to your Total Number of Results.`,
          },
          transformerConfig: {
            type: "default",
            inputs: [
              "literature-search-table",
              "pubmed-search-string",
              "cochrane-search-string",
            ],
            transformer: (inputs: Record<DocumentDataKey, string>) => {
              const keys = getTableColumns(
                TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT,
                "literature-search-table"
              );

              const literatureSearchTableArray = parseMarkdownTable(
                inputs["literature-search-table"]
              ).filter((r) => keys.every((k) => r.hasOwnProperty(k)));

              const pubmedSearchTable = parseMarkdownTable(
                inputs["pubmed-search-string"]
              );

              const cochraneSearchTable = parseMarkdownTable(
                inputs["cochrane-search-string"]
              );

              const numberOfPubmedResults = pubmedSearchTable.length;
              const numberOfCochraneResults = cochraneSearchTable.length;
              const totalNumberOfResults = literatureSearchTableArray.length;
              const exclusions = literatureSearchTableArray.filter(
                (r) => r["Title / Abstract"].toLowerCase() === "excluded"
              ).length;
              const fullTextExclusions = literatureSearchTableArray.filter(
                (r) => r["Full-text"].toLowerCase() === "excluded"
              ).length;
              const includedPublications = literatureSearchTableArray.filter(
                (r) =>
                  r["Title / Abstract"].toLowerCase() === "included" &&
                  r["Full-text"].toLowerCase() === "included"
              ).length;

              // create a new array to store the new table
              return `
                  | Search Criteria                       | Results |
                  | ------------------------------------- | ------- |
                  | PubMed Total Results                  | ${numberOfPubmedResults}    |
                  | Cochrane Total Results                | ${numberOfCochraneResults}    |
                  | Total Number of Results               | ${totalNumberOfResults}    |
                  | Number of Title / Abstract Exclusions | ${exclusions}    |
                  | Number of Full-Text Exclusions        | ${fullTextExclusions}    |
                  | Number of Included Publications       | ${includedPublications}    |
                 `;
            },
          },
        },
      },
      {
        id: "performance-literature-device",
        prompt: false,
        required: false,
        context: ["literature-search-table"],
        element: {
          id: "performance-literature-device",
          type: "table",
          options: {
            label:
              "Provide the summaries of the articles included for performance literature for your device and the equivalent device.",
            // |PubID | Summary|
            columns: [
              { name: "PubID", options: { flex: 0 } },
              { name: "Title", options: { maxWidth: 300 } },
              { name: "Summary" },
            ],
            helperText: `If no publications were found for your device or for the equivalent device, this table will be empty. That is ok, we can use other data to support the performance and safety of your device!`,
          },
          transformerConfig: {
            type: "default",
            inputs: [
              "literature-search-table",
              "pubmed-search-string",
              "cochrane-search-string",
            ],
            transformer: (inputs: Record<DocumentDataKey, string>) => {
              // transform the table to a list of objects to be able to manipulate it
              const literatureSearchTableArray = parseMarkdownTable(
                inputs["literature-search-table"]
              );

              const pubmedSearchTable = parseMarkdownTable(
                inputs["pubmed-search-string"]
              );

              const cochraneSearchTable = parseMarkdownTable(
                inputs["cochrane-search-string"]
              );

              const searchTables =
                pubmedSearchTable.concat(cochraneSearchTable);

              // get the table columns from the config
              const keys = getTableColumns(
                TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT,
                "literature-search-table"
              );

              const literatureSearchTableMapped = literatureSearchTableArray
                // Make sure all the keys are present in the object to prevent undefined object properties
                .filter((r) => keys.every((k) => r.hasOwnProperty(k)))
                .filter(
                  (r) =>
                    r["Performance & Safety / Safety"]?.toLowerCase().trim() ===
                      "performance & safety" &&
                    r["Device / State of the art"].toLowerCase().trim() ===
                      "device" &&
                    r["Full-text"].toLowerCase().trim() === "included" &&
                    r["Title / Abstract"].toLowerCase().trim() === "included"
                )
                .map(
                  (r) =>
                    `| ${r["PubID"]} | ${r["Title"]} | ${
                      searchTables.find(
                        (item) => item["ID"].trim() === r["PubID"].trim()
                      )?.["Abstract"]
                    } |`
                )
                .join("\n");

              return `| PubID | Title | Summary |\n| ---- | ---- | ---- |\n${literatureSearchTableMapped}`;
            },
          },
        },
      },
      {
        id: "literature-summary-device-performance",
        prompt: true,
        required: true,
        context: [
          "performance-literature-device",
          "intended-use",
          "equivalent-device-selection",
        ],
        element: {
          id: "literature-summary-device-performance",
          type: "textField",
          options: {
            label:
              "Provide a summary of the performance data related to your device or the equivalent device.",
            rows: 5,
            helperText: `This should be a summary of the literature as it relates to the performance of your device or the equivalent device. If no publications were found for your device or for the equivalent device, that is ok, we can use other data to support the performance and safety of your device!`,
          },
        },
      },
      {
        id: "performance-literature-state-of-the-art",
        prompt: false,
        required: true,
        context: ["literature-search-table"],
        element: {
          id: "performance-literature-state-of-the-art",
          type: "table",
          options: {
            label:
              "Provide the summaries of the articles included for performance literature for the state of the art.",
            columns: [
              { name: "PubID", options: { flex: 0 } },
              { name: "Title", options: { maxWidth: 300 } },
              { name: "Summary" },
            ],
            helperText: `These summaries should relate specifically to the performance devices similar to your device. These come from the filtered literature table that we created in the previous steps.`,
          },
          transformerConfig: {
            type: "default",
            inputs: [
              "literature-search-table",
              "pubmed-search-string",
              "cochrane-search-string",
            ],
            transformer: (inputs: Record<DocumentDataKey, string>) => {
              // transform the table to a list of objects to be able to manipulate it
              const literatureSearchTableArray = parseMarkdownTable(
                inputs["literature-search-table"]
              );

              const pubmedSearchTable = parseMarkdownTable(
                inputs["pubmed-search-string"]
              );

              const cochraneSearchTable = parseMarkdownTable(
                inputs["cochrane-search-string"]
              );

              const searchTables =
                pubmedSearchTable.concat(cochraneSearchTable);

              // get the table columns from the config
              const keys = getTableColumns(
                TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT,
                "literature-search-table"
              );

              const literatureSearchTableMapped = literatureSearchTableArray
                // Make sure all the keys are present in the object to prevent undefined object properties
                .filter((r) => keys.every((k) => r.hasOwnProperty(k)))
                .filter(
                  (r) =>
                    r["Performance & Safety / Safety"].toLowerCase().trim() ===
                      "performance & safety" &&
                    r["Device / State of the art"].toLowerCase().trim() ===
                      "state of the art" &&
                    r["Full-text"].toLowerCase().trim() === "included" &&
                    r["Title / Abstract"].toLowerCase().trim() === "included"
                )
                .map(
                  (r) =>
                    `| ${r["PubID"]} | ${r["Title"]} | ${
                      searchTables.find(
                        (item) => item["ID"].trim() === r["PubID"].trim()
                      )?.["Abstract"]
                    } |`
                )
                .join("\n");

              return `| PubID | Title | Summary |\n| ---- | ---- | ---- |\n${literatureSearchTableMapped}`;
            },
          },
        },
      },
      {
        id: "literature-summary-state-of-the-art-performance",
        prompt: true,
        required: true,
        context: ["performance-literature-state-of-the-art", "intended-use"],
        element: {
          id: "literature-summary-state-of-the-art-performance",
          type: "textField",
          options: {
            label:
              "Provide a summary of the performance data related to the state of the art.",
            rows: 5,
            helperText: `This should be a summary of the literature as it relates to the performance of the state of the art.`,
          },
        },
      },
      {
        id: "safety-literature-device",
        prompt: false,
        required: false,
        context: ["literature-search-table"],
        element: {
          id: "safety-literature-device",
          type: "table",
          options: {
            label:
              "Provide the summaries of the articles included for safety literature for your device and the equivalent device.",
            columns: [
              { name: "PubID", options: { flex: 0 } },
              { name: "Title", options: { maxWidth: 300 } },
              { name: "Summary" },
            ],

            helperText: `If no publications were found for your device or for the equivalent device, this table will be empty. That is ok, we can use other data to support the performance and safety of your device!`,
          },
          transformerConfig: {
            type: "default",
            inputs: [
              "literature-search-table",
              "pubmed-search-string",
              "cochrane-search-string",
            ],
            transformer: (inputs: Record<DocumentDataKey, string>) => {
              // transform the table to a list of objects to be able to manipulate it
              const literatureSearchTableArray = parseMarkdownTable(
                inputs["literature-search-table"]
              );

              const pubmedSearchTable = parseMarkdownTable(
                inputs["pubmed-search-string"]
              );

              const cochraneSearchTable = parseMarkdownTable(
                inputs["cochrane-search-string"]
              );

              const searchTables =
                pubmedSearchTable.concat(cochraneSearchTable);

              // get the table columns from the config
              const keys = getTableColumns(
                TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT,
                "literature-search-table"
              );

              const literatureSearchTableMapped = literatureSearchTableArray
                // Make sure all the keys are present in the object to prevent undefined object properties
                .filter((r) => keys.every((k) => r.hasOwnProperty(k)))
                .filter(
                  (r) =>
                    (r["Performance & Safety / Safety"].toLowerCase().trim() ===
                      "safety" ||
                      r["Performance & Safety / Safety"]
                        .toLowerCase()
                        .trim() === "performance & safety") &&
                    r["Device / State of the art"].toLowerCase().trim() ===
                      "device" &&
                    r["Full-text"].toLowerCase().trim() === "included" &&
                    r["Title / Abstract"].toLowerCase().trim() === "included"
                )
                .map(
                  (r) =>
                    `| ${r["PubID"]} | ${r["Title"]} | ${
                      searchTables.find(
                        (item) => item["ID"].trim() === r["PubID"].trim()
                      )?.["Abstract"]
                    } |`
                )
                .join("\n");

              return `| PubID | Title | Summary |\n| ---- | ---- | ---- |\n${literatureSearchTableMapped}`;
            },
          },
        },
      },
      {
        id: "literature-summary-device-safety",
        prompt: true,
        required: true,
        context: [
          "safety-literature-device",
          "intended-use",
          "equivalent-device-selection",
        ],
        element: {
          id: "literature-summary-device-safety",
          type: "textField",
          options: {
            label:
              "Provide a summary of the safety data related to your device or the equivalent device.",
            rows: 5,
            helperText: `This should be a summary of the literature as it relates to the safety of your device or the equivalent device. If no publications were found for your device or for the equivalent device, that is ok, we can use other data to support the performance and safety of your device!`,
          },
        },
      },
      {
        id: "safety-literature-state-of-the-art",
        prompt: false,
        required: true,
        context: ["literature-search-table"],
        element: {
          id: "safety-literature-state-of-the-art",
          type: "table",
          options: {
            label:
              "Provide the summaries of the articles included for safety literature for the state of the art.",
            columns: [
              { name: "PubID", options: { flex: 0 } },
              { name: "Title", options: { maxWidth: 300 } },
              { name: "Summary", options: { flex: 1 } },
            ],
            helperText: `If no publications were found for your device or for the equivalent device, this table will be empty. That is ok, we can use other data to support the performance and safety of your device!`,
          },
          transformerConfig: {
            type: "default",
            inputs: [
              "literature-search-table",
              "pubmed-search-string",
              "cochrane-search-string",
            ],

            transformer: (inputs: Record<DocumentDataKey, string>) => {
              // transform the table to a list of objects to be able to manipulate it
              const literatureSearchTableArray = parseMarkdownTable(
                inputs["literature-search-table"]
              );

              const pubmedSearchTable = parseMarkdownTable(
                inputs["pubmed-search-string"]
              );

              const cochraneSearchTable = parseMarkdownTable(
                inputs["cochrane-search-string"]
              );

              const searchTables =
                pubmedSearchTable.concat(cochraneSearchTable);

              // get the table columns from the config
              const keys = getTableColumns(
                TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT,
                "literature-search-table"
              );

              const literatureSearchTableMapped = literatureSearchTableArray
                // Make sure all the keys are present in the object to prevent undefined object properties
                .filter((r) => keys.every((k) => r.hasOwnProperty(k)))
                .filter(
                  (r) =>
                    (r["Performance & Safety / Safety"].toLowerCase().trim() ===
                      "safety" ||
                      r["Performance & Safety / Safety"]
                        .toLowerCase()
                        .trim() === "performance & safety") &&
                    r["Device / State of the art"].toLowerCase().trim() ===
                      "state of the art" &&
                    r["Full-text"].toLowerCase().trim() === "included" &&
                    r["Title / Abstract"].toLowerCase().trim() === "included"
                )
                .map(
                  (r) =>
                    `| ${r["PubID"]} | ${r["Title"]} | ${
                      searchTables.find(
                        (item) => item["ID"].trim() === r["PubID"].trim()
                      )?.["Abstract"]
                    } |`
                )
                .join("\n");

              return `| PubID | Title | Summary |\n| ---- | ---- | ---- |\n${literatureSearchTableMapped}`;
            },
          },
        },
      },
      {
        id: "literature-summary-state-of-the-art-safety",
        prompt: true,
        required: true,
        context: ["safety-literature-state-of-the-art", "intended-use"],
        element: {
          id: "literature-summary-state-of-the-art-safety",
          type: "textField",
          options: {
            label:
              "Provide a summary of the safety data related to the state of the art.",
            rows: 5,
            helperText: `This should be a summary of the literature as it relates to the safety of the state of the art.`,
          },
        },
      },
      {
        id: "literature-summary",
        prompt: true,
        required: true,
        context: [
          "intended-use",
          "literature-summary-device-performance",
          "literature-summary-device-safety",
          "literature-summary-state-of-the-art-performance",
          "literature-summary-state-of-the-art-safety",
          "equivalent-device-selection",
        ],
        element: {
          id: "literature-summary",
          type: "textField",
          options: {
            label:
              "Provide a summary of all of the performance and safety data from the literature.",
            rows: 5,
            helperText: `Here we are trying to link the information that we found in the literature to our device's performance and safety. The goal is to leverage the specific performance and safety data from the publications to make inferences on why our product would perform as we intend it to and be safe in a clinical setting.`,
          },
        },
      },
      {
        id: "full-text-appraisal-table",
        prompt: true,
        required: true,
        context: ["performance-literature-device", "safety-literature-device"],
        element: {
          id: "full-text-appraisal-table",
          type: "table",
          options: {
            label: "Check the filtered table of your full-text appraisals.",
            columns: [
              // |PubID|Title|Device|Intended Purpose|Patient Population| Outcomes| Statistical Approach|
              { name: "Pub ID" },
              { name: "Title" },
              { name: "Device" },
              { name: "Intended Purpose" },
              { name: "Patient Population" },
              { name: "Outcomes" },
              { name: "Statistical Approach" },
            ],
            helperText: `All publications that relate to your device or equivalent device need to be appraised for the quality of the publication. This essentially checks to make sure that high quality publications are highlighted and those that lack in good methodology or don't focus specifically on the same clinical condition of your device are weighted less in the analysis. 
  
              If the search found no publications that were related to your device or the equivalent device this table may be empty, which is ok!`,
          },
        },
      },
      {
        id: "clinical-trial-search-table-raw-csv",
        prompt: true,
        required: true,
        videoUrl:
          "https://www.loom.com/share/2e6aeaddb2774442b09dd35dba861929?sid=891c1790-52ca-4fdf-9980-f59e3885e640",
        element: {
          id: "clinical-trial-search-table-raw-csv",
          type: "parsedFileUploadElement",
          options: {
            label: "Upload the raw JSON of your clinical trials search.",
            allowedFileTypes: ".json",
            onUpload: (
              file: File
            ): Promise<{ type: "table" | "json"; result: string }> => {
              return new Promise((resolve, reject) => {
                try {
                  const reader = new FileReader();
                  reader.onload = async (e) => {
                    try {
                      const text = e.target?.result as string;

                      const entries: Record<string, any>[] = JSON.parse(text);

                      const mappedEntries = entries.map((e) => ({
                        id: e.protocolSection.identificationModule.nctId,
                        title:
                          e.protocolSection.identificationModule.officialTitle,
                        collaborators:
                          e.protocolSection.sponsorCollaboratorsModule,
                        description: e.protocolSection.descriptionModule,
                        conditions: e.protocolSection.conditionsModule,
                        armsInterventions:
                          e.protocolSection.armsInterventionsModule,
                        outcomes: e.protocolSection.outcomesModule,
                        eligibility: e.protocolSection.eligibilityModule,
                        locations:
                          e.protocolSection.contactsLocationsModule.locations,
                        results: e.resultsSection,
                        hasResults: e.hasResults,
                      }));

                      resolve({
                        type: "json",
                        result: JSON.stringify(mappedEntries),
                      });
                    } catch (e) {
                      reject(new Error("Error parsing JSON"));
                    }
                  };

                  reader.onerror = () => {
                    // If there's an error reading the file, reject the promise
                    reject(new Error("Failed to read file"));
                  };

                  reader.readAsText(file);
                } catch (e) {
                  reject(e);
                }
              });
            },
            helperText: `Watch the instructional video prior to performing this step! We will be searching for any relevant clinical trials for our device or similar devices. When you retrieve the JSON file according to the instructional video, upload it via the upload file button. Once the file is uploaded it should confirm with you the number of publications you retrieved.
  
              Here is a link to the clinical trials website: https://clinicaltrials.gov/`,
          },
        },
      },
      {
        id: "applied-clinical-trials-search-string",
        prompt: false,
        required: true,
        element: {
          id: "applied-clinical-trials-search-string",
          type: "textField",
          options: {
            label: "Paste the search string that you used in Clinical Trials.",
            rows: 5,
            helperText:
              "This can be the unaltered string that we suggested in the previous step or a modified version of it.",
          },
        },
      },
      {
        id: "date-of-clinical-trials-search",
        prompt: false,
        required: true,
        element: {
          id: "date-of-clinical-trials-search",
          type: "textField",
          options: {
            label:
              "What was the date you performed the clinical trials search?",
            helperText:
              "This is the date that you searched the clinical trials database, so likely it is today's date!",
          },
        },
      },
      {
        id: "person-performing-clinical-trials-search",
        prompt: false,
        required: true,
        element: {
          id: "person-performing-clinical-trials-search",
          type: "textField",
          options: {
            label: "Who performed your clinical trials search?",
          },
        },
      },
      {
        id: "clinical-trial-search-table",
        prompt: true,
        required: true,
        context: [
          "clinical-trial-search-table-raw-csv",
          "equivalent-device-selection",
          "intended-use",
        ],
        prePromptTransformerConfig: {
          inputs: ["clinical-trial-search-table-raw-csv"],
          transformer: (inputs: Record<DocumentDataKey, string>) => {
            const clinicalTrials = JSON.parse(
              inputs["clinical-trial-search-table-raw-csv"]
            );

            const filteredTable: Record<string, unknown>[] = clinicalTrials.map(
              (trial: any) => ({
                id: trial.id,
                title: trial.title,
                description: trial.description,
                outcomes: trial.outcomes,
                conditions: trial.conditions,
              })
            );

            return {
              "clinical-trial-search-table-raw-csv":
                JSON.stringify(filteredTable),
            };
          },
        },
        element: {
          id: "clinical-trial-search-table",
          type: "table",
          options: {
            label: "Check the filtered table of your clinical trial results.",
            columns: [
              { name: "Trial ID", options: { flex: 0 } },
              { name: "Title" },
              { name: "Intervention", options: { flex: 0 } },
              { name: "Status", options: { flex: 0 } },
              { name: "Results", options: { flex: 0 } },
              { name: "Relevant", options: { flex: 0 } },
            ],
            helperText: `The table has filtered the clincial trials you uploaded and included only those that are related to your device or similar devices. It should have excluded all irrelevant therapeutics or trials on different conditions. If you see any clinical trials that are clearly filtered incorrectly, fix them now.`,
          },
        },
      },
      {
        id: "clinical-trials-search-results",
        prompt: false,
        required: true,
        context: ["clinical-trial-search-table"],
        element: {
          id: "clinical-trials-search-results",
          type: "table",
          options: {
            label: "Provide the summary results of the clinical trials search.",
            columns: [{ name: "Search Criteria" }, { name: "Results" }],
            helperText: `This should simply count the number of total clinical trials and those that were included for analysis.`,
          },
          transformerConfig: {
            type: "default",
            inputs: ["clinical-trial-search-table"],

            transformer: (inputs: Record<DocumentDataKey, string>) => {
              // get the table columns from the config
              const keys = getTableColumns(
                TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT,
                "clinical-trial-search-table"
              );

              const clinicalTrialsTableArray = parseMarkdownTable(
                inputs["clinical-trial-search-table"]
              ).filter((r) => keys.every((k) => r.hasOwnProperty(k)));

              const totalNumberOfResults = clinicalTrialsTableArray.length;
              const numberOfRelevantResults = clinicalTrialsTableArray.filter(
                (r) => r["Relevant"].toLowerCase().trim() === "relevant"
              ).length;

              return `
                  | Search Criteria                       | Results |
                  | ------------------------------------- | ------- |
                  | Total Number of Results               | ${totalNumberOfResults}    |
                  | Number of Trials with Results Relevant to Device | ${numberOfRelevantResults}    |
                 `;
            },
          },
        },
      },
      {
        id: "clinical-trials-individual-summary",
        prompt: true,
        required: true,
        context: [
          "clinical-trial-search-table-raw-csv",
          "clinical-trial-search-table",
        ],
        prePromptTransformerConfig: {
          inputs: [
            "clinical-trial-search-table-raw-csv",
            "clinical-trial-search-table",
          ],
          transformer: (
            inputs: Record<DocumentDataKey, string>
          ): Partial<Record<DocumentDataKey, string>> => {
            const clinicalTrials = JSON.parse(
              inputs["clinical-trial-search-table-raw-csv"]
            );
            const clinicalTrialsSearchTable = parseMarkdownTable(
              inputs["clinical-trial-search-table"]
            );

            if (
              !clinicalTrials.every((trial: Record<string, unknown>) =>
                clinicalTrialsSearchTable.find(
                  (row) => row["Trial ID"] === trial.id
                )
              )
            ) {
              throw new Error(
                "The clinical trials identified in your the table do not match the clinical trials JSON file that you uploaded in the previous step. Please return to the file upload screen and upload the correct JSON file and then reload the table on the next screen to correct this issue. If the issue persists please contact us."
              );
            }

            const filteredTable: Record<string, unknown>[] =
              clinicalTrials.filter((trial: Record<string, unknown>) =>
                clinicalTrialsSearchTable.some(
                  (row) =>
                    row["Trial ID"] === trial.id &&
                    row["Relevant"].toLowerCase().trim() === "relevant"
                )
              );

            return {
              "clinical-trial-search-table-raw-csv":
                JSON.stringify(filteredTable),
            };
          },
        },
        element: {
          id: "clinical-trials-individual-summary",
          type: "textField",
          options: {
            label:
              "Summarize the clinical trial results for each study that was included for analysis.",
            helperText: `Here we are summarizing every clinical trial that was included for analysis. Depending on the number of studies that were relevant, this may be a large or small section. If no clinical trials were found to be relevant, that is ok!`,
            rows: 5,
          },
        },
      },
      {
        id: "clinical-trials-summary",
        prompt: true,
        required: true,
        context: ["clinical-trials-individual-summary", "intended-use"],
        element: {
          id: "clinical-trials-summary",
          type: "textField",
          options: {
            label:
              "Provide an overview of the results of performance and safety data from all clinical trials included for evaluation.",
            rows: 5,
            helperText: `This is our final summary of clinical trials that were included for analysis. Here, if there is data from similar devices, we want to relate them to our device to demonstrate the our device would perform similarly and would similarly be safe during clinical use. If there were no clinical trials included for analysis then just say "There were no clinical trials included for analysis."`,
          },
        },
      },
      {
        id: "vigilance-search-table",
        prompt: false,
        required: true,
        videoUrl:
          "https://www.loom.com/share/3b6fc3318ab14394b07da18188833fee?sid=abff474c-9627-4d6b-8ab8-349746f7c310",
        element: {
          id: "vigilance-search-table",
          type: "table",
          options: {
            label:
              "Watch the instructional video to create your table of vigilance events for your device and similar devices.",
            columns: [
              { name: "Database" },
              { name: "Report ID" },
              { name: "Event Title" },
              { name: "Manufacturer" },
              { name: "Device" },
              { name: "Event Summary" },
            ],
            default: `
              | Database | Report ID | Event Title | Manufacturer | Device | Event Summary |
              | -------- | --------- | ----------- | ------------ | ------ | ------------- |
              |          |           |             |              |        |               |
              |          |           |             |              |        |               |
              `,
            helperText: `Vigilance essentially means safety issues here. Watch the instructional video on how to create your vigilance table. We are going to search for similar devices to ours and see if there are any safety events related on those products. We will use those safety events to inform ourselves on the safety of our device. You will need to fill the table manually. Put the report ID that is provided by the respective database in the "Report ID" column. 
  
              Here are the links for the different vigilance databases:
              - FDA MAUDE: https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfmaude/search.cfm
              - FDA Recall: https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfres/res.cfm
              - BfArM: https://www.bfarm.de/SiteGlobals/Forms/Suche/EN/Expertensuche_Formular.html?nn=708434&cl2Categories_Format=kundeninfo
              - Swissmedic: https://fsca.swissmedic.ch/mep/#/
              
              Below are an example of the search keywords you can use for your vigilance search:
              {{ vigilance-search-keywords }}
              <br/><br/>
              `,
            addRowEnabled: true,
          },
        },
      },
      {
        id: "date-of-vigilance-search",
        prompt: false,
        required: true,
        element: {
          id: "date-of-vigilance-search",
          type: "textField",
          options: {
            label:
              "What is the date that you performed the vigilance database search?",
            helperText:
              "This is the date that you searched the vigilance database, so likely it is today's date!",
          },
        },
      },
      {
        id: "person-performing-vigilance-search",
        prompt: false,
        required: true,
        element: {
          id: "person-performing-vigilance-search",
          type: "textField",
          options: {
            label: "Who performed your vigilance database search?",
          },
        },
      },
      {
        id: "vigilance-search-results",
        prompt: false,
        required: true,
        context: ["vigilance-search-table"],
        element: {
          id: "vigilance-search-results",
          type: "table",
          options: {
            label:
              "Provide the numbers of vigilance events that resulted from searching each vigilance database.",
            columns: [
              { name: "Search Term" },
              { name: "FDA MAUDE" },
              { name: "FDA Recall" },
              { name: "BfArM" },
              { name: "Swissmedic" },
            ],
            helperText: `This is the total number of vigilance events that you captured from your search from each database.`,
            addRowEnabled: true,
          },
        },
      },
      {
        id: "vigilance-summary",
        prompt: true,
        required: true,
        context: ["vigilance-search-table", "equivalent-device-selection"],
        element: {
          id: "vigilance-summary",
          type: "textField",
          options: {
            label:
              "Summarize the adverse events identified from the vigilance database search if any were identified.",
            rows: 5,
            helperText: `Provide a summary of the adverse events you identified during your search of the vigilance databases. Make sure to highlight the adverse events that were relevant to your device and any serious adverse events that happened to users of your device or similar devices. Our goal here is to say that our device has similar risks (or less risk) than other similar devices like it on the market and that its risk profile is low.`,
          },
        },
      },
      {
        id: "clinical-benefits-summary",
        prompt: true,
        required: true,
        context: [
          "clinical-benefits",
          "literature-summary-device-performance",
          "literature-summary-state-of-the-art-performance",
          "literature-summary-device-safety",
          "literature-summary-state-of-the-art-safety",
          "clinical-trials-summary",
          "vigilance-summary",
        ],
        element: {
          id: "clinical-benefits-summary",
          type: "textField",
          options: {
            label:
              "Summarize how the clinical data analyzed supports your clinical benefits.",
            rows: 5,
            helperText: `Here you will need to provide justification of support for the clinical benefits you provided in the clinical evaluation plae. We will support our clinical benefits with the data we collected during the clinical evaluation. Your clinical benefits are often just your intended use and any other known benefits you want to bestow upon the user. The data to support your clinical benefit needs to be from testing you have performed or data analyzed in this clinical evaluation on your device or similar devices. We can use the performance of other devices to justify why our device may reasonably be able to achieve the same clinical outcomes.`,
          },
        },
      },
      {
        id: "clinical-claims-summary",
        prompt: true,
        required: true,
        context: [
          "clinical-claims",
          "literature-summary-device-performance",
          "literature-summary-state-of-the-art-performance",
          "literature-summary-device-safety",
          "literature-summary-state-of-the-art-safety",
          "clinical-trials-summary",
          "vigilance-summary",
        ],
        element: {
          id: "clinical-claims-summary",
          type: "textField",
          options: {
            label:
              "Provide evidence for how your clinical claims are supported by your data and data analyzed in this report.",
            rows: 5,
            helperText: `In a similar manner to our clinical benefits, any clinical marketing claims also need to be supported. Provide any supporting data that has been collected in this clinical evaluation. You can also include data from outside of the clinical evaluation, just reference it here. For example, do you have marketing claims on performance from a survey? State that additional information to support the claim comes from a survey you performed and that data is held internally by your company.`,
          },
        },
      },
      {
        id: "benefit-risk-ratio",
        prompt: true,
        required: true,
        context: [
          "literature-summary",
          "clinical-trials-summary",
          "risk-hazards",
          "clinical-benefits-summary",
          "vigilance-summary",
        ],
        element: {
          id: "benefit-risk-ratio",
          type: "textField",
          options: {
            label:
              "Describe how your benefit risk ratio is determined to be acceptable.",
            rows: 5,
            helperText: `For this we determined that if we could demonstrate our clinical benefits and our risks were similar to those of the state of the art, then our benefit risk ratio would be acceptable. Make sure that this statement includes those details as well as any specifics from the clinical data or data that you have internally to support it.`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.LIST_CAPA]: {
    id: TEMPLATE_TYPE.LIST_CAPA,
    docName: "List of CAPAs",
    docType: "LST",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    elements: [
      {
        id: "capa-list",
        prompt: true,
        required: true,
        element: {
          id: "capa-list",
          type: "table",
          options: {
            label:
              "Provide the details for your implemented Corrective and Preventive Actions (CAPAs).",
            columns: [
              { name: "CAPA ID" },
              { name: "Source of CAPA" },
              { name: "Problem" },
              { name: "Actions" },
              { name: "Initiation Date" },
              { name: "Verification Date" },
              { name: "Close Date" },
            ],
            addRowEnabled: true,
            default: `
            | CAPA ID | Source of CAPA | Problem | Actions | Initiation Date | Verification Date | Close Date |
            | ------- | -------------- | ------- | ------- | --------------- | ----------------- | ---------- |
            |         |                |         |         |                 |                   |            |`,
            helperText: `Fill in the table below with the information from your CAPA report(s). This list provides a quick overview of the CAPAs you have implemented.`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.FIELD_SAFETY_NOTICE]: {
    id: TEMPLATE_TYPE.FIELD_SAFETY_NOTICE,
    docName: "Field Safety Notice Form",
    docType: "RCD",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    dependencies: ["manufacturer-name", "udi-di", "srn"],
    elements: [
      {
        id: "fsn-number",
        prompt: false,
        required: true,
        element: {
          id: "fsn-number",
          type: "textField",
          options: {
            label: "Create an internal reference number for your FSN.",
            helperText:
              "This will be a way to track your FSN. You can use any reference number you prefer like FSN00001 for example.",
          },
        },
      },
      {
        id: "fsn-attention-of",
        prompt: false,
        required: true,
        element: {
          id: "fsn-attention-of",
          type: "textField",
          options: {
            label: "Who should read this notice?",
            helperText:
              "Identify either by name or role who needs to be aware of the hazard and/or take action in relation to this field safety notice. If this is multiple recipients then include full list. This should be your users, patients, etc.",
          },
        },
      },
      {
        id: "fsn-local-rep",
        prompt: false,
        required: true,
        element: {
          id: "fsn-local-rep",
          type: "textField",
          options: {
            label: "Provide details of your local representative for this FSN.",
            helperText:
              "Provide contact details like name, email, address, phone number, etc. for whoever the local representative should be for the region you are sending this document. This can be a local distributor or local manufacturer or a customer support resource in that particular location.",
          },
        },
      },
      {
        id: "fsn-risk",
        prompt: false,
        required: true,
        element: {
          id: "fsn-risk",
          type: "textField",
          options: {
            label: "What risk or event is being outlined by this fsn?",
            helperText:
              "Provide a very brief, one line description of what the risk is or the event taking place that necessitates this FSN. You will provide more specific information on this in the next sections.",
          },
        },
      },
      {
        id: "fsn-description",
        prompt: false,
        required: true,
        element: {
          id: "fsn-description",
          type: "textField",
          transformerConfig: {
            type: "default",
            inputs: ["clinical-device-description"],
            transformer: (inputs: Record<DocumentDataKey, string>) => {
              return inputs["clinical-device-description"] || "";
            },
          },
          options: {
            label:
              "Provide a device description for your device including its clinical purpose.",
            helperText: `This will be a public facing document so provide a description of your device and its clinical purpose that you are ok with being read in public. It should be a brief description of the device(s) in plain language, including its what it's used for clinically or otherwise by patients / users.`,
            rows: 5,
          },
        },
      },
      {
        id: "fsn-associated-devices",
        prompt: false,
        required: true,
        element: {
          id: "fsn-associated-devices",
          type: "textField",
          options: {
            label: "Are any associated devices affected?",
            helperText:
              "Provide any associated devices that may be connected to yours or used as accessories that are affected by this particular FSN.",
          },
        },
      },
      {
        id: "fsn-risk-information",
        prompt: true,
        required: true,
        element: {
          id: "fsn-risk-information",
          type: "table",
          options: {
            label: "Fill in the table with information on the device risk.",
            columns: [{ name: "Risk Information" }, { name: "Description" }],
            default: `
            | Risk Information             | Description |
            | ---------------------------- | ----------- |
            | Description of Product Issue |             |
            | Hazard Resulting from issue  |             |
            | Likelihood of Risk           |             |
            | Risk to Users/Patients       |             |
            | Additional Information       |             |`,
            helperText: `Provide details for the risks and harms that are intended to be outlined in this FSN. Consider the following:

              - When describing the product issue, there may be “none” if the Field Safety Notice (FSN) is to reinforce instructions for use or the like.
              - For hazards resulting from the issue, provide details of the greatest hazard to the patient/end user that the advice/action is intended to mitigate. Make clear whether risk is to user, patient or both. Should also try to indicate the residual risk if the FSN advice/action is taken.
              - For risks to users/patients, provide a description of the risk to each type of user, if different, and the severity of the risk.
              - For additional information, provide any additional information that may be relevant including how you were made aware of the risk, root cause, etc. as needed.`,
          },
        },
      },
      {
        id: "fsn-action-taken",
        prompt: true,
        required: true,
        element: {
          id: "fsn-action-taken",
          type: "table",
          options: {
            label: "Fill in the table with information on actions to be taken.",
            columns: [{ name: "Action Information" }, { name: "Description" }],
            default: `
            | Action Information                  | Description |
            | ----------------------------------- | ----------- |
            | Action to be Taken by User          |             |
            | User Action Timeline                |             |
            | Customer Reply Required             |             |
            | Action Taken by Manufacturer        |             |
            | Manufacturer Action Timeline        |             |
            | Information for Lay Patient or User |             |`,
            helperText: `Provide details about the actions the readers should take regarding this FSN. Consider the following:
              
                - Action to be taken by user: Describe what the user shall do, if anything, to handle any existing devices in their possession or elsewhere. This can include identifying, quarantining, returning, or destroying the device. Doing on-site modifications/inspections, following patient management recommendations, or taking additional notice of instructions for use. If nothing is required by the user, state so.
                - User action timeline: Provide users with a timeline to perform the above actions by.
                - Customer reply required: State here if a customer reply to the FSN is required. Include how and when they should reply to you.
                - Action taken by the manufacturer: Provide an overview of the action being performed by you to correct the issue. This can include product removal, software upgrades, new IFU or labeling changes, on-site device modifications/inspections, other actions, or nothing at all.
                - Manufacturer action timeline: Provide the timeline for you to implement your actions. If no action is required then this can be left blank.
                - Information for lay patient or user: Is this information required to be passed on to a lay person/patient/use? If so, say so and who it should be passed on to. Also, you will want to add more information to be passed on to them that differs from what is in this FSN. This can be an attachment or some short instructions here.`,
          },
        },
      },
      {
        id: "fsn-followup",
        prompt: false,
        required: true,
        element: {
          id: "fsn-followup",
          type: "textField",
          options: {
            label: "Will there be a followup FSN for this issue?",
            helperText:
              "Will there be an anticipated FSN to follow up this one? Provide a brief description of what the anticipated FSN will be related to and the actions it will include, if any. If not, you can leave this blank.",
            rows: 5,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.INCIDENT_ASSESSMENT_FORM]: {
    id: TEMPLATE_TYPE.INCIDENT_ASSESSMENT_FORM,
    docName: "Incident Assessment Form",
    docType: "RCD",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docReferences: [TEMPLATE_TYPE.SOP_VIGILANCE],
    elements: [
      {
        id: "incident-qualification",
        prompt: true,
        required: true,
        element: {
          id: "incident-qualification",
          type: "table",
          options: {
            label: "Determine if the incident is reportable.",
            default: `
        | Criteria                                                                                                                                                                                                                                                                                                                                                          | Response                                                                                                                       |
        | ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- | ------------------------------------------------------------------------------------------------------------------------------ |
        | Does the event meet the definition of an incident according to Art. 2(64) MDR? See Section 2 for definition.                                                                                                                                                                                                                                                      | Yes/No                                                                                                                |
        | Does the incident directly or indirectly led, might have led or might lead to any of the outcomes of a serious incident? This can include death of a patient, user or other person, the temporary or permanent serious deterioration of a patient's, user's or other person's, state of health, or a serious public health threat. | Death of a patient / Deterioration of state of health / Serious public health threat / None |
        | Has a causal relationship between the serious incident and the manufacturer's device been established, or is reasonably possible or suspected?                                                                                                                                                                                                                    | Yes/No                                                                                                                |
        | Provide an explanation for your responses to the above questions.                                                                                                                                                                                                                                                                                                 |                                                                                                                                |
        | Is this considered a reportable incident? (If all above questions were answered "Yes", answer "Yes" here. Otherwise, the answer is "No".)                                                                                                                                                                                                                         | Yes/No                                                                                                                |
        `,
            columns: [{ name: "Criteria" }, { name: "Response" }],
            helperText: `Complete the table below to determine if the incident you are investigating is reportable. 

        Not all events are required to be reported. If it is determined that the event is a non-reportable event, the form is completed and can be saved for record if desired. If it is determined that the event is reportable, then continue completing this form and save it into your QMS.`,
          },
        },
      },
      {
        id: "incident-assessment",
        prompt: true,
        required: true,
        element: {
          id: "incident-assessment",
          type: "table",
          options: {
            label: "Provide details about the incident.",
            default: `
        | Category                                      | Information |
        | --------------------------------------------- | ----------- |
        | Event Description                             |             |
        | Source of Event                               |             |
        | Location of Event                             |             |
        | Date and Time Event Reported                  |             |
        | Product and Version Affected                  |             |
        | Planned Actions (FSCA or other actions)       |             |
        | Relevant Competent Authorities to be Notified |             |
        `,
            columns: [{ name: "Category" }, { name: "Information" }],
            helperText: `Provide all of the details for the incident in the table. If there are field safety corrective actions or field safety notices planned, provide the details in the table as well. If it is a vigilance reportable event, provide the competent authorities in the region for which the incident occurred so that the report can be sent to the correct authority.`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOP_CAPA]: {
    id: TEMPLATE_TYPE.SOP_CAPA,
    docName: "SOP Corrective and Preventative Action",
    docType: "SOP",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,

    elements: [],
  },
  [TEMPLATE_TYPE.SOP_VIGILANCE]: {
    id: TEMPLATE_TYPE.SOP_VIGILANCE,
    docName: "SOP Vigilance",
    docType: "SOP",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    elements: [],
  },
  [TEMPLATE_TYPE.SOP_FEEDBACK_MANAGEMENT]: {
    id: TEMPLATE_TYPE.SOP_FEEDBACK_MANAGEMENT,
    docName: "SOP Feedback Management",
    docType: "SOP",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    elements: [
      {
        id: "customer-support",
        required: true,
        element: {
          id: "customer-support",
          type: "textField",
          options: {
            label: "Please describe your customer support system.",
            rows: 5,
            helperText:
              "Give a description of your customer support system. You will need to have a way for patients and/or users to contact you if they have any issues with the product. For example, do you have a customer feedback email and phone number? Do you outsource your customer feedback issues? Customer complaints that reach you will need to be logged in your system somewhere. Those complaints that are serious will need to go through more formal resolution processes.",
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.CAPA_REPORT]: {
    id: TEMPLATE_TYPE.CAPA_REPORT,
    docName: "CAPA Report",
    docType: "RCD",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docReferences: [TEMPLATE_TYPE.SOP_CAPA, TEMPLATE_TYPE.LIST_CAPA],
    elements: [
      {
        id: "capa-plan-id",
        prompt: false,
        required: true,
        element: {
          id: "capa-plan-id",
          type: "textField",
          options: {
            label: "What is the CAPA Plan ID?",
            helperText: `Provide the CAPA Plan ID that this report is associated with. This is the unique identifier for the CAPA plan that was created.`,
          },
        },
      },
      {
        id: "capa-plan-title",
        prompt: false,
        required: true,
        element: {
          id: "capa-plan-title",
          type: "table",
          options: {
            label: "What is the title of the CAPA Plan?",
            helperText: `Copy the requested information over from the CAPA Plan that you referenced by the CAPA Plan ID.`,
            columns: [{ name: "Criteria" }, { name: "Information" }],
            default: `
            | Criteria    | Information |
            | ----------- | ----------- |
            | CAPA Title  |             |
            | CAPA Number |             |`,
          },
        },
      },
      {
        id: "capa-plan-information",
        prompt: false,
        required: true,
        element: {
          id: "capa-plan-information",
          type: "table",
          options: {
            label: "What is the information for the CAPA Plan?",
            helperText: `Copy the requested information over from the CAPA Plan that you referenced by the CAPA Plan ID.`,
            columns: [{ name: "CAPA Information" }, { name: "Description" }],
            default: `
            | CAPA Information                  | Description |
            | --------------------------------- | ----------- |
            | Source of CAPA                    |             |
            | Product and Version of Device     |             |
            | Description of Problem            |             |
            | Description of Investigation      |             |
            | Root Cause                        |             |`,
          },
        },
      },
      {
        id: "capa-plan-actions",
        prompt: false,
        required: true,
        element: {
          id: "capa-plan-actions",
          type: "textField",
          options: {
            label: "What are the planned actions for this CAPA?",
            helperText: `Copy the requested information over from the CAPA Plan that you referenced by the CAPA Plan ID.`,
            rows: 5,
          },
        },
      },
      {
        id: "capa-actions-performed",
        required: true,
        element: {
          id: "capa-actions-performed",
          type: "textField",
          options: {
            label: "What actions were performed as part of this CAPA?",
            rows: 5,
            helperText: `Provide a detailed summary of what actions were performed as part of this CAPA. If any actions initially laid out in the CAPA plan were not performed, state so along with a justification as to why. If additional actions were taken, provide a justification and describe what additional actions were performed.`,
          },
        },
      },
      {
        id: "capa-verification",
        required: true,
        element: {
          id: "capa-verification",
          type: "textField",
          options: {
            label: "How was the CAPA verified for effectiveness?",
            rows: 5,
            helperText: `How did you verify that the actions performed as part of the CAPA were implemented correctly. Provide a narrative as to why these actions were sufficient to resolve the CAPA.`,
          },
        },
      },
      {
        id: "capa-status",
        required: true,
        element: {
          id: "capa-status",
          type: "select",
          options: {
            label: "Is this CAPA now closed?",
            helperText: `Is this CAPA closed or is it still open?`,
            options: ["Open", "Closed"],
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.CAPA_PLAN]: {
    id: TEMPLATE_TYPE.CAPA_PLAN,
    docName: "CAPA Plan",
    docType: "RCD",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docReferences: [TEMPLATE_TYPE.SOP_CAPA, TEMPLATE_TYPE.LIST_CAPA],
    elements: [
      {
        id: "capa-title",
        required: true,
        element: {
          id: "capa-title",
          type: "table",
          options: {
            label: "Provide the basic title and number for you CAPA.",
            helperText: `You can name the CAPA whatever you would like and the numbering format should be unique to each CAPA. You can use CAPA0001 as an idea for your first CAPA numbering.`,
            columns: [{ name: "Criteria" }, { name: "Information" }],
            default: `
            | Criteria    | Information |
            | ----------- | ----------- |
            | CAPA Title  |             |
            | CAPA Number |             |`,
          },
        },
      },
      {
        id: "capa-information",
        required: true,
        element: {
          id: "capa-information",
          type: "table",
          options: {
            label: "Provide information regarding how the CAPA came about.",
            helperText: `Give a description of the problem and investigation that resulted in the CAPA. Provide some additional information on the root cause that was identified for this CAPA.

As part of the CAPA Report, a root cause analysis should be performed on the initiating event according to the Five Whys method or a similar root cause analysis method. 

For the Five Whys method, fill in the problem statement below and ask the "Why" question to the problem statement for "Why #1". Provide the answer to the question "Why did this problem occur" in the box next to "Why #1". Each consecutive "Why" asks the same question, "why did this happen?" to the previous "Why" answer above it, until it is determined that the root cause has been identified for the original problem statement. This can take less than 5 "Whys" or more than 5 "Whys". 

For the Five Whys method, the following table can be used to perform the Five Whys analysis but is not required. The final root cause shall be added to the CAPA Report.

| Criteria          | Answer                                                                                                             |
| ----------------- | ------------------------------------------------------------------------------------------------------------------ |
| Problem Statement | *Provide a simple description of the event, problem, or issue*                                                     |
| Why #1            | *Team / individual performing analysis asks the question "why did this issue happen" and provides the answer here* |
| Why #2            | *Why #2 answer*                                                                                                    |
| Why #3            | Why #3 answer                                                                                                      |
| Why #4            | Why #4 answer                                                                                                      |
| Why #5            | Why #5 answer                                                                                                      |
| Root Cause        | *Provide a description of the root cause(s) here*                                                                  |`,
            columns: [{ name: "CAPA Information" }, { name: "Description" }],
            default: `
            | CAPA Information                  | Description |
            | --------------------------------- | ----------- |
            | Source of CAPA                    |             |
            | Product and Version of Device     |             |
            | Description of Problem            |             |
            | Description of Investigation      |             |
            | Root Cause                        |             |`,
          },
        },
      },
      {
        id: "capa-scoring",
        required: true,
        element: {
          id: "capa-scoring",
          type: "table",
          options: {
            label: "Score the CAPA based on the criteria in the table.",
            helperText: `The quality team should perform CAPA qualification. Using the metric below, a member of the quality team can score the event to determine if a CAPA should be initiated. Go through each row sequentially. For each row that presents a numerical score, summarize the appropriate score from each row. For each row with an agree/disagree question, answer each question to be best of your knowledge. Follow the CAPA Initiation Criteria instructions below the table to determine if a CAPA should be initiated.

CAPA Initiating Criteria

- **Initiate CAPA if** the summed score calculated above is >3 and all answers to the agree/disagree questions are "Disagree".

- **Do NOT initiate CAPA if** any answer to the agree/disagree questions are "Agree" regardless of the score or if the summed score calculated above is under 3.`,
            columns: [
              { name: "Category" },
              { name: "Scoring" },
              { name: "Score" },
            ],
            default: `
| Category            | Scoring                                                                                                                                                               | Score |
| ------------------- | --------------------------------------------------------------------------------------------------------------------------------------------------------------------- | ----- |
| Input Type          | Audit Findings (+3), Non-conforming product/process (+3), Customer complaints (+2), Post-market Surveillance (+2), Management review findings (+2), other inputs (+1) |       |
| Systemic            | Systemic issue (+2), Non-systemic issue (+0)                                                                                                                          |       |
| Trend Analysis      | Result of a trend analysis (+2), Not result of a trend analysis (+0)                                                                                                  |       |
| Significant Impact  | Significant impact on performance or safety (+2), Insignificant impact on performance or safety (+0)                                                                  |       |
| Patient / User Harm | Event resulted in patient / user harm (+2), No harm (+0)                                                                                                              |       |
| Not Easily fixed    | The issue can be easily fixable without a CAPA. (Agree/Disagree)                                                                                                      |       |
| Existing CAPA       | There are CAPAs currently covering this topic. (Agree/Disagree)                                                                                                       |       |
| CAPA Total Score    |                                                                                                                                                                       |       |`,
          },
        },
      },
      {
        id: "capa-actions",
        required: true,
        element: {
          id: "capa-actions",
          type: "textField",
          options: {
            label: "What are the planned actions for this CAPA?",
            helperText: `Provide an detailed summary of what will be performed as part of this CAPA. Include descriptions of the activities that will be performed and the teams that will be involved. As part of your CAPA you will need to verify that it was effective. Make sure that you have some sort of verification activity included as part of your plan. This can be completing a checklist at the end, re-running some tests, checking with stakeholders, etc.`,
            rows: 5,
          },
        },
      },
      {
        id: "capa-impact-performance-safety",
        required: true,
        element: {
          id: "capa-impact-performance-safety",
          type: "textField",
          options: {
            label: "Does this CAPA impact your device's performance or safety?",
            helperText: `Provide an overview of the impact of the CAPA on performance and safety. As a result of this CAPA, is there a significant impact on how your device performs in the market or has the risk profile changed significantly? If not, then state so.`,
            rows: 5,
          },
        },
      },
      {
        id: "capa-impact-regulatory",
        required: true,
        element: {
          id: "capa-impact-regulatory",
          type: "textField",
          options: {
            label:
              "Does this CAPA impact your device's regulatory requirements?",
            helperText: `Does this CAPA require a change in your regulatory requirements? For example, is your product required to become Class IIA because
of this CAPA? Alternatively, does it change your software safety classification? If your regulatory requirements aren't changing because of the CAPA, state so.`,
            rows: 5,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOP_SOFTWARE_PROBLEM_RESOLUTION]: {
    id: TEMPLATE_TYPE.SOP_SOFTWARE_PROBLEM_RESOLUTION,
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    docType: "SOP",
    docName: "SOP Software Problem Resolution",
    docReferences: [TEMPLATE_TYPE.SOP_INTEGRATED_SOFTWARE_DEVELOPMENT],
    elements: [
      {
        id: "ticketing-system",
        required: true,
        element: {
          id: "ticketing-system",
          type: "textField",
          options: {
            label:
              "What system do you use for tracking tickets for problems, issues, or complaints for your software?",
            rows: 1,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.CHECKLIST_SOFTWARE_RELEASE]: {
    id: TEMPLATE_TYPE.CHECKLIST_SOFTWARE_RELEASE,
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    docName: "Software Release Checklist",
    docType: "RCD",
    dependencies: ["software-version"],
    docReferences: [
      TEMPLATE_TYPE.SOP_PRODUCT_REGISTRATION_AND_CERTIFICATION,
      TEMPLATE_TYPE.SOFTWARE_DEVELOPMENT_AND_MAINTENANCE_PLAN,
      TEMPLATE_TYPE.SOP_INTEGRATED_SOFTWARE_DEVELOPMENT,
      TEMPLATE_TYPE.SUBSYSTEM_REQUIREMENTS_LIST,
      TEMPLATE_TYPE.INTENDED_USE,
      TEMPLATE_TYPE.RISK_MANAGEMENT_PLAN,
      TEMPLATE_TYPE.RISK_ASSESSMENT,
      TEMPLATE_TYPE.RISK_MANAGEMENT_REPORT,
      TEMPLATE_TYPE.SOFTWARE_REQUIREMENTS_LIST,
      TEMPLATE_TYPE.SOFTWARE_ARCHITECTURE,
      TEMPLATE_TYPE.SOFTWARE_TEST_PLAN,
      TEMPLATE_TYPE.SOFTWARE_SYSTEM_TEST_REPORT,
      TEMPLATE_TYPE.USABILITY_EVALUATION_PLAN,
      TEMPLATE_TYPE.USABILITY_EVALUATION_PROTOCOL,
      TEMPLATE_TYPE.USABILITY_EVALUATION_REPORT,
      TEMPLATE_TYPE.CLINICAL_EVALUATION_PLAN,
      TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT,
      TEMPLATE_TYPE.INSTRUCTIONS_FOR_USE,
      TEMPLATE_TYPE.RELEASE_NOTES,
      TEMPLATE_TYPE.DEVICE_LABEL,
      TEMPLATE_TYPE.DECLARATION_OF_CONFORMITY,
      TEMPLATE_TYPE.TECHNICAL_FILE,
      TEMPLATE_TYPE.CHANGE_REQUEST,
      TEMPLATE_TYPE.CHANGE_EVALUATION_REPORT,
    ],
    elements: [
      {
        id: "release-processes",
        required: true,
        prompt: false,
        element: {
          id: "release-processes",
          type: "table",
          options: {
            label:
              "Check that you have completed all of the processes required for this software version release.",
            default: `
            | Item                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                | Yes / No | Comment |
            | --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- | -------- | ------- |
            | The Software Requirement List and Software Requirements List Checklist have been completed to ensure design specification has occurred.                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             |          |         |
            | All relevant risks (including risks of known anomalies) have been evaluated; the Risk Management Report is complete.                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                |          |         |
            | Software verification in the form of code review and Software System Test Plan and Software System Test Reports have been completed if needed.                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      |          |         |
            | Design control traceability has been implemented: Stakeholder requirements (User Needs List) has been traced to software requirements. Software requirements can be traced to risks and risk control measures in the Risk assessment. Software requirements have be traced to system tests and to software code reviews. Stakeholder requirements (User Needs List) can be traced to Usability Evaluation Protocol and Report.<br>Hazard-related use scenarios can be traced to the Usability Evaluation Protocol and Report. Hazard-related use scenarios can be traced to risks and risk control measures in the Risk assessment. |          |         |
            | A version number as defined in the Software Development and Maintenance Plan has been assigned and added as a tag to git.                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           |          |         |
            | Software is registered with the competent authority and with a Notified Body if Class Im/Ir/Is, Class IIa, Class IIb and Class III.                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 |          |         |
            | If release includes a significant change: Notified Body has been informed.                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          |          |         |
            | Label is applied correctly including CE mark                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        |          |         |`,
            columns: [
              { name: "Item" },
              {
                name: "Yes / No",
                options: {
                  flex: 0,
                },
              },
              { name: "Comment" },
            ],
            helperText: `Provide a "Yes" or "No" if the processes were completed for this version of the software. If you mark "No" make sure to provide a comment why they were not completed. A reason why something was not completed may be because for this change was not a significant change so a notified body was not required to be notified. `,
          },
        },
      },
      {
        id: "release-documents",
        required: true,
        prompt: false,
        element: {
          id: "release-documents",
          type: "table",
          options: {
            label:
              "Identify all documentation that you updated for this software release.",
            helperText: `Put a "Yes" or "No" in the "Updated" column where you either updated your document or didn't update a document, respectively. If you didn't update a document and put "no", provide a reason for why you didn't update it. A reason for this could be that your update didn't change the intended use or didn't affect your existing Clinical Evaluation so it didn't need to be updated.`,
            columns: [
              {
                name: "Document Name",
                options: {
                  flex: 0,
                },
              },
              {
                name: "Document Number",
                options: {
                  flex: 0,
                },
              },
              {
                name: "Updated",
                options: {
                  flex: 0,
                },
              },
              { name: "Justification for no update" },
            ],
          },
          transformerConfig: {
            type: "default",
            inputs: ["management-team"],
            transformer: (
              _: Record<DocumentDataKey, string>,
              docReferences: Record<string, string>
            ) => {
              const table = `
                | Document Name                 | Document Number                         | Updated | Justification for no update |
                | ----------------------------- | --------------------------------------- | ------- | --------------------------- |
                | User Needs List               | {{ did-user-needs-list }}               |         |                             |
                | Intended Use                  | {{ did-intended-use }}                  |         |                             |
                | Risk Management Plan          | {{ did-risk-management-plan }}          |         |                             |
                | Risk Assessment               | {{ did-risk-assessment }}               |         |                             |
                | Risk Management Report        | {{ did-risk-management-report }}        |         |                             |
                | Software Requirements List    | {{ did-subsystem-requirements-list }}    |         |                             |
                | Software Architecture         | {{ did-software-architecture }}         |         |                             |
                | Software System Test Plan     | {{ did-software-system-test-plan }}     |         |                             |
                | Software System Test Report   | {{ did-software-system-test-report}}    |         |                             |
                | Usability Evaluation Plan     | {{ did-usability-evaluation-plan }}     |         |                             |
                | Usability Evaluation Protocol | {{ did-usability-evaluation-protocol }} |         |                             |
                | Usability Evaluation Report   | {{ did-usability-evaluation-report }}   |         |                             |
                | Clinical Evaluation Plan      | {{ did-clinical-evaluation-plan }}      |         |                             |
                | Clinical Evaluation Report    | {{ did-clinical-evaluation-report }}    |         |                             |
                | Instructions for Use          | {{ did-instructions-for-use }}          |         |                             |
                | Release Notes                 | {{ did-release-notes }}                 |         |                             |
                | Device Label                  | {{ did-device-label }}                  |         |                             |
                | Declaration of Conformity     | {{ did-eu-declaration-of-conformity }}     |         |                             |
                | Technical File                | {{ did-technical-file }}                |         |                             |
                | Change Request                | {{ did-change-request}}                 |         |                             |
                | Change Evaluation Report      | {{ did-change-evaluation-report }}      |         |                             |`;

              const template = Handlebars.compile(table, { noEscape: true });
              return template(docReferences);
            },
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.LIST_OF_MEDICAL_DEVICES]: {
    id: TEMPLATE_TYPE.LIST_OF_MEDICAL_DEVICES,
    hidden: false,
    functionalGroup: FunctionalGroup.PD,
    docType: "LST",
    docName: "List of Medical Devices",
    dependencies: ["software-version", "udi-di"],
    elements: [
      {
        id: "release-date",
        required: true,
        prompt: false,
        element: {
          id: "release-date",
          type: "textField",
          options: {
            label: "What date did you release this version of the software?",
          },
        },
      },

      {
        id: "market-availability",
        required: true,
        prompt: false,
        element: {
          id: "market-availability",
          type: "textField",
          options: {
            label:
              "List the countries in which your device is available on the market.",
            rows: 5,
            default: `Austria, Belgium, Bulgaria, Croatia, Republic of Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden.`,
          },
        },
      },
      {
        id: "previous-versions",
        required: true,
        prompt: false,
        element: {
          id: "previous-versions",
          type: "table",
          options: {
            label:
              "Provide a list of all the previous versions of your device.",

            columns: [
              { name: "Device Name", options: { flex: 0 } },
              { name: "Device Version", options: { flex: 0 } },
              { name: "Release Date", options: { flex: 0 } },
              { name: "Decommissioning Date", options: { flex: 0 } },
              { name: "Device Identifier", options: { flex: 0 } },
              { name: "Reason for Decommission" },
            ],
            addRowEnabled: true,
            helperText: `For this device, provide the previous versions that you have created along with the decommissioning date for that version. Also, provide the reason for the decommission of this version of the device. Usually, this reason will be that a new version of the device is being released and will replace the old version that is being decommissioned.`,
          },
        },
      },
      {
        id: "similar-marketed-devices",
        required: true,
        prompt: true,
        element: {
          id: "similar-marketed-devices",
          type: "table",
          options: {
            label:
              "Provide a list of devices on the market currently that are similar to yours.",
            columns: [
              { name: "Device Name", options: { flex: 0 } },
              { name: "Manufacturer" },
              { name: "Markets / Counties Available" },
              { name: "Additional Information" },
            ],
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOP_MANAGEMENT_REVIEW]: {
    id: TEMPLATE_TYPE.SOP_MANAGEMENT_REVIEW,
    hidden: false,
    functionalGroup: FunctionalGroup.CP,
    docType: "SOP",
    docName: "SOP Management Review",
    elements: [
      {
        id: "management-team",
        required: true,
        prompt: false,
        element: {
          id: "management-team",
          type: "table",
          options: {
            label: "Who is part of your management team?",
            columns: [{ name: "Position" }, { name: "Name" }],
            addRowEnabled: true,
            helperText: `This is typically senior level members of the organization. This doesn't necessarily need to be the CEO, CTO, etc. of the organization but for most startups it is easiest to make it the C-level. The requirements are that they, irrespective of their other responsibilities,
                have responsibility and authority that includes:<br/>
                a) ensuring that processes needed for the quality management system are documented;<br/>
                b) reporting to top management on the effectiveness of the quality management system and any need for improvement;<br/>
                c) ensuring the promotion of awareness of applicable regulatory requirements and quality
                management system requirements throughout the organization.`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.LIST_OF_REGULATORY_REQUIREMENTS]: {
    id: TEMPLATE_TYPE.LIST_OF_REGULATORY_REQUIREMENTS,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "LST",
    docName: "List of Regulatory Requirements",
    docReferences: [TEMPLATE_TYPE.SOP_UPDATE_OF_REGULATIONS],
    elements: [
      {
        id: "regulations",
        required: true,
        prompt: false,
        element: {
          id: "regulations",
          type: "table",
          options: {
            label: "List the regulations that apply to your product.",
            default: `| Regulation                                | Applicable Devices | Description                                                                                                                       | Jurisdiction | Review Cycle | Last Review     | Links                                                   |
| ----------------------------------------- | ------------------ | --------------------------------------------------------------------------------------------------------------------------------- | ------------ | ------------ | --------------- | ------------------------------------------------------- |
| EU Medical Device Regulations 2017/745    | All                | Regulations required for CE marking of medical devices in the EU.                                                                 | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://eur-lex.europa.eu/eli/reg/2017/745/oj |
| (GDPR) General Data Protection Regulation | All                | Regulates the protection of natural persons with regard to the processing of personal data and on the free movement of such data. | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://eur-lex.europa.eu/eli/reg/2016/679/oj |`,
            columns: [
              { name: "Regulation" },
              { name: "Applicable Devices" },
              { name: "Description" },
              { name: "Jurisdiction" },
              { name: "Review Cycle" },
              { name: "Last Review" },
              { name: "Links" },
            ],
          },
        },
      },
      {
        id: "national-laws",
        required: true,
        prompt: false,
        element: {
          id: "national-laws",
          type: "table",
          options: {
            label: "List the national laws that apply to your product.",
            default: `| Regulation                        | Applicable Devices | Description                                                                                 | Jurisdiction | Review Cycle | Last Review                      | Links                                                  |
| --------------------------------- | ------------------ | ------------------------------------------------------------------------------------------- | ------------ | ------------ | -------------------------------- | ------------------------------------------------------ |
| (MPDG) German Medical Devices Law | All                | Law providing additional medical device requirements to medical products listed in Germany. | Germany      | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.gesetze-im-internet.de/mpdg/ |`,
            columns: [
              { name: "Regulation" },
              { name: "Applicable Devices" },
              { name: "Description" },
              { name: "Jurisdiction" },
              { name: "Review Cycle" },
              { name: "Last Review" },
              { name: "Links" },
            ],
          },
        },
      },
      {
        id: "standards",
        required: true,
        prompt: false,
        element: {
          id: "standards",
          type: "table",
          options: {
            label: "List the standards that apply to your product.",
            default: `| Regulation                          | Applicable Devices | Description                                                       | Jurisdiction | Review Cycle | Last Review     | Links                                          |
| ----------------------------------- | ------------------ | ----------------------------------------------------------------- | ------------ | ------------ | --------------- | ---------------------------------------------- |
| ISO 13485:2016 + AC:2018 + A11:2021 | All                | QM Systems                                                        | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iso.org/standard/59752.html |
| ISO 14971:2019                      | All                | Risk Management System                                            | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iso.org/standard/72704.html |
| IEC 62304:2006                      | All                | Software Lifecycle Processes                                      | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iec.ch/standard/38515.html  |
| ISO 62366-1:2015 + A1:2021          | All                | Usability Engineering                                             | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iso.org/standard/63179.html |
| ISO 14155:2020                      | All                | Clinical Investigations                                           | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iso.org/standard/71690.html |
| ISO 15223-1:2016                    | All                | Symbols to be used with medical device labels                     | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iso.org/standard/59752.html |
| ISO/TR 24971:2020                   | All                | Guidance on the application of ISO 14971                          | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iso.org/standard/72704.html |
| ISO 20417:2021                      | All                | Medical devices -- Information to be provided by the manufacturer | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iso.org/standard/72704.html |`,
            columns: [
              { name: "Regulation" },
              { name: "Applicable Devices" },
              { name: "Description" },
              { name: "Jurisdiction" },
              { name: "Review Cycle" },
              { name: "Last Review" },
              { name: "Links" },
            ],
          },
        },
      },
      {
        id: "guidance-documents",
        required: true,
        prompt: false,
        element: {
          id: "guidance-documents",
          type: "table",
          options: {
            label: "List the guidance documents that apply to your product.",
            default: `| Regulation               | Applicable Devices | Description                                                                                                                                                                                 | Jurisdiction | Review Cycle | Last Review     | Links                                                                                                                             |
| ------------------------ | ------------------ | ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- | ------------ | ------------ | --------------- | --------------------------------------------------------------------------------------------------------------------------------- |
| MDCG 2020-1              | All                | Guidance on clinical evaluation (MDR) / Performance evaluation (IVDR) of medical device software                                                                                            | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2020-5              | All                | Guidance on Clinical Evaluation – Equivalence                                                                                                                                               | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2019-11             | All                | Guidance on Qualification and Classification of Software                                                                                                                                    | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2019-16             | All                | Guidance on cybersecurity for medical devices                                                                                                                                               | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2020-7              | All                | Guidance on PMCF plan template                                                                                                                                                              | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2020-8              | All                | Guidance on significant changes to the intended purpose                                                                                                                                     | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2020-13             | All                | Guidance on clinical evaluation of medical devices                                                                                                                                          | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2022-12             | All                | Guidance on harmonised administrative practices and alternative technical solutions until Eudamed is fully functional (for Regulation (EU) 2017/746 on in vitro diagnostic medical devices) | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2019-7              | All                | Guidance on Article 15 of the Medical Device Regulation (MDR) and in vitro Diagnostic Medical Device Regulation (IVDR) regarding a ""person responsible for regulatory compliance""           | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2022-21             | All                | Guidance on Periodic Safety Update Reports (PSURs) according to Regulation (EU) 2017/745                                                                                                    | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2018-1 rev.4 | All                | Guidance on basic UDI-DI and changes to UDI-DI                                                                                                                                              | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/system/files/2021-04/md_mdcg_2018-1_guidance_udi-di_en_0.pdf                                  |
| MDCG 2019-16 rev.1       | All                | Guidance on cybersecurity for medical devices                                                                                                                                               | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/document/download/b23b362f-8a56-434c-922a-5b3ca4d0a7a1_en?filename=md_cybersecurity_en.pdf    |`,
            columns: [
              { name: "Regulation" },
              { name: "Applicable Devices" },
              { name: "Description" },
              { name: "Jurisdiction" },
              { name: "Review Cycle" },
              { name: "Last Review" },
              { name: "Links" },
            ],
          },
        },
      },
    ],
  },

  [TEMPLATE_TYPE.SOP_INTERNAL_AUDIT]: {
    id: TEMPLATE_TYPE.SOP_INTERNAL_AUDIT,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "SOP",
    docName: "SOP Internal Audit",
    elements: [],
  },
  [TEMPLATE_TYPE.AUDIT_PLAN]: {
    id: TEMPLATE_TYPE.AUDIT_PLAN,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "RCD",
    docName: "Audit Plan",
    elements: [
      {
        id: "audit-information",
        required: true,
        prompt: false,
        element: {
          id: "audit-information",
          type: "table",
          options: {
            label: "Provide the basic information for the audit.",
            default:
              "| Audit Information |  Details   |\n| ----------------- | --- |\n| Audit Date        |     |\n| Audit Time        |     |\n| Audit Location    |     |",
            columns: [{ name: "Audit Information" }, { name: "Details" }],
            addRowEnabled: false,
          },
        },
      },
      {
        id: "auditor-information",
        required: true,
        prompt: false,
        element: {
          id: "auditor-information",
          type: "table",
          options: {
            label:
              "Provide the basic information for the auditors performing the audit.",
            helperText: "You can add more auditors as needed.",
            default:
              "| Auditor Information             |  Details   |\n| ------------------------------- | --- |\n| Lead Auditor Name               |     |\n| Lead Auditor Position & Company |     |\n| Auditor Name                    |     |\n| Auditor Position & Company      |     |",
            columns: [{ name: "Auditor Information" }, { name: "Details" }],
            addRowEnabled: true,
          },
        },
      },
      {
        id: "audit-participants",
        required: true,
        prompt: false,
        element: {
          id: "audit-participants",
          type: "table",
          options: {
            label:
              "Identify who from your company will participate in the audit.",
            helperText: `Provide a list of the participants from your company who are intended to be involved in the audit. Typically this is the subject matter experts for each process. This list should include their names and their positions or roles within the company and only includes those planned to directly involved in interviews or with helping the auditor(s) during the audit.

If the final list of participants differs from this list, that is ok, we will have a final list in the audit report. During an audit, the auditor(s) may request additional process or participants so it is totally normal if your final list of participants differs from this list.`,
            default:
              "| Name     | Position / Role |\n| -------- | --------------- |\n| Person 1 |                 |\n| Person 2 |                 |\n| Person 3 |                 |\n| (...)    | (...)           |",
            columns: [{ name: "Name" }, { name: "Position / Role" }],
            addRowEnabled: true,
          },
        },
      },
      {
        id: "audit-scope",
        required: true,
        prompt: false,
        element: {
          id: "audit-scope",
          type: "table",
          options: {
            label:
              "Mark what standards or regulations will be covered in this audit.",
            default: `
              | Audit Criterion                         | Covered in this Audit |
              | --------------------------------------- | --------------------- |
              | EN ISO 13485:2016                       |                       |
              | ISO 14971:2019                          |                       |
              | (EU) Medical Device Regulation 2017/745 |                       |
              | IEC 62304:2006                         |                       |
              | IEC 62366-1:2015                        |                       |
              | FDA Quality System Regulation (21 CFR 820) |                       |
              | ISO 14155:2020                          |                       |
              | ISO 27001:2013                          |                       |
              | ISO 9001:2015                           |                       |`,
            columns: [
              { name: "Audit Criterion" },
              { name: "Covered in this Audit" },
            ],
            addRowEnabled: true,
          },
        },
      },
      {
        id: "audit-activities",
        required: true,
        prompt: false,
        element: {
          id: "audit-activities",
          type: "table",
          options: {
            label:
              "Define the audit activities that will occur each day during the audit.",
            helperText: `Provide an overview of the audit schedule for the first day. Include the time, topic, operational unit, QMS process, audit criteria, and participants for each activity. The audit plan should be detailed enough to provide a general understanding of the audit activities that will be covered in each time slot. You can adjust each time slot based on how long you think it will take to review each process. For example, documentation management will likely take longer than reviewing the sales SOP. 
- Date: This is the date that the audit activities being described are occurring on.
- Time: This is the time frame the audit activity will occur (e.x. - 8:00 - 9:00 AM CEST)
- Topic / Process / Requirement : can include QMS general information, documentation requirements, product realization, post-market surveillance, breaks, summaries, introductions, lunch, etc.
- Audit criteria: this should list the actual standard or regulation that the audit will be referring to for compliance for each opportunity. For example, for documentation requirements, the audit criteria would be "EN ISO 13485:2016, para. 4.2".
- Participants: Put the names of the company employees or participated in the audit for each activity here.`,
            default: `
              | Date | Time | Topic / Process / Requirement | Audit Criteria | Participants |
              | ---- | ---- | ----------------------------- | -------------- | ------------ |
              |      |      |                               |                |              |
              |      |      |                               |                |              |
              |      |      |                               |                |              |`,
            columns: [
              { name: "Date" },
              { name: "Time" },
              { name: "Topic / Process / Requirement" },
              { name: "Audit Criteria" },
              { name: "Participants" },
            ],
            addRowEnabled: true,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.AUDIT_PROGRAM]: {
    id: TEMPLATE_TYPE.AUDIT_PROGRAM,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "TCD",
    docName: "Audit Program",
    docReferences: [TEMPLATE_TYPE.SOP_INTERNAL_AUDIT],
    elements: [
      {
        id: "audit-program",
        required: true,
        prompt: false,
        element: {
          id: "audit-program",
          type: "table",
          options: {
            label: "Provide a plan for when you do your internal audits.",
            helperText: `Internal audits are part of your quality system requirements. For your audit program, you need to identify when you plan to have someone come in to review your processes for you. In this table, provide some suggested times within the next 3 years when you'll have your quality system and technical documentation audited for completeness and accuracy. Mark what sections of the quality management system or MDR you plan to have audited during each audit and add any additional sections you want reviewed, if any.

For the audit plan ID and date, these correspond to your audit plan document that you created separately. Put your suggested date and you can fill in the audit plan ID once the audit has been performed.`,
            columns: [
              { name: "Audit ID" },
              { name: "Audit #1" },
              { name: "Audit #2" },
              { name: "Audit #3" },
            ],
            default: `
| Audit ID                                                                                           | Audit #1 | Audit #2 | Audit #3 |
| -------------------------------------------------------------------------------------------------- | -------- | -------- | -------- |
| Date                                                                                               |          |          |          |
| Audit Plan ID                                                                                      |          |          |          |
| ISO 13485:2016, para. 4.1, 4.21: General QMS requirements                                          |          |          |          |
| ISO 13485:2016, para. 4.2.2, 5.3, 5.4: Quality manual and QMS planning                             |          |          |          |
| ISO 13485: 2016, para. 4.2.3: Medical device file                                                  |          |          |          |
| ISO 13485:2016, para. 4.2.4, 4.2.5: Control of documents and records                               |          |          |          |
| ISO 13485:2016, para. 5.1, 5.2, 5.3., 5.4, 5.5: Management responsibility                          |          |          |          |
| ISO 13485:2016, para. 5.6: Management review                                                       |          |          |          |
| ISO 13485:2016, para. 6.1, 6.3: Resource management                                                |          |          |          |
| ISO 13485:2016, para. 6.2: Human resources management                                              |          |          |          |
| ISO 13485:2016, para. 6.4: Work environment and contamination control                              | n/a      | n/a      | n/a      |
| ISO 13485:2016, para. 7.1: Planning product realization                                            |          |          |          |
| ISO 13485:2016, para. 7.2: Customer-related processes                                              |          |          |          |
| ISO 13485:2016, para. 7.3: Design and development                                                  |          |          |          |
| ISO 13485:2016, para. 7.4: Purchasing                                                              |          |          |          |
| ISO 13485:2016, para. 7.5: Production and service provision                                        |          |          |          |
| ISO 13485:2016, para. 7.6: Measuring equipment                                                     |          |          |          |
| ISO 13485:2016, para. 8.1, 8.2.1, 8.2.2: Feedback and complaints handling                          |          |          |          |
| ISO 13485:2016, para. 8.1, 8.2.3: Reporting to authorities                                         |          |          |          |
| ISO 13485:2016, para. 8.1, 8.2.4: Internal auditing                                                |          |          |          |
| ISO 13485:2016, para. 8.1, 8.2.5, 8.2.6: Measurement of products and processes                     |          |          |          |
| ISO 13485:2016, para. 8.3: Nonconforming products                                                  |          |          |          |
| ISO 13485:2016, para. 8.4: Analysis of data                                                        |          |          |          |
| ISO 13485:2016, para. 8.5: Improvement                                                             |          |          |          |
| Reg. (EU) 2017/745, Chapter VII, Art. 83-86: Post-Market Surveillance                              |          |          |          |
| Reg. (EU) 2017/745, Chapter VII, Art. 87-90: Vigilance                                             |          |          |          |
| Reg. (EU) 2017/745, Chapter 61 & Annex XIV: Clinical Evaluation and Post-Market Clinical Follow-up |          |          |          |`,
            addRowEnabled: false,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.AUDIT_REPORT]: {
    id: TEMPLATE_TYPE.AUDIT_REPORT,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "RCD",
    docName: "Audit Report",
    elements: [
      {
        id: "audit-report-information",
        required: true,
        prompt: false,
        element: {
          id: "audit-report-information",
          type: "table",
          options: {
            label: "Provide the information about the audit you performed.",
            default: `
              | Audit Information              |     |
              | ------------------------------ | --- |
              | Date(s) of Audit               |     |
              | Lead Auditor Name              |     |
              | Lead Auditor Title and Company |     |
              | Other Auditor(s) Name(s)       |     |
              | Site of Audit (or remote)      |     |`,
            columns: [{ name: "Audit Information" }, { name: "" }],
            addRowEnabled: false,
          },
        },
      },
      {
        id: "audit-report-participants",
        required: true,
        prompt: false,
        element: {
          id: "audit-report-participants",
          type: "table",
          options: {
            label:
              "Describe what company personnel were in the audit and what they were responsible for.",
            helperText: `
              Fill in the table below with the names, titles and responsibilities of the employees of your company participating in the audit. For responsibilities, provide a description of the information and/or processes they are responsible for defending during the audit.
              `,
            default: `
              | Person Participating in Audit | Responsibilities                                    |
              | ----------------------------- | --------------------------------------------------- |
              | <Person One / Title One>      | <Document control, post-market surveillance>        |
              | <Person Two / Title Two>      | <Software development, verification and validation> |
              | <etc.>                        |                                                     |`,
            columns: [
              { name: "Person Participating in Audit" },
              { name: "Responsibilities" },
            ],
            addRowEnabled: true,
          },
        },
      },
      {
        id: "audit-results",
        required: true,
        prompt: false,
        element: {
          id: "audit-results",
          type: "table",
          options: {
            label: "Provide the results of the audit in the table.",
            helperText: `
            Fill in the table with the regulatory requirements that were covered during your audit (The one provided is an example). Put a number in the column of "Major NC", "Minor NC" or "Rec" for any major nonconformities, minor nonconformities, or recommendations that resulted from the audit. Add more rows for additional audit criteria that were covered or delete rows that were not covered.`,
            default: `
              | Regulation or Standard | Section         | Description                      | Major NC | Minor NC | Rec |
              | ---------------------- | --------------- | -------------------------------- | -------- | -------- | --- |
              | ISO 13485:2016         | 4.1             | General QMS requirements         |          |          |     |
              | ISO 13485:2016         | 4.2.2, 5.3, 5.4 | Quality manual and QMS planning  |          |          |     |
              | ISO 13485:2016         | 4.2.3           | Medical device file              |          |          |     |
              | ISO 13485:2016         | 4.2.4, 4.2.5    | Control of documents and records |          |          |     |
              | ISO 13485:2016         | 5.1 - 5.5       | Management responsibility        |          |          |     |
              | ISO 13485:2016         | 5.6             | Management review                |          |          |     |
              | ISO 13485:2016         | 6 1, 6.3        | Resource management              |          |          |     |
              | ISO 13485:2016         | 6.2             | Human resources management       |          |          |     |
              | ISO 13485:2016         | 7.1             | Planning product realization     |          |          |     |
              | ISO 13485:2016         | 7.2             | Customer-related processes       |          |          |     |
              | ISO 13485:2016         | 7.3             | Design and development           |          |          |     |
              | ISO 13485:2016         | 7.4             | Purchasing                       |          |          |     |
              | ISO 13485:2016         | 7.5             | Production and service provision |          |          |     |`,
            columns: [
              { name: "Regulation or Standard" },
              { name: "Section" },
              { name: "Description" },
              { name: "Major NC" },
              { name: "Minor NC" },
              { name: "Rec" },
            ],
          },
        },
      },
      {
        id: "major-nonconformities",
        required: true,
        prompt: false,
        element: {
          id: "major-nonconformities",
          type: "textField",
          options: {
            label:
              "Describe the major nonconformities identified as a result of the audit.",
            helperText: `Here describe the major nonconformities that came from the internal audit. Describe what the process was and related documentation that required changes and what changes were implemented or planned for implementation.

If there were no major nonconformities identified during your audit, state that there were "No major nonconformities identified during the audit."`,
            rows: 5,
          },
        },
      },
      {
        id: "minor-nonconformities",
        required: true,
        prompt: false,
        element: {
          id: "minor-nonconformities",
          type: "textField",
          options: {
            label:
              "Describe the minor nonconformities identified as a result of the audit.",
            helperText: `Here describe the minor nonconformities that came from the internal audit. Describe what the process was and related documentation that required changes and what changes were implemented or planned for implementation.

If there were no minor nonconformities identified during your audit, state that there were "No minor nonconformities identified during the audit."`,
            rows: 5,
          },
        },
      },
      {
        id: "audit-recommendations",
        required: true,
        prompt: false,
        element: {
          id: "audit-recommendations",
          type: "textField",
          options: {
            label:
              "Describe the recommendations identified as a result of the audit.",
            helperText: `Here describe the recommendations that came from the internal audit. Describe what the process was and related documentation that required changes and what changes were implemented or planned for implementation.

If there were no recommendations identified during your audit, state that there were "No recommendations identified during the audit."`,
            rows: 5,
          },
        },
      },
      {
        id: "audit-conclusion",
        required: true,
        prompt: true,
        context: [
          "audit-results",
          "major-nonconformities",
          "minor-nonconformities",
          "audit-recommendations",
        ],
        element: {
          id: "audit-conclusion",
          type: "textField",
          options: {
            label: "Provide an overall conclusion from the audit.",
            helperText: `Provide an overall conclusion from the audit including a summary of the planned actions and any significant organizational or product changes that resulted from the findings. If no actions are required, describe why.`,
            rows: 5,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.MANAGEMENT_REVIEW_REPORT]: {
    id: TEMPLATE_TYPE.MANAGEMENT_REVIEW_REPORT,
    hidden: false,
    functionalGroup: FunctionalGroup.CP,
    docType: "RCD",
    docName: "Management Review Report",
    elements: [
      {
        id: "kpi-assessment",
        required: true,
        prompt: false,
        element: {
          id: "kpi-assessment",
          type: "table",
          options: {
            label:
              "What is the assessment of your QMS and Key Performance Indicators (KPIs) based on their acceptance criteria?",
            default: `
              | Process                                 | Key Performance Indicator                                                                           | Data on KPI                                                                                        | Management Assessment                               | Improvement Measures | Evaluation of Previous Measures |
              | --------------------------------------- | --------------------------------------------------------------------------------------------------- | -------------------------------------------------------------------------------------------------- | --------------------------------------------------- | -------------------- | ------------------------------- |
              | Quality Manual                          | All quality objectives are met                                                                      | 100% of objectives met                                                                             | QMS is effective and meets requirements             | None                 | N/A                             |
              | Document and Record Control             | Archived documents maintained for device lifetime                                                   | 100% of documents retained                                                                         | Document management process is effective            | None                 | N/A                             |
              | Document and Record Control             | No process non-conformities during external audits                                                  | 0 non-conformities                                                                                 | Document management process is effective            | None                 | N/A                             |
              | Human Resources                         | Initial training completion rate for employees                                                      | 95% of employees complete                                                                          | Training process is effective                       | None                 | N/A                             |
              | Integrated Software Development         | Software system testing with limited failures                                                       | Failure under 30% of testing                                                                       | Software development process is effective           | None                 | N/A                             |
              | Integrated Software Development         | Known software anomalies lead to no adverse events                                                  | 0 adverse events due to known software anomalies                                                   | Software development process is effective           | None                 | N/A                             |
              | Software Problem Resolution             | Efficient investigation and resolution of software problems                                         | Greater than 90% of problems resolved successfully                                                 | Software problem resolution process is effective    | None                 | N/A                             |
              | Risk Management                         | Number of unacceptable risks mitigated                                                              | 100% of unacceptable risks mitigated with risk controls                                            | Risk management process is effective                | None                 | N/A                             |
              | Risk Management                         | Risk controls successfully implemented                                                              | 100% of risk controls implemented                                                                  | Risk management process is effective                | None                 | N/A                             |
              | Change Management                       | No non-conformities due to incomplete change documentation                                          | 0 non-conformities                                                                                 | Change management process is effective              | None                 | N/A                             |
              | Feedback and Customer Management        | Customer feedback response rate                                                                     | Over 80% of feedback processed                                                                     | Customer feedback process is effective              | None                 | N/A                             |
              | Vigilance                               | Incidents reported within legal reporting deadlines                                                 | 100% of incidents reported                                                                         | Vigilance process is effective                      | None                 | N/A                             |
              | Corrective and Preventative Action      | CAPA closure rate                                                                                   | Over 90% of CAPAs closed                                                                           | CAPA process is effective                           | None                 | N/A                             |
              | Deployment                              | No deployment without prior system testing                                                          | 100% of deployments tested                                                                         | Deployment process is effective                     | None                 | N/A                             |
              | Software Validation                     | Software validation completed for all necessary software                                            | 100% of software validated                                                                         | Software validation process is effective            | None                 | N/A                             |
              | Post-Market Surveillance                | PMS Report, PSUR, or Summary of Safety and Clinical Performance delivered within timeframe required | 0 late reports                                                                                     | Post-market surveillance process is effective       | None                 | N/A                             |
              | Internal Audit                          | Number of internal audits completed on schedule                                                     | 100% of audits completed                                                                           | Internal audit process is effective                 | None                 | N/A                             |
              | Clinical Evaluation                     | On-time and as planned PMCF activities                                                              | PMCF activities conducted on schedule                                                              | Clinical evaluation process is effective            | None                 | N/A                             |
              | Management Review                       | Frequency of management review meetings                                                             | At least 1 meeting annually                                                                        | Management review process is effective              | None                 | N/A                             |
              | Update of Regulations                   | Regulatory updates identified and implemented within six months of publication effective dates      | Updates implemented within 6 months                                                                | Regulatory update process is effective              | None                 | N/A                             |
              | Product Registration and Certification  | Zero unsuccessful attempts of Notified Body assessment due to incomplete documentation              | No failed assessments due to documentation issues                                                  | Registration and certification process is effective | None                 | N/A                             |
              | Purchasing                              | Supplier acceptance on surveillance                                                                 | Under 10% of suppliers moved from accepted to blocked during surveillance                          | Purchasing process is effective                     | None                 | N/A                             |
              | Sales                                   | Sales and marketing materials approved prior to use                                                 | 100% of materials to be used in official sales capacity with medical clients approved prior to use | Sales process is effective                          | None                 | N/A                             |
              | Usability and Human Factors Engineering | Usability testing with no failed critical tasks                                                     | 100% of usability critical tasks passed                                                            | Usability process is effective                      | None                 | N/A                             |`,
            columns: [
              { name: "Process" },
              { name: "Key Performance Indicator" },
              { name: "Data on KPI" },
              { name: "Management Assessment" },
              { name: "Improvement Measures" },
              { name: "Evaluation of Previous Measures" },
            ],
            addRowEnabled: true,
            helperText: `You will likely need to speak with the team members who own each of these processes and determine what the results are for the time period you are assessing. If acceptance criteria is met as stated then you can leave this table as is. If any acceptance criteria are wrong, then update the report with the changes and list the improvement measures you will implement so that the KPI will be met next time. Examples of improvement measures can include:
              -Improving frequency of training to improve overall process knowledge
              -Update an SOP to improve its effectiveness
              -Change the KPI to something more reasonable`,
          },
        },
      },
      {
        id: "management-review-assessment",
        required: true,
        prompt: true,
        context: ["kpi-assessment"],
        element: {
          id: "management-review-assessment",
          type: "textField",
          options: {
            label:
              "Give a general summary of your QMS performance based on the KPIs?",
            rows: 5,
            helperText: `Provide an overall summary on how your QMS has performed based on the table you just completed. If every KPI is met then say your QMS is performing as expected and no improvements are needed at this time.`,
          },
        },
      },
      {
        id: "management-review-signatures",
        required: true,
        prompt: false,
        element: {
          id: "management-review-signatures",
          type: "table",

          options: {
            label:
              "Provide the names of your management team and initial in your QMS.",
            helperText:
              "This is typically senior level members of the organization. This doesn't necessarily need to be the CEO, CTO, etc. of the organization but for most startups it is easiest to make it the C-level.",
            columns: [
              { name: "Position" },
              { name: "Name" },
              { name: "Signature" },
            ],
            addRowEnabled: true,
          },
          transformerConfig: {
            type: "default",
            inputs: ["management-team"],
            transformer: (inputs: Record<DocumentDataKey, string>) => {
              const managementTeam = parseMarkdownTable(
                inputs["management-team"]
              );
              return `
  | Position | Name | Signature |
  | -------- | ---- | --------- |
  ${managementTeam.map((member: Record<string, string>) => `| ${member.Position} | ${member.Name} | |`).join("\n")}
                `;
            },
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.DECLARATION_OF_CONFORMITY]: {
    id: TEMPLATE_TYPE.DECLARATION_OF_CONFORMITY,
    hidden: false,
    functionalGroup: FunctionalGroup.PD,
    docType: "TCD",
    docName: "EU Declaration of Conformity",
    dependencies: [
      "udi-di",
      "gspr-authorized-rep",
      "gspr-measuring",
      "manufacturer-name",
      "manufacturer-address",
      "pmcf-contacts",
      "software-version",
      "intended-use",
      "device-classification",
      "device-classification-justification",
    ],
    elements: [
      {
        id: "notified-body-name",
        required: false,
        prompt: false,
        element: {
          id: "notified-body-name",
          type: "textField",
          options: {
            label: "What is the name of the notified body you used?",
            helperText:
              "If it is a Class I non-sterile/measuring/reusable device then keep this blank.",
          },
        },
      },
      {
        id: "basic-udi-di",
        required: true,
        prompt: false,
        element: {
          id: "basic-udi-di",
          type: "textField",
          options: {
            label: "Provide your basic UDI-DI number.",
            helperText: `
This is derived from your UDI-DI. To create your basic UDI-DI go to the GS1 basic udi-di calculator page and insert the company prefix you received when you purchased your UDI-DI. 

Here is a link to the calculator page: https://www.gs1.org/services/check-character-calculator
            `,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOP_PRODUCT_REGISTRATION_AND_CERTIFICATION]: {
    id: TEMPLATE_TYPE.SOP_PRODUCT_REGISTRATION_AND_CERTIFICATION,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "SOP",
    docName: "SOP Product Registration and Certification",
    elements: [],
  },
  [TEMPLATE_TYPE.GSPR_CHECKLIST]: {
    id: TEMPLATE_TYPE.GSPR_CHECKLIST,
    docName: "GSPR Checklist",
    docType: "TCD",
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    elements: [
      {
        id: "gspr-measuring",
        required: true,
        element: {
          id: "gspr-measuring",
          type: "select",
          options: {
            label:
              "Does your device have a measuring function with a claimed accuracy?",
            options: ["Yes", "No"],
            helperText: `A measuring function means that it is the following criteria must all be met for your device:
  
  a) The device is intended by the manufacturer to measure:
    - quantitatively a physiological or anatomical parameter, or
    - a quantity or a quantifiable characteristic of energy or of substances (including medicinal products) delivered to or removed from the human body. Spoons or plastic syringes co-packed with medicinal products and used to measure a quantity of that medicinal product to be administered to the patient are in this category. Devices for the delivery of liquid to the human body without graduation or scale (e.g. medicine spoons, cups, droppers without graduation or scale or display of measuring unit) are not in this category.
  
    b) The intended purpose implies accuracy, claimed explicitly or implicitly, where a non-compliance with the implied accuracy could result in a significant adverse effect on the patient’s health and safety. The expression ‘claimed implicitly’ covers cases where the user, on the basis of the designation of the device or of its accompanying documents, or on the basis of the common use, is entitled to expect accuracy where the accuracy of the measurement has an impact on the diagnosis or therapy of the patient. Measuring activities during the manufacturing process including those for calibration purposes are not covered and do not imply a measuring function of the manufactured device.`,
          },
        },
      },
      {
        id: "gspr-clinical-parameters",
        required: true,
        element: {
          id: "gspr-clinical-parameters",
          type: "select",
          options: {
            label: "Do you measure clinical parameters?",
            options: ["Yes", "No"],
            helperText: `Does your device monitor clinical parameters that can lead to death or severe deterioration of the patient's health?`,
          },
        },
      },
      {
        id: "gspr-authorized-rep",
        required: true,
        element: {
          id: "gspr-authorized-rep",
          type: "select",
          options: {
            label: "Do you have an authorized representative?",
            options: ["Yes", "No"],
            helperText: `If you are not based in the EU then you need to have appointed an authorized representative who can act on your behalf. If you do have an authorized representative, select "Yes". Otherwise, select "No".`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOP_CHANGE_MANAGEMENT]: {
    id: TEMPLATE_TYPE.SOP_CHANGE_MANAGEMENT,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docName: "SOP Change Management",
    docType: "SOP",
    elements: [],
  },
  [TEMPLATE_TYPE.CHANGE_REQUEST]: {
    id: TEMPLATE_TYPE.CHANGE_REQUEST,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docName: "Change Request",
    docType: "RCD",
    docReferences: [TEMPLATE_TYPE.SOP_CHANGE_MANAGEMENT],
    elements: [
      {
        id: "change-source",
        required: true,
        prompt: false,
        element: {
          id: "change-source",
          type: "select",
          options: {
            label: "What was the source of the change request?",
            options: [
              "Customer complaint",
              "New feature",
              "Problem report",
              "Clinical Evaluation or Post-Market Surveillance",
              "Employee identification",
              "Other source",
            ],
            helperText:
              "Describe how this change request originated. Did it come from any of the following: Customer complaint, New feature, Problem report (please provide problem report number or identifier), Clinical Evaluation or Post-Market Surveillance, Employee identification, Other source",
          },
        },
      },
      {
        id: "change-description",
        required: true,
        prompt: false,
        element: {
          id: "change-description",
          type: "textField",
          options: {
            label:
              "Provide a description of what changes are going to be made.",
            helperText:
              "Describe what the changes would entail. Provide detail here as to what is being added, removed, or changed.",
            rows: 5,
          },
        },
      },
      {
        id: "change-products",
        required: true,
        prompt: false,
        element: {
          id: "change-products",
          type: "table",
          options: {
            label: "What products and versions are affected by this change?",
            helperText:
              "Provide the affected products and product versions that will be affected by the change in the table.",
            default: `
              | Product Name(s) | Version(s) |
              | --------------- | ---------- |
              |                 |            |`,
            columns: [{ name: "Product Name(s)" }, { name: "Version(s)" }],
          },
        },
      },
      {
        id: "change-software-items",
        required: true,
        prompt: false,
        element: {
          id: "change-software-items",
          type: "table",
          transformerConfig: {
            type: "default",
            inputs: ["software-items-table"],
            transformer: (inputs: Record<DocumentDataKey, string>) => {
              const softwareItems = parseMarkdownTable(
                inputs["software-items-table"]
              );
              return `
| Software Item | Affected by Change (Yes/No) | Comments |
| ------------- | --------------------------- | -------- |
${softwareItems.map((item: Record<string, string>) => `| ${item["Software Item"]} | | |`).join("\n")}
              `;
            },
          },
          options: {
            label: "What software items are affected by this change?",
            helperText:
              "Determine what software items will be affected by the change and which will not be affected. Provide a 'Yes' or 'No' in the table below for which software items will and won't be affected, respectively.",
            default: `
              | Software Item | Affected by Change (Yes/No) | Comments |
              | ------------- | --------------------------- | -------- |
              |               |                             |          |`,
            columns: [
              { name: "Software Item" },
              { name: "Affected by Change (Yes/No)" },
              { name: "Comments" },
            ],
          },
        },
      },
      {
        id: "change-documents-processes",
        required: true,
        prompt: false,
        element: {
          id: "change-documents-processes",
          type: "table",
          options: {
            label:
              "What processes or documents are anticipated to be impacted by the change?",
            helperText:
              "Fill in the table as to what documentation is anticipated to be affected by the change and will require an update because of it. For the 'Update Anticipated' column just put a 'Yes' or a 'No' and provide comments as needed.",
            default: `
              | Document / Process             | Update Anticipated | Comments |
              | ------------------------------ | ------------------ | -------- |
              | Intended Use                   |                    |          |
              | Device Classification          |                    |          |
              | User Needs list                |                    |          |
              | Risk Management File           |                    |          |
              | Software Safety Classification |                    |          |
              | Software Requirements          |                    |          |
              | Software Architecture          |                    |          |
              | Software System Test           |                    |          |
              | Usability Evaluation           |                    |          |
              | Clinical Evaluation            |                    |          |
              | Instructions for Use           |                    |          |`,
            columns: [
              { name: "Document / Process" },
              { name: "Update Anticipated" },
              { name: "Comments" },
            ],
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.CHANGE_EVALUATION_REPORT]: {
    id: TEMPLATE_TYPE.CHANGE_EVALUATION_REPORT,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docName: "Change Evaluation Report",
    docType: "RCD",
    elements: [
      {
        id: "change-document-number",
        required: true,
        prompt: false,
        element: {
          id: "change-document-number",
          type: "textField",
          options: {
            label: "Which change request does this evaluation relate to?",
            helperText:
              "Provide the document number of the change request that this report is related to.",
          },
        },
      },
      {
        id: "change-made",
        required: true,
        prompt: false,
        element: {
          id: "change-made",
          type: "textField",
          options: {
            label: "Provide a description of the changes that were made.",
            helperText: `Provide a description of the change performed in response to the change request. Include: 
- New or changed code
- Documentation that was updated including any testing that was performed
- Processes that were updated or changed
- New requirements or risks that were identified`,
            rows: 5,
          },
        },
      },
      {
        id: "change-impact",
        required: true,
        prompt: false,
        element: {
          id: "change-impact",
          type: "table",
          options: {
            label: "What is the impact of the changes?",
            helperText: `Fill in the table below to determine the impact of the changes that were performed to the released product. The evaluation will determine whether the change was significant and whether a notified body and/or users need to be notified of the change.

For the "Impact" column, put one of the following options: 
 
- Impacted
- Not Impacted
 
For the "Details" column, provide a description of the impact of the change if the impact column is listed as "Impacted". If there is no change to the intended use or general safety and performance requirements then provide a description as to why here as well.`,
            default: `
              | Evaluation Criteria                                                                                                                                                            | Impact | Details |
              | ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ | ------ | ------- |
              | Does the change impact the intended use, patient population, intended users, use environment, contraindications, or interaction with the body?                                 |        |         |
              | Does the change impact the device's conformity with the general safety and performance requirements?                                                                           |        |         |
              | Does the change introduce new risks that require control measures or is the existing risk profile for the device negatively affected by the new risks?                                                                                  |        |         |
              | Does the change have an impact on clinical performance or safety which necessitates additional clinical or usability testing?                                                  |        |         |
              | Does the change alter built-in control mechanisms or alarms, core operating principles, operating systems or sources of energy or introduce a new channel of interoperability? |        |         |
              | Does the change introduce a new or change to the algorithm used for the core medical functionality?                                                                            |        |         |`,
            columns: [
              { name: "Evaluation Criteria" },
              { name: "Impact" },
              { name: "Details" },
            ],
          },
        },
      },
      {
        id: "change-verification",
        required: true,
        prompt: false,
        element: {
          id: "change-verification",
          type: "textField",
          options: {
            label:
              "What verification has been done to ensure the changes have been made properly?",
            helperText: `What testing or verification has been performed that the changes have been incorporated properly. If new requirements were created and tested, provide an overview of that here. If a new process was added or existing processes were changed, provide an example of how those processes were or will be verified to ensure that they will work properly in their new format.`,
            rows: 5,
          },
        },
      },
      {
        id: "change-significance",
        required: true,
        prompt: false,
        element: {
          id: "change-significance",
          type: "select",
          options: {
            label: "Is the change significant?",
            options: ["Significant", "Nonsignificant"],
            helperText: `Determine whether your change is considered significant or nonsignificant given your answers in the previous table. If you identified any criteria as "Significantly impacted" in the previous table, then the change is "significant". Otherwise, the changes are nonsignificant.

A significant change would require notification of your notified body of the change if your device is Class IIA and above.`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SOFTWARE_DEVELOPMENT_AND_MAINTENANCE_PLAN]: {
    id: TEMPLATE_TYPE.SOFTWARE_DEVELOPMENT_AND_MAINTENANCE_PLAN,
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    docType: "TCD",
    docName: "Software Development and Maintenance Plan",
    elements: [
      {
        id: "version-control-system",
        prompt: false,
        required: true,
        element: {
          id: "version-control-system",
          type: "textField",
          options: {
            label: "Provide what you use for your version control system.",
            default: "GitHub",
            rows: 1,
            helperText:
              "This can be something like GitHub, GitLab, BitBucket, etc.",
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.RELEASE_NOTES]: {
    id: TEMPLATE_TYPE.RELEASE_NOTES,
    hidden: false,
    functionalGroup: FunctionalGroup.SW,
    docType: "RCD",
    docName: "Release Notes",
    elements: [
      {
        id: "release-notes-version",
        required: true,
        prompt: false,
        element: {
          id: "release-notes-version",
          type: "textField",
          options: {
            label: "What version of the software are the release notes for?",
          },
        },
      },
      {
        id: "release-notes-date",
        required: true,
        prompt: false,
        element: {
          id: "release-notes-date",
          type: "textField",
          options: {
            label: "What date do you want to use for these release notes?",
          },
        },
      },
      {
        id: "release-notes-bug-fixes",
        required: true,
        prompt: false,
        element: {
          id: "release-notes-bug-fixes",
          type: "textField",
          options: {
            label: "What bug fixes have you made for this software release?",
            rows: 5,
            helperText:
              "Provide a list of bug fixes made for this software release. If this is the initial release state that there are no bug fixes in this release.",
          },
        },
      },
      {
        id: "release-notes-changes",
        required: true,
        prompt: false,
        element: {
          id: "release-notes-changes",
          type: "textField",
          options: {
            label:
              "What changes have you implemented for this software release?",
            rows: 5,
            helperText:
              "This can include the new features of your software or changes to existing features. Describe them in detail. Using a bulleted list can help to organize your changes. If this is the initial release of your product, state that.",
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.UDI_CREATION]: {
    id: TEMPLATE_TYPE.UDI_CREATION,
    hidden: false,
    functionalGroup: FunctionalGroup.UNGROUPED,
    docType: "OTH",
    docName: "UDI Creation",
    elements: [
      {
        id: "udi-di",
        required: true,
        prompt: false,
        element: {
          id: "udi-di",
          type: "textField",
          options: {
            label:
              "Follow the video instructions to get your UDI-DI for your device.",
            helperText: `You will need to register with one of the approved third party providers for a UDI-DI. There are 4 possible entities to register with: 
              - <a href="https://www.gs1.org" target="_blank">GS1</a>
              - <a href="https://www.hibcc.org/" target="_blank">Health Industry Business Communications Council (HIBCC)</a>
              - <a href="https://www.iccbba.org/" target="_blank">International Council for Commonality in Blood Banking Automation (ICCBBA)</a>
              - <a href="https://www.ifaffm.de/" target="_blank">Informationsstelle für Arzneispezialitäten (IFA) GmbH.</a>
              
              We recommend registering with GS1 and have a video walking you through how to do that. Watch the video and then insert your UDI-DI below.`,
          },
        },
        videoUrl:
          "https://www.loom.com/share/9c81feeed09c4d1dbccb795d4334de6c?sid=8fb8aa78-d5d3-4990-9f84-aca6024c4006",
      },
      {
        id: "udi-pi",
        required: true,
        prompt: false,
        element: {
          id: "udi-pi",
          type: "textField",
          options: {
            label: "Create your UDI-PI for this version of your product.",
            helperText:
              "You can create your UDI-PI for your device without needing to go to any third party. Watch the video on how to create a UDI-PI and then insert the UDI-PI for your software below.",
          },
        },
        videoUrl:
          "https://www.loom.com/share/7711b49854a042f48af24461f1918590?sid=41055f24-07cc-44fd-8e42-04314f0b50b7",
      },
      {
        id: "srn",
        required: true,
        prompt: false,
        element: {
          id: "srn",
          type: "textField",
          options: {
            label:
              "Follow the video instructions to register your Single Registration Number (SRN).",
            helperText: `
              For the Single Registration Number (SRN) is created through the EUDAMED website. First you need to create an account and then you will register as a manufacturer for your SRN. Watch the video to to set up your account. It should take ~2 days for the competent authority you submitted to to respond and give you your SRN. When you receive it, insert it in the text box below.

              EUDAMED link: https://webgate.ec.europa.eu/eudamed/landing-page#/`,
          },
        },
        videoUrl:
          "https://www.loom.com/share/2d9328cd4fb1407ba1d40d4a4568da36?sid=1f79bf44-319a-4962-a436-5837c52b320b",
      },

      {
        id: "emdn-code",
        required: true,
        prompt: false,
        element: {
          id: "emdn-code",
          type: "textField",
          options: {
            label:
              "Select a European Medical Device Nomenclature (EMDN) code that best fits for your device.",
            helperText: `This is important for the registration of the device so you want to find a code that matches most specifically to the functionality of your device but is still accurate to its function. For example, if your product is software related to the measurement of electromyography but the closest code you can find to it is electromyographical hardware analysis, do NOT select this code as it is not accurate.

          Follow this link to search through the EMDN Codes available: https://webgate.ec.europa.eu/dyna2/emdn/`,
          },
        },
        videoUrl:
          "https://www.loom.com/share/bcdf029c869e499fafde9569c1fd95c7?sid=82699a7f-fd35-4ac6-ab21-19c2e6dab5af",
      },
    ],
  },
  [TEMPLATE_TYPE.TECHNICAL_FILE]: {
    id: TEMPLATE_TYPE.TECHNICAL_FILE,
    hidden: false,
    functionalGroup: FunctionalGroup.UNGROUPED,
    docType: "TCD",
    docName: "Technical File",
    docReferences: [
      TEMPLATE_TYPE.INTENDED_USE,
      TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT,
      TEMPLATE_TYPE.MDR_DEVICE_CLASSIFICATION,
      TEMPLATE_TYPE.SOFTWARE_ARCHITECTURE,
      TEMPLATE_TYPE.DEVICE_LABEL,
      TEMPLATE_TYPE.INSTRUCTIONS_FOR_USE,
      TEMPLATE_TYPE.SOP_INTEGRATED_SOFTWARE_DEVELOPMENT,
      TEMPLATE_TYPE.SUBSYSTEM_REQUIREMENTS_LIST,
      TEMPLATE_TYPE.SOUP_LIST,
      TEMPLATE_TYPE.USER_NEEDS_LIST,
      TEMPLATE_TYPE.LIST_OF_QUALIFIED_SUPPLIERS,
      TEMPLATE_TYPE.GSPR_CHECKLIST,
      TEMPLATE_TYPE.LIST_OF_REGULATORY_REQUIREMENTS,
      TEMPLATE_TYPE.RISK_MANAGEMENT_PLAN,
      TEMPLATE_TYPE.RISK_ASSESSMENT,
      TEMPLATE_TYPE.RISK_MANAGEMENT_REPORT,
      TEMPLATE_TYPE.SOFTWARE_TEST_PLAN,
      TEMPLATE_TYPE.SOFTWARE_SYSTEM_TEST_REPORT,
      TEMPLATE_TYPE.SOP_POST_MARKET_SURVEILLANCE,
      TEMPLATE_TYPE.POST_MARKET_SURVEILLANCE_PLAN,
      TEMPLATE_TYPE.SOFTWARE_DEVELOPMENT_AND_MAINTENANCE_PLAN,
      TEMPLATE_TYPE.LIST_OF_KNOWN_ANOMALIES,
      TEMPLATE_TYPE.SOP_PRODUCT_REGISTRATION_AND_CERTIFICATION,
      TEMPLATE_TYPE.SOP_DEPLOYMENT,
      TEMPLATE_TYPE.USABILITY_EVALUATION_PLAN,
      TEMPLATE_TYPE.USABILITY_EVALUATION_PROTOCOL,
      TEMPLATE_TYPE.CLINICAL_EVALUATION_PLAN,
      TEMPLATE_TYPE.USABILITY_EVALUATION_REPORT,
      TEMPLATE_TYPE.SOP_CLINICAL_EVALUATION,
      TEMPLATE_TYPE.SOP_FEEDBACK_MANAGEMENT,
      TEMPLATE_TYPE.SOP_VIGILANCE,
      TEMPLATE_TYPE.SOP_CAPA,
    ],

    dependencies: [
      "notified-body-name",
      "expected-lifetime",
      "basic-udi-di",
      "benefit-risk-ratio",
      "clinical-background",
      "clinical-claims",
      "clinical-device-description",
      "clinical-evaluation-team",
      "contraindications",
      "device-classification-justification",
      "device-classification",
      "device-usage",
      "emdn-code",
      "gspr-authorized-rep",
      "ifu-accessories",
      "indications-for-use",
      "intended-use",
      "known-anomalies",
      "manufacturer-address",
      "manufacturer-name",
      "minimum-requirements",
      "overall-residual-risk",
      "patient-population",
      "pmcf-activities",
      "pmcf-contacts",
      "principles-of-operation",
      "risk-acceptability-criteria",
      "risk-acceptance-matrix",
      "risk-analysis-results",
      "risk-analysis",
      "risk-control-subsystem-requirements",
      "risk-controls-results",
      "similar-device-table",
      "software-architecture-backup",
      "software-architecture-security",
      "software-connections-table",
      "software-items-table",
      "subsystem-requirements",
      "software-version",
      "srn",
      "state-of-the-art",
      "user-profile",
      "user-testing-summary",
      "variants-and-accessories",
      "version-control-system",
      "user-needs",
      "software-safety-classification",
      "electronic-ifu-location",
    ],
    elements: [
      {
        id: "devices-covered",
        required: true,
        prompt: false,
        element: {
          id: "devices-covered",
          type: "table",
          options: {
            label:
              "Provide a list of the devices covered by this technical file including the names of the models, configurations, and company serial numbers if relevant.",
            columns: [
              { name: "Device Name" },
              { name: "Configuration" },
              { name: "Company Serial Number" },
            ],
            addRowEnabled: true,
          },
        },
      },
      {
        id: "did-user-manual",
        required: true,
        prompt: false,
        element: {
          id: "did-user-manual",
          type: "textField",
          options: {
            label:
              "Do you have a user manual in addition to your instructions for use?",
            helperText:
              "If yes, provide the document number for the user manual.",
          },
        },
      },
      {
        id: "did-physicians-handbook",
        required: true,
        prompt: false,
        element: {
          id: "did-physicians-handbook",
          type: "textField",
          options: {
            label:
              "Do you have a physicians handbook containing additional instructions/information?",
            helperText:
              "If yes, provide the document number for the physician's handbook.",
          },
        },
      },
      {
        id: "tf-patient-handbook",
        required: true,
        prompt: false,
        element: {
          id: "tf-patient-handbook",
          type: "textField",
          options: {
            label: "Provide the document number for the patient handbook.",
            helperText: "If you don't have a patient handbook, state '-'",
          },
        },
      },
      {
        id: "ce-marketing-materials",
        required: true,
        prompt: false,
        element: {
          id: "ce-marketing-materials",
          type: "textField",
          options: {
            label:
              "Do you have any marketing materials that claim your product is CE marked? If so, provide a copy below.",
            helperText:
              "Upload a copy of marketing materials used that mention your product is CE marked. If not you can leave this blank. Save these materials into your QMS and provide the document number here.",
          },
        },
      },
      {
        id: "tf-critical-suppliers",
        required: true,
        prompt: false,
        element: {
          id: "tf-critical-suppliers",
          type: "table",
          options: {
            label: "Provide the list of any critical suppliers you have.",
            columns: [{ name: "Supplier" }],
            addRowEnabled: true,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.DEVICE_LABEL]: {
    id: TEMPLATE_TYPE.DEVICE_LABEL,
    hidden: false,
    functionalGroup: FunctionalGroup.UNGROUPED,
    docType: "OTH",
    docName: "Device Label",
    dependencies: [
      "device-classification",
      "udi-di",
      "udi-pi",
      "manufacturer-name",
      "manufacturer-address",
      "release-date",
      "software-version",
      "electronic-ifu-location",
      "gspr-authorized-rep",
      "pmcf-contacts",
      "release-notes-version",
      "release-notes-date",
    ],
    elements: [],
  },
  [TEMPLATE_TYPE.USER_MANUAL]: {
    id: TEMPLATE_TYPE.USER_MANUAL,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "OTH",
    docName: "User Manual",
    elements: [],
    userUpload: true,
  },
  [TEMPLATE_TYPE.PHYSICIANS_HANDBOOK]: {
    id: TEMPLATE_TYPE.PHYSICIANS_HANDBOOK,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "OTH",
    docName: "Physicians Handbook",
    elements: [],
    userUpload: true,
  },
  [TEMPLATE_TYPE.MARKETING_CONTENT]: {
    id: TEMPLATE_TYPE.MARKETING_CONTENT,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "OTH",
    docName: "Marketing Content",
    elements: [],
    userUpload: true,
  },
  [TEMPLATE_TYPE.ADDITIONAL_SOFTWARE_TEST_REPORTS]: {
    id: TEMPLATE_TYPE.ADDITIONAL_SOFTWARE_TEST_REPORTS,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "OTH",
    docName: "Additional Software Test Reports",
    elements: [],
    userUpload: true,
  },
  [TEMPLATE_TYPE.ADDITIONAL_SOFTWARE_TEST_PLANS]: {
    id: TEMPLATE_TYPE.ADDITIONAL_SOFTWARE_TEST_PLANS,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "OTH",
    docName: "Additional Software Test Plans",
    elements: [],
    userUpload: true,
  },
  [TEMPLATE_TYPE.PRIMARY_LANGUAGE_TRANSLATED_IFU]: {
    id: TEMPLATE_TYPE.PRIMARY_LANGUAGE_TRANSLATED_IFU,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "OTH",
    docName: "Primary Language Translated IFU",
    elements: [],
    userUpload: true,
  },
  [TEMPLATE_TYPE.OTHER_LANGUAGES_TRANSLATED_IFUS]: {
    id: TEMPLATE_TYPE.OTHER_LANGUAGES_TRANSLATED_IFUS,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "OTH",
    docName: "Other Languages Translated IFUs",
    elements: [],
    userUpload: true,
  },
  [TEMPLATE_TYPE.SOP_RISK_MANAGEMENT]: {
    id: TEMPLATE_TYPE.SOP_RISK_MANAGEMENT,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "SOP",
    docName: "SOP Risk Management",
    elements: [],
  },
  [TEMPLATE_TYPE.SOP_DESIGN_CONTROL]: {
    id: TEMPLATE_TYPE.SOP_DESIGN_CONTROL,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "SOP",
    docName: "SOP Design Control",
    elements: [],
  },
  [TEMPLATE_TYPE.SOP_NONCONFORMANCE]: {
    id: TEMPLATE_TYPE.SOP_NONCONFORMANCE,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "SOP",
    docName: "SOP Nonconformance",
    elements: [],
  },
  [TEMPLATE_TYPE.SOP_USABILITY_ENGINEERING]: {
    id: TEMPLATE_TYPE.SOP_USABILITY_ENGINEERING,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "SOP",
    docName: "SOP Usability Engineering",
    elements: [],
  },
  [TEMPLATE_TYPE.SOP_CYBERSECURITY]: {
    id: TEMPLATE_TYPE.SOP_CYBERSECURITY,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "SOP",
    docName: "SOP Cybersecurity",
    elements: [],
  },
  [TEMPLATE_TYPE.SOP_STATISTICAL_METHODS]: {
    id: TEMPLATE_TYPE.SOP_STATISTICAL_METHODS,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "SOP",
    docName: "SOP Statistical Methods",
    elements: [],
  },
  [TEMPLATE_TYPE.SOP_MANUFACTURING]: {
    id: TEMPLATE_TYPE.SOP_MANUFACTURING,
    hidden: false,
    functionalGroup: FunctionalGroup.QA,
    docType: "SOP",
    docName: "SOP Manufacturing",
    elements: [],
  },
  [TEMPLATE_TYPE.SYSTEM_REQUIREMENTS_LIST]: {
    id: TEMPLATE_TYPE.SYSTEM_REQUIREMENTS_LIST,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "System Requirements List",
    elements: [
      {
        id: "system-requirements",
        required: true,
        prompt: true,
        context: ["user-needs", "shelf-life", "expected-lifetime"],
        element: {
          id: "system-requirements",
          type: "table",
          options: {
            label: "What are your system requirements for your product?",
            columns: [
              { name: "User Need ID" },
              { name: "System ID" },
              { name: "Description" },
              { name: "Relevant Subsystem" },
            ],
            addRowEnabled: true,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.SUBSYSTEM_REQUIREMENTS_LIST]: {
    id: TEMPLATE_TYPE.SUBSYSTEM_REQUIREMENTS_LIST,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Subsystem Requirements List",
    elements: [
      {
        id: "subsystem-requirements",
        required: true,
        prompt: true,
        context: ["system-requirements", "shelf-life", "expected-lifetime"],
        element: {
          id: "subsystem-requirements",
          type: "table",
          options: {
            label: "What are your subsystem requirements for your product?",
            helperText: `Subsystem requirements define the key functions of your device's subsystem. They are linked to the requirements defined in your System Requirements List and should provide more granularity on how each subsystem will satisfy that System requirement. If using software, think of them as user stories but more generalized to the function of the actual software.

There is no "correct number" of subsystem requirements. The regulations specify that the subsystem requirements should not be redundant and should fully satisfy the system requirement for which they are linked. Provide technical specifications here when needed. For example, if you need your product to be water resistant, specify the depth or pressure or if you need your product to compress data, specify the speed, time, or efficiency required to do so.
`,
            columns: [
              { name: "Subsystem" },
              { name: "System ID" },
              { name: "Subsystem ID" },
              { name: "Description" },
            ],
          },
        },
      },
      {
        id: "risk-control-subsystem-requirements",
        required: true,
        prompt: true,
        context: ["subsystem-requirements", "risk-controls"],
        element: {
          id: "risk-control-subsystem-requirements",
          type: "table",
          options: {
            label:
              "What are your risk control subsystem requirements for your product?",
            helperText: `During the risk assessment, we decided what risks may need additional risk controls to make the product safe enough for our users. Here, we want to implement product subsystem requirements for those risk controls so that we can build them into the product. Provide a requirement for any risk controls or any features that are to be implemented to help reduce risk to the patients. These should be worded the same way as the subsystem requirements we created in the previous step.`,
            columns: [
              { name: "Risk ID", options: { flex: 0 } },
              { name: "Risk Control Requirement ID", options: { flex: 0 } },
              { name: "Subsystem", options: { flex: 0 } },
              { name: "Description" },
            ],
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.HARDWARE_DESIGN_AND_DEVELOPMENT_PLAN]: {
    id: TEMPLATE_TYPE.HARDWARE_DESIGN_AND_DEVELOPMENT_PLAN,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Hardware Design and Development Plan",
    elements: [],
  },
  [TEMPLATE_TYPE.MECHANICAL_DESIGN]: {
    id: TEMPLATE_TYPE.MECHANICAL_DESIGN,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Mechanical Design",
    elements: [
      {
        id: "mechanical-subsystem-components",
        required: true,
        prompt: false,
        element: {
          id: "mechanical-subsystem-components",
          type: "table",
          options: {
            label:
              "What are the components for your mechanical or physical subsystem(s)?",
            helperText:
              "Break down the main components for your mechanical subsystem(s). A component is an individual piece or set of pieces of the product that perform a function.",
            columns: [{ name: "Subsystem" }, { name: "Component" }],
          },
        },
      },
      {
        id: "material-specifications",
        required: true,
        prompt: false,
        element: {
          id: "material-specifications",
          type: "table",
          options: {
            label: "Describe your products material components?",
            helperText:
              "The material specifications give an idea of the material makeup of each component. If multiple materials are present then provide a general description of what materials exist in the component.",
            columns: [
              { name: "Component Name", options: { flex: 0 } },
              { name: "Material Description" },
              { name: "Supplier Name" },
              { name: "Notes" },
            ],
          },
        },
      },
      {
        id: "mechanical-specifications",
        required: true,
        prompt: false,
        element: {
          id: "mechanical-specifications",
          type: "table",
          options: {
            label:
              "What are the physical and mechanical specifications for your product?",
            helperText: `The mechanical and physical specifications for your product are the measurable criteria that your product needs to meet in order to function as intended. Determine whether items like hardness, tensile strength, flexion, tension, etc are necessary in order to achieve the products appropriate functionality. Fill in the table with the following context for the column titles:
            
- Component: What component or part requires this property
- Property type: Provide the kind of property you are referring to. (i.e. - hardness, tensile strength, etc.
- Specification: Provide objective and measurable criteria that would satisfy the products functionality requirements (i.e. - > 450 mPa, 0.2 - 0.8 cm, etc.)`,
            columns: [
              { name: "Component", options: { flex: 0 } },
              { name: "Property type" },
              { name: "Specification" },
            ],
          },
        },
      },
      {
        id: "environmental-specifications",
        required: true,
        prompt: false,
        element: {
          id: "environmental-specifications",
          type: "table",
          options: {
            label:
              "What are the operational environmental specifications for your product?",
            helperText: `Provide the environmental conditions that are required in order for your product to operate as intended. Fill in the table with the following context for the column titles:
            
- Property type: Provide the kind of property you are referring to. (i.e. - termperature, pressure, humidity, etc.
- Specification: Provide objective ranges or numbers for the environmental property (i.e. - 10°C to 40°C, 1.0 atm, etc.)`,
            columns: [
              { name: "Property type", options: { flex: 0 } },
              { name: "Specification" },
            ],
          },
        },
      },
      {
        id: "mechanical-models",
        required: true,
        prompt: false,
        element: {
          id: "mechanical-models",
          type: "fileUpload",
          options: {
            label: "Upload your model design or drawing.",
            helperText:
              "Upload the image or design of your model. This can be a CAD model or something similar.",
            allowedFileTypes: "image/png,image/jpeg,image/jpg",
          },
        },
      },
      {
        id: "mechanical-assembly",
        required: true,
        prompt: false,
        element: {
          id: "mechanical-assembly",
          type: "fileUpload",
          options: {
            label: "Upload your product assembly file.",
            helperText:
              "Upload the image or file that describes the mechanical assembly of the device.",
            allowedFileTypes: "image/png,image/jpeg,image/jpg",
          },
        },
      },
      {
        id: "additional-mechanical-images",
        required: false,
        prompt: false,
        element: {
          id: "additional-mechanical-images",
          type: "fileUpload",
          options: {
            label: "Upload any additional files for your mechanical design.",
            helperText:
              "Upload any additional information related to your product design or assembly that might be revelant.",
            allowedFileTypes: "image/png,image/jpeg,image/jpg",
          },
        },
      },
      {
        id: "finishing-coating",
        required: true,
        prompt: false,
        element: {
          id: "finishing-coating",
          type: "textField",
          options: {
            label:
              "Provide a description of any finishes or coating on the product.",
            helperText:
              "Give a detailed summary fo the finishing or coating that will be used on your product. Provide references to manufacturers or suppliers if there are any. If none will be used, state so.",
            rows: 5,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.ELECTRICAL_DESIGN]: {
    id: TEMPLATE_TYPE.ELECTRICAL_DESIGN,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Electrical Design",
    elements: [
      {
        id: "electrical-subsystem-components",
        required: true,
        prompt: false,
        element: {
          id: "electrical-subsystem-components",
          type: "table",
          options: {
            label: "What are the electrical components in your subsystem(s)?",
            helperText:
              "Provide the electrical components for your subsystem(s). Provide the name of the component, a brief description of the component, the supplier of the component and any notes you think are relevant.",
            columns: [
              { name: "Subsystem", options: { flex: 0 } },
              { name: "Component Name", options: { flex: 0 } },
              { name: "Description" },
              { name: "Supplier Name", options: { flex: 0 } },
              { name: "Notes" },
            ],
          },
        },
      },
      {
        id: "electrical-specifications",
        required: true,
        prompt: false,
        element: {
          id: "electrical-specifications",
          type: "table",
          options: {
            label: "What are the electrical specifications for your product?",
            helperText: `Provide the specifications for your electrical components that are required for the device to function as you intend it to. Use the following context for the column headers:
              
-Property Type: Define the electrical property that you are providing a specification for (i.e. - Battery life, max voltage, etc.)
-Specification: Provide objective criteria that need to be satisfied for that property type (i.e. - > 5 hours, < 24 V, etc.)
`,
            columns: [
              { name: "Property Type", options: { flex: 0 } },
              { name: "Specification" },
            ],
          },
        },
      },
      {
        id: "circuit-schematics",
        required: true,
        prompt: false,
        element: {
          id: "circuit-schematics",
          type: "fileUpload",
          options: {
            label: "Upload your circuit schematics.",
            helperText:
              "Provide the schematic diagrams of your electronic circuits within the product (i.e. - heart rate sensor circuitry, data processing units, wireless communication modules).",
            allowedFileTypes: "image/png,image/jpeg,image/jpg",
          },
        },
      },
      {
        id: "pcb-layout",
        required: true,
        prompt: false,
        element: {
          id: "pcb-layout",
          type: "fileUpload",
          options: {
            label: "Upload your PCB layout file.",
            helperText:
              "Provide the layout of your printed circuit board. This is usually a visual representations and/or CAD files illustrating component placement, routing, and layer stack-ups.",
            allowedFileTypes: "image/png,image/jpeg,image/jpg",
          },
        },
      },
      {
        id: "electrical-assembly",
        required: true,
        prompt: false,
        element: {
          id: "electrical-assembly",
          type: "fileUpload",
          options: {
            label: "Upload your electrical assembly drawing.",
            helperText:
              "Provide the assembly drawings for your electrical components. This usually outlines which components go where on the printed circuit board, reference designators, orientation, and any special assembly considerations.",
            allowedFileTypes: "image/png,image/jpeg,image/jpg",
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.FIRMWARE_DESIGN]: {
    id: TEMPLATE_TYPE.FIRMWARE_DESIGN,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Firmware Design",
    elements: [
      {
        id: "firmware-specifications",
        required: true,
        prompt: false,
        element: {
          id: "firmware-specifications",
          type: "table",
          options: {
            label: "What are the firmware specifications for your product?",
            helperText: `Describe the specific specifications for the functions that your firmware will need to perform in order for your product to operate properly. Use the following context for the column titles:
              
              - Subsystem: What subsystem does the firmware function belong to
              - Firmware Function: What operation is the firmware function performing (i.e. - Power management, data transmission, etc.)
              - Specification: What are the objective measures by which the firmware is required to meet in order for the device to function as intended (i.e. - lowers power after 5 s of inactivity, >1 mbps, etc.)`,
            columns: [
              { name: "Subsystem", options: { flex: 0 } },
              { name: "Firmware Function" },
              { name: "Specification" },
            ],
          },
        },
      },
      {
        id: "firmware-architecture",
        required: true,
        prompt: false,
        element: {
          id: "firmware-architecture",
          type: "fileUpload",
          options: {
            label: "Upload your firmware architecture diagram.",
            helperText:
              "Provide an image that shows the firmware architecture and its interactions with the hardware components.",
            allowedFileTypes: "image/png,image/jpeg,image/jpg",
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.INTERFACE_DESIGN]: {
    id: TEMPLATE_TYPE.INTERFACE_DESIGN,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Interface Design",
    elements: [
      {
        id: "interface-description",
        required: true,
        prompt: true,
        context: ["intended-use"],
        element: {
          id: "interface-description",
          type: "textField",
          options: {
            label:
              "Provide a description of the interfaces between the different subsystems within your medical device system",
            helperText:
              "The description should provide enough detail that it is understood which subsystems are interacting with each other in the system. It is useful to provide additional context around which components are the primary points of contact between each subsystem and the nature of the connection.",
            rows: 5,
          },
        },
      },
      {
        id: "user-interface-design",
        required: true,
        prompt: true,
        element: {
          id: "user-interface-design",
          type: "textField",
          options: {
            label: "Describe your user interface",
            helperText: `Provide some details about how your use interacts with your product including the way it is accessed (Graphical UI on browser / smartphone, physically manipulated, control panel). Additionally, if there are physical steps the user must follow, authentication requirements, or other processes the user needs to undergo during normal use of the device describe them briefly here.`,
            rows: 5,
          },
        },
      },
      {
        id: "interface-diagram",
        required: true,
        prompt: false,
        element: {
          id: "interface-diagram",
          type: "fileUpload",
          options: {
            label:
              "Upload a diagram of the interface between your medical device subsystems",
            helperText:
              "This can be a simple schematic showing high level how your system is connected. It is useful to point out significant components which are key in providing the connection and the nature and flow of the connection.",
            allowedFileTypes: "image/png,image/jpeg,image/jpg",
          },
        },
      },
      {
        id: "interface-connections",
        required: true,
        prompt: true,
        context: ["interface-description"],
        element: {
          id: "interface-connections",
          type: "table",
          options: {
            label: "Provide the specifications of your system interface",
            helperText:
              "Provide the components of each subsystem that interface with each other as well as a description of that interface and the nature of the connection. If specific conditions are required in order to initiate or maintain this connection, provide them in the requirements section. This can include authentication, encryption, usb cables, bluetooth, etc.",
            columns: [
              { name: "Subsystems", options: { flex: 0 } },
              { name: "Component", options: { flex: 0 } },
              { name: "Interface Description" },
            ],
          },
        },
      },
      {
        id: "interoperability",
        required: false,
        prompt: false,
        element: {
          id: "interoperability",
          type: "textField",
          options: {
            label:
              "Describe any external networks or configurations required for your system",
            helperText: `If your system requires interfacing with any type of network or support configuration for proper performance or safety, describe the subsystem(s) invovled here as well as the external source it is required to connect with. Then provide a description of the nature of this connection and why it is required.
            
            If no external connections are required, then you can leave the table blank.`,
            rows: 5,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.PACKAGING_AND_SHELF_LIFE_DESIGN]: {
    id: TEMPLATE_TYPE.PACKAGING_AND_SHELF_LIFE_DESIGN,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Packaging and Shelf-Life Design",
    dependencies: ["shelf-life"],
    elements: [
      /* Name	Template Data Key is Found	Data Key	AI or Manual	User Question	Hint Text
Packaging description	Packaging and Shelf-life Design	packaging-description	Manual	Decribe your packaging.	"Descrbe the packaging that your product will will be provided in. 

- Primary packaging: The primary packaging is the first layer of packaging that comes into direct contact with the medical device. For sterile devices this is the sterile barrier for the device.
- Secondary packaging: While optional, secondary packaging is the outer layer of packaging that provides additional protection and aids in the handling, storage, and transportation of the primary packaged device.

Provide a description of this packaging including it's design. Provide the anticpated materials used in this packaging as well as any special requirements including sterility, opening features, or specific design characteristics that need to be accounted for.

If you have no secondary packaging then you can delete this row."
Primary packaging drawing	Packaging and Shelf-life Design	primary-packaging-drawing	Fle upload.	Upload drawings or schematics of your primary packaging.	
Secondary packaging drawwing	Packaging and Shelf-life Design	secondary-packaging-drawing	File upload	Upload drawings or schematics of your primary packaging.	
Packaging sterility	Packaging and Shelf-life Design	packaging-sterility	Manual	Provide a description of the type of sterility required for your product packaging.	If your product is intended to be provided sterile, give a description of the sterility that will be required and for what components. We will use this information for our eventual sterility verification testing. 
Storage conditions	Packaging and Shelf-life Design	storage-conditions	Manual (pre-filled table)	What are the storage conditions for your device?	Provide the extremes of environmental conditions for which you would like your device to be stored at. Remember, what you put here will need to be tested. For example, if you say your device can be stored at -20 degrees C, you will need to make sure that your device will still be able to operate at that temperature successfully in your shelf-life verification testing. */
      {
        id: "packaging-description",
        required: true,
        prompt: false,
        element: {
          id: "packaging-description",
          type: "table",
          options: {
            label: "Describe your packaging",
            helperText: `Descrbe the packaging that your product will will be provided in. 

- Primary packaging: The primary packaging is the first layer of packaging that comes into direct contact with the medical device. For sterile devices this is the sterile barrier for the device.
- Secondary packaging: While optional, secondary packaging is the outer layer of packaging that provides additional protection and aids in the handling, storage, and transportation of the primary packaged device.

Provide a description of this packaging including it's design. Provide the anticpated materials used in this packaging as well as any special requirements including sterility, opening features, or specific design characteristics that need to be accounted for.

If you have no secondary packaging then you can delete this row.`,

            columns: [
              { name: "Packaging" },
              { name: "Description" },
              { name: "Materials" },
              { name: "Special requirements" },
            ],
            default: `
| Packaging | Description | Materials | Special requirements |
| --------- | ----------- | --------- | -------------------- |
| Primary packaging |   |   |   |
| Secondary Packaging |   |   |   |`,
          },
        },
      },
      {
        id: "primary-packaging-drawing",
        required: true,
        prompt: false,
        element: {
          id: "primary-packaging-drawing",
          type: "fileUpload",
          options: {
            label: "Upload drawings or schematics of your primary packaging.",
            allowedFileTypes: "image/png,image/jpeg,image/jpg",
          },
        },
      },
      {
        id: "secondary-packaging-drawing",
        required: true,
        prompt: false,
        element: {
          id: "secondary-packaging-drawing",
          type: "fileUpload",
          options: {
            label: "Upload drawings or schematics of your primary packaging.",
            allowedFileTypes: "image/png,image/jpeg,image/jpg",
          },
        },
      },
      {
        id: "packaging-sterility",
        required: true,
        prompt: false,
        element: {
          id: "packaging-sterility",
          type: "textField",
          options: {
            label:
              "Provide a description of the type of sterility required for your product packaging.",
            helperText: `If your product is intended to be provided sterile, give a description of the sterility that will be required and for what components. We will use this information for our eventual sterility verification testing. `,
            rows: 5,
          },
        },
      },
      {
        id: "storage-conditions",
        required: true,
        prompt: false,
        element: {
          id: "storage-conditions",
          type: "table",
          options: {
            label: "What are the storage conditions for your device?",
            helperText: `Provide the extremes of environmental conditions for which you would like your device to be stored at. Remember, what you put here will need to be tested. For example, if you say your device can be stored at -20 degrees C, you will need to make sure that your device will still be able to operate at that temperature successfully in your shelf-life verification testing.`,
            columns: [{ name: "Condition" }, { name: "Specification" }],
            default: `
| Condition        | Specification                                  |
|------------------|-----------------------------------------------|
| Temperature      | 5°C to 45°C (41°F to 113°F)                  |
| Humidity         | 10% to 90% RH (non-condensing)               |
| Pressure         | 400 hPa to 1200 hPa (5.8 psi to 17.4 psi)    |
| Light Exposure   | Avoid prolonged exposure to extreme sunlight or UV light when possible |
| Ventilation      | Minimal ventilation to prevent accumulation of dust or moisture |
| Security         | Physical barriers to avoid unauthorized access |`,
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.DESIGN_REVIEW_CHECKLIST]: {
    id: TEMPLATE_TYPE.DESIGN_REVIEW_CHECKLIST,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Design Review Checklist",
    elements: [],
  },
  [TEMPLATE_TYPE.MANUFACTURING_VALIDATION_PLAN_AND_PROTOCOL]: {
    id: TEMPLATE_TYPE.MANUFACTURING_VALIDATION_PLAN_AND_PROTOCOL,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Manufacturing Validation Plan and Protocol",
    elements: [],
  },
  [TEMPLATE_TYPE.MANUFACTURING_VALIDATION_REPORT]: {
    id: TEMPLATE_TYPE.MANUFACTURING_VALIDATION_REPORT,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Manufacturing Validation Report",
    elements: [],
  },
  [TEMPLATE_TYPE.DESIGN_TRANSFER_PLAN]: {
    id: TEMPLATE_TYPE.DESIGN_TRANSFER_PLAN,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Design Transfer Plan",
    elements: [],
  },
  [TEMPLATE_TYPE.DESIGN_TRANSFER_REPORT]: {
    id: TEMPLATE_TYPE.DESIGN_TRANSFER_REPORT,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Design Transfer Report",
    elements: [],
  },
  [TEMPLATE_TYPE.VERIFICATION_AND_VALIDATION_PLAN]: {
    id: TEMPLATE_TYPE.VERIFICATION_AND_VALIDATION_PLAN,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Verification and Validation Plan",
    elements: [],
  },
  [TEMPLATE_TYPE.BIOCOMPATIBILITY_EVALUATION_PLAN]: {
    id: TEMPLATE_TYPE.BIOCOMPATIBILITY_EVALUATION_PLAN,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Biocompatibility Evaluation Plan",
    elements: [],
  },
  [TEMPLATE_TYPE.ELECTRICAL_VERIFICATION_PROTOCOL]: {
    id: TEMPLATE_TYPE.ELECTRICAL_VERIFICATION_PROTOCOL,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Electrical Verification Protocol",
    elements: [],
  },
  [TEMPLATE_TYPE.PACKAGING_AND_SHELF_LIFE_VERIFICATION_PROTOCOL]: {
    id: TEMPLATE_TYPE.PACKAGING_AND_SHELF_LIFE_VERIFICATION_PROTOCOL,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Packaging and Shelf-Life Verification Protocol",
    elements: [],
  },
  [TEMPLATE_TYPE.PERFORMANCE_AND_BENCH_VERIFICATION_PROTOCOL]: {
    id: TEMPLATE_TYPE.PERFORMANCE_AND_BENCH_VERIFICATION_PROTOCOL,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Performance and Bench Verification Protocol",
    elements: [],
  },
  [TEMPLATE_TYPE.PERFORMANCE_AND_BENCH_VALIDATION_PROTOCOL]: {
    id: TEMPLATE_TYPE.PERFORMANCE_AND_BENCH_VALIDATION_PROTOCOL,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Performance and Bench Validation Protocol",
    elements: [],
  },
  [TEMPLATE_TYPE.BIOCOMPATIBILITY_EVALUATION_REPORT]: {
    id: TEMPLATE_TYPE.BIOCOMPATIBILITY_EVALUATION_REPORT,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Biocompatibility Evaluation Report",
    elements: [],
  },
  [TEMPLATE_TYPE.ELECTRICAL_VERIFICATION_REPORT]: {
    id: TEMPLATE_TYPE.ELECTRICAL_VERIFICATION_REPORT,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Electrical Verification Report",
    elements: [],
  },
  [TEMPLATE_TYPE.PACKAGING_AND_SHELF_LIFE_VERIFICATION_REPORT]: {
    id: TEMPLATE_TYPE.PACKAGING_AND_SHELF_LIFE_VERIFICATION_REPORT,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Packaging and Shelf-Life Verification Report",
    elements: [],
  },
  [TEMPLATE_TYPE.PERFORMANCE_AND_BENCH_VERIFICATION_AND_VALIDATION_REPORT]: {
    id: TEMPLATE_TYPE.PERFORMANCE_AND_BENCH_VERIFICATION_AND_VALIDATION_REPORT,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Performance and Bench Verification and Validation Report",
    elements: [],
  },
  [TEMPLATE_TYPE.VERIFICATION_AND_VALIDATION_REPORT]: {
    id: TEMPLATE_TYPE.VERIFICATION_AND_VALIDATION_REPORT,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Verification and Validation Report",
    elements: [],
  },
  [TEMPLATE_TYPE.TRACEABILITY_MATRIX]: {
    id: TEMPLATE_TYPE.TRACEABILITY_MATRIX,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Traceability Matrix",
    elements: [
      {
        id: "traceability-matrix",
        required: true,
        prompt: true,
        context: [
          "user-needs",
          "system-requirements",
          "subsystem-requirements",
          "system-test-plan",
        ],
        element: {
          id: "traceability-matrix",
          type: "table",
          options: {
            label: "Review your products traceability matrix.",
            helperText:
              "The traceability matrix ensures that your user needs and product requirements have been tested through verification and validation and that the product you set out to build from the beginning has been successfully realized without anything missing. Check to make sure all of your requirements are here and that there are no blank spaces!",
            columns: [
              { name: "User Need ID", options: { flex: 0 } },
              { name: "System Requirements ID" },
              { name: "Subsystem Requirements ID" },
              { name: "Design Output" },
              { name: "Verification" },
              { name: "Validation" },
            ],
          },
        },
      },
      {
        id: "risk-traceability-matrix",
        required: true,
        prompt: true,
        context: [
          "risk-analysis",
          "risk-controls",
          "risk-control-subsystem-requirements",
          "system-test-plan",
        ],
        element: {
          id: "risk-traceability-matrix",
          type: "table",
          options: {
            label: "Review your risk traceability.",
            helperText:
              "The risk traceability matrix provides transparency to how you implemented your risk controls and ensures that every risk is accounted for and any risk control has been verified.",
            columns: [
              { name: "Risk ID", options: { flex: 0 } },
              { name: "Hazard" },
              { name: "Hazardous Situation" },
              { name: "Harm" },
              { name: "Risk Acceptability" },
              { name: "Risk Control ID" },
              { name: "Risk Control Requirements ID" },
              { name: "Description" },
              { name: "Verification" },
            ],
          },
        },
      },
    ],
  },
  [TEMPLATE_TYPE.PRODUCT_RELEASE_CHECKLIST]: {
    id: TEMPLATE_TYPE.PRODUCT_RELEASE_CHECKLIST,
    hidden: false,
    functionalGroup: FunctionalGroup.TD,
    docType: "TCD",
    docName: "Product Release Checklist",
    elements: [],
  },
};

// Checks for cyclical dependencies to make sure that templates dont have dependencies that are elements og themselves
Object.values(config).forEach((template) => {
  const dependencies = template.dependencies;
  const elementIds = template.elements.map((element) => element.id);
  if (dependencies && elementIds.some((id) => dependencies.includes(id))) {
    throw new Error(`Cyclical dependency found in template ${template.id}`);
  }
});

export const getCookie = (name: string): string | null => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? decodeURIComponent(match[2]) : null;
};

export const setCookie = (name: string, value: string, maxAge: number) => {
  if (!getCookie(name)) {
    document.cookie = `${name}=${encodeURIComponent(value)}; path=/; max-age=${maxAge}`;
  }
};

export const setReferrerCookie = () => {
  const referrer = document.referrer;
  if (referrer) {
    setCookie('referrer', referrer, 86400); // 1 day in seconds
  }
}; 
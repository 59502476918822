import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Step } from "src/components/Form/Step";
import { MultiStepFormWizardTopBar } from "src/components/WizardTopBar/MultiStepFormWizardTopBar";
import { ROUTE_SLUGS, ROUTES } from "src/config/navigation/routes";
import {
  MultiStepFormSkeleton,
  ProgressBubbles,
  UpdateDeviceDescriptionModal,
} from "../components";
import { ASSISTANT_CONFIG } from "../config";
import {
  useGetDevice,
  useGetDocument,
  useGetDocuments,
  useGetTasks,
  useGetUser,
} from "../hooks";
import { TEMPLATE_TYPE } from "../stores/models";
import { TemplateElement } from "../types";
import {
  fillRouteWithSlugs,
  getDocumentStepsFromConfig,
  getFilteredSteps,
  mapDocumentVersionsToDataKeys,
} from "../utils";

const returnToRoadmapText = "Return to Roadmap";
const returnToQmsText = "Return to QMS";

export const MultiStepForm: React.FC = () => {
  const {
    orgId = "",
    deviceId = "",
    templateId = "",
    docId = "",
  } = useParams<{
    orgId: string;
    deviceId: string;
    templateId: TEMPLATE_TYPE;
    docId?: string;
  }>();

  const navigate = useNavigate();
  const { state } = useLocation();
  const [previousPath, setPreviousPath] = useState<string>("");
  const [previousPathButtonText, setPreviousPathButtonText] =
    useState<string>("");

  const [_, setSearchParams] = useSearchParams();

  if (!templateId) {
    throw new Error("No template id specified");
  }
  if (!deviceId) {
    throw new Error("No device id specified");
  }

  const steps = getDocumentStepsFromConfig(templateId);

  const docConfig = ASSISTANT_CONFIG[templateId];

  const containerRef = React.useRef<HTMLDivElement>(null);

  let [searchParams] = useSearchParams();

  /**
   * QUERIES
   */
  const { data: user } = useGetUser();
  const { data: device } = useGetDevice({ orgId, deviceId });
  const { data: document } = useGetDocument(orgId, deviceId, docId);
  const { data: documents } = useGetDocuments(orgId, deviceId);

  const { data: tasks } = useGetTasks(orgId, deviceId);

  /**
   * This hook gets the filtered steps and sets the first step as the active step if no step is set in the url
   */
  useEffect(() => {
    if (device && documents) {
      const dataKeysWithValue = mapDocumentVersionsToDataKeys(documents);
      const filteredSteps = getFilteredSteps(steps, device, dataKeysWithValue);

      let stepIdUrlParam = searchParams.get("step");

      if (!stepIdUrlParam) {
        stepIdUrlParam = filteredSteps[0].id;
        setSearchParams((params) => ({ ...params, step: stepIdUrlParam }));
      }
    }
  }, [device?.id, searchParams, documents]);

  // Set the exit path for the top bar, from the previous page when we first get to this component
  useEffect(() => {
    // Save the previous path on first render
    if (!previousPath && state?.from) {
      setPreviousPath(state.from);
      state.from.includes("/documents") || state.from.includes("/files")
        ? setPreviousPathButtonText(returnToQmsText)
        : setPreviousPathButtonText(returnToRoadmapText);
    }
  }, [state?.from, previousPath]);

  const handleNextStep = (step: TemplateElement, steps: TemplateElement[]) => {
    const nextStep = steps[steps.findIndex((s) => s.id === step.id) + 1];
    setSearchParams((params) => ({ ...params, step: nextStep.id }));
  };

  const handlePrevStep = (step: TemplateElement, steps: TemplateElement[]) => {
    const prevStep = steps[steps.findIndex((s) => s.id === step.id) - 1];
    setSearchParams((params) => ({ ...params, step: prevStep.id }));
  };

  if (
    !device ||
    !templateId ||
    !document ||
    !documents ||
    !document.versions[0] ||
    !user ||
    !tasks
  ) {
    return <MultiStepFormSkeleton />;
  }

  const documentVersion = document.versions[0];

  const dataKeysWithValue = mapDocumentVersionsToDataKeys(documents);
  const filteredSteps = getFilteredSteps(steps, device, dataKeysWithValue);

  const activeStep = steps.find((s) => s.id === searchParams.get("step"));

  if (!activeStep) {
    return <MultiStepFormSkeleton />;
  }

  return (
    <div className="flex flex-1 flex-col gap-y-2">
      <MultiStepFormWizardTopBar
        activeStep={activeStep}
        steps={filteredSteps}
        documentVersion={documentVersion}
        nextStep={() => handleNextStep(activeStep, filteredSteps)}
        prevStep={() => handlePrevStep(activeStep, filteredSteps)}
        leftButton={
          previousPath !== "" && previousPathButtonText !== ""
            ? {
                onClick: () => navigate(previousPath),
                text: previousPathButtonText,
              }
            : {
                onClick: () =>
                  navigate(
                    fillRouteWithSlugs(ROUTES.DEVICE_OVERVIEW, {
                      [ROUTE_SLUGS.ORG_ID]: orgId,
                      [ROUTE_SLUGS.DEVICE_ID]: deviceId,
                    })
                  ),
                text: returnToRoadmapText,
              }
        }
      />
      <div className="flex flex-1 flex-col gap-y-2">
        <div className="flex">
          <div className="flex flex-1 flex-col">
            <Typography variant="body1">
              {"Document Assistant: " + docConfig.docName}
            </Typography>
            <ProgressBubbles
              filteredSteps={filteredSteps}
              activeStep={activeStep}
              documentVersion={documentVersion}
            />
          </div>
        </div>

        {/* Hidden is needed to make slide relative to container work https://github.com/mui/material-ui/issues/31977#issuecomment-1253487009 */}
        <div className="flex flex-1 overflow-hidden" ref={containerRef}>
          {filteredSteps.map((step) => (
            <Step
              user={user}
              templateId={templateId}
              containerRef={containerRef}
              activeStep={activeStep}
              device={device}
              documents={documents}
              documentVersion={documentVersion}
              key={step.id}
              step={step}
              steps={filteredSteps}
            />
          ))}
        </div>
      </div>
      <UpdateDeviceDescriptionModal device={device} />
    </div>
  );
};

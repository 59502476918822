import {
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import React, { useEffect } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouteObject,
  useLocation,
} from "react-router-dom";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { NavigateToHomeOrReturnTo } from "src/pages/NavigateToHomeOrReturnTo";
import { MainLayout, MultiStepForm } from "../components";
import config, { ROUTES } from "../config";
import {
  AuthPage,
  CheckoutSuccess,
  Dashboard,
  ErrorPage,
  HelpCenter,
  HelpCenterArticle,
  Loading,
  Organization,
  Roadmap,
  Subscription,
} from "../pages";
import QMS from "../pages/QMS";
import { authHelper } from "../stores/helpers";
type ProtectedRouteProps = {
  component: any;
  [key: string]: any;
};

const ProtectedRoute = ({ component, ...args }: ProtectedRouteProps) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  // const params = useParams();
  // const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      authHelper.setAccessTokenSilently(getAccessTokenSilently);

      // if (!params.orgId || !uuidValidate(params.orgId)) navigate("/");
      // const user = await getUserAsync();

      // Navigate to home if user is not in organization
      // if (
      //   !user.organizationMemberships.some(
      //     (orgMember) => orgMember.organizationId === params.orgId
      //   )
      // ) {
      //   const homeOrgId = getUserHomeOrg(user)?.id;
      //   navigate(
      //     homeOrgId
      //       ? fillRouteWithSlugs(ROUTES.DEVICE_MANAGEMENT, {
      //           [ROUTE_SLUGS.ORG_ID]: homeOrgId,
      //         })
      //       : "/"
      //   );
      // }
    }
  }, [isAuthenticated]);

  // if (isLoading) return <Loading />;

  const Component = withAuthenticationRequired(component, {
    ...args,
    onRedirecting: () => <Loading />,
  });

  return <Component />;
};

const GENERAL_ROUTES: RouteObject[] = [
  {
    path: ROUTES.MANAGE_ORGANIZATION,
    element: <Organization />,
  },
  {
    path: "",
    element: <NavigateToHomeOrReturnTo />,
  },
  {
    path: ROUTES.DEVICE_MANAGEMENT,
    element: <Dashboard />,
  },
  {
    path: ROUTES.DEVICE_OVERVIEW,
    element: <Roadmap />,
  },
  { path: ROUTES.QMS, element: <QMS /> },
  { path: ROUTES.QMS_OPEN_DOC, element: <QMS /> },
  { path: ROUTES.QMS_OPEN_FILE, element: <QMS /> },
  { path: ROUTES.TEMPLATE_ASSISTANT, element: <MultiStepForm /> },
  {
    path: ROUTES.SUBSCRIPTION,
    element: <Subscription />,
  },
  {
    path: ROUTES.CHECKOUT_SUCCESS,
    element: <CheckoutSuccess />,
  },
];

const SaveLocationWrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) {
      const currentPath = `${location.pathname}${location.search}`;
      sessionStorage.setItem("returnTo", currentPath);
    }
  }, [location, isAuthenticated]);

  return <>{children}</>;
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Auth0Provider
        domain={config.auth.issuerBaseUrl}
        clientId={config.auth.clientId}
        audience={config.auth.audience}
        redirectUri={window.location.origin + ROUTES.CALLBACK}
        cacheLocation="localstorage"
      >
        <Outlet />
      </Auth0Provider>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to={ROUTES.LOGIN} replace />,
      },
      {
        path: ROUTES.CALLBACK,
        element: <NavigateToHomeOrReturnTo />,
      },
      {
        path: ROUTES.LOGIN,
        element: <AuthPage type="login" />,
      },
      {
        path: ROUTES.SIGNUP,
        element: <AuthPage type="signup" />,
      },
      {
        path: ROUTES.HELP_CENTER,
        element: <HelpCenter />,
      },
      { path: ROUTES.HELP_CENTER_ARTICLE, element: <HelpCenterArticle /> },
      {
        path: `/${ROUTE_SLUGS.ORG_ID}`,
        element: (
          <SaveLocationWrapper>
            <MainLayout />
          </SaveLocationWrapper>
        ),
        children: [
          {
            path: "",
            element: <ProtectedRoute component={Outlet} />,
            children: GENERAL_ROUTES.map((route) => ({
              path: route.path,
              element: route.element,
              loader: route.loader || undefined,
            })),
          },
        ],
      },
    ],
  },
]);

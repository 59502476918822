import { useGetDevice, useGetDocuments, useGetUser } from "@hooks";
import { Document, DocumentVersion } from "@models";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { ArrowDropDown } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { UPGRADE_PLAN_TOOLTIP } from "src/utils/consts";
import {
  getDocVersionRevisionNumber,
  getDocVersionsWithStatus,
} from "../../../../../utils";
import SelectableOption from "./SelectableOption";

const DocVersionDropDown = ({
  selectedVersion,
  onSelectDocVersion,
  showStatus = false,
  isDocEnabled = true,
  isMultiInstanceDoc = false,
  document,
}: {
  selectedVersion: DocumentVersion;
  onSelectDocVersion?: (doc: DocumentVersion) => void;
  showStatus?: boolean;
  isDocEnabled?: boolean;
  isMultiInstanceDoc?: boolean;
  document: Document;
}) => {
  const { data: user } = useGetUser();
  const { orgId = "" } = useParams();
  const { data: documents } = useGetDocuments(orgId, document.deviceId);
  const { data: device } = useGetDevice({ orgId, deviceId: document.deviceId });
  const [docVersionDropdownOpen, setDocVersionDropdownOpen] = useState(false);

  if (!user || !device || !documents) {
    // captureSentryMessage("User not found to display docVersionDropdown");
    return null;
  }

  const docVersionsWithStatus = getDocVersionsWithStatus({
    document,
    user,
    documents: documents,
    orgId,
    device,
  });

  return (
    <Tooltip
      title={UPGRADE_PLAN_TOOLTIP}
      {...(isDocEnabled && { open: false })}
    >
      <div>
        <ClickAwayListener
          onClickAway={() => isDocEnabled && setDocVersionDropdownOpen(false)}
        >
          <div className="relative z-10	">
            <div
              className={`p-x-2 flex ${!isDocEnabled ? "pointer-events-none" : "cursor-pointer"} items-center justify-between ${isMultiInstanceDoc ? "rounded-r-md" : "rounded-md"} border border-solid border-gray-300 px-2 py-1.5 hover:bg-zinc-100`}
              onClick={() => {
                setDocVersionDropdownOpen((prev) => !prev);
              }}
            >
              <>
                <SelectableOption
                  label={`Revision ${getDocVersionRevisionNumber(
                    selectedVersion,
                    document.versions
                  )}`}
                  type={
                    docVersionsWithStatus.find(
                      (v) => v.id === selectedVersion.id
                    )?.status
                  }
                  showStatus={showStatus}
                  disabled={!isDocEnabled}
                />
                <ArrowDropDown fontSize="small" />
              </>
            </div>
            <div
              className={`mt-1 flex  min-w-max flex-col overflow-auto rounded-md border border-solid border-slate-300 bg-color-bg shadow-md ${docVersionDropdownOpen ? "absolute" : "hidden"}`}
            >
              {isDocEnabled &&
                docVersionsWithStatus
                  .sort((a, b) => (a.revision < b.revision ? 1 : -1))
                  .filter((version) => version.status !== "AVAILABLE")
                  .map((version) => (
                    <div
                      className={`flex cursor-pointer items-center p-2 hover:bg-slate-100 ${selectedVersion.id === version.id && "bg-slate-200"}`}
                      onClick={() => {
                        onSelectDocVersion && onSelectDocVersion(version);
                        setDocVersionDropdownOpen((prev) => !prev);
                      }}
                      key={`${version.id}-selectable-option`}
                    >
                      <SelectableOption
                        label={`Revision ${version.revision} - ${moment(version.createdAt).fromNow()}`}
                        type={version.status}
                        showStatus={showStatus}
                      />
                    </div>
                  ))}
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </Tooltip>
  );
};

export default DocVersionDropDown;

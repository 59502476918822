import { SvgIconComponent } from "@mui/icons-material";

export const DemoDeviceButton = ({
  title,
  description,
  onClick,
  Icon,
}: {
  title: string;
  description: string;
  onClick: () => void;
  Icon: SvgIconComponent;
}) => {
  return (
    <div
      className={
        "w-full flex items-center gap-x-4 justify-start p-3 rounded-lg border border-gray-200 border-solid min-h-12 hover:border-color-primary-medium hover:bg-gray-100 hover:shadow-sm transition-all duration-200 cursor-pointer"
      }
      onClick={onClick}
    >
      <div className="rounded-lg p-2 flex flex-none items-center justify-center h-12 w-12 bg-blue-100">
        <Icon className="h-3/4 w-3/4" color="primary" />
      </div>
      <div className="flex flex-col items-start">
        <span className="font-medium">{title}</span>
        <span className="text-sm text-gray-600 line-clamp-3">{description}</span>
      </div>
    </div>
  );
};

import { useCallback, useEffect, useRef } from "react";

export function useDebounce<T>(
  delay: number,
  onDebounce: (value: T) => void
): (value: T) => void {
  const timerRef = useRef<NodeJS.Timeout>();

  const debouncedCallback = useCallback(
    (newValue: T) => {
      // Clear existing timer
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      // Set new timer
      timerRef.current = setTimeout(() => {
        onDebounce(newValue);
      }, delay);
    },
    [delay, onDebounce]
  );

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return debouncedCallback;
}

import { SvgIconComponent } from "@mui/icons-material";

import { Button, CircularProgress, Typography, styled } from "@mui/material";

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const BaseAnimation = ({
  Icon,
  text,
  subText,
  buttonText,
  ButtonIcon,
  onChangeInput,
  onClick,
  isLoading,
  animated = true,
  disabled,
}: {
  Icon: SvgIconComponent;
  text?: string;
  subText?: string;
  buttonText?: string;
  ButtonIcon: SvgIconComponent;
  onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  onClick?: () => void;
  animated?: boolean;
  disabled?: boolean;
}) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4 h-full">
      <Icon
        className={`${animated && "animate-bounce"} text-[8rem] opacity-20 -z-1`}
      />
      <Typography variant="h2" className="opacity-40 text-center px-4">
        {text}
      </Typography>
      {subText && (
        <Typography
          variant="subtitle1"
          textAlign="center"
          className="-mt-2 opacity-40 w-4/5"
        >
          {subText}
        </Typography>
      )}
      {buttonText && (
        <Button
          component="label"
          variant="contained"
          disabled={isLoading || disabled}
          tabIndex={-1}
          {...(onClick && { onClick })}
          endIcon={
            isLoading ? (
              <CircularProgress color="inherit" size={16} />
            ) : (
              <ButtonIcon />
            )
          }
        >
          {buttonText}
          {!onClick && (
            <VisuallyHiddenInput
              type="file"
              onChange={onChangeInput}
              accept=".pdf, .txt, .docx, .png, .jpeg"
              max={1}
            />
          )}
        </Button>
      )}
    </div>
  );
};

import GenericButton from "src/components/GenericButton/GenericButton";
import OnboardingTooltip from "src/components/OnboardingTooltip/OnboardingTooltip";
import { UPGRADE_PLAN_TOOLTIP } from "../../../../../utils";

export const ApproveButton = ({
  hasOutstandingAction,
  onDocInfoButtonClick,
  isDocEnabled,
}: {
  hasOutstandingAction: boolean;
  onDocInfoButtonClick: () => void;
  isDocEnabled: boolean;
}) => {
  return (
    <span className="relative inline-flex">
      <OnboardingTooltip
        databaseId="approvalTooltipSeen"
        title="Approval time!"
        subtitle="Congrats on generating your doc 🎉 Now get some coworkers to approve it."
        buttonText="Got it!"
        placement="left-start"
      >
        <GenericButton
          isDisabled={!isDocEnabled}
          onClick={onDocInfoButtonClick}
          tooltipContent={
            isDocEnabled ? (
              <>
                Toggle approval <kbd>A</kbd>
              </>
            ) : (
              UPGRADE_PLAN_TOOLTIP
            )
          }
          text="Approval"
          size="medium"
          variant="outlined"
          hasOutstandingAction={isDocEnabled && hasOutstandingAction}
        />
      </OnboardingTooltip>
    </span>
  );
};

export default ApproveButton;

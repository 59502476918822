import { ASSISTANT_CONFIG, FunctionalGroupNames } from "@config";
import { Device, Document, File, TEMPLATE_TYPE, User } from "@models";
import {
  EditOutlined,
  HistoryEdu,
  InsertDriveFileOutlined,
  LockOutlined,
  NoteAddOutlined,
  TaskOutlined,
} from "@mui/icons-material";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { DocumentStatus, FunctionalGroup, Leaf } from "@types";
import { fillRouteWithSlugs, getDocTypeStatus } from "@utils";
import DocExplorerLeaf from "src/components/DocExplorer/components/DocExplorerLeaf";
import { ROUTE_SLUGS, ROUTES } from "src/config/navigation/routes";

export const HighlightedText = ({
  text,
  highlight,
}: {
  text: string;
  highlight: string;
}) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }

  const parts = text.split(new RegExp(`(${highlight})`, "gi"));

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} style={{ backgroundColor: "#fff3b0" }}>
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        )
      )}
    </span>
  );
};

const buildLeafFromDoc = (
  docStatus: DocumentStatus,
  document: Document,
  orgId: string,
  deviceId: string,
  searchTerm?: string
): Leaf | null => {
  const docStatusDictionaryToLeaf: Partial<
    Record<
      DocumentStatus,
      Pick<Leaf, "tooltipText" | "Icon" | "color" | "disabled">
    >
  > | null = {
    [DocumentStatus.AVAILABLE]: {
      tooltipText: "Static Document",
      Icon: NoteAddOutlined,
      color: "inherit",
    },
    [DocumentStatus.DRAFT]: {
      tooltipText: "Draft",
      Icon: EditOutlined,
      color: "warning",
    },
    [DocumentStatus.UNAPPROVED]: {
      tooltipText: "Unapproved",
      Icon: HistoryEdu,
      color: "primary",
    },
    [DocumentStatus.LIVE]: {
      tooltipText: "Live",
      Icon: TaskOutlined,
      color: "success",
    },
    [DocumentStatus.LOCKED]: {
      tooltipText: "Locked",
      Icon: LockOutlined,
      color: "inherit",
      disabled: true,
    },
  };

  if (!docStatusDictionaryToLeaf[docStatus]) {
    return null;
  }

  return {
    id: document.name,
    label: ASSISTANT_CONFIG[document.name as TEMPLATE_TYPE].docName,
    tooltipText: docStatusDictionaryToLeaf[docStatus].tooltipText,
    Icon: docStatusDictionaryToLeaf[docStatus].Icon,
    color: docStatusDictionaryToLeaf[docStatus].color,
    link: fillRouteWithSlugs(ROUTES.QMS_OPEN_DOC, {
      [ROUTE_SLUGS.ORG_ID]: orgId,
      [ROUTE_SLUGS.DEVICE_ID]: deviceId,
      [ROUTE_SLUGS.TEMPLATE_ID]: document.name,
    }),
    ...(searchTerm && {
      highlightedText: (
        <HighlightedText
          text={ASSISTANT_CONFIG[document.name as TEMPLATE_TYPE].docName}
          highlight={searchTerm}
        />
      ),
    }),
    disabled: docStatusDictionaryToLeaf[docStatus].disabled || false,
  };
};

export const getFunctionalGroupsDocsToTreeItems = ({
  documents,
  filteredBySearchDocs,
  user,
  orgId,
  deviceId,
  device,
  searchTerm,
}: {
  documents: Document[];
  filteredBySearchDocs: Document[];
  user: User;
  orgId: string;
  deviceId: string;
  device: Device;
  searchTerm?: string;
}) => {
  const functionalGroupTosDocMap: Partial<Record<FunctionalGroup, Leaf[]>> = {};
  Object.values(filteredBySearchDocs).forEach((document) => {
    const functionalGroup =
      ASSISTANT_CONFIG[document.name as TEMPLATE_TYPE].functionalGroup;
    const docStatus = getDocTypeStatus({
      documents: documents,
      templateId: document.name as TEMPLATE_TYPE,
      user,
      orgId,
      device,
    });
    const leaf = buildLeafFromDoc(
      docStatus,
      document,
      orgId,
      deviceId,
      searchTerm
    );
    const currentFunctionalGroup = functionalGroupTosDocMap[functionalGroup];

    if (leaf) {
      if (!currentFunctionalGroup) {
        functionalGroupTosDocMap[functionalGroup] = [leaf];
      } else if (!currentFunctionalGroup?.find((d) => d.label === leaf.label)) {
        // Do not add the same document name when more then one record exists
        currentFunctionalGroup?.push(leaf);
      }
    }
  });
  return functionalGroupTosDocMap;
};

export const buildLeafFromFile = (
  file: File,
  orgId: string,
  deviceId: string,
  searchTerm?: string
): Leaf => {
  return {
    id: file.id,
    label: file.filename,
    tooltipText: "Uploaded File",
    Icon: InsertDriveFileOutlined,
    color: "inherit",
    link: fillRouteWithSlugs(ROUTES.QMS_OPEN_FILE, {
      [ROUTE_SLUGS.ORG_ID]: orgId,
      [ROUTE_SLUGS.DEVICE_ID]: deviceId,
      [ROUTE_SLUGS.FILE_ID]: file.id,
    }),
    ...(searchTerm && {
      highlightedText: (
        <HighlightedText text={file.filename} highlight={searchTerm} />
      ),
    }),
  };
};

export const getFileLeaves = (
  files: File[] | undefined,
  orgId: string,
  deviceId: string,
  searchTerm?: string
): Leaf[] => {
  if (!files) return [];

  return files.map((file) =>
    buildLeafFromFile(file, orgId, deviceId, searchTerm)
  );
};

export const generateTree = ({
  documents,
  deviceId,
  filteredBySearchDocs,
  user,
  searchTerm,
  orgId,
  files,
  device,
}: {
  documents: Document[];
  deviceId: string;
  filteredBySearchDocs: Document[];
  user: User;
  searchTerm: string;
  orgId: string;
  files: File[] | undefined;
  device: Device;
}) => {
  const functionalGroupsToTreeItems = getFunctionalGroupsDocsToTreeItems({
    documents,
    filteredBySearchDocs,
    user,
    orgId,
    deviceId,
    device,
    searchTerm,
  });

  const filesLeafs = getFileLeaves(files, orgId, deviceId, searchTerm);

  if (filesLeafs.length > 0) {
    functionalGroupsToTreeItems["UNGROUPED"] = [
      ...(functionalGroupsToTreeItems["UNGROUPED"] || []),
      ...filesLeafs,
    ];
  }

  return Object.entries(functionalGroupsToTreeItems)
    .sort(([groupA], [groupB]) => groupA.localeCompare(groupB))
    .map(([functionalGroup, item]) => {
      return (
        <TreeItem
          key={functionalGroup}
          itemId={functionalGroup}
          label={FunctionalGroupNames[functionalGroup as FunctionalGroup]}
        >
          {item
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((leaf, index) => {
              return (
                <DocExplorerLeaf
                  {...leaf}
                  key={`${leaf.label}-${index}-link`}
                />
              );
            })}
        </TreeItem>
      );
    });
};

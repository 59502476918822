import {
  useDocEngine,
  useGetAllFilesInOrg,
  useGetDevice,
  useGetFile,
  useGetUser,
} from "@hooks";
import { Document, File, TEMPLATE_TYPE } from "@models";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { FileViewer } from "src/components/DocViewer/components/FileViewer";
import { templatesWithUpload } from "src/config";
import { DocProgress } from "../../types";
import { DocumentViewerSkeleton } from "../Skeletons";
import { GeneratedDocViewer } from "./components/GeneratedDocViewer";
import NoDocAnimation from "./components/NoDocAnimation";
import { FileDocViewer } from "./components/fileDocViewer/FileDocViewer";

const DocViewer = ({
  documents,
  deviceId,
  templateId,
  docProgress,
  isLoading,
}: {
  documents: Document[] | undefined;
  deviceId: string;
  templateId: TEMPLATE_TYPE | undefined;
  docProgress: DocProgress | undefined;
  isLoading: boolean;
}) => {
  const docEngine = useDocEngine(deviceId);

  const { orgId = "", fileId = "" } = useParams();
  const { data: files, isLoading: areFilesLoading } = useGetAllFilesInOrg({
    orgId,
    deviceId,
  });
  const { data: device } = useGetDevice({ orgId, deviceId });
  const { data: user } = useGetUser();
  const currentFile = files?.find((f: File) => f.id === fileId);
  const { data: fileBlob, isLoading: isFileLoading } = useGetFile({
    orgId,
    fileId,
  });

  const [isButtonActionLoading, setIsButtonActionLoading] = useState(false);

  if (templateId && templatesWithUpload.includes(templateId)) {
    return (
      <FileDocViewer
        deviceId={deviceId}
        templateId={templateId}
        key={templateId}
      />
    );
  }

  if (fileBlob) {
    return (
      <FileViewer
        key={fileId}
        fileId={fileId}
        fileBlob={fileBlob.data}
        file={currentFile}
      />
    );
  }

  if (
    isLoading ||
    !documents ||
    !device ||
    isFileLoading ||
    areFilesLoading ||
    !user
  ) {
    return <DocumentViewerSkeleton />;
  }

  if (!templateId || !docProgress) return <NoDocAnimation type="NoDoc" />;

  if (docProgress?.enabled === false) {
    <NoDocAnimation type="LockedDoc" />;
  }

  if (docProgress?.noInstance === true) {
    return (
      <NoDocAnimation
        type="NoInstance"
        isLoading={isButtonActionLoading}
        buttonOnClick={async () => {
          setIsButtonActionLoading(true);
          await docEngine.navigateToWizardOrQMS({
            templateId,
            device,
            documents,
            user,
          });
          setIsButtonActionLoading(false);
        }}
      />
    );
  }

  return <GeneratedDocViewer deviceId={deviceId} templateId={templateId} />;
};

export default DocViewer;

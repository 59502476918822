import config from "./config";
import ARTICLES from "./helpcenter/articles";
import { ROADMAP_CARD_ID } from "./roadmap";

export type Config = typeof config;
export default config;
export * from "./assistant";
export { config as ASSISTANT_CONFIG, templatesWithUpload } from "./assistant";
export * from "./helpcenter/articles";
export { ROUTES } from "./navigation/routes";
export * from "./request-keys";
export * from "./roadmap";
export { theme } from "./theme";
export { ARTICLES };

export const ALWAYS_AVAILABLE_CARDS = [
  ROADMAP_CARD_ID.PURCHASING_SALES_AND_SUPPLIERS,
  ROADMAP_CARD_ID.ESTABLISH_QUALITY_MANAGEMENT_SYSTEM,
  ROADMAP_CARD_ID.COMPUTER_SYSTEM_VALIDATION,
  ROADMAP_CARD_ID.TRAINING,
  ROADMAP_CARD_ID.AUDITS_AND_REVIEW,
  ROADMAP_CARD_ID.DEVICE_BASIC_CHARACTERISTICS,
  ROADMAP_CARD_ID.DESIGN_AND_DEVELOPMENT_PLANS,
  ROADMAP_CARD_ID.USER_NEEDS,
  ROADMAP_CARD_ID.RISK_MANAGEMENT,
];

export const DEVICE_CHARACTERISTICS_STATEMENTS = {
  hasHumanContact: "The product comes in contact with the human body.",
  hasSoftware: "The product uses software.",
  isSoftwareOnly: "The product is a software only application.",
  incorporatesAI: "The product incorporates artificial intelligence.",
  hasHardware: "The product has hardware.",
  isImplantable: "The product is implantable.",
};

export const imageElementsIds = ["software-architecture-diagram"];

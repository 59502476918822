import { MoreVert } from "@mui/icons-material";
import { Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { AnswerItem, StepValue } from "@types";
import React from "react";

interface Props {
  fieldId: string;
  accordionIdx: number;
  list: AnswerItem[];
  patchItem: ({ answer }: { answer: AnswerItem }) => void;
  onChange: (value: StepValue) => void;
}

export const AccordionListMenu: React.FC<Props> = ({
  fieldId,
  accordionIdx,
  list,
  patchItem,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const moreOptionsOpen = Boolean(anchorEl);

  const handleClickMoreOptions = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOptions = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div className="flex items-center">
      <div>
        <IconButton
          aria-controls={moreOptionsOpen ? `${fieldId}-menu` : undefined}
          aria-haspopup={true}
          aria-expanded={moreOptionsOpen ? true : undefined}
          id={`${fieldId}-button`}
          onClick={handleClickMoreOptions}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id={`${fieldId}-menu`}
          anchorEl={anchorEl}
          open={moreOptionsOpen}
          onClose={handleCloseMoreOptions}
          MenuListProps={{
            [`aria-labelledby`]: `${fieldId}-button`,
          }}
          disablePortal
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Typography color="error" className="px-4">
            Danger Zone
          </Typography>
          <Divider className="my-2" />
          <MenuItem
            id={`${fieldId}-delete`}
            onClick={(event) => {
              handleClickMoreOptions(event);
              const updatedItem = list[accordionIdx];
              const isItemUploaded = updatedItem.createdBy !== "";

              if (isItemUploaded) {
                const updatedList = list.map((item, index) => {
                  if (index === accordionIdx) {
                    return { ...item, archived: true };
                  }
                  return item;
                });
                patchItem({ answer: updatedList[accordionIdx] });
                onChange({ answerItems: updatedList });
              } else {
                const updatedList = list.filter(
                  (_, index) => index !== accordionIdx
                );
                onChange({ answerItems: updatedList });
              }
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};
export default AccordionListMenu;

import { useGetAllFilesInOrg, useGetDevice, useGetUser } from "@hooks";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { FunctionalGroup } from "@types";
import { docsFilteredBySearch } from "@utils";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MoreOptions } from "src/components/DocExplorer/components/MoreOptions";
import { SearchExplorer } from "src/components/DocExplorer/components/SearchExplorer";
import { generateTree } from "src/components/DocExplorer/utils";
import { ASSISTANT_CONFIG } from "../../config";
import { Document, File } from "../../stores/models";
import { TEMPLATE_TYPE } from "../../stores/models/template-document";
import TreeViewSkeleton from "./TreeViewSkeleton";

export const DocExplorer = ({
  deviceId,
  templateId,
  className = "",
  documents,
  isLoading = false,
}: {
  deviceId: string;
  templateId?: TEMPLATE_TYPE;
  className?: string;
  documents: Document[] | undefined;
  isLoading?: boolean;
}) => {
  const { orgId = "", fileId } = useParams();
  const { data: user } = useGetUser();
  const { data: device } = useGetDevice({ orgId, deviceId });
  const { data: uploadedFiles } = useGetAllFilesInOrg({
    orgId,
    deviceId,
  });

  const [search, setSearch] = useState("");

  const initialExpandedItems = templateId
    ? [ASSISTANT_CONFIG[templateId].functionalGroup]
    : fileId
      ? ["UNGROUPED"]
      : [];

  const [expandedItems, setExpandedItems] =
    useState<string[]>(initialExpandedItems);

  const filteredBySearchDocs = docsFilteredBySearch(documents, search);
  const filteredBySearchFiles = uploadedFiles?.filter((file: File) =>
    file.filename.includes(search)
  );

  useEffect(() => {
    if (!documents) return;

    if (!search.trim()) {
      setExpandedItems(initialExpandedItems);
      return;
    }

    const matchingGroups = filteredBySearchDocs.map(
      (doc) => ASSISTANT_CONFIG[doc.name as TEMPLATE_TYPE].functionalGroup
    );

    // The uploaded are not in the documents array, so we need to add them to the matchingGroups
    uploadedFiles?.forEach((file: File) => {
      if (
        file.filename.includes(search) &&
        !matchingGroups.includes(FunctionalGroup.UNGROUPED)
      ) {
        matchingGroups.push(FunctionalGroup.UNGROUPED);
      }
    });

    setExpandedItems([...new Set(matchingGroups)]);
  }, [search, documents]);

  const showLoadingSkeleton = isLoading;

  const showEmptyState = !isLoading && documents && documents.length === 0;
  const showNoMatches =
    !isLoading &&
    search.trim() !== "" &&
    filteredBySearchDocs.length === 0 &&
    (!filteredBySearchFiles || filteredBySearchFiles.length === 0);

  return (
    <div
      className={`min-h-[30rem] min-w-[16rem] overflow-auto rounded border border-solid border-slate-300 ${className}`}
    >
      <div className="flex items-center gap-2 border-0 border-b border-solid border-slate-300 p-2 pl-3">
        <div className="flex items-center w-full">
          <SearchExplorer search={search} setSearch={setSearch} />
          <MoreOptions
            deviceId={deviceId}
            setExpandedItems={setExpandedItems}
          />
        </div>
      </div>
      <div className="py-2">
        {showLoadingSkeleton && <TreeViewSkeleton />}

        {showEmptyState && (
          <div className="p-4 text-center text-gray-500">
            You don't have any documents yet. 🙁
          </div>
        )}

        {showNoMatches && (
          <div className="p-4 text-center text-gray-500">
            Oops, no matches found. 🧐
          </div>
        )}

        {!showLoadingSkeleton &&
          !showEmptyState &&
          !showNoMatches &&
          user &&
          device &&
          documents && (
            <SimpleTreeView
              aria-label="file system navigator"
              expandedItems={expandedItems}
              onExpandedItemsChange={(_, ids) => setExpandedItems(ids)}
              selectedItems={templateId || fileId || ""}
            >
              {generateTree({
                documents,
                deviceId,
                filteredBySearchDocs,
                user,
                searchTerm: search,
                orgId,
                files: filteredBySearchFiles,
                device,
              })}
            </SimpleTreeView>
          )}
      </div>
    </div>
  );
};

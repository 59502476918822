import { UserUiStateKey } from "@models";
import { Button, Tooltip, TooltipProps, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import useUiStateItemLock from "src/hooks/useUiStateItemLock";

/**
 * Tooltip component for user tips and onboarding.
 * To add more tooltips:
 * 1. Add a new tooltip to the UserUiState model in the backend/database
 * 2. Add a new key to the UserUiState enum in the frontend
 * 3. Use this component in the UI, with the required display logic
 */
const OnboardingTooltip = observer(
  ({
    children,
    databaseId,
    title,
    subtitle,
    buttonText = "Got it!",
    placement,
  }: {
    children: React.ReactElement;
    databaseId: UserUiStateKey; // used to identify the tooltip state in the database
    title: string;
    subtitle: string;
    buttonText: string;
    placement?: TooltipProps["placement"];
  }) => {
    const { closeItem: closeTooltip, itemIsOpen: tooltipIsOpen } =
      useUiStateItemLock(databaseId);

    return (
      <Tooltip
        open={tooltipIsOpen}
        onClick={closeTooltip}
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "rgb(7, 78, 232)",
              padding: "6px",
              borderRadius: "8px",
            }}
            onClick={closeTooltip}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#FFFFFF",
                fontWeight: 600,
                marginBottom: "4px",
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "rgba(255, 255, 255, 0.9)",
                marginBottom: 2,
              }}
            >
              {subtitle}
            </Typography>
            <Button
              sx={{
                pointerEvents: "auto",
                alignSelf: "end",
                color: "#FFFFFF",
                borderColor: "rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  borderColor: "#FFFFFF",
                  bgcolor: "rgba(255, 255, 255, 0.1)",
                },
              }}
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                closeTooltip();
              }}
            >
              {buttonText}
            </Button>
          </div>
        }
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "rgb(7, 78, 232)",
              "& .MuiTooltip-arrow": {
                color: "rgb(7, 78, 232)",
              },
            },
          },
        }}
        {...(placement ? { placement } : {})}
      >
        <div>{children}</div>
      </Tooltip>
    );
  }
);

export default OnboardingTooltip;

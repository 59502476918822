import {
  Device,
  Document,
  DocumentAnswer,
  DocumentAnswerType,
  DocumentVersion,
  DocumentVersionApprover,
  DocumentVersionApproverStatus,
  TEMPLATE_TYPE,
  User,
} from "@models";
import {
  EditOutlined,
  FileCopyOutlined,
  HistoryEdu,
  LockOutlined,
  NoteAddOutlined,
  TaskOutlined,
} from "@mui/icons-material";
import { ChipOwnProps } from "@mui/material";
import { isTaskVisible } from "@utils";
import { chain, orderBy, uniq } from "lodash";
import { isUserEntitledToDoc } from "src/utils/entitlements";
import { allStepsCompleted } from "src/utils/step";
import { getOrgFromUser } from "src/utils/user";
import { Entries } from "type-fest";
import {
  ASSISTANT_CONFIG,
  ROADMAP_TASKS,
  templatesWithUpload,
} from "../config";
import {
  AnswerItem,
  DataKeyWithValue,
  DocStatus,
  DocumentDataKey,
  DocumentStatus,
  DocVersionWithStatusAndRevision,
  EnabledDocStatus,
  FunctionalGroup,
  TemplateAssistant,
  TemplateElement,
} from "../types";

export const getDocTemplate = (templateId: string) => {
  if (Object.values(TEMPLATE_TYPE).includes(templateId as TEMPLATE_TYPE)) {
    return ASSISTANT_CONFIG[templateId as TEMPLATE_TYPE];
  } else {
    return undefined;
  }
};
/**
 * Get all template types where the respective documents are not completed but specified as required context by one of the elements in in given template type
 */
const getIncompleteContexts = (
  templateType: TEMPLATE_TYPE,
  documents: Document[],
  user: User,
  orgId: string,
  device: Device
): TEMPLATE_TYPE[] => {
  const { elements } = ASSISTANT_CONFIG[templateType];

  const configOfRelatedDocuments: TEMPLATE_TYPE[] = uniq(
    elements
      .map((e) => e.context || [])
      .flat()
      .map(
        (dataKey) =>
          (Object.entries(ASSISTANT_CONFIG).find(
            ([k, v]) =>
              k !== templateType &&
              v.elements.map((e) => e.id).includes(dataKey)
          )?.[0] as TEMPLATE_TYPE) || []
      )
      .flat()
  );

  // Check if every document that is mentioned inside the context config in the TemplateAssistantConfig is present in the documents array and marked as completed
  return configOfRelatedDocuments.filter((documentId) => {
    const document = documents.find((d) => d.name === documentId);
    if (!document) return true;

    const docStatus = getDocTypeStatus({
      templateId: documentId,
      documents,
      user: user,
      orgId: orgId,
      device,
    });

    if (
      docStatus === "LOCKED" ||
      docStatus === "DRAFT" ||
      docStatus === "AVAILABLE"
    ) {
      return true;
    }

    return false;
  });
};

export const checkIfDocumentIsBeingEdited = ({
  selectedDoc,
  selectedDocVersion,
  generatedDoc,
  device,
  documents,
}: {
  selectedDoc: Document | undefined | null;
  selectedDocVersion: DocumentVersion | undefined | null;
  generatedDoc: { docVersionId: string; data: string } | undefined;
  device: Device;
  documents: Document[];
}) =>
  selectedDoc &&
  selectedDocVersion &&
  !generatedDoc &&
  !isDocumentCompleted({
    document: selectedDoc,
    device,
    documents,
    documentVersion: selectedDocVersion,
  });

export const canDocHaveMultipleInstances = (templateId: TEMPLATE_TYPE) =>
  ASSISTANT_CONFIG[templateId].docType === "RCD";

export const checkIfDocHasDraft = (
  document: Document,
  user: User,
  documents: Document[],
  orgId: string,
  device: Device
) =>
  getDocVersionsWithStatus({
    document,
    user,
    documents,
    orgId,
    device,
  }).some((s) => s.status === "DRAFT");

/**
 * Get all dependencies that are not completed
 * IMPORTANT this does not check if required context is completed or other dependencies.
 * Refer to * isDocumentUnlocked for that.
 * @param templateType  TEMPLATE_TYPE
 * @param documents Document[]
 * @returns TEMPLATE_TYPE[]
 */
export const getIncompleteDependencies = (
  templateType: TEMPLATE_TYPE,
  documents: Document[]
): TEMPLATE_TYPE[] => {
  const incompleteDependencies: TEMPLATE_TYPE[] = [];

  // Check if every document that is mentioned inside the dependencies in the TemplateAssistantConfig is present in the documents array and marked as completed
  ASSISTANT_CONFIG[templateType].dependencies?.forEach((dependency) => {
    const templateRelatedToDependency = Object.entries(ASSISTANT_CONFIG).find(
      ([_, value]) =>
        value.elements.some((element) => element.id === dependency)
    )?.[0];

    if (!templateRelatedToDependency) {
      throw new Error(
        `Could not find template related to dependency ${dependency}`
      );
    }

    const isDependencyDocumentComplete =
      documents.find((d) => d.name === templateRelatedToDependency)?.versions[0]
        ?.readyForApproval === true;

    if (!isDependencyDocumentComplete) {
      incompleteDependencies.push(templateRelatedToDependency as TEMPLATE_TYPE);
    }
  });

  return incompleteDependencies;
};

export const isDocumentHidden = (
  templateId: TEMPLATE_TYPE,
  documents: Document[],
  device: Device
) => {
  if (ASSISTANT_CONFIG[templateId].hidden === true) {
    return true;
  }
  // get the visibility of the document from the tasks config
  const task = ROADMAP_TASKS.find((task) => task.id === templateId);

  if (task?.visibilityCondition) {
    return !isTaskVisible(task, documents, device);
  }

  return false;
};

/**
 * Checks if a document is ready for a user to fill out.
 */
export const isDocumentUnlocked = (
  templateType: TEMPLATE_TYPE,
  documents: Document[],
  user: User,
  orgId: string,
  device: Device
): EnabledDocStatus => {
  const incompleteContexts = getIncompleteContexts(
    templateType,
    documents,
    user,
    orgId,
    device
  );
  const incompleteDependencies = getIncompleteDependencies(
    templateType,
    documents
  );

  const missingDocuments =
    ASSISTANT_CONFIG[templateType].docReferences?.filter(
      (ref) => !documents.find((d) => d.name === ref)
    ) || [];

  // TODO check if list of inputs in prePromptTransformerConfig and element.transformerConfig are present in the documents array and marked as completed
  // ASSISTANT_CONFIG[templateType].elements.forEach((element) => {
  // })
  const incompleteDocuments = uniq([
    ...incompleteContexts,
    ...incompleteDependencies,
    ...missingDocuments,
  ]);

  const isDocumentEnabled = !incompleteDocuments.length;
  return {
    enabled: isDocumentEnabled,
    ...(!isDocumentEnabled && { incompleteDocuments }),
  };
};

/**
 * Checks if a static or non-static document is finished by the user
 */
export const isDocumentCompleted = ({
  document,
  device,
  documents,
  documentVersion,
}: {
  document: Document;
  device: Device;
  documents: Document[];
  documentVersion?: DocumentVersion;
}) => {
  if (documentVersion === undefined) return false;

  const docTemplate = getDocTemplate(document.name);
  //TODO: does this make sense to ask if readyForApproval?
  if (docTemplate !== undefined) {
    if (isStaticallyGeneratedDocument(docTemplate.id)) {
      return true;
    }
  }
  if (!documentVersion.readyForApproval) {
    return false;
  }

  return allStepsCompleted(document, documentVersion, device, documents);
};

/**
 * Takes the answers from the document version and returns an object with the answers as values and the corresponding data keys as keys
 */
export const getDocumentAnswerMap = (documentVersion: DocumentVersion) => {
  const mappedAnswers: Partial<Record<DocumentDataKey, string | AnswerItem[]>> =
    {};

  if (Array.isArray(documentVersion.answers)) {
    documentVersion.answers.forEach(
      ({ answer, element, answerFileId, answerItems }) => {
        if (answerFileId) {
          mappedAnswers[element] = answerFileId;
        } else if (answerItems && answerItems.length > 0) {
          mappedAnswers[element] = answerItems;
        } else {
          mappedAnswers[element] = answer ?? undefined;
        }
      }
    );
  }

  return mappedAnswers;
};

export const getDocumentStepsFromConfig = (
  type: TEMPLATE_TYPE
): TemplateElement[] => {
  return ASSISTANT_CONFIG[type].elements;
};

export const getFilteredSteps = (
  steps: TemplateElement[],
  device: Device,
  availableDataKeys: DataKeyWithValue[]
): TemplateElement[] => {
  return steps.filter((element) => {
    if (element.visibilityCondition) {
      return element.visibilityCondition(availableDataKeys, device);
    }
    return true;
  });
};

export const isStaticallyGeneratedDocument = (
  templateId: TEMPLATE_TYPE
): boolean =>
  !hasDocInputs(templateId) && !ASSISTANT_CONFIG[templateId].userUpload;

export const isStaticAndHasNoDependencies = (templateId: TEMPLATE_TYPE) =>
  isStaticallyGeneratedDocument(templateId) &&
  !ASSISTANT_CONFIG[templateId].dependencies?.length;

export const hasDocInputs = (templateId: TEMPLATE_TYPE): boolean => {
  const templateConfig = ASSISTANT_CONFIG[templateId];
  return (
    templateConfig.elements.filter((e) => !e.hasOwnProperty("entity"))
      .length !== 0
  );
};

export const getElementConfigByDataKey = (
  dataKey: DocumentDataKey
): TemplateElement => {
  const docConfigs = Object.entries(ASSISTANT_CONFIG) as Entries<
    typeof ASSISTANT_CONFIG
  >;
  // Get the document config that contains the element with the dataKey
  const docConfigOfElement = docConfigs.find(([_, config]) =>
    config.elements.some((element) => element.id === dataKey)
  );
  if (!docConfigOfElement) {
    throw new Error(`Could not find document config for dataKey ${dataKey}`);
  }
  // Get the element config that has the dataKey
  const elementConfig = docConfigOfElement[1].elements
    // Filter out the elements that are not TemplateElement
    .filter((e): e is TemplateElement => !e.hasOwnProperty("entity"))
    .find((element) => element.id === dataKey);
  if (!elementConfig) {
    throw new Error(`Could not find element config for dataKey ${dataKey}`);
  }
  return elementConfig;
};

export const loadDocumentReferences = (
  documents: Document[]
): Record<string, string> => {
  const referenceObject: Record<string, string> = {};

  const docConfigs = Object.entries(ASSISTANT_CONFIG) as Entries<
    typeof ASSISTANT_CONFIG
  >;
  docConfigs.forEach(([key]) => {
    const document = documents.find((d) => d.name === key);
    if (!document || !document.versions[0]) {
      referenceObject[`did-${key}`] = "Reference not found.";
      return;
    }
    const documentIdentifier = getDocumentIdentifier(
      key,
      document,
      document.versions[0],
      document.versions
    );

    referenceObject[`did-${key}`] = documentIdentifier;
  });

  return referenceObject;
};

export const getDocumentIdentifier = (
  templateType: TEMPLATE_TYPE,
  document: Document,
  documentVersion: DocumentVersion,
  documentVersions: DocumentVersion[]
) => {
  const templateConfig = ASSISTANT_CONFIG[templateType];
  const { docName, docType, functionalGroup } = templateConfig;
  const documentNumber = document.id;
  const revision = getDocVersionRevisionNumber(
    documentVersion,
    documentVersions
  );
  return `${functionalGroup}-${docType}-${documentNumber}-R${revision}-${docName}`;
};

export const isDocRevisionLive = (
  docRevision: DocumentVersion,
  docVersions: DocumentVersion[]
) => {
  if (typeof docRevision.revision !== "number") return false;

  const allRevisions = chain(docVersions)
    .filter((v) => typeof v.revision === "number")
    .orderBy("revision", "desc")
    .value();

  if (allRevisions.length === 0) return false;

  const isLatestRevision = allRevisions[0]?.id === docRevision.id;
  return isLatestRevision;
};

export const isDocVersionDraft = (
  docVersion: DocumentVersion,
  allDocVersions: DocumentVersion[]
): boolean => {
  if (docVersion.revision !== null) return false;

  const latestRevision = chain(allDocVersions)
    .filter((v) => typeof v.revision === "number")
    .orderBy("revision", "desc")
    .value()[0];

  if (latestRevision && docVersion.createdAt < latestRevision.createdAt)
    return false;

  const allVersionsThatAreNotRevisions = chain(allDocVersions)
    .filter((v) => v.revision === null)
    .orderBy("createdAt", "desc")
    .value();

  if (allVersionsThatAreNotRevisions.length === 0) return false;

  const isLatestVersion =
    allVersionsThatAreNotRevisions[0]?.id === docVersion.id;
  return isLatestVersion;
};

export const isDocRevisionArchived = (
  docVersion: DocumentVersion,
  docVersions: DocumentVersion[]
) => {
  if (typeof docVersion.revision !== "number") return false;

  // If it is not the latest revision then it is archived
  const allRevisions = chain(docVersions)
    .filter((v) => typeof v.revision === "number")
    .orderBy("revision", "desc")
    .value();

  if (allRevisions.length <= 1) return false;

  return allRevisions[0]?.id !== docVersion.id;
};

export const isDocEditable = (doc: Document) => {
  const docTemplate = getDocTemplate(doc.name);

  if (docTemplate !== undefined) {
    const config = ASSISTANT_CONFIG[docTemplate.id];
    if (["TMP", "EXT"].includes(config.docType)) {
      return false;
    } else {
      return true;
    }
  }
  // Default: if the document is not of type TEMPLATE_TYPE then it is not editable
  return false;
};

export const canPrecreateDocumentVersion = (templateId: TEMPLATE_TYPE) =>
  !ASSISTANT_CONFIG[templateId].userUpload;

export const getDocVersionsWithStatus = ({
  document,
  user,
  documents,
  templateId,
  ignoreEntitlement,
  orgId,
  device,
}: {
  document: Document;
  user: User;
  documents: Document[];
  templateId?: TEMPLATE_TYPE;
  ignoreEntitlement?: boolean;
  orgId: string;
  device: Device;
}): DocVersionWithStatusAndRevision[] => {
  return document.versions
    .map((version) => ({
      ...version,
      status: getDocVersionStatus({
        selectedDocVersion: version,
        document,
        user,
        device,
        templateId: templateId ?? (document.name as TEMPLATE_TYPE),
        documents,
        ignoreEntitlement,
        orgId,
      }),
      revision: getDocVersionRevisionNumber(version, document.versions),
    }))
    .filter((v): v is DocVersionWithStatusAndRevision => v.status !== null);
};

/** Get the status of all document versions
 * across all documents with the same templateId (name)
 * and return the lowest status
 * LOCKED < AVAILABLE < DRAFT < UNAPPROVED < LIVE
 */
export const getDocTypeStatus = ({
  documents,
  templateId,
  user,
  ignoreEntitlement,
  orgId,
  device,
}: {
  documents: Document[];
  templateId: TEMPLATE_TYPE;
  user: User;
  device: Device;
  ignoreEntitlement?: boolean;
  orgId: string;
}): DocStatus => {
  const documentsOfDocType = documents.filter((doc) => doc.name === templateId);
  let initialStatus = DocumentStatus.LIVE;
  const isEmptyDoc = documentsOfDocType.length === 0;
  const hasNoVersion =
    !isEmptyDoc && documentsOfDocType[0].versions.length === 0;

  if (isEmptyDoc || hasNoVersion) {
    initialStatus =
      getDocVersionStatus({
        document: documentsOfDocType[0],
        user,
        documents,
        templateId,
        ignoreEntitlement,
        orgId,
        device,
      }) || DocumentStatus.LOCKED;
  }

  const allDocVersions = documentsOfDocType
    .map((doc) => {
      return getDocVersionsWithStatus({
        document: doc,
        user,
        device,
        documents,
        ignoreEntitlement,
        orgId,
      });
    })
    .flat();

  // // LOCKED < AVAILABLE < DRAFT < UNAPPROVED < LIVE
  let lowestStatus = initialStatus;

  // update lowestStatus if docVersion.status is lower
  const someUnapproved = allDocVersions.some(
    (docVersion) => docVersion.status === DocumentStatus.UNAPPROVED
  );
  if (someUnapproved) {
    lowestStatus = DocumentStatus.UNAPPROVED;
  }

  const someDraft = allDocVersions.some(
    (docVersion) => docVersion.status === DocumentStatus.DRAFT
  );
  if (someDraft) {
    lowestStatus = DocumentStatus.DRAFT;
  }

  const someAvailable = allDocVersions.some(
    (docVersion) => docVersion.status === DocumentStatus.AVAILABLE
  );
  if (someAvailable) {
    lowestStatus = DocumentStatus.AVAILABLE;
  }

  const someLocked = allDocVersions.some(
    (docVersion) => docVersion.status === DocumentStatus.LOCKED
  );
  if (someLocked) {
    lowestStatus = DocumentStatus.LOCKED;
  }

  return lowestStatus;
};

export const getDocVersionStatus = ({
  documents,
  selectedDocVersion,
  document,
  user,
  templateId,
  ignoreEntitlement,
  orgId,
  device,
}: {
  //the doc might not have been created yet therefore it might not have
  //a selectedDocVersion and a document
  selectedDocVersion?: DocumentVersion;
  document: Document;
  user: User;
  templateId: TEMPLATE_TYPE;
  documents: Document[];
  ignoreEntitlement?: boolean;
  orgId: string;
  device: Device;
}): DocStatus | null => {
  const isStaticDoc =
    isStaticallyGeneratedDocument(templateId) ||
    isStaticAndHasNoDependencies(templateId);
  const isUpload = isUploadDoc(templateId);
  const org = getOrgFromUser(user, orgId);
  if (!org) return null;
  const isDocEnabled =
    (ignoreEntitlement || isUserEntitledToDoc(org, templateId)) &&
    isDocumentUnlocked(templateId, documents, user, orgId, device).enabled;

  if (!isDocEnabled) {
    return DocumentStatus.LOCKED;
  }

  const documentIsCompleted = isDocumentCompleted({
    document,
    device,
    documents,
    documentVersion: selectedDocVersion,
  });

  if (isDocEnabled) {
    if (selectedDocVersion && document) {
      const isDraft = isDocVersionDraft(selectedDocVersion, document.versions);
      const isArchived = isDocRevisionArchived(
        selectedDocVersion,
        document.versions
      );
      if (isArchived) {
        return DocumentStatus.ARCHIVED;
      } else if (isDraft) {
        if (selectedDocVersion.readyForApproval || isUpload) {
          // We need to override the status to draft if the document is not completed
          if (!isUpload && !documentIsCompleted) {
            return DocumentStatus.DRAFT;
          }
          return DocumentStatus.UNAPPROVED;
        } else {
          return DocumentStatus.DRAFT;
        }
      } else if (isDocRevisionLive(selectedDocVersion, document.versions)) {
        return DocumentStatus.LIVE;
      }
    }
    if (isUpload || !document || !selectedDocVersion || isStaticDoc) {
      return DocumentStatus.AVAILABLE;
    }
  }
  return null;
};

export const isUploadDoc = (templateType: TEMPLATE_TYPE) =>
  templatesWithUpload.includes(templateType);

export const getDocVersionRevisionNumber = (
  docVersion: DocumentVersion,
  allDocVersionsOfSameDoc: DocumentVersion[]
): number => {
  if (typeof docVersion.revision === "number") {
    return docVersion.revision + 1;
  } else {
    // Check if there are any other revisions and if yes then return the highest revision number + 1
    const revisions = allDocVersionsOfSameDoc.filter(
      (v) => typeof v.revision === "number"
    );

    const isFirstRevision = revisions.length === 0;
    if (isFirstRevision) return 1;
    const highestRevision = Math.max(
      ...revisions.map((v) => v.revision as number)
    );

    return highestRevision + 2;
  }
};

export const getDependantTemplates = (
  changedAnswersIds: string[]
): [TEMPLATE_TYPE, string][] => {
  const templateIdAndTemplatePairs = Object.entries(ASSISTANT_CONFIG) as [
    TEMPLATE_TYPE,
    TemplateAssistant,
  ][];

  const directDependants = templateIdAndTemplatePairs
    .filter(([_, template]) => {
      if (!template || !template.elements) return false;

      const hasUpdatedIdInContext = template.elements.some((element) => {
        const contextExists = "context" in element;
        return (
          contextExists &&
          element.context?.some((contextId) =>
            changedAnswersIds.includes(contextId)
          )
        );
      });

      const hasUpdatedIdInDependencies =
        template.dependencies?.some((dependencyId) =>
          changedAnswersIds.includes(dependencyId)
        ) ?? false;

      return hasUpdatedIdInContext || hasUpdatedIdInDependencies;
    })
    .map(([templateId, template]): [TEMPLATE_TYPE, string] => [
      templateId,
      template.docName,
    ]);

  return directDependants;
};

export const getRecentlyUpdatedElementIds = (
  newDocVersion: DocumentVersion,
  secondToLastVersion: DocumentVersion | undefined
): DocumentDataKey[] => {
  if (!secondToLastVersion) {
    return [];
  }

  return newDocVersion.answers
    .filter((newAnswer) => {
      const previousElement = secondToLastVersion.answers.find(
        (a) => a.element === newAnswer.element
      );
      const hasAnswerChanged = newAnswer.answer !== previousElement?.answer;
      const hasAnswerFileIdChanged =
        newAnswer.answerFileId !== previousElement?.answerFileId;

      return previousElement && (hasAnswerChanged || hasAnswerFileIdChanged);
    })
    .map((answer) => answer.element);
};

export const isDocumentApproved = ({
  approvers,
  requiredNumberOfApprovers = 2,
}: {
  approvers: DocumentVersion["documentVersionApprover"];
  requiredNumberOfApprovers?: number;
}) =>
  approvers &&
  approvers.length === requiredNumberOfApprovers &&
  approvers.every(
    (approver: DocumentVersionApprover) =>
      approver.approvalStatus === DocumentVersionApproverStatus.APPROVED
  );

export const hasRevisionOneOreMoreApprovals = (docVersion: DocumentVersion) => {
  return docVersion.documentVersionApprover.some(
    (a) => a.approvalStatus === DocumentVersionApproverStatus.APPROVED
  );
};

export const isDocVersionAuthor = (version: DocumentVersion, user: User) =>
  version.createdBy === user.id;

export const getFunctionalGroupsToDocsMap = (documents: Document[]) => {
  const functionalGroupTosDocMap: Partial<Record<FunctionalGroup, Document[]>> =
    {};

  Object.values(documents).forEach((document) => {
    const functionalGroup =
      ASSISTANT_CONFIG[document.name as TEMPLATE_TYPE].functionalGroup;
    if (functionalGroupTosDocMap[functionalGroup] === undefined) {
      functionalGroupTosDocMap[functionalGroup] = [document];
      // Only add the document if it is not already in the array
    } else if (
      !functionalGroupTosDocMap[functionalGroup].find(
        (d) => d.name === document.name
      )
    ) {
      functionalGroupTosDocMap[functionalGroup]?.push(document);
    }
  });
  return functionalGroupTosDocMap;
};

export const getAvailableDataKeys = (
  documents: Document[]
): DataKeyWithValue[] => {
  return documents
    .map((doc): DataKeyWithValue[] => {
      // Get the live version of if no live version exist the latest draft
      const relevantDoc =
        doc.versions.find((v) => isDocRevisionLive(v, doc.versions)) ??
        doc.versions[0];
      return relevantDoc?.answers.map((answer) => ({
        key: answer.element,
        value: answer.answer,
      }));
    })
    .flat();
};

export const getLatestDocumentVersion = (
  document: Document
): DocumentVersion | undefined => {
  return orderBy(document.versions, ["createdAt"], ["desc"])[0];
};

export const isStatusComplete = (docStatus: DocStatus | null) =>
  docStatus &&
  [DocumentStatus.LIVE, DocumentStatus.ARCHIVED].includes(docStatus);

export const docStatusDictionary: Record<
  DocStatus,
  {
    label: string;
    color: ChipOwnProps["color"];
    borderColor: string;
    icon: JSX.Element;
  }
> = {
  LOCKED: {
    label: "Locked",
    color: "default",
    borderColor: "rgb(189, 189, 189)",
    icon: (
      <LockOutlined
        data-testid="locked-icon"
        sx={{
          "&.MuiSvgIcon-root": { fill: "rgb(189, 189, 189)" },
        }}
      />
    ),
  },
  ARCHIVED: {
    label: "Archived",
    color: "default",
    borderColor: "rgba(0, 0, 0, 0.87)",
    icon: <FileCopyOutlined color="inherit" data-testid="archived-icon" />,
  },
  DRAFT: {
    label: "Draft",
    color: "warning",
    borderColor: "rgb(237, 108, 2)",
    icon: <EditOutlined color="warning" data-testid="draft-icon" />,
  },
  UNAPPROVED: {
    label: "Unapproved",
    color: "primary",
    borderColor: "rgb(7, 78, 232)",
    icon: <HistoryEdu color="primary" data-testid="unapproved-icon" />,
  },
  LIVE: {
    label: "Live",
    color: "success",
    borderColor: "rgb(34, 197, 94)",
    icon: <TaskOutlined color="success" data-testid="live-icon" />,
  },
  AVAILABLE: {
    label: "Available",
    color: "default",
    borderColor: "rgba(0, 0, 0, 0.87)",
    icon: <NoteAddOutlined color="inherit" data-testid="available-icon" />,
  },
};

export const docsFilteredBySearch = (
  documents: Document[] | undefined,
  search: string
) => {
  if (!documents) return [];
  if (!search.trim()) return documents;

  return documents.filter((doc) => {
    const template = ASSISTANT_CONFIG[doc.name as TEMPLATE_TYPE];
    const nameMatch = template.docName
      .toLowerCase()
      .includes(search.toLowerCase());

    // Return early if name matches
    if (nameMatch) return true;

    // Only check elements if template has them
    if (template.elements) {
      return template.elements.some((element) => {
        // Only check elements that have an id property
        if ("element" in element && "id" in element.element) {
          const searchTerms = search.toLowerCase().split(" ");
          return searchTerms.some((term) =>
            element.element.id.toLowerCase().includes(term)
          );
        }
        return false;
      });
    }

    return false;
  });
};

export const getAnswerType = (answer: DocumentAnswer): DocumentAnswerType => {
  const element = getElementConfigByDataKey(answer.element);

  if (!element) throw new Error(`Element with id ${answer.element} not found`);

  if (element.element.type === "answerItemsElement") {
    return DocumentAnswerType.LIST;
  }
  if (element.element.type === "fileUpload") {
    return DocumentAnswerType.FILE;
  }
  return DocumentAnswerType.TEXT;
};

import { AnswerItem, AnswerItemsElement } from "@types";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef } from "react";

export const getHighestId = (list: AnswerItem[]): number => {
  const allItems = [...list];

  return allItems.length > 0
    ? allItems.reduce((highestId, accordion) => {
        const currentId = accordion.sequentialTypeNumber || 0;
        return Math.max(highestId, currentId);
      }, 0)
    : 0;
};

export const getAlwaysShownValues = (
  accordion: AnswerItem,
  element: AnswerItemsElement
): string => {
  const alwaysShownFields = element.options.fields.filter(
    (field) => field.alwaysShown
  );

  return alwaysShownFields
    .map((field) => accordion.fields?.[field.fieldKey])
    .filter(Boolean)
    .join(" | ");
};

export const isItemComplete = (
  item: AnswerItem,
  element: AnswerItemsElement
): boolean => {
  return element.options.fields.every((field) => {
    if (!field.required) {
      return true;
    }
    const value = item.fields?.[field.fieldKey];
    return value !== undefined && value !== "";
  });
};

/**
 * A custom hook that creates a debounced version of a callback function
 * while maintaining the latest callback reference.
 *
 * @param callback - The function to be debounced that takes a number parameter
 * @returns A debounced version of the callback that will only execute after 1000ms of inactivity
 *
 * @example
 * const debouncedCallback = useDebounce((index: number) => {
 *   // This will only execute after 1000ms of no calls
 *   saveData(index);
 * });
 */
export const useDebounce = (callback: (idx: number) => void) => {
  // Keep a mutable reference to the latest callback
  const ref = useRef<(idx: number) => void>();

  // Update the ref whenever the callback changes
  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  // Create a memoized debounced function that uses the ref
  const debouncedCallback = useMemo(() => {
    const func = (idx: number) => {
      ref.current?.(idx);
    };
    return debounce(func, 1000);
  }, []);
  return debouncedCallback;
};

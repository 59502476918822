import {
  Device,
  Document,
  DocumentAnswer,
  DocumentVersion,
  TEMPLATE_TYPE,
} from "@models";
import { AnswerItem, TemplateElement } from "@types";
import {
  getAvailableDataKeys,
  getDocumentStepsFromConfig,
  getFilteredSteps,
} from "src/utils/documents";

export const isAnswerItemsComplete = (
  answerItems: AnswerItem[],
  step: TemplateElement
): boolean => {
  return answerItems.every((item) => {
    // Archived items are considered complete
    if (item.archived) {
      return true;
    }

    // Skip validation if fields are missing
    if (!item.fields) {
      return false;
    }

    // Validate each field against its configuration
    return Object.values(item.fields).every((fieldValue, index) => {
      // Skip validation if field configuration is missing
      if (!("fields" in step.element.options)) {
        return true;
      }

      const fieldConfig = step.element.options.fields[index];
      // Only validate required fields
      return !fieldConfig?.required || !!fieldValue;
    });
  });
};

export const isStepComplete = (
  step: TemplateElement,
  answer: Partial<DocumentAnswer>
): boolean => {
  if (!step.required) {
    return true;
  }

  if (step.element.type === "fileUpload") {
    return !!answer?.answerFileId;
  }

  if (step.element.type === "answerItemsElement") {
    const answerItems = answer.answerItems;
    if (!Array.isArray(answerItems)) {
      return false;
    }

    return isAnswerItemsComplete(answerItems, step);
  }

  // For all other element types, check for non-empty answer or default value
  const hasNonEmptyAnswer = !!answer?.answer && answer.answer.trim() !== "";
  const hasDefaultValue = !!step.element.options?.default;

  return hasNonEmptyAnswer || hasDefaultValue;
};

export const allStepsCompleted = (
  document: Document,
  documentVersion: DocumentVersion,
  device: Device,
  documents: Document[]
) => {
  const steps = getDocumentStepsFromConfig(document.name as TEMPLATE_TYPE);

  const dataKeysWithValue = getAvailableDataKeys(documents);

  const filteredSteps = getFilteredSteps(steps, device, dataKeysWithValue);

  return filteredSteps.every((step) => {
    const stepAnswer = documentVersion.answers.find(
      (a) => a.element === step.id
    );

    if (!stepAnswer) {
      return false;
    }

    if (step.element.type === "fileUpload") {
      return isStepComplete(step, {
        answerFileId: stepAnswer.answerFileId || null,
      });
    }

    if (step.element.type === "answerItemsElement") {
      return isStepComplete(step, {
        answerItems: stepAnswer.answerItems || null,
        element: step.id,
      });
    }

    return isStepComplete(step, { answer: stepAnswer.answer || null });
  });
};

import {
  BillingInterval,
  ComponentConfigurationType,
  ComponentType,
  Device,
  Document,
  DocumentAnswer,
  DocumentVersion,
  Entitlement,
  EntitlementType,
  Organization,
  OrganizationMemberWithOrganization,
  Product,
  RoadmapTasks,
  Subscription,
  SubscriptionItem,
  TEMPLATE_TYPE,
  User,
} from "@models";
import { Suggestion, TemplateElement } from "@types";

export const mockSoftwareDevice: Device = {
  id: "device-1",
  name: "Device 1",
  description: "Device 1 description",
  isDemoDevice: false,
  components: [
    {
      id: "component-1",
      name: "Component 1",
      description: "Component 1 description",
      type: ComponentType.SOFTWARE,
      configuration: [
        {
          type: ComponentConfigurationType.HAS_AI,
          value: false,
        },
      ],
      subComponents: [],
    },
  ],
  createdBy: "user-1",
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const mockHardwareDevice: Device = {
  id: "device-1",
  name: "Device 1",
  description: "Device 1 description",
  isDemoDevice: false,
  components: [
    {
      id: "component-1",
      name: "Component 1",
      description: "Component 1 description",
      type: ComponentType.HARDWARE,
      configuration: [],
      subComponents: [],
    },
  ],
  createdBy: "user-1",
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const mockHardwareDeviceWithSterile: Device = {
  ...mockHardwareDevice,
  components: [
    ...mockHardwareDevice.components,
    {
      ...mockHardwareDevice.components[0],
      configuration: [
        {
          type: ComponentConfigurationType.IS_STERILIZATION_INTENDED,
          value: true,
        },
      ],
    },
  ],
};

const userId = "user-1";
const orgId = "1";

export const mockOrganization: Organization = {
  id: orgId,
  name: "Organization 1",
  createdAt: new Date(),
  updatedAt: new Date(),
  subscription: null,
};

const membership: OrganizationMemberWithOrganization = {
  id: "1",
  organizationId: orgId,
  homeOrg: true,
  createdAt: new Date(),
  updatedAt: new Date(),
  userId,
  organization: mockOrganization,
};

export const subscription: Subscription = {
  id: "1",
  organizationId: "1",
  status: "active",
  stripeSubscriptionId: "1",
  subscriptionItems: [
    {
      id: "1",
      subscriptionId: "1",
      productId: "1",
      productQuantity: 1,
      interval: BillingInterval.MONTHLY,
      product: {
        id: "1",
        name: "Premium+",
        stripeProductId: window._env_.PREMIUM_PLUS_STRIPE_PRODUCT_ID,
        entitlements: [
          {
            id: "1",
            type: EntitlementType.CONSULTING,
            value_bool: true,
            value_int: null,
          },
          {
            id: "2",
            type: EntitlementType.DEVICE_LIMIT,
            value_bool: null,
            value_int: 1,
          },
          {
            id: "3",
            type: EntitlementType.ALL_DOCUMENTS_UNLOCKED,
            value_bool: true,
            value_int: null,
          },
        ],
      },
    },
  ],
};

export const mockFreeUser: User = {
  id: userId,
  authId: "auth-1",
  firstName: "User",
  lastName: "1",
  email: "user1@example.com",
  createdAt: new Date(),
  updatedAt: new Date(),
  organizationMemberships: [membership],
  userUiState: {
    welcomeSeen: true,
    supportTooltipSeen: true,
    nextTodoCardTooltipSeen: true,
    approvalTooltipSeen: true,
    demoCompleteModalSeen: true,
    id: "1",
    createdAt: new Date(),
    updatedAt: new Date(),
    userId,
  },
};

export const mockPremiumUser: User = {
  ...mockFreeUser,
  organizationMemberships: [
    {
      ...mockFreeUser.organizationMemberships[0],
      organization: {
        ...mockFreeUser.organizationMemberships[0].organization,
        subscription,
      },
    },
  ],
};

export const mockRoadmapTasks: RoadmapTasks = {
  tasks: Object.values(TEMPLATE_TYPE).reduce(
    (acc, task) => {
      acc[task] = false;
      return acc;
    },
    {} as Record<string, boolean>
  ),
  id: "1",
  deviceId: "1",
  createdBy: "1",
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const mockDocumentVersion: DocumentVersion = {
  id: "123",
  documentId: "123",
  readyForApproval: false,
  documentVersionApprover: [],
  revision: null,
  createdBy: userId,
  createdAt: new Date(),
  updatedAt: new Date(),
  snapshot: null,
  revisionSummary: "",
  answers: [],
  suggestions: [],
  fileId: null,
  file: null,
};

export const mockDocument: Document = {
  id: "123",
  name: TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT,
  deviceId: "device-1",
  createdBy: userId,
  createdAt: new Date(),
  updatedAt: new Date(),
  versions: [mockDocumentVersion],
};

export const mockDocumentAnswer: DocumentAnswer = {
  element: "expected-lifetime",
  answer: "correct",
  id: "123",
  createdAt: new Date(),
  documentVersionId: "123",
  createdBy: userId,
};

export const mockEntitlement: Entitlement = {
  id: "ent-1",
  type: EntitlementType.DEVICE_LIMIT,
  value_int: -1,
};

export const mockProduct: Product = {
  id: "prod-1",
  name: "Premium Flat",
  stripeProductId: window._env_.PREMIUM_STRIPE_PRODUCT_ID,
  entitlements: [mockEntitlement],
};

const subscriptionId = "sub-1";

export const mockSubscriptionItem: SubscriptionItem = {
  id: "item-1",
  productQuantity: 2,
  interval: BillingInterval.YEARLY,
  subscriptionId: subscriptionId,
  productId: mockProduct.id,
  product: mockProduct,
};

export const mockSubscription: Subscription = {
  id: subscriptionId,
  organizationId: mockOrganization.id,
  status: "active",
  stripeSubscriptionId: "stripe-sub-1",
  subscriptionItems: [mockSubscriptionItem],
};

export const mockAnswerItems = [
  {
    type: "QS",
    id: "qs-1",
    organizationId: "org-1",
    createdBy: "user-1",
    createdAt: new Date(),
    updatedAt: new Date(),
    archived: true, // This item should be skipped
    sequentialTypeNumber: 1,
    fields: {
      name: "Archived Supplier",
      purchase_description: "Test Purchase",
      status: "Approved",
    },
  },
  {
    type: "QS",
    id: "qs-2",
    organizationId: "org-1",
    createdBy: "user-1",
    createdAt: new Date(),
    updatedAt: new Date(),
    archived: false,
    sequentialTypeNumber: 2,
    fields: {
      name: "Active Supplier",
      purchase_description: "Test Purchase",
      status: "Approved",
    },
  },
];

export const mockVersionWithAnswerItems: DocumentVersion = {
  ...mockDocumentVersion,
  answers: [
    {
      ...mockDocumentAnswer,
      element: "list-of-qualified-suppliers",
      answerItems: mockAnswerItems,
    },
  ],
};

export const mockDocuments: Document[] = [
  {
    id: "doc-1",
    name: TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT,
    deviceId: "device-1",
    createdBy: userId,
    createdAt: new Date(),
    updatedAt: new Date(),
    versions: [
      {
        ...mockDocumentVersion,
        id: "version-1",
        documentId: "doc-1",
      },
    ],
  },
  {
    id: "doc-2",
    name: TEMPLATE_TYPE.BUG_FIX_REPORT,
    deviceId: "device-1",
    createdBy: userId,
    createdAt: new Date(),
    updatedAt: new Date(),
    versions: [
      {
        ...mockDocumentVersion,
        id: "version-2",
        documentId: "doc-2",
      },
    ],
  },
  {
    id: "doc-7",
    name: TEMPLATE_TYPE.SOP_CHANGE_MANAGEMENT,
    deviceId: "device-1",
    createdBy: "user-1",
    createdAt: new Date(),
    updatedAt: new Date(),
    versions: [mockDocumentVersion],
  },
  {
    id: "doc-8",
    name: TEMPLATE_TYPE.CHECKLIST_SOFTWARE_RELEASE,
    deviceId: "device-1",
    createdBy: "user-1",
    createdAt: new Date(),
    updatedAt: new Date(),
    versions: [mockDocumentVersion],
  },
  {
    id: "doc-9",
    name: TEMPLATE_TYPE.LIST_OF_QUALIFIED_SUPPLIERS,
    deviceId: "device-1",
    createdBy: "user-1",
    createdAt: new Date(),
    updatedAt: new Date(),
    versions: [mockVersionWithAnswerItems],
  },
  {
    id: "doc-10",
    name: TEMPLATE_TYPE.SOP_PURCHASING,
    deviceId: "device-1",
    createdBy: "user-1",
    createdAt: new Date(),
    updatedAt: new Date(),
    versions: [mockDocumentVersion],
  },
];

export const mockStep: TemplateElement = {
  id: "applied-clinical-trials-search-string",
  required: true,
  element: {
    type: "textField",
    id: "test-field",
    options: {
      label: "Test Field",
    },
  },
};

export const mockSuggestion: Suggestion = {
  applied: false,
  value: "",
  completed: false,
  saved: false,
  isStatusMessage: false,
  statusMessageType: undefined,
  loading: false,
  error: false,
};

export const mockDocumentVersionWithAnswers: DocumentVersion = {
  id: "version-1",
  documentId: "doc-1",
  readyForApproval: false,
  documentVersionApprover: [],
  revision: 1,
  createdBy: "user-1",
  createdAt: new Date("2023-01-01"),
  updatedAt: new Date("2023-01-01"),
  snapshot: null,
  revisionSummary: "This is a revision summary",
  suggestions: [],
  answers: [
    {
      id: "device-name",
      answer: "Test Device",
      element: "device-name",
      documentVersionId: mockDocumentVersion.id,
      createdBy: "user-1",
      createdAt: new Date("2023-01-01"),
    },
    {
      id: "software-items-table",
      answer: "| Software Item | Description |\n| Test | Test |",
      element: "software-items-table",
      documentVersionId: mockDocumentVersion.id,
      createdBy: "user-1",
      createdAt: new Date("2023-01-01"),
    },
    {
      id: "software-connections-table",
      answer: "| Software Item | Infrastructure |\n| Test | Test |",
      element: "software-connections-table",
      documentVersionId: mockDocumentVersion.id,
      createdBy: "user-1",
      createdAt: new Date("2023-01-01"),
    },
    {
      id: "software-architecture-diagram",
      answerFileId: "file-123",
      element: "software-architecture-diagram",
      documentVersionId: mockDocumentVersion.id,
      createdBy: "user-1",
      createdAt: new Date("2023-01-01"),
    },
    {
      id: "software-architecture-security",
      answer: "Security details",
      element: "software-architecture-security",
      documentVersionId: mockDocumentVersion.id,
      createdBy: "user-1",
      createdAt: new Date("2023-01-01"),
    },
    {
      id: "software-architecture-backup",
      answer: "Backup details",
      element: "software-architecture-backup",
      documentVersionId: mockDocumentVersion.id,
      createdBy: "user-1",
      createdAt: new Date("2023-01-01"),
    },
    {
      id: "minimum-requirements",
      answer: "Minimum requirements details",
      element: "minimum-requirements",
      documentVersionId: mockDocumentVersion.id,
      createdBy: "user-1",
      createdAt: new Date("2023-01-01"),
    },
  ],
  fileId: null,
  file: null,
};

import { ClickAwayListener } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const Card = ({
  title,
  cardId,
  openCardDialog,
  disabled = false,
  allCardTasksCompleted = false,
}: {
  title: string;
  cardId: string;
  openCardDialog: () => void;
  disabled: boolean;
  allCardTasksCompleted: boolean;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const highlightedCard = searchParams.get("card");

  let background = allCardTasksCompleted ? "bg-green-600" : "bg-slate-200";
  let backgroundHover = allCardTasksCompleted
    ? "hover:bg-green-700"
    : "hover:bg-slate-300";

  const textColor = allCardTasksCompleted ? "text-white" : "text-default";

  const isHighlighted = highlightedCard === cardId;

  return (
    <div
      className="relative cursor-pointer"
      onClick={() => {
        !disabled && openCardDialog();
        setSearchParams((params) => {
          params.delete("card");
          return params;
        });
      }}
    >
      <div
        key={title}
        className={`
        ${background} 
        ${textColor} 
        ${backgroundHover} 
        relative mr-[6px] 
        flex h-28 
        cursor-pointer 
        items-center 
        justify-center 
        gap-x-2 
        rounded-sm 
        p-3 
        text-center 
      `}
      >
        {disabled && (
          <div className="absolute bottom-0 left-0 right-0 top-0 z-10 bg-slate-100/60"></div>
        )}
        {title}
      </div>
      {isHighlighted && (
        <ClickAwayListener
          onClickAway={() => {
            setSearchParams((params) => {
              params.delete("card");
              params.delete("task");
              return params;
            });
          }}
        >
          <div
            data-testid={`${cardId}-highlight`}
            className={`absolute w-[calc(100%_-_6px)] h-full top-0 motion-safe:animate-fade animate-infinite animate-ease-in animate-fill-forwards ring ring-[#074EE8] rounded-sm`}
          />
        </ClickAwayListener>
      )}
    </div>
  );
};

export default Card;

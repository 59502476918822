import { useGetDevice, useGetDocuments, useGetUser } from "@hooks";
import { TEMPLATE_TYPE } from "@models";
import { CloudDownload } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import GenericButton from "src/components/GenericButton/GenericButton";
import { ASSISTANT_CONFIG } from "src/config";
import {
  canDocHaveMultipleInstances,
  getDocVersionStatus,
} from "../../../../utils";
import ApproveButton from "./actions/ApproveButton";
import CopyButton from "./actions/CopyButton";
import { DeleteButton } from "./actions/DeleteButton";
import EditButton from "./actions/EditButton";
import EditRawDocButton from "./actions/EditRawDocButton";
import MoreOptions from "./actions/MoreOptions";
import { DocViewerTopBarProps } from "./DocViewerTopBar.types";
import DocRevisionDropDown from "./selects/DocRevisionDropDown";
import DocVersionDropdown from "./selects/DocVersionDropdown";

export const DocViewerTopBar = ({
  docProps,
  handleFileDownload,
  handleFileDelete,
  file,
}: DocViewerTopBarProps) => {
  const { data: user } = useGetUser();
  const { orgId = "" } = useParams();

  if (file && handleFileDelete) {
    return (
      <div className="flex items-center gap-2 justify-end">
        <GenericButton onClick={handleFileDownload} tooltipContent="Download">
          <CloudDownload fontSize="small" />
        </GenericButton>
        <DeleteButton file={file} handleDeleteFile={handleFileDelete} />
      </div>
    );
  }

  if (!docProps) {
    return null;
  }

  const {
    docs,
    generatedDoc,
    hasOutstandingAction,
    isCreatingNewInstance,
    isEditingRawDocument,
    loadingNewDocVersion,
    onCancelEditRawDocument,
    onCreateDocInstance,
    onDocInfoButtonClick,
    onEditRawDocument,
    onSaveRawDocument,
    onSelectDocInstance,
    onSelectDocVersion,
    selectedDoc,
    selectedVersion,
    isDocEnabled,
    handleCreateNewVersionWithFile,
    isDocumentBeingEdited,
    onEditClick,
  } = docProps;

  const { data: documents } = useGetDocuments(orgId, selectedDoc.deviceId);
  const { data: device } = useGetDevice({
    orgId,
    deviceId: selectedDoc.deviceId,
  });
  const templateId = selectedDoc.name as TEMPLATE_TYPE;
  const docConfig = ASSISTANT_CONFIG[templateId];
  const canHaveMultipleInstances = canDocHaveMultipleInstances(templateId);

  if (!user || !device || !documents) {
    // captureSentryMessage("User not found to display DocViewerTopBar");
    return null;
  }

  const docVersionStatus = getDocVersionStatus({
    selectedDocVersion: selectedVersion,
    document: selectedDoc,
    user,
    templateId,
    documents,
    orgId,
    device,
  });

  // Conditions to show the buttons
  const showEditButton =
    !isDocumentBeingEdited && docVersionStatus && !isEditingRawDocument;

  const showApproveButton =
    docVersionStatus !== "DRAFT" &&
    onDocInfoButtonClick &&
    !isEditingRawDocument;

  const showDownloadButton = handleFileDownload;

  const showCopyAndMoreOptions =
    !isDocumentBeingEdited &&
    onEditRawDocument &&
    !isEditingRawDocument &&
    generatedDoc;

  const showRawDocEditButton =
    isEditingRawDocument && onCancelEditRawDocument && onSaveRawDocument;

  return (
    <div className={`flex items-center justify-between rounded-md`}>
      <div>
        {!selectedVersion ? (
          <Skeleton width={200} height={42} className="transform-none" />
        ) : (
          <div className="flex">
            {canHaveMultipleInstances && docs && selectedDoc && (
              <DocRevisionDropDown
                selectedDoc={selectedDoc}
                selectedVersion={selectedVersion}
                isDocEnabled={isDocEnabled}
                {...(canHaveMultipleInstances && {
                  docs,
                  onCreateDocInstance,
                  onSelectDocInstance,
                  isCreatingNewInstance,
                })}
              />
            )}
            <DocVersionDropdown
              isMultiInstanceDoc={canHaveMultipleInstances}
              showStatus={!["TMP", "EXT"].includes(docConfig.docType)}
              document={selectedDoc}
              selectedVersion={selectedVersion}
              onSelectDocVersion={onSelectDocVersion}
              isDocEnabled={isDocEnabled}
            />
          </div>
        )}
      </div>

      <div className="flex items-center gap-2">
        {showRawDocEditButton && (
          <EditRawDocButton
            onCancelEditRawDocument={onCancelEditRawDocument}
            onSaveRawDocument={onSaveRawDocument}
          />
        )}

        {showDownloadButton && (
          <GenericButton onClick={handleFileDownload} tooltipContent="Download">
            <CloudDownload fontSize="small" />
          </GenericButton>
        )}

        {showEditButton && (
          <EditButton
            docVersionStatus={docVersionStatus}
            isDocEnabled={isDocEnabled}
            loadingNewDocVersion={loadingNewDocVersion}
            onEditClick={onEditClick}
            selectedDoc={selectedDoc}
            handleCreateNewVersionWithFile={handleCreateNewVersionWithFile}
          />
        )}

        {showCopyAndMoreOptions && (
          <>
            <CopyButton
              generatedDoc={generatedDoc}
              isDocEnabled={isDocEnabled}
            />
            <MoreOptions
              onEditRawDocument={onEditRawDocument}
              selectedDoc={selectedDoc}
              selectedVersion={selectedVersion}
              isDocEnabled={isDocEnabled}
            />
          </>
        )}

        {showApproveButton && (
          <ApproveButton
            hasOutstandingAction={hasOutstandingAction}
            onDocInfoButtonClick={onDocInfoButtonClick}
            isDocEnabled={isDocEnabled}
          />
        )}
      </div>
    </div>
  );
};

export default DocViewerTopBar;

import { useUploadFileMutation } from "@hooks";
import { CloudUploadOutlined, MoreVert } from "@mui/icons-material";
import { Divider, Menu, MenuItem, Typography } from "@mui/material";
import { fillRouteWithSlugs } from "@utils";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GenericButton from "src/components/GenericButton/GenericButton";
import { ROUTE_SLUGS, ROUTES } from "src/config/navigation/routes";

export const MoreOptions = ({
  deviceId,
  setExpandedItems,
}: {
  deviceId: string;
  setExpandedItems: (items: string[]) => void;
}) => {
  const { orgId = "" } = useParams();

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const moreOptionsOpen = Boolean(anchorEl);
  const handleClickMoreOptions = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMoreOptions = () => {
    setAnchorEl(null);
  };

  const fileInput = useRef<HTMLInputElement>(null);
  const uploadFileMutation = useUploadFileMutation();
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const uploadedFile = await uploadFileMutation.mutateAsync({
      orgId,
      file,
      deviceId,
    });

    navigate(
      fillRouteWithSlugs(ROUTES.QMS_OPEN_FILE, {
        [ROUTE_SLUGS.ORG_ID]: orgId,
        [ROUTE_SLUGS.DEVICE_ID]: deviceId,
        [ROUTE_SLUGS.FILE_ID]: uploadedFile.id,
      })
    );

    setExpandedItems(["UNGROUPED"]);
    if (fileInput.current) {
      fileInput.current.value = "";
    }
  };

  const handleUploadClick = () => {
    fileInput.current?.click();
  };

  return (
    <>
      <GenericButton
        ariacontrols={moreOptionsOpen ? "basic-menu" : undefined}
        ariahaspopup={true}
        ariaexpanded={moreOptionsOpen ? true : undefined}
        id="basic-button"
        onClickEvent={handleClickMoreOptions}
        hideTooltip
      >
        <MoreVert />
      </GenericButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={moreOptionsOpen}
        onClose={handleCloseMoreOptions}
        onClick={handleCloseMoreOptions}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        disablePortal
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography className="px-4">More Actions</Typography>
        <Divider className="my-2" />
        <MenuItem
          className="flex items-center gap-2"
          onClick={handleUploadClick}
        >
          <CloudUploadOutlined />
          Upload Document
        </MenuItem>
      </Menu>
      <input
        id="hidden-qms-file-input"
        type="file"
        className="hidden"
        accept=".pdf, .txt, .docx, .png, .jpeg, .csv, .jpg"
        max={1}
        data-testid="hidden-qms-file-input"
        ref={fileInput}
        onChange={handleFileChange}
      />
    </>
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isTaskVisible } from "@utils";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { DemoCompleteModal } from "src/components/DemoCompleteModal/DemoCompleteModal";
import { Cards } from "src/components/Roadmap/Cards/Cards";
import { areAllCardTasksCompleted } from "src/components/Roadmap/utils";
import { RoadmapSkeleton, TaskModal } from "../components";
import PageTitle from "../components/PageTitle";
import {
  ROADMAP_CARD_ID,
  RoadmapStageId,
  getDeviceQueryKey,
  getDevicesQueryKey,
} from "../config";
import {
  useGetDevice,
  useGetDocuments,
  useGetTasks,
  useGetUser,
} from "../hooks";
import { useStore } from "../stores";
import { Device, TEMPLATE_TYPE } from "../stores/models";

export const Roadmap = () => {
  const { deviceStore } = useStore();
  const { deviceId = "", orgId = "" } = useParams();
  const queryClient = useQueryClient();
  if (!deviceId) throw new Error("No device ID provided");

  const [selectedCard, setSelectedCard] = useState<
    { card: ROADMAP_CARD_ID; phase: RoadmapStageId } | undefined
  >();
  const [updatedDevice, setUpdatedDevice] = useState<Device | undefined>(
    undefined
  );

  const [editDevice, setEditDevice] = useState(false);
  let [_, setSearchParams] = useSearchParams();

  const { data: user } = useGetUser();

  const { data: device } = useGetDevice({ orgId, deviceId });

  const { isLoading: tasksLoading, data: tasks } = useGetTasks(orgId, deviceId);
  const { data: documents } = useGetDocuments(orgId, deviceId);

  const updateDevice = useMutation({
    mutationFn: (device: Device) => deviceStore.updateDevice(orgId, device),
    onMutate: (device) => {
      queryClient.setQueryData(getDeviceQueryKey(device.id), device);
    },
    onSuccess: (device) => {
      queryClient.invalidateQueries({ queryKey: getDeviceQueryKey(device.id) });
      queryClient.invalidateQueries({ queryKey: getDevicesQueryKey(orgId) });
    },
  });

  if (!device || tasksLoading || !tasks || !documents || !user) {
    return <RoadmapSkeleton />;
  }

  const roadmap = deviceStore.mapRoadmap(tasks.tasks, device, documents);

  const handleClickOpenCardDialog = (
    card: ROADMAP_CARD_ID,
    phase: RoadmapStageId
  ) => {
    setSelectedCard({ card, phase });
  };

  const handleCloseCardDialog = () => {
    setSearchParams((params) => {
      params.delete("task");
      return params;
    });
    setSelectedCard(undefined);
  };

  const card = roadmap
    .find((step) => step.id === selectedCard?.phase)
    ?.cards.find((card) => card.id === selectedCard?.card);

  const first =
    "polygon(0 0,calc(100% - 6px) 0,100% 50%,calc(100% - 6px) 100%,0 100%)";
  const all =
    "polygon(calc(100% - 6px) 0,100% 50%,calc(100% - 6px) 100%,2px 100%,8px 50%,2px 0)";

  return (
    <div className="flex flex-col gap-y-3">
      <PageTitle
        title={device.name}
        enableEdit
        editOnClick={() => setEditDevice(true)}
        isDemoDevice={!!device.isDemoDevice}
      />
      {!!device.isDemoDevice && <DemoCompleteModal orgId={orgId} />}
      <Typography variant="h2">Roadmap</Typography>

      <div className="flex flex-col">
        <div className="flex">
          {roadmap.map((step, index) => {
            const stagesCardsStatus = step.cards.map((card) => {
              const cardTasks = card.tasks
                .filter((task) => isTaskVisible(task, documents, device))
                .map((task) => task.id);
              const cardDocuments = documents.filter((document) =>
                cardTasks.includes(document.name as TEMPLATE_TYPE)
              );

              return areAllCardTasksCompleted(
                cardTasks,
                cardDocuments,
                user,
                documents,
                orgId,
                device
              );
            });

            const areAllStagesDocumentsCompleted = stagesCardsStatus.every(
              (status) => status === true
            );

            const percentageEach = 100 / stagesCardsStatus.length;
            const percentageCompleted = parseFloat(
              stagesCardsStatus
                .reduce(
                  (acc, status) => (status ? acc : acc - percentageEach),
                  100
                )
                .toFixed(2)
            );

            const color1 = "rgb(21,128, 61)";
            const color2 = "rgb(203, 213, 225)";

            return (
              <div key={step.title} className="flex flex-1 flex-col">
                <div
                  className={`flex flex-1 items-center justify-center px-4 py-1 `}
                  style={{
                    background: `linear-gradient(to right,  ${color1}, ${color1} ${percentageCompleted}%, ${color2} ${percentageCompleted}%, ${color2} ${100 - percentageCompleted}%)`,
                    color: areAllStagesDocumentsCompleted ? "white" : "black",
                    clipPath: index === 0 ? first : all,
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {step.title}
                </div>
              </div>
            );
          })}
        </div>
        <Cards
          roadmap={roadmap}
          onClickOpenCardDialog={handleClickOpenCardDialog}
          documents={documents}
          user={user}
          device={device}
        />
      </div>
      <Dialog
        open={editDevice}
        onClose={() => setEditDevice(false)}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>Edit Device</DialogTitle>
        <DialogContent className="flex flex-col gap-y-4">
          {/* <Alert severity="info">
            Device changes will not be reflected in the technical file form
            builder. Please update the device name in the form builder
            separately.
          </Alert> */}
          <TextField
            label="Device Name"
            fullWidth
            value={updatedDevice?.name ?? device.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setUpdatedDevice({ ...device, name: event.target.value })
            }
          />
          <TextField
            label="Device Description"
            fullWidth
            value={updatedDevice?.description ?? device.description}
            multiline
            minRows={5}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setUpdatedDevice({ ...device, description: event.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              setEditDevice(false);
              setUpdatedDevice(undefined);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!updatedDevice}
            variant="contained"
            color="success"
            onClick={() => {
              setEditDevice(false);
              updatedDevice && updateDevice.mutate(updatedDevice);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {!!selectedCard && !!card && (
        <TaskModal
          cardConfig={{ card, phase: selectedCard.phase }}
          open={!!selectedCard}
          deviceId={deviceId}
          handleCloseCardDialog={handleCloseCardDialog}
          orgId={orgId}
        />
      )}
    </div>
  );
};

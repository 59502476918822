import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { RadioButtonElement } from "@types";
import React, { ChangeEvent } from "react";

type Props = {
  element: RadioButtonElement;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  style?: React.CSSProperties;
};

export const RadioButton: React.FC<Props> = ({
  onChange,
  element,
  value,
  style,
}) => {
  return (
    <FormControl className={"flex flex-row items-center gap-2"} style={style}>
      <FormLabel id={element.id}>{element.options.label}</FormLabel>
      <RadioGroup
        aria-labelledby={element.id}
        name="radio-buttons-group"
        key={element.id}
        id={element.id}
        onChange={onChange}
        value={value}
        className={"flex flex-row"}
      >
        {element.options.options.map((option) => (
          <FormControlLabel
            value={option.value}
            control={
              <Radio
                className={"p-0 pr-1 ml-4"}
                checked={value === option.value}
              />
            }
            label={option.label}
            key={option.value + option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
export default RadioButton;

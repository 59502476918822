import { TEMPLATE_TYPE } from "@models";
import {
  Card,
  CircularProgress,
  ClickAwayListener,
  Tooltip,
} from "@mui/material";
import { DocStatus, DocumentStatus } from "@types";
import { docStatusDictionary } from "@utils";
import { useSearchParams } from "react-router-dom";
import { RoadmapTask, theme } from "./../../../config";

type Props = {
  task: RoadmapTask;
  docStatus: DocStatus | null;
  isLoading: boolean;
};

export const TaskAccordionCard = ({ task, docStatus, isLoading }: Props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const highlightedTask = searchParams.get("task") as TEMPLATE_TYPE;

  const isHighlighted = highlightedTask === task?.id;

  const isLocked = docStatus === DocumentStatus.LOCKED;

  if (docStatus === null) return;

  const isTaskLocked = task.enabled === false;

  const tooltipText = isTaskLocked
    ? "Enroll in the beta to unlock this task."
    : "Click to show task's dependencies.";

  return (
    <Tooltip
      title={tooltipText}
      followCursor
      //enterNextDelay={1500}
      {...(!isLocked && { open: false })}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 6],
              },
            },
          ],
        },
      }}
    >
      <div className="flex items-center w-full">
        <div className="relative w-full">
          <Card
            data-testid={task.id}
            id={task.id}
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              border: `1px solid ${docStatusDictionary[docStatus].borderColor}`,
              boxShadow: "none",
              padding: "10px",
              backgroundColor: "transparent",
              "&:hover": {
                background: `${theme.palette.grey[100]}`,
              },
            }}
          >
            {isLoading ? (
              <CircularProgress size={21} />
            ) : (
              docStatusDictionary[docStatus].icon
            )}
            <div>{task.title}</div>
          </Card>
          {isHighlighted && (
            <ClickAwayListener
              onClickAway={() => {
                setSearchParams((params) => {
                  params.delete("task");
                  return params;
                });
              }}
            >
              <div
                data-testid={`${task?.id}-highlight`}
                className={`absolute w-full h-full top-0 ${isHighlighted ? `motion-safe:animate-fade animate-infinite animate-ease-in animate-fill-forwards ring ring-[${theme.palette.primary.main}] rounded` : ""}`}
              />
            </ClickAwayListener>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

import "@fontsource/rubik";
import { tooltipClasses } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import colors from "./colors";

declare module "@mui/material/Chip" {
  interface ChipPropsSizeOverrides {
    tiny: true;
  }
}

const rootElement = document.getElementById("root");

const bgColor = "#FBFBFB"; // Also in tailwind as bg-color-bg

export let theme = createTheme({
  // https://zenoo.github.io/mui-theme-creator/
  palette: {
    primary: {
      main: "#074EE8",
    },
    success: {
      main: "#22c55e",
      contrastText: "#ffffff",
    },
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontFamily: ["Rubik", "sans-serif"].join(","),
    fontSize: 14,
    h1: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiModal: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: ["Rubik", "sans-serif"].join(","),
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: bgColor,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: bgColor,
          // backgroundColor: theme.palette.primary.main,
          color: colors.primary.contrastText,
          boxShadow: "none",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.divider,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          strokeLinecap: "round",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "0.375rem",
        },
      },
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: "outlined", color: "inherit" },
          style: {
            borderColor: "rgb(209, 213, 219)",
            color: "rgba(0, 0, 0, 0.87)",
            backgroundColor: "rgb(251, 251, 251)",
            "&:hover": {
              backgroundColor: "rgb(244 244 245)",
            },
          },
        },
      ],
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          elevation: 0,
          border: "none",
          backgroundColor: bgColor,
          flex: 1,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "filled",
        InputProps: {
          disableUnderline: true,
        },
      },
      styleOverrides: {
        root: {},
      },
    },

    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: "none",
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        slotProps: {
          popper: {
            sx: {
              [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                {
                  marginTop: "6px",
                },
              [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                {
                  marginBottom: "6px",
                },
              [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                {
                  marginLeft: "6px",
                },
              [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                {
                  marginRight: "6px",
                },
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.divider,
          "&:hover": {
            backgroundColor: theme.palette.grey[50],
            borderColor: theme.palette.divider,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          borderRadius: 4,
          borderWidth: 1,
          borderColor: theme.palette.divider,
          borderStyle: "solid",
          "&:hover": {
            backgroundColor: theme.palette.grey[50],
            borderColor: theme.palette.divider,
          },
          "&::before": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "none",
          },
          "&.Mui-focused:after": {
            borderBottom: "none",
          },
          "&.Mui-focused": {
            borderWidth: 1,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            backgroundColor: theme.palette.grey[50],
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderBottom: "none",
          },
          "& .MuiInputBase-root::before": {
            borderBottom: "none",
          },
          "& .MuiInputBase-root": {
            paddingTop: "11px",
          },
          "&:hover .MuiInputBase-root::before": {
            borderBottom: "none !important",
          },
          "& .MuiInputBase-root::after": {
            borderBottom: "none",
          },
          "& .MuiInputBase-input": {
            padding: "4px",
          },
          "& .MuiInputBase-input.MuiFilledInput-input": {
            padding: "6px 4px",
          },
          "& .MuiInputBase-input.MuiFilledInput-input.MuiInputBase-inputAdornedEnd":
            {
              paddingTop: "8px",
              paddingBottom: "4px",
            },
          "& .MuiFormLabel-root.MuiInputLabel-root": {
            transform: "translate(12px, 12px) scale(1)",
          },
          "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink": {
            transform: "translate(12px, 3px) scale(0.75)",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            background: "#FBFBFB",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-sizeSmall": {
            height: "auto",
          },
          variants: [
            {
              props: { size: "tiny" },
              style: {
                height: "auto",
                "& .MuiChip-label": {
                  fontSize: "0.75rem",
                  lineHeight: "1.5em",
                  //padding: "2px 6px",
                },
              },
            },
          ],
        },
        // label: {
        //   padding: "0",
        // },
      },
      variants: [
        {
          props: { variant: "tiny" },
          style: {
            height: "auto",
            "& .MuiChip-label": {
              fontSize: "0.75rem",
              padding: "",
            },
          },
        },
      ],
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          "& .MuiBadge-badge.MuiBadge-standard": {
            border: `2px solid ${theme.palette.background.paper}`,
            padding: "0 4px",
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          "& > .MuiPaper-root": {
            minWidth: "fit-content",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: "2px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: "6px 10px",
          margin: "4px 6px",
          borderRadius: "4px",
          transition: "background-color 0.2s ease-in-out",
        },
        hover: {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          lineHeight: "1.6em",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "6px 10px",
          margin: "4px 6px",
          borderRadius: "4px",
          transition: "background-color 0.2s ease-in-out",
        },
        hover: {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          alignSelf: "baseline",
          width: "100%",
          backgroundColor: "white",
          "&.MuiAccordion-root": {
            boxShadow: "none",
            margin: "0",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "16px",
          "&:hover": {
            "& #title": {
              textDecoration: "underline",
            },
          },
          backgroundColor: "white",
          "& .MuiAccordionSummary-content": {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            margin: "0",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0",
          },
        },
      },
    },
  },
});

import { Typography } from "@mui/material";
import { DemoDeviceButton } from "src/components/DeviceCreation/modal-list-items/DemoDeviceModalComponents/DemoDeviceButton";
import {
  ActiveDemoDeviceKeys,
  ActiveDemoDevices,
} from "src/components/DeviceCreation/modal-list-items/demo-devices";

export const DemoDevicePicker = ({
  onClick,
  disabled,
}: {
  onClick: (key: ActiveDemoDeviceKeys) => void;
  disabled?: boolean;
}) => {
  return (
    <div
      className={`flex flex-col gap-y-2 justify-center items-center w-full ${
        disabled ? "pointer-events-none" : ""
      }`}
    >
      <Typography variant="body1" className="self-start ml-2">
        Choose a device:
      </Typography>
      {Object.entries(ActiveDemoDevices).map(([key, demoDevice]) => (
        <DemoDeviceButton
          key={key}
          title={demoDevice.device.deviceConfig.name}
          description={demoDevice.device.deviceConfig.description}
          onClick={() => onClick(key as ActiveDemoDeviceKeys)}
          Icon={demoDevice.icon}
        />
      ))}
    </div>
  );
};

import { DocumentAnswer, DocumentVersion } from "@models";
import { TemplateElement, WizardTopBarLeftButton } from "@types";
import { WizardTopBar } from "./WizardTopBar";

export const MultiStepFormWizardTopBar = ({
  activeStep,
  steps,
  documentVersion,
  nextStep,
  prevStep,
  leftButton,
}: {
  activeStep: TemplateElement;
  steps: TemplateElement[];
  documentVersion: DocumentVersion;
  nextStep: () => void;
  prevStep: () => void;
  leftButton?: WizardTopBarLeftButton;
}) => {
  const isLastStep = activeStep.id === steps[steps.length - 1].id;
  const isFirstStep = activeStep.id === steps[0].id;

  return (
    <WizardTopBar
      forwardDisabled={
        isLastStep ||
        !documentVersion.answers.find(
          (a: DocumentAnswer) => a.element === activeStep.id
        )
      }
      backDisabled={isFirstStep}
      nextStep={nextStep}
      prevStep={prevStep}
      {...(leftButton ? { leftButton } : {})}
    />
  );
};

import { Device } from "@models";
import { ChevronRight, Delete } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fillRouteWithSlugs } from "@utils";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { DemoChip } from "src/components/DemoChip/DemoChip";
import { ROUTE_SLUGS } from "src/config/navigation/routes";
import { getDevicesQueryKey, ROUTES } from "../../config";
import { useStore } from "../../stores";

type Props = {
  devices: Device[];
};

const DeviceList = ({ devices }: Props) => {
  const { deviceStore } = useStore();
  const navigate = useNavigate();
  const { orgId = "" } = useParams();

  const queryClient = useQueryClient();
  const deleteDevice = useMutation({
    mutationFn: (id: string) => deviceStore.deleteDevice(orgId, id),
    onMutate: (id) => {
      queryClient.setQueryData(
        getDevicesQueryKey(orgId),
        (old: Device[] | undefined) => {
          return old?.filter((device) => device.id !== id);
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getDevicesQueryKey(orgId) });
    },
  });

  const confirmationToDelete = (id: string) => {
    // confirmation popup
    let confirmed = window.confirm(
      "Are you sure you want to delete this device? All related data will be lost."
    );
    {
      confirmed && deleteDevice.mutate(id);
    }
  };

  const handleContinue = async (device: Device) => {
    navigate(
      fillRouteWithSlugs(ROUTES.DEVICE_OVERVIEW, {
        [ROUTE_SLUGS.ORG_ID]: orgId,
        [ROUTE_SLUGS.DEVICE_ID]: device.id,
      })
    );
  };

  return (
    <div data-testid="DeviceList" className="flex flex-col gap-y-3">
      {devices.map((device) => {
        return (
          <div
            key={device.id}
            onClick={() => handleContinue(device)}
            className="flex w-full cursor-pointer items-center justify-between rounded border border-solid border-gray-200 bg-white p-5 hover:bg-zinc-50"
          >
            <div className="flex items-center gap-x-2">
              {device.isDemoDevice && <DemoChip size="tiny" />}
              <Typography variant="body1">{device.name}</Typography>
            </div>
            <div className="flex items-center gap-x-2">
              <IconButton>
                <ChevronRight />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  confirmationToDelete(device.id);
                }}
              >
                <Delete />
              </IconButton>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default observer(DeviceList);

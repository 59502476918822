import { SvgIconComponent } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

/**
 * According with a bubble style
 */
export const BubbleAccordion = ({
  children,
  expandedId,
  title,
  subtitle,
  Icon,
  id,
  handleChange,
}: {
  children: React.ReactNode;
  expandedId: number | false;
  title: string;
  subtitle: string;
  Icon: SvgIconComponent;
  id: number;
  handleChange: (
    id: number
  ) => (_: React.SyntheticEvent, isExpanded: boolean) => void;
}) => {
  return (
    <Accordion
      expanded={expandedId === id}
      onChange={handleChange(id)}
      className={`shadow-none rounded-md my-2 bg-gray-100`}
      disableGutters
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${id}bh-content`}
        id={`panel${id}bh-header`}
        sx={{
          backgroundColor: "rgb(243 244 246 / var(--tw-bg-opacity, 1))",
          "& .MuiAccordionSummary-content": {
            flexDirection: "row",
            justifyContent: "normal",
          },
        }}
      >
        <Icon fontSize="small" className="text-gray-600" />
        <Typography
          component="span"
          variant="body1"
          className="text-gray-600 ml-2 font-bold"
        >
          {title}
        </Typography>
        <Typography
          component="span"
          variant="body2"
          className="text-gray-600 ml-2"
        >
          - {subtitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="bg-white rounded-md m-4 mt-0 p-4">
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

import { Organization, User } from "@models";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import OnboardingModal from "./OnboardingModal";
import UserInfo from "./UserInfo";

const Welcome = observer(
  ({
    user,
    org,
    closeModal,
  }: {
    user: User;
    org: Organization;
    closeModal: () => void;
  }) => {
    const [step, setStep] = useState<number>(0);
    const [zoom, setZoom] = useState<boolean>(true);

    const handleOnboardingGetStarted = () => {
      if (!user.firstName || !user.lastName) {
        setZoom(false);
        setTimeout(() => {
          setStep(1);
          setZoom(true);
        }, 200);
        return;
      }

      closeModal();
    };

    return (
      <div
        className={`transition-transform origin-center duration-500 
       ${zoom ? "scale-100" : "scale-0"}`}
      >
        {step === 0 && (
          <OnboardingModal
            handleOnboardingGetStarted={handleOnboardingGetStarted}
          />
        )}
        {step === 1 && (
          <UserInfo
            user={user}
            org={org}
            setZoom={setZoom}
            closeOnboarding={closeModal}
          />
        )}
      </div>
    );
  }
);

export default Welcome;

import { UserUiStateKey } from "@models";
import * as contentful from "contentful";
import { ChatboxPosition, Crisp } from "crisp-sdk-web";
import { makeAutoObservable } from "mobx";
import React from "react";
import { RootStoreType } from ".";

type ActiveUiStateItem = UserUiStateKey | undefined;

export class UIStore {
  rootStore;
  _contentfulClient: contentful.ContentfulClientApi<undefined>;
  activeUiStateItem: ActiveUiStateItem; // Tooltip/Modal lock that only allows 1 tooltip or onboarding (uiUserState) to be shown at a time.
  mainRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(rootStore: RootStoreType) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;

    this._contentfulClient = contentful.createClient({
      space: "vq9zrtptqmou",
      environment: "master", // defaults to 'master' if not set
      accessToken: "qAW1AmVqqdr3cdsCp3UWbpe1TEysmcDg_bBrOfuNGgs",
    });

    this.init();
  }

  init() {
    Crisp.configure("51e809ec-3334-4cf4-82c1-7ffb48f6d2b5", {
      locale: "en",
      autoload: false,
    });
    Crisp.setPosition(ChatboxPosition.Right);
  }

  setActiveUiStateItem(tooltip: ActiveUiStateItem) {
    this.activeUiStateItem = tooltip;
  }
}

import type { CreateDemoDeviceRequest } from '@types'
import moment from 'moment'

export const prefilledDocumentsConfig: CreateDemoDeviceRequest['documentsConfig'] = [
    
    {
      name: "quality-manual-policy-objectives",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "role-of-company",
            answer: "Manufacturer"
          },
          {
            element: "quality-policy",
            answer: "Our mission is to provide high-quality medical devices that ensure optimal performance and safety. We are committed to maintaining and continually improving the effectiveness of our Quality Management System, which complies with the requirements of ISO 13485:2016. We pledge to adhere to all relevant regulatory standards and laws of the European Union, ensuring our products meet the highest standards of safety and efficacy. This commitment extends to all aspects of our operations, from design and manufacturing to distribution and customer service."
          },
          {
            element: "quality-objectives",
            answer: `1. Maintain a product defect rate of less than 0.5% across all manufacturing batches.
2. Achieve a customer satisfaction rating of 95% or higher in post-purchase surveys.
3. Ensure 100% compliance with ISO 13485:2016 and all relevant EU regulations in all audits.
4. Continually improve the effectiveness of our Quality Management System, with a target of reducing non-conformities by 10% annually.
5. Ensure all reported product issues are addressed and resolved within 48 hours of report receipt.`
          }
        ],
        suggestions: [
       
        ]
      }
    },
    {
      name: "sop-document-record-control",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-design-control",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-human-resources-administration",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-software-problem-resolution",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "ticketing-system",
            answer: "Jira"
          }
        ],
        suggestions: []
      }
    },
    {
      name: "sop-integrated-software-development",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-risk-management",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-nonconformance",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-usability-engineering",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-deployment",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-software-validation",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-cybersecurity",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-statistical-methods",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-manufacturing",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-change-management",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-purchasing",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-sales",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "sales-system",
            answer: ""
          }
        ],
        suggestions: []
      }
    },
    {
      name: "list-of-qualified-suppliers",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "list-of-qualified-suppliers",
            answer: ""
          }
        ],
        suggestions: []
      }
    },
    {
      name: "supplier-checklist",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "supplier-company",
            answer: "Cognitive"
          },
          {
            element: "supplier-product",
            answer: "Cognitive"
          },
          {
            element: "supplier-quality-requirements",
            answer: `1. Server uptime should be 99.9%
2. Bugs during development should be less than 10%
3. Malfunctions should be less than 1%
4. Interruption in service should be less than 1%`
          },
          {
            element: "supplier-criticality-table",
            answer: `
| Criticality Criteria                                                                                                                           | Yes / No | Explanation / Commentary |
| ---------------------------------------------------------------------------------------------------------------------------------------------- | -------- | ------------------------ |
| (1.) Could the purchased goods or services have a **direct** impact on the **safety** of the organization's medical devices?                   | NO         |                          |
| (2.) Could the purchased goods or services have an **indirect** impact on the **safety** of the organization's medical devices?                | NO         |                          |
| (3.) Could the purchased goods or services have a **direct** impact on the **performance** of the organization's medical devices?              | NO         |                          |
| (4.) Could the purchased goods or services have an **indirect** impact on the **performance** of the organization's medical devices?           | NO         |                          |
| (5.) Could the purchased goods or services have a **direct** impact on the **regulatory compliance** of the organization's medical devices?    | NO         |                          |
| (6.) Could the purchased goods or services have an **indirect** impact on the **regulatory compliance** of the organization's medical devices? | NO         |                          |
| (7.) Is the organization not able to manufacture medical devices without the supplier's goods or services?                                     | NO         |                          |
| (8.) Is this the only available supplier for these goods or services?                                                                          | NO         |                          |
`
          },
          {
            element: "supplier-criticality",
            answer: "No"
          },
          {
            element: "supplier-evaluation",
            answer: `| Evaluation Criteria            | Score | Explanation / Commentary |
| ------------------------------ | ----- | ------------------------ |
| Quality of Products/Services   | 2     |                          |
| Timeliness / Punctuality       | 2     |                          |
| Cooperation / Customer Service | 2     |                          |
| Payment Terms                  | 2     |                          |`
          },
          {
            element: "supplier-additional",
            answer: ""
          },
          {
            element: "supplier-evaluation-score",
            answer: "Average score 1 - 2: supplier can be approved, provided that surveillance measures are in place."
          },
          {
            element: "supplier-final",
            answer: `
| Final Evaluation Result                                                | Yes / No | Explanation / Commentary |
| ---------------------------------------------------------------------- | -------- | ------------------------ |
| Does the supplier comply with the organization's quality requirements? |    Yes      |                          |`
          },
          {
            element: "supplier-evaluator",
            answer: "John Doe"
          },
          {
            element: "supplier-surveillance",
            answer: `
| Surveillance Measures                                                  | Yes / No | Explanation / Commentary |
| ---------------------------------------------------------------------- | -------- | ------------------------ |
| Is the supplier required to provide proof of certification?            |    Yes      |                          |
| Is the supplier required to enter a quality assurance agreement (QAA)? |    Yes      |                          |
| Is the supplier required to agree to a supplier audit program?         |    Yes      |                          |
| Are any other supplier surveillance measures required?                 |    Yes      |                          |`
          }
        ],
        suggestions: []
      }
    },
    {
      name: "list-of-employee-training",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "employee-information",
            answer: `| Employee Name        | Start Date | Functional Group | Manager / Supervisor |
            | -------------------- | ---------- | ----------------- | --------------------- |
            |  John Doe             | 2024-01-01 | Quality           | Jane Doe              |`
          },
          {
            element: "employee-training",
            answer: `| Training Name | Initial / Continuous Training | Training Delivery | Due Date | Date Completed | Summary of Learnings | Initials |
            | ------------- | ----------------------------- | ----------------- | -------- | -------------- | -------------------- | -------- |
            |   Workplace Safety            |                               |                   |          |                |                      |          |`
          }
        ],
        suggestions: [
         
        ]
      }
    },
    {
      name: "group-training-record",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "group-training-information",
            answer: `| Training Information | Details |
      | -------------------- | ------- |
      | Name of Training     |   Workplace Safety      |
      | Date of Training     |  2024-01-01      |
      | Training Method      |   In-person      |
      | Trainer              |   John Doe      |
      | Content              |   Workplace Safety      |`
          },
          {
            element: "group-participants",
            answer: `| No. | Name | Title | Completed |
      | --- | ---- | --------- | --------- |
      | 1   | John Doe | Software Engineer | Yes |
      | 2   | Jane Doe | Quality Engineer | Yes |`
          },
          {
            element: "group-training-review",
            answer: `| Action | Name | Team / Role | Date | Initials |
      | ------ | ---- | ----------- | ---- | -------- |
      | Review | John Doe | Quality Team | 2024-01-01 | JD |`
          },
          {
            element: "group-actions",
            answer: "No additional actions required."
          }
        ],
        suggestions: []
      }
    },
    {
      name: "required-documentation-training-by-functional-group",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-management-review",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "management-team",
            answer: `| Position | Name |
      | -------- | ---- |
      | CEO      | John Doe |`
          }
        ],
        suggestions: []
      }
    },
    {
      name: "list-of-regulatory-requirements",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "regulations",
            answer: `| Regulation                                | Applicable Devices | Description                                                                                                                       | Jurisdiction | Review Cycle | Last Review     | Links                                                   |
| ----------------------------------------- | ------------------ | --------------------------------------------------------------------------------------------------------------------------------- | ------------ | ------------ | --------------- | ------------------------------------------------------- |
| EU Medical Device Regulations 2017/745    | All                | Regulations required for CE marking of medical devices in the EU.                                                                 | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://eur-lex.europa.eu/eli/reg/2017/745/oj |
| (GDPR) General Data Protection Regulation | All                | Regulates the protection of natural persons with regard to the processing of personal data and on the free movement of such data. | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://eur-lex.europa.eu/eli/reg/2016/679/oj |`
          },
          {
            element: "national-laws",
            answer: `| Regulation                        | Applicable Devices | Description                                                                                 | Jurisdiction | Review Cycle | Last Review                      | Links                                                  |
| --------------------------------- | ------------------ | ------------------------------------------------------------------------------------------- | ------------ | ------------ | -------------------------------- | ------------------------------------------------------ |
| (MPDG) German Medical Devices Law | All                | Law providing additional medical device requirements to medical products listed in Germany. | Germany      | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.gesetze-im-internet.de/mpdg/ |`
          },
          {
            element: "standards",
            answer: `| Regulation                          | Applicable Devices | Description                                                       | Jurisdiction | Review Cycle | Last Review     | Links                                          |
| ----------------------------------- | ------------------ | ----------------------------------------------------------------- | ------------ | ------------ | --------------- | ---------------------------------------------- |
| ISO 13485:2016 + AC:2018 + A11:2021 | All                | QM Systems                                                        | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iso.org/standard/59752.html |
| ISO 14971:2019                      | All                | Risk Management System                                            | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iso.org/standard/72704.html |
| IEC 62304:2006                      | All                | Software Lifecycle Processes                                      | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iec.ch/standard/38515.html  |
| ISO 62366-1:2015 + A1:2021          | All                | Usability Engineering                                             | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iso.org/standard/63179.html |
| ISO 14155:2020                      | All                | Clinical Investigations                                           | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iso.org/standard/71690.html |
| ISO 15223-1:2016                    | All                | Symbols to be used with medical device labels                     | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iso.org/standard/59752.html |
| ISO/TR 24971:2020                   | All                | Guidance on the application of ISO 14971                          | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iso.org/standard/72704.html |
| ISO 20417:2021                      | All                | Medical devices -- Information to be provided by the manufacturer | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://www.iso.org/standard/72704.html |`
          },
          {
            element: "guidance-documents",
            answer: `| Regulation               | Applicable Devices | Description                                                                                                                                                                                 | Jurisdiction | Review Cycle | Last Review     | Links                                                                                                                             |
| ------------------------ | ------------------ | ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- | ------------ | ------------ | --------------- | --------------------------------------------------------------------------------------------------------------------------------- |
| MDCG 2020-1              | All                | Guidance on clinical evaluation (MDR) / Performance evaluation (IVDR) of medical device software                                                                                            | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2020-5              | All                | Guidance on Clinical Evaluation – Equivalence                                                                                                                                               | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2019-11             | All                | Guidance on Qualification and Classification of Software                                                                                                                                    | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2019-16             | All                | Guidance on cybersecurity for medical devices                                                                                                                                               | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2020-7              | All                | Guidance on PMCF plan template                                                                                                                                                              | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2020-8              | All                | Guidance on significant changes to the intended purpose                                                                                                                                     | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2020-13             | All                | Guidance on clinical evaluation of medical devices                                                                                                                                          | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2022-12             | All                | Guidance on harmonised administrative practices and alternative technical solutions until Eudamed is fully functional (for Regulation (EU) 2017/746 on in vitro diagnostic medical devices) | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2019-7              | All                | Guidance on Article 15 of the Medical Device Regulation (MDR) and in vitro Diagnostic Medical Device Regulation (IVDR) regarding a ""person responsible for regulatory compliance""           | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2022-21             | All                | Guidance on Periodic Safety Update Reports (PSURs) according to Regulation (EU) 2017/745                                                                                                    | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en |
| MDCG 2018-1 rev.4 | All                | Guidance on basic UDI-DI and changes to UDI-DI                                                                                                                                              | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/system/files/2021-04/md_mdcg_2018-1_guidance_udi-di_en_0.pdf                                  |
| MDCG 2019-16 rev.1       | All                | Guidance on cybersecurity for medical devices                                                                                                                                               | EU           | Annual       | ${moment().format("YYYY-MM-DD")} | https://health.ec.europa.eu/document/download/b23b362f-8a56-434c-922a-5b3ca4d0a7a1_en?filename=md_cybersecurity_en.pdf    |`
          }
        ],
        suggestions: []
      }
    },
    {
      name: "sop-update-of-regulations",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-internal-audit",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "audit-plan",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "audit-information",
            answer: `| Audit Information |  Details   |\n| ----------------- | --- |\n| Audit Date        |     |\n| Audit Time        |     |\n| Audit Location    |     |`
          },
          {
            element: "auditor-information",
            answer: "| Auditor Information             |  Details   |\n| ------------------------------- | --- |\n| Lead Auditor Name               |     |\n| Lead Auditor Position & Company |     |\n| Auditor Name                    |     |\n| Auditor Position & Company      |     |"
          },
          {
            element: "audit-participants",
            answer: "| Name     | Position / Role |\n| -------- | --------------- |\n| Person 1 |                 |\n| Person 2 |                 |\n| Person 3 |                 |\n| (...)    | (...)           |"
          },
          {
            element: "audit-scope",
            answer: `
              | Audit Criterion                         | Covered in this Audit |
              | --------------------------------------- | --------------------- |
              | EN ISO 13485:2016                       |   Yes                  |
              | ISO 14971:2019                          |   Yes                  |
              | (EU) Medical Device Regulation 2017/745 |   Yes                  |
              | IEC 62304:2006                         |   Yes                  |
              | IEC 62366-1:2015                        |   Yes                  |
              | FDA Quality System Regulation (21 CFR 820) |   No                   |
              | ISO 14155:2020                          |   Yes                  |
              | ISO 27001:2013                          |   Yes                  |
              | ISO 9001:2015                           |   Yes                  |`
          },
          {
            element: "audit-activities",
            answer: `
              | Date | Time | Topic / Process / Requirement | Audit Criteria | Participants |
              | ---- | ---- | ----------------------------- | -------------- | ------------ |
              |   ${moment().format("YYYY-MM-DD")}   | 8:00 - 9:00 AM CEST | Documentation Management | EN ISO 13485:2016, para. 4.2 | John Doe |
              |   ${moment().format("YYYY-MM-DD")}   | 9:00 - 10:00 AM CEST | Documentation Management | EN ISO 13485:2016, para. 4.2 | Jane Doe |
              |   ${moment().format("YYYY-MM-DD")}   | 10:00 - 11:00 AM CEST | Documentation Management | EN ISO 13485:2016, para. 4.2 | John Doe |`
          }
        ],
        suggestions: []
      }
    },
    {
      name: "audit-program",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "audit-program",
            answer: `
| Audit ID                                                                                           | Audit #1 | Audit #2 | Audit #3 |
| -------------------------------------------------------------------------------------------------- | -------- | -------- | -------- |
| Date                                                                                               |          |          |          |
| Audit Plan ID                                                                                      |          |          |          |
| ISO 13485:2016, para. 4.1, 4.21: General QMS requirements                                          |          |          |          |
| ISO 13485:2016, para. 4.2.2, 5.3, 5.4: Quality manual and QMS planning                             |          |          |          |
| ISO 13485: 2016, para. 4.2.3: Medical device file                                                  |          |          |          |
| ISO 13485:2016, para. 4.2.4, 4.2.5: Control of documents and records                               |          |          |          |
| ISO 13485:2016, para. 5.1, 5.2, 5.3., 5.4, 5.5: Management responsibility                          |          |          |          |
| ISO 13485:2016, para. 5.6: Management review                                                       |          |          |          |
| ISO 13485:2016, para. 6.1, 6.3: Resource management                                                |          |          |          |
| ISO 13485:2016, para. 6.2: Human resources management                                              |          |          |          |
| ISO 13485:2016, para. 6.4: Work environment and contamination control                              | n/a      | n/a      | n/a      |
| ISO 13485:2016, para. 7.1: Planning product realization                                            |          |          |          |
| ISO 13485:2016, para. 7.2: Customer-related processes                                              |          |          |          |
| ISO 13485:2016, para. 7.3: Design and development                                                  |          |          |          |
| ISO 13485:2016, para. 7.4: Purchasing                                                              |          |          |          |
| ISO 13485:2016, para. 7.5: Production and service provision                                        |          |          |          |
| ISO 13485:2016, para. 7.6: Measuring equipment                                                     |          |          |          |
| ISO 13485:2016, para. 8.1, 8.2.1, 8.2.2: Feedback and complaints handling                          |          |          |          |
| ISO 13485:2016, para. 8.1, 8.2.3: Reporting to authorities                                         |          |          |          |
| ISO 13485:2016, para. 8.1, 8.2.4: Internal auditing                                                |          |          |          |
| ISO 13485:2016, para. 8.1, 8.2.5, 8.2.6: Measurement of products and processes                     |          |          |          |
| ISO 13485:2016, para. 8.3: Nonconforming products                                                  |          |          |          |
| ISO 13485:2016, para. 8.4: Analysis of data                                                        |          |          |          |
| ISO 13485:2016, para. 8.5: Improvement                                                             |          |          |          |
| Reg. (EU) 2017/745, Chapter VII, Art. 83-86: Post-Market Surveillance                              |          |          |          |
| Reg. (EU) 2017/745, Chapter VII, Art. 87-90: Vigilance                                             |          |          |          |
| Reg. (EU) 2017/745, Chapter 61 & Annex XIV: Clinical Evaluation and Post-Market Clinical Follow-up |          |          |          |`
          }
        ],
        suggestions: []
      }
    },
    {
      name: "audit-report",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "audit-report-information",
            answer: ""
          },
          {
            element: "audit-report-participants",
            answer: ""
          },
          {
            element: "audit-results",
            answer: ""
          },
          {
            element: "major-nonconformities",
            answer: ""
          },
          {
            element: "minor-nonconformities",
            answer: ""
          },
          {
            element: "audit-recommendations",
            answer: ""
          },
          {
            element: "audit-conclusion",
            answer: ""
          }
        ],
        suggestions: [
          {
            element: "audit-conclusion",
            suggestion: ""
          }
        ]
      }
    },
    {
      name: "management-review-report",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "kpi-assessment",
            answer: ""
          },
          {
            element: "management-review-assessment",
            answer: ""
          },
          {
            element: "management-review-signatures",
            answer: ""
          }
        ],
        suggestions: [
          {
            element: "management-review-assessment",
            suggestion: ""
          }
        ]
      }
    },
    {
      name: "list-of-validated-software",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "validated-software-list",
            answer: ""
          }
        ],
        suggestions: []
      }
    },
    {
      name: "software-validation-form",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "validation-software-name",
            answer: ""
          },
          {
            element: "validation-software-version",
            answer: ""
          },
          {
            element: "validation-software-manufacturer",
            answer: ""
          },
          {
            element: "validation-software-link",
            answer: ""
          },
          {
            element: "validation-software-description",
            answer: ""
          },
          {
            element: "validation-qualification",
            answer: ""
          },
          {
            element: "validation-processes-replacing",
            answer: ""
          },
          {
            element: "validation-intended-use",
            answer: ""
          },
          {
            element: "validation-software-requirements",
            answer: ""
          },
          {
            element: "validation-risk-assessment",
            answer: ""
          },
          {
            element: "validation-risk-controls",
            answer: ""
          },
          {
            element: "validation-test-plan",
            answer: ""
          },
          {
            element: "validation-test-environment",
            answer: ""
          },
          {
            element: "validation-participants",
            answer: ""
          },
          {
            element: "validation-test-date",
            answer: ""
          },
          {
            element: "validation-test-results",
            answer: ""
          },
          {
            element: "validation-conclusion",
            answer: ""
          },
          {
            element: "validation-deployment",
            answer: ""
          },
          {
            element: "validation-monitoring-review",
            answer: ""
          },
          {
            element: "validation-history",
            answer: ""
          }
        ],
        suggestions: [
          {
            element: "validation-software-description",
            suggestion: ""
          },
          {
            element: "validation-processes-replacing",
            suggestion: ""
          },
          {
            element: "validation-intended-use",
            suggestion: ""
          },
          {
            element: "validation-software-requirements",
            suggestion: ""
          },
          {
            element: "validation-risk-assessment",
            suggestion: ""
          },
          {
            element: "validation-risk-controls",
            suggestion: ""
          },
          {
            element: "validation-test-plan",
            suggestion: ""
          },
          {
            element: "validation-test-environment",
            suggestion: ""
          },
          {
            element: "validation-test-results",
            suggestion: ""
          },
          {
            element: "validation-conclusion",
            suggestion: ""
          },
          {
            element: "validation-deployment",
            suggestion: ""
          },
          {
            element: "validation-monitoring-review",
            suggestion: ""
          }
        ]
      }
    },
    {
      name: "sop-feedback-and-complaint-management",
      version: {
        revisionSummary: "Initial version",
        answers: [
          {
            element: "customer-support",
            answer: ""
          }
        ],
        suggestions: []
      }
    },
    {
      name: "sop-vigilance",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-capa",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-post-market-surveillance",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-product-registration-and-certification",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    },
    {
      name: "sop-clinical-evaluation",
      version: {
        revisionSummary: "Initial version",
        answers: [],
        suggestions: []
      }
    }
  ]